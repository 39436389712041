import ActivityTypeSelect from '../ActivityTypeSelect';
import CreatableInputOnly from '../../../common/AtsSelect/CreatableInputOnly';
import AtsInputNumber from '../../../common/AtsInputNumber';
import AtsSelect from '../../../common/AtsSelect';
import JobFunctionsSkillsSelect from '../../../common/Categories/JobFunctionsSkillsSelect';
import AtsTypeAheadSearchSelect from '../../../common/AtsTypeAheadSearchSelect';
import AtsDatePicker from '../../../common/AtsDatePicker';
import searchConst from '../../../../constants/searchConst';
const includeAll = searchConst.searchOptions.includeAll;
const includeAny = searchConst.searchOptions.includeAny;
const exclude = searchConst.searchOptions.exclude;
const withValue = searchConst.searchOptions.withValue;
const withoutValue = searchConst.searchOptions.withoutValue;

const job = [
  {
    value: 'accountCoordinatorUserIds',
    label: 'Account Coordinator',
    searchOptions: [includeAny, exclude],
    dropdownOptionsLookupId: ['users', 'teams'],
    dropdownOptionsMapTo: ['accountCoordinatorUserIds', 'accountCoordinatorTeamIds'],
    searchOptionComponent: AtsSelect,
  },
  {
    value: 'accountManagerUserIds',
    label: 'Account Manager',
    searchOptions: [includeAny, exclude],
    dropdownOptionsLookupId: ['users', 'teams'],
    dropdownOptionsMapTo: ['accountManagerUserIds', 'accountManagerTeamIds'],
    searchOptionComponent: AtsSelect,
  },
  {
    value: 'activity',
    label: 'Activity',
    searchOptions: [searchConst.searchOptions.includeAnyActivity, searchConst.searchOptions.includeAllActivity, searchConst.searchOptions.excludeActivity, searchConst.searchOptions.activityDefaultValue],
    searchOptionComponent: ActivityTypeSelect
  },
  {
    value: 'assignedToUserIds',
    label: 'Assigned To',
    searchOptions: [includeAny, exclude],
    dropdownOptionsLookupId: ['users', 'teams'],
    dropdownOptionsMapTo: ['assignedToUserIds', 'assignedToTeamIds'],
    searchOptionComponent: AtsSelect,
  },
  {
    value: 'badgeIds',
    label: 'Badges',
    searchOptions: [includeAny, includeAll, exclude, withValue, withoutValue],
    dropdownOptionsLookupId: 'badges',
    searchOptionComponent: AtsSelect
  },
  {
    value: 'benefits',
    label: 'Benefits Information',
    searchOptions: [includeAny, exclude, withValue, withoutValue],
    searchOptionComponent: CreatableInputOnly,
    props: {
      placeholder: 'Type words for search and press enter...',
    }
  },
  {
    value: 'bullhornIds',
    label: 'Bullhorn ID',
    searchOptions: [includeAny, exclude, withValue, withoutValue],
    searchOptionComponent: CreatableInputOnly
  },
  {
    value: 'businessUnitIds',
    label: 'Business Units',
    searchOptions: [includeAny, includeAll],
    dropdownOptionsLookupId: 'businessUnits',
    searchOptionComponent: AtsSelect
  },
  {
    value: 'phcCareerCodeIds',
    label: 'Career Codes',
    searchOptions: [includeAny, includeAll, exclude],
    dropdownOptionsLookupId: 'phcCareerCodes',
    searchOptionComponent: AtsSelect
  },
  {
    value: 'closeReason',
    label: 'Close Reason',
    searchOptions: [includeAny, exclude],
    dropdownOptionsLookupId: 'jobCloseReasons',
    searchOptionComponent: AtsSelect
  },
  {
    value: 'companyIds',
    label: 'Client Company',
    searchOptions: [includeAny, exclude],
    searchOptionComponent: AtsTypeAheadSearchSelect,
    entityType: 'COMPANY',
    clearButton: false
  },
  {
    value: 'contactIds',
    label: 'Contact',
    searchOptions:[includeAny, exclude, withValue, withoutValue],
    searchOptionComponent: AtsTypeAheadSearchSelect,
    entityType: 'CONTACT',
    clearButton: false
  },
  {
    value: 'dateCreatedRange',
    label: 'Date Added',
    searchOptions: searchConst.searchOptions.date.concat([searchConst.searchOptions.inTheLast, searchConst.searchOptions.inTheLastCalendar, searchConst.searchOptions.inTheCurrentCalendar]),
    searchOptionComponent: AtsDatePicker,
  },
  {
    value: 'dateExpectedStartRange',
    label: 'Date Expected to Start',
    searchOptions: searchConst.searchOptions.date.concat(
      [ searchConst.searchOptions.inTheLast, searchConst.searchOptions.inTheNext,
        searchConst.searchOptions.inTheLastCalendar, searchConst.searchOptions.inTheCurrentCalendar, searchConst.searchOptions.inTheNextCalendar
      ]
    ).concat([withValue, withoutValue]),
    searchOptionComponent: AtsDatePicker,
    dateOnly: true
  },
  {
    value: 'dateExpectedEndRange',
    label: 'Date Expected to End',
    searchOptions: searchConst.searchOptions.date.concat(
      [ searchConst.searchOptions.inTheLast, searchConst.searchOptions.inTheNext,
        searchConst.searchOptions.inTheLastCalendar, searchConst.searchOptions.inTheCurrentCalendar, searchConst.searchOptions.inTheNextCalendar
      ]
    ).concat([withValue, withoutValue]),
    searchOptionComponent: AtsDatePicker,
    dateOnly: true
  },
  {
    value: 'dateUpdatedRange',
    label: 'Date Last Updated',
    searchOptions: searchConst.searchOptions.date.concat([searchConst.searchOptions.inTheLast, searchConst.searchOptions.inTheLastCalendar, searchConst.searchOptions.inTheCurrentCalendar]),
    searchOptionComponent: AtsDatePicker
  },
  {
    value: 'dateClosedRange',
    label: 'Date Closed',
    searchOptions: searchConst.searchOptions.date.concat([searchConst.searchOptions.inTheLast, searchConst.searchOptions.inTheLastCalendar, searchConst.searchOptions.inTheCurrentCalendar]),
    searchOptionComponent: AtsDatePicker,
  },
  {
    value: 'degreeRequirements',
    label: 'Degree Requirements',
    searchOptions: [includeAny, exclude, withValue, withoutValue],
    searchOptionComponent: CreatableInputOnly,
    props: {
      placeholder: 'Type any degree and press enter...',
    }
  },
  {
    value: 'departmentIds',
    label: 'Departments',
    searchOptions: [includeAny, includeAll, exclude],
    dropdownOptionsLookupId: 'departments',
    searchOptionComponent: AtsSelect
  },
  {
    value: 'description',
    label: 'Description',
    searchOptions: [includeAny, exclude, withValue, withoutValue],
    searchOptionComponent: CreatableInputOnly,
    props: {
      placeholder: 'Type words for search and press enter...',
    }
  },
  {
    value: 'supplementalDocumentsKeywords',
    label: 'Documents',
    searchOptions: [includeAny, includeAll, exclude, withValue, withoutValue],
    searchOptionComponent: CreatableInputOnly
  },
  {
    value: 'experienceLevel',
    label: 'Experience Level',
    searchOptions: [includeAny, exclude, withValue, withoutValue],
    dropdownOptionsLookupId: 'jobExperienceLevels',
    searchOptionComponent: AtsSelect
  },
  {
    value: 'phcFacilityTypeIds',
    label: 'Facility Types',
    searchOptions: [includeAny, includeAll, exclude],
    dropdownOptionsLookupId: 'phcFacilityTypes',
    searchOptionComponent: AtsSelect
  },
  {
    value: 'highlightedSkills',
    label: 'Highlighted Skills',
    searchOptions: [includeAny, includeAll, exclude, withValue, withoutValue],
    searchOptionComponent: CreatableInputOnly,
    props: {
      placeholder: 'Type any skill and press enter...',
    }
  },
  {
    value: 'numHoursPerWeek',
    label: 'Hours per Week',
    searchOptions: searchConst.searchOptions.number.concat([withValue, withoutValue]),
    searchOptionComponent: AtsInputNumber
  },
  {
    value: 'industryIds',
    label: 'Industries',
    searchOptions: [includeAny, includeAll, exclude],
    dropdownOptionsLookupId: 'industries',
    searchOptionComponent: AtsSelect
  },
  {
    value: 'practiceAreaIds',
    label: 'Job Category',
    searchOptions: [includeAny, exclude, withValue, withoutValue],
    dropdownOptionsLookupId: 'practiceAreas',
    searchOptionComponent: AtsSelect
  },
  {
    value: 'jobFunctionIds',
    label: 'Job Functions',
    searchOptions: [includeAny, includeAll, exclude, withValue, withoutValue],
    dropdownOptionsLookupId: 'jobFunctions',
    searchOptionComponent: JobFunctionsSkillsSelect
  },
  {
    value: 'jobTitle',
    label: 'Job Title',
    searchOptions: [includeAny, exclude],
    searchOptionComponent: CreatableInputOnly,
    props: {
      placeholder: 'Type words for search and press enter...',
    }
  },
  {
    value: 'jobSource',
    label: 'Job Sources',
    searchOptions: [includeAny, exclude, withValue, withoutValue],
    dropdownOptionsLookupId: 'jobSources',
    searchOptionComponent: AtsSelect
  },
  {
    value: 'type',
    label: 'Job Type',
    searchOptions: [includeAny, exclude],
    dropdownOptionsLookupId: 'jobTypes',
    searchOptionComponent: AtsSelect,
  },
  {
    value: 'listIds',
    label: 'Lists',
    searchOptions: [includeAny, includeAll, exclude, withValue, withoutValue],
    searchOptionComponent: AtsTypeAheadSearchSelect,
    entityType: 'JOB',
    clearButton: false,
    maxLengthToApi: searchConst.minLength, // typeAhead min input length, send to api, then filter in the frontEnd
    delay: 0,
    searchOperation:'listsSearchMy',
    filterBy: ['name']
  },
  {
    value: 'minYearsExperience',
    label: 'Minimum Years of Experience',
    searchOptions: searchConst.searchOptions.number.concat([withValue, withoutValue]),
    searchOptionComponent: AtsInputNumber
  },
  {
    value: 'maxYearsExperience',
    label: 'Maximum Years of Experience',
    searchOptions: searchConst.searchOptions.number.concat([withValue, withoutValue]),
    searchOptionComponent: AtsInputNumber
  },
  {
    value: 'openStatus',
    label: 'Open Status',
    searchOptions: [includeAny, exclude],
    dropdownOptionsLookupId: 'jobOpenStatuses',
    searchOptionComponent: AtsSelect,
  },
  {
    value: 'overtimeType',
    label: 'Overtime Type',
    searchOptions: [includeAny, exclude],
    dropdownOptionsLookupId: 'jobOvertimeTypes',
    searchOptionComponent: AtsSelect,
  },
  {
    value: 'ownerUserIds',
    label: 'Owner',
    searchOptions: [includeAny, exclude],
    dropdownOptionsLookupId: ['users', 'teams'],
    dropdownOptionsMapTo: ['ownerUserIds', 'ownerTeamIds'],
    searchOptionComponent: AtsSelect,
  },
  {
    value: 'patientCareType',
    label: 'Patient Care',
    searchOptions: [includeAny, exclude],
    dropdownOptionsLookupId: 'jobPatientCareTypes',
    searchOptionComponent: AtsSelect
  },
  {
    value: 'numOpenPositions',
    label: 'Positions Available',
    searchOptions: searchConst.searchOptions.number,
    searchOptionComponent: AtsInputNumber
  },
  {
    value: 'priority',
    label: 'Priority',
    searchOptions: [includeAny, exclude],
    dropdownOptionsLookupId: 'jobPriorities',
    searchOptionComponent: AtsSelect
  },
  {
    value: 'baseSalary',
    label: 'Rate - Base Salary',
    searchOptions: searchConst.searchOptions.number,
    searchOptionComponent: AtsInputNumber,
  },
  {
    value: 'billRate',
    label: 'Rate - Billing',
    searchOptions: searchConst.searchOptions.number,
    searchOptionComponent: AtsInputNumber,
  },
  {
    value: 'currencyType',
    label: 'Rate - Currency',
    searchOptions: [includeAny, exclude],
    dropdownOptionsLookupId: 'currencyTypes',
    searchOptionComponent: AtsSelect,
  },
  {
    value: 'grossProfit',
    label: 'Rate - Gross Profit',
    searchOptions: searchConst.searchOptions.number.concat([withValue, withoutValue]),
    searchOptionComponent: AtsInputNumber,
  },
  {
    value: 'markupRate',
    label: 'Rate - Markup',
    searchOptions: searchConst.searchOptions.number.concat([withValue, withoutValue]),
    searchOptionComponent: AtsInputNumber,
  },
  {
    value: 'payRate',
    label: 'Rate - Pay',
    searchOptions: searchConst.searchOptions.number.concat([withValue, withoutValue]),
    searchOptionComponent: AtsInputNumber,
  },
  {
    value: 'permRate',
    label: 'Rate - Perm Fee',
    searchOptions: searchConst.searchOptions.number.concat([withValue, withoutValue]),
    searchOptionComponent: AtsInputNumber,
  },
  {
    value: 'totalCompensation',
    label: 'Rate - Total Compensation',
    searchOptions: searchConst.searchOptions.number.concat([withValue, withoutValue]),
    searchOptionComponent: AtsInputNumber,
  },
  {
    value: 'rateType',
    label: 'Rate - Unit',
    searchOptions: [includeAny, exclude],
    dropdownOptionsLookupId: 'jobRateTypes',
    searchOptionComponent: AtsSelect,
  },
  {
    value: 'recruiterUserIds',
    label: 'Recruiter(s)',
    searchOptions: [includeAny, exclude],
    dropdownOptionsLookupId: ['users', 'teams'],
    dropdownOptionsMapTo: ['recruiterUserIds', 'recruiterTeamIds'],
    searchOptionComponent: AtsSelect,
  },
  {
    value: 'salespersonUserIds',
    label: 'Salesperson',
    searchOptions: [includeAny, exclude],
    dropdownOptionsLookupId: ['users', 'teams'],
    dropdownOptionsMapTo: ['salespersonUserIds', 'salespersonTeamIds'],
    searchOptionComponent: AtsSelect
  },
  {
    value: 'skillIds',
    label: 'Skills',
    searchOptions: [includeAny, includeAll, exclude, withValue, withoutValue],
    dropdownOptionsLookupId: 'skills',
    searchOptionComponent: JobFunctionsSkillsSelect
  },
  {
    value: 'jobSummary',
    label: 'Summary',
    searchOptions: [includeAny, exclude, withValue, withoutValue],
    searchOptionComponent: CreatableInputOnly,
    props: {
      placeholder: 'Type words for search and press enter...',
    }
  },
  {
    value: 'vmsJobId',
    label: 'VMS ID',
    searchOptions: [includeAny, exclude, withValue, withoutValue],
    searchOptionComponent: CreatableInputOnly,
    props: {
      placeholder: 'Type words for search and press enter...',
    }
  },
  {
    value: 'vmsName',
    label: 'VMS Name',
    searchOptions: [includeAny, exclude, withValue, withoutValue],
    searchOptionComponent: CreatableInputOnly,
    props: {
      placeholder: 'Type words for search and press enter...',
    }
  },
  {
    value: 'postingStatus',
    label: 'Website - Posting Status',
    searchOptions: [includeAny, exclude],
    dropdownOptionsLookupId: 'jobPostingStatuses',
    searchOptionComponent: AtsSelect,
  },
  {
    value: 'postedAt',
    label: 'Website - Posted At',
    searchOptions: [includeAny, exclude],
    dropdownOptionsLookupId: 'jobPostingDestinations',
    searchOptionComponent: AtsSelect,
  },
  {
    value: 'websiteRecruiterUserIds',
    label: 'Website Recruiter',
    searchOptions: [includeAny, exclude],
    dropdownOptionsLookupId: ['users', 'teams'],
    dropdownOptionsMapTo: ['websiteRecruiterUserIds', 'websiteRecruiterTeamIds'],
    searchOptionComponent: AtsSelect,
  }
];

export default job;
