const initSate = {};

export default function hotKeyReducer(state = initSate, action) {
  switch (action.type) {
  case 'ADD_HOT_KEY':
    return {
      ...state,
      [action.payload.id]: action.payload.handler,
    };
  case 'UPDATE_RECENT_NAV_ID':
    return {
      ...state,
      recentNavId: action.payload.recentNavId,
    };
  default:
    return state;
  }
}
