import moment from 'moment';
import {cloneDeep, compact, get, has, isEmpty, pick, uniq} from '../../../libs/lodash';
import utils from '../../../libs/utils';

function prepareForRenderAppointments(data) {
  const renderData = cloneDeep(data);
  try {
    renderData.startsAtWithTimeZone = cloneDeep(data.startsAt);
    renderData.endsAtWithTimeZone = cloneDeep(data.endsAt);
    renderData.date = moment.utc(data.startsAt).tz(data.timezone).format('MM/DD/YY');
    renderData.startsAt = moment.utc(renderData.startsAt).tz(renderData.timezone).format('hh:mm A');
    renderData.endsAt = moment.utc(renderData.endsAt).tz(renderData.timezone).format('hh:mm A');

    if (renderData.ownerUserId === undefined) {
      renderData.ownerUserId = renderData.owner.userId;
    }

    if (renderData.entity.type === 'CONTACT') {
      renderData.attendeesByEntity.contacts = renderData.attendeesByEntity.contacts.filter(contact => +(contact.id || contact.contactId) !== +renderData.entity.id);
    }

    if (!isEmpty(renderData.attendeesByEntity.userIds)) {
      renderData.attendeesByEntity.userIds = (renderData.attendeesByEntity.userIds || []).filter(id => id !== renderData.ownerUserId);
    } else if (!isEmpty(renderData.attendeesByEntity.users)) {
      renderData.attendeesByEntity.userIds = (renderData.attendeesByEntity.users || []).filter(user => user.id !== renderData.ownerUserId).map(user => user.id);
    }

    if (!renderData.activityTypeId) {
      renderData.activityTypeId = renderData.activityType.activityTypeId;
    }

    renderData.id = renderData.appointmentId;
    renderData.sendInviteToCandidates = !!data.sendInviteToCandidates;
    renderData.sendInviteToContacts = !!data.sendInviteToContacts;

  } catch (err) {
    console.log(err);
  }

  return renderData;
}

function prepareForApiAppointments(data) {
  const apiData = pick(cloneDeep(data), [
    'appointmentType',
    'subject',
    'activityTypeId',
    'timezone',
    'ownerUserId',
    'sendInviteToCandidates',
    'sendInviteToContacts',
    'isPrivate',
    'communicationMethod',
    'onlineMeetingUrl',
    'location',
    'isCanceled',
    'entity',
    'isDeleted'
  ]);

  apiData.attendeeIdsByEntity = {
    candidateIds: (get(data, 'attendeesByEntity.candidates') || []).map((c) => c.candidateId || c.id),
    contactIds: (get(data, 'attendeesByEntity.contacts') || []).map((c) => c.contactId || c.id),
    userIds: uniq(compact((get(data, 'attendeesByEntity.userIds') || []).concat(data.ownerUserId)))
  };

  apiData.entity = {id: apiData.entity.id, type: apiData.entity.type, name: apiData.entity.name };

  if(data.entity.type === 'CONTACT') {
    apiData.attendeeIdsByEntity.contactIds = uniq(compact((apiData.attendeeIdsByEntity.contactIds || []).concat([apiData.entity.id])));
  }

  // startsAt
  const startsTime = data.startsAt.split(/:| /);
  const dataOnly = moment(Date.parse(data.date)).tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format('YYYY-MM-DD');
  let startsHourOnly = startsTime[2] === 'AM' || ( startsTime[2] === 'PM' && parseInt(startsTime[0]) >= 12) ? startsTime[0] : 12 + parseInt(startsTime[0]);
  startsHourOnly = startsTime[2] === 'AM' && startsHourOnly === '12' ? '00' : startsHourOnly;
  const startsMinOnly = startsTime[1];
  apiData.startsAt = moment.tz(dataOnly + ' ' + startsHourOnly + ':' + startsMinOnly + ':00', data.timezone);

  // endsAt
  const endsTime = data.endsAt.split(/:| /);
  let endsHourOnly = endsTime[2] === 'AM' || ( endsTime[2] === 'PM' && parseInt(endsTime[0]) >= 12) ? endsTime[0] : 12 + parseInt(endsTime[0]);
  endsHourOnly = endsTime[2] === 'AM' && endsHourOnly === '12' ? '00' : endsHourOnly;
  const endsMinOnly = endsTime[1];
  apiData.endsAt = moment.tz(dataOnly + ' ' + endsHourOnly + ':' + endsMinOnly + ':00', data.timezone);
  if (apiData.endsAt < apiData.startsAt) {
    apiData.endsAt = moment.tz(moment(dataOnly).add(1, 'days').format('YYYY-MM-DD') + ' ' + endsHourOnly + ':' + endsMinOnly + ':00', data.timezone);
  }

  if (data.body) {
    apiData.body = encodeURIComponent(data.body);
  }

  if (!has(data, 'sendInviteToCandidates')) {
    apiData.sendInviteToCandidates = false;
  }

  if (!has(data, 'sendInviteToContacts')) {
    apiData.sendInviteToContacts = false;
  }

  if (has(data, 'notifyTargetIds') && Array.isArray(data.notifyTargetIds) && data.notifyTargetIds.length>0) {
    utils.splitIdsForApi(data.notifyTargetIds, apiData);
  }

  return apiData;
}

export {
  prepareForRenderAppointments,
  prepareForApiAppointments
};
