import './style.scss';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { AtsModal } from '../../common';
import SubmissionForm from './SubmissionForm';
import AtsRadioSelect from '../../common/AtsRadioSelect';

export default function SubmissionFormModal(props) {
  const [scopeOptions] = useState([
    { value: 'SINGLE_SUBMIT', label: 'Single Submit' },
    { value: 'MASS_SUBMIT', label: 'Mass Submit' },
  ]);
  const [scope, setScope] = useState();

  const handleChange = (value) => {
    setScope(value.scope);
  };

  const prepareModalTitle = () => {
    return (
      <div id='submission-form-header' className='row col-12'>
        <div id='submission-form-title' className='d-flex'>
          <div className='name'>Add New Submission</div>
          <AtsRadioSelect
            name='scope'
            defaultValue={scope}
            options={scopeOptions || []}
            handleChange={handleChange}
          />
        </div>
      </div>
    );
  };

  useEffect(() => {
    setScope(props.scope ? props.scope : scopeOptions[0].value);
  }, [props.scope, scopeOptions]);

  return (
    <>
      {props.modalVisible ?
        <AtsModal
          id='submission-form-modal'
          modalTitle={prepareModalTitle()}
          modalSize='lg'
          modalVisible={props.modalVisible}
          modalCancel={props.modalCancel}
        >
          <SubmissionForm {...props} scope={scope} />
        </AtsModal> : ''
      }
    </>
  );
}

SubmissionForm.propTypes = {
  modalVisible: PropTypes.bool.isRequired,
  modalCancel: PropTypes.func.isRequired
};
