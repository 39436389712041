import './style.scss';
import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {AtsModal, AtsSelect, AtsInput, Button, AtsEditor, AtsTaskDatePicker, AtsTypeAheadSelectAll, FormFields, AtsCheckBox} from '../../common';
import useForm from '../../../hooks/useForm';
import { prepareForRenderTasks, prepareForApiTask } from '../libs/utils';
import { priorityOptions, statusOptions, nextTaskIndicatorPages } from '../../../constants/taskConst';
import useLookup from '../../../hooks/useLookup';
import utils from '../../../libs/utils';
import {get, has, uniq} from '../../../libs/lodash';
import apiUserStatus from '../../../services/apiUserStatus';
import useNotifyUserIds from '../../../hooks/useNotifyUserIds';
import * as taskActions from '../../../actions/task';
import * as dealsActions from '../../../actions/dealsActions';
import ApiClient from '../../../services/ApiClient';

export default function EditModal({actionType='Add', modalVisible, modalCancel, formData, saveContent, defaultValue, saveCallback, relatedEntityType}) {
  const lookups = useLookup({names: ['users', 'teams']});
  const {userId = null} = apiUserStatus.get() || {};
  const page = window.location.pathname.split('/')[1];
  const entity = useSelector(state => state.taskReducer.entity);
  const dispatch = useDispatch();

  const prepareFormData = () => {
    return formData || defaultValue || utils.prepareDefaultTask();
  };

  const formSubmit = async() => {
    if (!isLoading) {
      try {
        let fn;
        const requestBody = prepareForApiTask(values);

        setIsLoading(!isLoading);
        if (actionType === 'Add') {
          if (!get(requestBody, 'dueDate')) {
            delete requestBody.dueDate;
          }
          fn = ApiClient.tasksCreate;
        } else {
          fn = ApiClient.tasksUpdate;
        }

        const res = await fn(values.id ? {id: values.id} : {}, requestBody);

        // only primary search and details page
        if (nextTaskIndicatorPages.includes(page)) {
          const matched = get(entity, 'type') === get(values, 'entity.type') && get(entity, 'id') === get(values, 'entity.id');

          if (values.taskId) {
            // update task
            const newContent = {...values, ...requestBody};
            if (matched && newContent.status !== 'DONE' && newContent.isPrivate !== true) {
              dispatch(taskActions.updateTask(newContent));
            } else {
              // related change to other entity or status changed to done
              dispatch(taskActions.removeTask(newContent.taskId));
            }
          } else if (matched && res.body.status !== 'DONE' && !res.body.isPrivate) {
            // new task
            dispatch(taskActions.addTask(res.body));
          }
        }

        if (saveContent && typeof saveContent === 'function') {
          saveContent(!values.id ? prepareForRenderTasks(res.body) : prepareForRenderTasks(values));
        } else if (saveCallback) {
          saveCallback();
        } else if (relatedEntityType === 'DEAL') {
          dispatch(dealsActions.getDeals({ companyId: requestBody.entity.id }));
        } else {
          window.amplitudeLogEvent(values.id ? 'edit' : 'create', { action: values.id ? 'save task' : 'save new task', object: 'task', timer : 'end' });
        }

        setIsLoading(!isLoading);
        modalCancel();
      } catch (error) {
        console.log('task submit error:', error);
        setIsLoading(false);
      }
    }
  };

  const formType = 'Task';
  const [isLoading, setIsLoading] = useState(false);
  const {values, formValidated, handleChange, handleSubmit} = useForm(prepareFormData(), formSubmit);
  const {notifyTargetIdsField} = useNotifyUserIds({lookups, handleChange, entity: values});

  const updateCallback = (updatedValues) => {
    if (has(updatedValues, 'assignedToUserId')) {
      const assignedTo = utils.convertIdToUser(lookups, 'users', updatedValues.assignedToUserId);
      updatedValues.assignedTo = assignedTo;
      if (updatedValues.assignedToUserId !== userId) {
        const notifyUserId = `notifyUserIds_${updatedValues.assignedToUserId}`;
        updatedValues.notifyTargetIds = values.notifyTargetIds ? uniq([...values.notifyTargetIds, notifyUserId]) : [notifyUserId];
      }
      handleChange(updatedValues);
    } else if (has(updatedValues, 'entity')) {
      const name = get(updatedValues, 'entity.primaryLink') || get(updatedValues, 'entity.name') || utils.shortName(updatedValues.entity) || get(updatedValues, 'entity.jobTitle') || null;
      const id = get(updatedValues, 'entity.entityId') || get(updatedValues, 'entity.id') || null;
      const type = get(updatedValues, 'entity.entityType') || get(updatedValues, 'entity.type') || null;
      const subject = values.subject || !id ? values.subject : `Follow up with ${name}`;
      if (actionType === 'Add' && !id) {
        handleChange({entity: null, subject});
      } else {
        handleChange({entity: {id, type, name}, subject});
      }
    } else if (has(updatedValues, 'isPrivate')) {
      handleChange({isPrivate: !values.isPrivate});
    } else {
      handleChange(updatedValues);
    }
  };

  return (
    <>
      {
        modalVisible ? (
          <AtsModal
            id='edit-modal'
            modalTitle={`${actionType} ${formType}`}
            modalSize='lg'
            modalVisible={modalVisible}
            modalCancel={modalCancel}
          >
            <form noValidate onSubmit={(e) => handleSubmit(e)} autoComplete='off'>

              <div className='form-row col-12 mx-0 px-0 pt-3'>
                <div className='form-group col-12'>
                  <label htmlFor='subject'>
                    Task Title
                    <span className='required'>*</span>
                  </label>
                  <AtsInput
                    id='subject'
                    name='subject'
                    required={true}
                    validated={formValidated}
                    defaultValue={values.subject || ''}
                    callback={handleChange}
                  />
                </div>
              </div>

              <div className='form-row col-12 mx-0 px-0 pt-3'>
                <div className='form-group col-6'>
                  <label htmlFor='assignedToUserId'>
                    Assigned To
                    <span className='required'>*</span>
                  </label>
                  <AtsSelect
                    key='assignedToUserId'
                    id='assignedToUserId'
                    name='assignedToUserId'
                    isClearable={false}
                    placeholder='Assigned To'
                    defaultValue={values.assignedToUserId || ''}
                    required={true}
                    options={lookups.users || []}
                    validated={formValidated}
                    callback={updateCallback}
                  />
                </div>
                <div className='form-group col-6 px-0'>
                  <AtsTypeAheadSelectAll
                    labelName='Related To'
                    id='entity'
                    fieldName='entity'
                    required={false}
                    entity={get(values, 'entity.id') && get(values, 'entity.type') ? get(values, 'entity') : null}
                    formValidated={formValidated}
                    handleChange={updateCallback}
                  />
                </div>
              </div>

              <div className='form-row col-12 mx-0 px-0 pt-3'>
                <div className='form-group col-6'>
                  <label htmlFor='dueDate'>
                    Due Date
                  </label>
                  <AtsTaskDatePicker
                    key='dueDate'
                    id='dueDate'
                    name='dueDate'
                    defaultValue={values.dueDate || ''}
                    position='from'
                    callback={handleChange}
                  />
                </div>
                <div className='form-group col-6'>
                  <label htmlFor='priority'>
                    Priority
                    <span className='required'>*</span>
                  </label>
                  <AtsSelect
                    key='priority'
                    id='priority'
                    name='priority'
                    isClearable={false}
                    placeholder='Select Priority'
                    defaultValue={values.priority || 'NORMAL'}
                    isMulti={false}
                    required={true}
                    options={priorityOptions || []}
                    validated={formValidated}
                    callback={handleChange}
                  />
                </div>
              </div>

              <div className='form-row col-12 mx-0 px-0 pt-3'>
                <div className='form-group col-6'>
                  <label htmlFor='status'>
                    Status
                    <span className='required'>*</span>
                  </label>
                  <AtsSelect
                    key='status'
                    id='status'
                    name='status'
                    isClearable={false}
                    placeholder='Select Status'
                    defaultValue={values.status || 'TO_DO'}
                    isMulti={false}
                    required={true}
                    options={statusOptions || []}
                    validated={formValidated}
                    callback={handleChange}
                  />
                </div>
                <div className='form-group col-6'>
                  <label htmlFor='isPrivate' id='task-private'>
                    Private Task
                    <AtsCheckBox
                      id='isPrivate'
                      name='isPrivate'
                      defaultValue={values.isPrivate?['CHECKED']:[]}
                      options={[{label: '', value: 'CHECKED'}]}
                      handleChange={updateCallback} />
                  </label>
                </div>
              </div>

              <div className='form-row col-12 mx-0 px-0 pt-3'>
                <div className='form-group col-12'>
                  <label htmlFor='description'>
                    Description
                  </label>
                  <AtsEditor
                    id='task-description'
                    name='description'
                    initialValue={values.description || ''}
                    required={false}
                    callback={handleChange}
                  />
                </div>
              </div>

              {
                get(values, 'entity.id') &&  get(values, 'entity.type') && actionType === 'Add' ?
                  <FormFields
                    fields={notifyTargetIdsField}
                    formValidated={formValidated}
                    callback={handleChange}
                  />
                  :''
              }

              <div className="d-flex justify-content-end pt-5">
                <Button type='button' variant='mb-4 btn-secondary mr-4' click={modalCancel}>Cancel</Button>
                <Button type='submit' variant='mb-4 btn-primary ats-md-btn'>Save</Button>
              </div>
            </form>
          </AtsModal>
        ) : (
          null
        )
      }
    </>

  );
}
