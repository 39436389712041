import actionTypes from '../constants/actionTypes';
import {get, has} from '../libs/lodash';

const initialState = {
  isFetched: false,
  mostRecentTimeStamp: '',
  allUnread: 0,
  hasReachedTargets: false,
  parameters: {
    offset: 0,
    maxResults: 20,
    entityType: null
  },
  data: {
    summary: {
      numResultsFound: 0,
      numResultsReturned: 0,
      numUnRead: 0,
    },
    notifications: []
  }
};

export default function notificationReducer(state = initialState, action) {
  switch (action.type) {
  case actionTypes.notificationActionTypes.SET_NOTIFICATIONS:
    return {
      ...state,
      data: {
        summary: action.payload.summary,
        notifications: action.payload.notifications ? action.payload.notifications : [],
      }
    };
  case actionTypes.notificationActionTypes.SET_NOTIFICATIONS_ALL_UNREAD:
    return {
      ...state,
      allUnread: action.payload
    };
  case actionTypes.notificationActionTypes.ADD_RECENT_NOTIFICATIONS:
    const unReadCount = action.payload.notifications.filter((notification) => {
      return notification.isRead === false;
    }).length;

    // filter recent notification for the current filter, then add to the list
    let unReadCountForCurrentFilter = unReadCount;
    let numResultsFoundRecent = get(action, 'payload.summary.numResultsFound');
    let numResultsReturnedRecent = get(action, 'payload.summary.numResultsReturned');

    const recentNotifications = (action.payload.notifications || []).filter((notification) => {
      const filterType = (notification.entity.type === state.parameters.entityType || !state.parameters.entityType);
      const filterRead = !has(state, 'parameters.isRead') ||
        (has(state, 'parameters.isRead') && get(state, 'parameters.isRead') === notification.isRead);
      const filterFlag = !has(state, 'parameters.isFlagged') ||
        (has(state, 'parameters.isFlagged') && get(state, 'parameters.isFlagged') === notification.isFlagged);
      const filterReply = !has(state, 'parameters.hasReplied') ||
        (has(state, 'parameters.hasReplied') && get(state, 'parameters.hasReplied') === notification.hasReplied);

      const addToCurNotificationList  = filterType && filterRead && filterFlag && filterReply;

      // if recent notification doesn't match current filter
      if (!addToCurNotificationList) {
        numResultsFoundRecent = numResultsFoundRecent - 1;
        numResultsReturnedRecent = numResultsReturnedRecent - 1;
      }


      if (!addToCurNotificationList && !notification.isRead) {
        unReadCountForCurrentFilter = unReadCountForCurrentFilter > 0 ? unReadCountForCurrentFilter - 1 : 0;
      }

      return addToCurNotificationList;
    });

    return {
      ...state,
      parameters: {
        ...state.parameters,
        offset: recentNotifications.length>0 ? state.parameters.offset + recentNotifications.length : state.parameters.offset,
      },
      allUnread: state.allUnread + unReadCount,
      data: {
        summary: {
          numResultsFound: numResultsFoundRecent > 0 ? state.data.summary.numResultsFound + numResultsFoundRecent : state.data.summary.numResultsFound,
          numResultsReturned: numResultsReturnedRecent > 0 ? state.data.summary.numResultsReturned + numResultsReturnedRecent : state.data.summary.numResultsReturned,
          numUnRead: get(state.data.summary, 'numUnRead', 0) + unReadCountForCurrentFilter,
        },
        notifications: action.payload.notifications ? [...recentNotifications, ...state.data.notifications] : state.data.notifications,
      }
    };
  case actionTypes.notificationActionTypes.UPDATE_NOTIFICATION:
    const fieldNames = Object.keys(action.payload.notification);
    let newNotifications = [...state.data.notifications];

    let adjustmentResultCount = 0;
    let adjustmentUnreadCount = 0;
    let isRead = false;
    // filter by flagged or not flagged,
    // then set/reset flag, the notification should be removed from list
    const toggleFlagAndflagFilter = fieldNames.includes('isFlagged') && has(state, 'parameters.isFlagged');

    // filter by not replied, or unread
    // reply will mark read and replied, so the notification should be removed from list.
    const replyAndNotRepliedFilter = fieldNames.includes('hasReplied') && (get(state, 'parameters.hasReplied') === false || get(state, 'parameters.isRead') === false);

    if (!fieldNames.includes('isDeleted') && !toggleFlagAndflagFilter && !replyAndNotRepliedFilter) {
      newNotifications.forEach((notification) => {
        if (notification.notificationId === action.payload.id) {
          for (const fieldName of fieldNames) {
            if (fieldName === 'isRead' && notification[fieldName] === false) {
              adjustmentUnreadCount = 1;
            }
            notification[fieldName] = action.payload.notification[fieldName];
          }
        }
      });
    } else {
      newNotifications = state.data.notifications.filter(function(notification) {
        if (notification.notificationId === action.payload.id) {
          isRead = notification.isRead;
        }
        return notification.notificationId !== action.payload.id;
      });

      adjustmentResultCount = 1;

      // set/reset flag will not cause unread count changed
      if ((fieldNames.includes('isDeleted') || fieldNames.includes('hasReplied')) && !isRead) {
        adjustmentUnreadCount = 1;
      }
    }

    return {
      ...state,
      parameters: {
        ...state.parameters,
        offset:  state.parameters.offset - adjustmentResultCount
      },
      allUnread: state.allUnread > 0 ? state.allUnread - adjustmentUnreadCount : 0,
      data: {
        notifications: newNotifications,
        summary: {
          numResultsFound: state.data.summary.numResultsFound > 0 ? state.data.summary.numResultsFound - adjustmentResultCount : state.data.summary.numResultsFound,
          numResultsReturned: state.data.summary.numResultsReturned > 0 ? state.data.summary.numResultsReturned - adjustmentResultCount : state.data.summary.numResultsReturned,
          numUnRead: get(state.data.summary, 'numUnRead', 0) > 0 ? get(state.data.summary, 'numUnRead', 0)-adjustmentUnreadCount : 0,
        },
      }
    };
  case actionTypes.notificationActionTypes.BULK_UPDATE_NOTIFICATIONS:
    let bulkUpdatedNotifications = [...state.data.notifications];
    let bulkUpdatedSummary = {...state.data.summary};
    let allUnread = state.allUnread;

    // no matter clear all/mark all read, any unread go to read or deleted
    allUnread = allUnread - bulkUpdatedSummary.numUnRead;

    if (get(action, 'payload.action') === 'DELETE') {
      bulkUpdatedSummary    = {...state.data.summary, numResultsFound: 0, numResultsReturned: 0, numUnRead: 0};
      bulkUpdatedNotifications = [];
    } else if (get(action, 'payload.action') === 'READ') {
      // filter by unread, should all gone after mark read
      if (state.parameters.isRead === false) {
        bulkUpdatedSummary = {...state.data.summary, numResultsFound: 0, numResultsReturned: 0, numUnRead: 0};
        bulkUpdatedNotifications = [];
      } else {
        // filter by others
        bulkUpdatedSummary = {...state.data.summary, numUnRead: 0};
        bulkUpdatedNotifications.forEach((notification) => {
          notification.isRead = true;
        });
      }
    }

    return {
      ...state,
      allUnread,
      data: {
        notifications: bulkUpdatedNotifications,
        summary: bulkUpdatedSummary,
      }
    };
  case actionTypes.notificationActionTypes.FETCH_NOTIFICATIONS_REQUEST:
    return {
      ...state,
      isFetched: true
    };
  case actionTypes.notificationActionTypes.FETCH_NOTIFICATIONS_SUCCESS:
    return {
      ...state,
      isFetched: false
    };
  case actionTypes.notificationActionTypes.FETCH_NOTIFICATIONS_FAIL:
    return {
      ...state,
      isFetched: false
    };
  case actionTypes.notificationActionTypes.SET_NOTIFICATIONS_PARAMS:
    return {
      ...state,
      parameters: action.payload
    };
  case actionTypes.notificationActionTypes.SET_NOTIFICATIONS_RECENT_TIMESTAMP:
    return {
      ...state,
      mostRecentTimeStamp: action.payload ? action.payload : state.mostRecentTimeStamp
    };
  default:
    return state;
  }
}
