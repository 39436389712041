export default (element) => {
  const addEditMode = (elementId) => {
    if (typeof elementId === 'string') {
      const element = document.getElementById(elementId);
      element && element.classList.add('edit-mode');
    } else {
      element && element.classList.add('edit-mode');
    }
  };

  const removeEditMode = (elementId) => {
    if (typeof elementId === 'string') {
      const element = document.getElementById(elementId);
      element && element.classList.remove('edit-mode');
    } else {
      element && element.classList.remove('edit-mode');
    }
  };

  return [
    addEditMode,
    removeEditMode
  ];
};
