import ActivityTypeSelect from '../ActivityTypeSelect';
import CreatableInputOnly from '../../../common/AtsSelect/CreatableInputOnly';
import AtsSelect from '../../../common/AtsSelect';
import AtsTypeAheadSearchSelect from '../../../common/AtsTypeAheadSearchSelect';
import AtsDatePicker from '../../../common/AtsDatePicker';
import AtsInputNumber from '../../../common/AtsInputNumber';

import searchConst from '../../../../constants/searchConst';
const includeAll = searchConst.searchOptions.includeAll;
const includeAny = searchConst.searchOptions.includeAny;
const exclude = searchConst.searchOptions.exclude;
const withValue = searchConst.searchOptions.withValue;
const withoutValue = searchConst.searchOptions.withoutValue;

const company = [
  {
    value: 'ownerUserIds',
    label: 'Account Manager',
    searchOptions: [includeAny, exclude],
    dropdownOptionsLookupId: ['users', 'teams'],
    dropdownOptionsMapTo: ['ownerUserIds', 'ownerTeamIds'],
    searchOptionComponent: AtsSelect,
  },
  {
    value: 'coOwnerUserIds',
    label: 'Co-Account Manager',
    searchOptions: [includeAny, exclude],
    dropdownOptionsLookupId: ['users', 'teams'],
    dropdownOptionsMapTo: ['coOwnerUserIds', 'coOwnerTeamIds'],
    searchOptionComponent: AtsSelect,
  },
  {
    value: 'activity',
    label: 'Activity',
    searchOptions: [searchConst.searchOptions.includeAnyActivity, searchConst.searchOptions.includeAllActivity, searchConst.searchOptions.excludeActivity, searchConst.searchOptions.activityDefaultValue],
    searchOptionComponent: ActivityTypeSelect
  },
  {
    value: 'badgeIds',
    label: 'Badges',
    searchOptions: [includeAny, includeAll, exclude, withValue, withoutValue],
    dropdownOptionsLookupId: 'badges',
    searchOptionComponent: AtsSelect
  },
  {
    value: 'bullhornIds',
    label: 'Bullhorn ID',
    searchOptions: [includeAny, exclude, withValue, withoutValue],
    searchOptionComponent: CreatableInputOnly
  },
  {
    value: 'businessUnitIds',
    label: 'Business Units',
    searchOptions: [includeAny, includeAll],
    dropdownOptionsLookupId: 'businessUnits',
    searchOptionComponent: AtsSelect
  },
  {
    value: 'childCompanyIds',
    label: 'Child Companies',
    searchOptions: [includeAny, exclude],
    searchOptionComponent: AtsTypeAheadSearchSelect,
    entityType: 'COMPANY',
    clearButton: false
  },
  {
    value: 'isClientMSAExecuted',
    label: 'Client MSA Executed',
    searchOptions: [includeAll],
    searchOptionComponent: AtsSelect,
    dropdownOptions: [{id: true, name: 'Yes'}, {id: false, name: 'No'}]
  },
  {
    value: 'name',
    label: 'Company Name',
    searchOptions: [includeAny, exclude],
    searchOptionComponent: CreatableInputOnly,
    props: {
      placeholder: 'Type words for search and press enter...',
    }
  },
  {
    value: 'companyType',
    label: 'Company Type',
    searchOptions: [includeAny, exclude, withValue, withoutValue],
    searchOptionComponent: AtsSelect,
    dropdownOptionsLookupId: 'companyTypes',
  },
  {
    value: 'contractEffectiveDate',
    label: 'Contract Effective Date',
    searchOptions: searchConst.searchOptions.date.concat(
      [ searchConst.searchOptions.inTheLast,
        searchConst.searchOptions.inTheNext,
        searchConst.searchOptions.inTheLastCalendar,
        searchConst.searchOptions.inTheCurrentCalendar,
        searchConst.searchOptions.inTheNextCalendar
      ]
    ),
    searchOptionComponent: AtsDatePicker,
    dateOnly: true
  },
  {
    value: 'contractExpirationDate',
    label: 'Contract Expiration Date',
    searchOptions: searchConst.searchOptions.date.concat(
      [ searchConst.searchOptions.inTheLast,
        searchConst.searchOptions.inTheNext,
        searchConst.searchOptions.inTheLastCalendar,
        searchConst.searchOptions.inTheCurrentCalendar,
        searchConst.searchOptions.inTheNextCalendar
      ]
    ),
    searchOptionComponent: AtsDatePicker,
    dateOnly: true
  },
  {
    value: 'contractExpirationDate',
    label: 'Contract Termination Date',
    searchOptions: searchConst.searchOptions.date.concat(
      [ searchConst.searchOptions.inTheLast,
        searchConst.searchOptions.inTheNext,
        searchConst.searchOptions.inTheLastCalendar,
        searchConst.searchOptions.inTheCurrentCalendar,
        searchConst.searchOptions.inTheNextCalendar
      ]
    ),
    searchOptionComponent: AtsDatePicker,
    dateOnly: true
  },
  {
    value: 'dateCreatedRange',
    label: 'Date Added',
    searchOptions: searchConst.searchOptions.date.concat([searchConst.searchOptions.inTheLast, searchConst.searchOptions.inTheLastCalendar, searchConst.searchOptions.inTheCurrentCalendar]),
    searchOptionComponent: AtsDatePicker
  },
  {
    value: 'dateUpdatedRange',
    label: 'Date Last Updated',
    searchOptions: searchConst.searchOptions.date.concat([searchConst.searchOptions.inTheLast, searchConst.searchOptions.inTheLastCalendar, searchConst.searchOptions.inTheCurrentCalendar]),
    searchOptionComponent: AtsDatePicker
  },
  {
    value: 'supplementalDocumentsKeywords',
    label: 'Documents',
    searchOptions: [includeAny, includeAll, exclude, withValue, withoutValue],
    searchOptionComponent: CreatableInputOnly
  },
  {
    value: 'industryIds',
    label: 'Industries',
    searchOptions: [includeAny, includeAll, exclude, withValue, withoutValue],
    dropdownOptionsLookupId: 'industries',
    searchOptionComponent: AtsSelect
  },
  {
    value: 'jobType',
    label: 'Job Types',
    searchOptions: [includeAny, exclude, withValue, withoutValue],
    dropdownOptionsLookupId: 'companyJobTypes',
    searchOptionComponent: AtsSelect,
  },
  {
    value: 'listIds',
    label: 'Lists',
    searchOptions: [includeAny, includeAll, exclude, withValue, withoutValue],
    searchOptionComponent: AtsTypeAheadSearchSelect,
    entityType: 'COMPANY',
    clearButton: false,
    maxLengthToApi: searchConst.minLength, // typeAhead min input length, send to api, then filter in the frontEnd
    delay: 0,
    searchOperation:'listsSearchMy',
    filterBy: ['name']
  },
  {
    value: 'numEmployees',
    label: 'Number Of Employees',
    searchOptions: searchConst.searchOptions.number.concat([withValue, withoutValue]),
    searchOptionComponent: AtsInputNumber
  },
  {
    value: 'originatorUserId',
    label: 'Originator',
    searchOptions: [includeAny, exclude],
    dropdownOptionsLookupId: ['users', 'teams'],
    dropdownOptionsMapTo: ['originatorUserId'],
    searchOptionComponent: AtsSelect,
  },
  {
    value: 'ownershipType',
    label: 'Ownership Type',
    searchOptions: [includeAny, exclude, withValue, withoutValue],
    dropdownOptionsLookupId: 'companyOwnershipTypes',
    searchOptionComponent: AtsSelect,
  },
  {
    value: 'parentCompanyIds',
    label: 'Parent Company',
    searchOptions: [includeAny, exclude, withValue, withoutValue],
    searchOptionComponent: AtsTypeAheadSearchSelect,
    entityType: 'COMPANY',
    clearButton: false
  },
  {
    value: 'primaryContactIds',
    label: 'Primary Contact',
    searchOptions:[includeAny, exclude, withValue, withoutValue],
    searchOptionComponent: AtsTypeAheadSearchSelect,
    entityType: 'CONTACT',
    clearButton: false
  },
  {
    value: 'revenue',
    label: 'Revenue',
    searchOptions: searchConst.searchOptions.number.concat([withValue, withoutValue]),
    searchOptionComponent: AtsInputNumber
  },
  {
    value: 'salespersonUserId',
    label: 'Salesperson',
    searchOptions: [includeAny, exclude],
    dropdownOptionsLookupId: ['users', 'teams'],
    dropdownOptionsMapTo: ['salespersonUserId'],
    searchOptionComponent: AtsSelect,
  },
  {
    value: 'status',
    label: 'Status',
    searchOptions: [includeAny, exclude],
    dropdownOptionsLookupId: 'companyStatuses',
    searchOptionComponent: AtsSelect,
  },

  {
    value: 'summary',
    label: 'Summary',
    searchOptions: [includeAny, exclude, withValue, withoutValue],
    searchOptionComponent: CreatableInputOnly,
    props: {
      placeholder: 'Type words for search and press enter...',
    }
  },
  {
    value: 'targetPotentialType',
    label: 'Target Potential',
    searchOptions: [includeAny, exclude, withValue, withoutValue],
    dropdownOptionsLookupId: 'companyTargetPotentialTypes',
    searchOptionComponent: AtsSelect,
  },
  {
    value: 'vmsName',
    label: 'VMS Name',
    searchOptions: [includeAny, exclude, withValue, withoutValue],
    searchOptionComponent: CreatableInputOnly,
    props: {
      placeholder: 'Type words for search and press enter...',
    }
  },
  {
    value: 'websiteUrl',
    label: 'Website URL',
    searchOptions: [includeAny, exclude, withValue, withoutValue],
    searchOptionComponent: CreatableInputOnly,
    props: {
      placeholder: 'Type words for search and press enter...',
    }
  }
];

export default company;
