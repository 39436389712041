export const hotKeys = {
  TAB: 9,
  SPACE: 32,
  COMMA: 188,
  ENTER: 13,
  RIGHT_ARROW: 39,
  LEFT_ARROW: 37,
  ARROW_UP: 38,
  ARROW_DOWN: 40,
  KEY_N: 78,
  KEY_P: 80,
  KEY_Q: 81,
  KEY_E: 69,
  KEY_EQUAL: 187,
  KEY_A: 65,
  KEY_T: 84,
  KEY_R: 82,
  KEY_H: 72,
  KEY_S: 83,
  KEY_SPACE: 32
};

export const shortCuts = {
  '+ / =': 'Add New',
  'q': 'Quick Search',
  '← →': 'Previous/ Next Result (on search results screen)',
  'p': 'Add Prescreen Note (on details screen)',
  'n': 'Add Note (on details screen)',
  's': 'Add Submission (on details screen)',
  'e': 'Edit (on details screen)',
  'a': 'Search Activity (on details screen)',
  't': 'Toggle to next Tab (on details screen',
  'r': 'Refresh Launch App: Notifications',
  // 'r': 'Recent (last used recent entity slide-in, candidates if first time)',
  // 'Tab': '',
  'Space': 'Select all / Deselect all ( on search results screen)',
  'h': 'Open Shortcuts Help Menu',
};
