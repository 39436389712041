import actionTypes from '../constants/actionTypes';
import {get, omit, isEmpty} from '../libs/lodash';
import ApiClient from '../services/ApiClient';

export const updateAppointment = (appointment) => {
  return (dispatch)=>{
    dispatch({
      type: actionTypes.appointmentActionTypes.UPDATE_APPOINTMENT,
      payload: {appointment},
    });
  };
};

export const addAppointment = (appointment) => {
  return (dispatch)=>{
    dispatch({
      type: actionTypes.appointmentActionTypes.CREATE_APPOINTMENT,
      payload: {appointment}
    });
  };
};

export const removeAppointment = (appointmentId) => {
  return (dispatch)=>{
    dispatch({
      type: actionTypes.appointmentActionTypes.REMOVE_APPOINTMENT,
      payload: {appointmentId}
    });
  };
};

export const fetchAppointments = (parameters) => {
  const fromSubmission = get(parameters, 'criteria.entity.type') === 'SUBMISSION';
  const type = actionTypes.appointmentActionTypes[fromSubmission ? 'UPDATE_SUBMISSION_APPOINTMENTS' : 'UPDATE_APPOINTMENTS'];
  return async (dispatch) => {
    try {
      if (!parameters || isEmpty(get(parameters, 'criteria'))) {
        dispatch({
          type: type,
          payload: fromSubmission ? {submissionAppts: []} : {appointments: []}
        });

        return;
      }

      const res = await ApiClient.appointmentsSearch(omit(parameters, ['criteria']), parameters.criteria);

      dispatch({
        type: type,
        payload: fromSubmission ? {submissionAppts: res.body.appointments} : {appointments: res.body.appointments}
      });

    } catch (error) {
      console.log(error);
    }
  };
};

export const refreshAppointmentsList = (status) => {
  return (dispatch)=>{
    dispatch({
      type: actionTypes.appointmentActionTypes.REFRESH_APPOINTMENTS_LIST,
      payload: {refreshAppointmentsList: status}
    });
  };
};
