import React from 'react';
import PropTypes from 'prop-types';
import {compact} from '../../../../libs/lodash';

export default function Location({entity}) {
  const {isRemote = false} = entity;
  const location = entity.location ? entity.location : entity.address;
  const shortAddr = location ? compact([location.city, location.state]) : [];
  return (
    <>
      { shortAddr.length || isRemote ? <div className='pt-1 location'>{isRemote ? 'Remote' : shortAddr.join(', ')}</div>  : ''}
    </>
  );
}

Location.propTypes = {
  entity: PropTypes.object.isRequired
};
