import './style.scss';
import React from 'react';
import Fireworks from './Fireworks';
import {getHolidayTheme, updateTheme} from './libs/utils';

export default () => {
  const holidayTheme = getHolidayTheme();

  if (holidayTheme) {
    updateTheme(holidayTheme);
  }

  const googleSearch = (topic, e) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }

    if (topic) {
      const newUrl = `https://google.com/search?q=${topic}`;
      window.open(newUrl, '_blank');
    }
  };

  return (
    <>
      {
        holidayTheme && holidayTheme.themeImage ?
          <div id='holiday-image' onClick={(e) => googleSearch(holidayTheme.themeTopic, e)}>
            {
              holidayTheme.rainbowText ? <span className='rainbow-text'>{holidayTheme.rainbowText}</span> : ''
            }
          </div>
          : ''
      }
      {
        holidayTheme && holidayTheme.themeAnimation ? <Fireworks /> : ''
      }
    </>
  );
};

