const entityActionTypes =  {
  SEARCH_ENTITIES_REQUEST: 'SEARCH_ENTITIES_REQUEST',
  SEARCH_ENTITIES_SUCCESS: 'SEARCH_ENTITIES_SUCCESS',
  SEARCH_ENTITIES_FAIL: 'SEARCH_ENTITIES_FAIL',
  SET_ENTITIES: 'SET_ENTITIES',
  UPDATE_PARAMETERS: 'UPDATE_PARAMETERS',
  SET_ENTITY_ID: 'SET_ENTITY_ID',
  UPDATE_ENTITY: 'UPDATE_ENTITY',
  UPDATE_METADATA: 'UPDATE_METADATA',
  SET_SAVED_SEARCH: 'SET_SAVED_SEARCH'
};

const locationActionTypes = {
  SET_LOCATIONS: 'SET_LOCATIONS',
};

const activityActionTypes = {
  SET_ACTIVITIES: 'SET_ACTIVITIES',
  FETCH_ACTIVITIES_REQUEST: 'FETCH_ACTIVITIES_REQUEST',
  FETCH_ACTIVITIES_SUCCESS: 'FETCH_ACTIVITIES_SUCCESS',
  FETCH_ACTIVITIES_FAIL: 'FETCH_ACTIVITIES_FAIL',
  SET_ACTIVITIES_PARAMS: 'SET_ACTIVITIES_PARAMS',
  SET_PRESCREENS: 'SET_PRESCREENS',
  ADD_RECENT_ACTIVITIES: 'ADD_RECENT_ACTIVITIES',
  REFRESH_STATUS_SCREENED: 'REFRESH_STATUS_SCREENED',
  ADD_PRESCREEN: 'ADD_PRESCREEN'
};

const submissionActionTypes = {
  SET_SUBMISSIONS: 'SET_SUBMISSIONS',
  DROP_SUBMISSION: 'DROP_SUBMISSION',
  SET_ADDITIONAL_SUBMISSIONS: 'SET_ADDITIONAL_SUBMISSIONS'
};

const reportActionTypes = {
  ADD_REPORT: 'ADD_REPORT',
  UPDATE_REPORT: 'UPDATE_REPORT',
  REMOVE_REPORT: 'REMOVE_REPORT'
};

const commonActionTypes = {
  FETCH_REQUEST: 'FETCH_REQUEST',
  FETCH_SUCCESS: 'FETCH_SUCCESS',
  FETCH_FAIL: 'FETCH_FAIL',
  SET_PARAMS: 'SET_PARAMS',
};

const notificationActionTypes = {
  SET_NOTIFICATIONS: 'SET_NOTIFICATIONS',
  FETCH_NOTIFICATIONS_REQUEST: 'FETCH_NOTIFICATIONS_REQUEST',
  FETCH_NOTIFICATIONS_SUCCESS: 'FETCH_NOTIFICATIONS_SUCCESS',
  FETCH_NOTIFICATIONS_FAIL: 'FETCH_NOTIFICATIONS_FAIL',
  SET_NOTIFICATIONS_PARAMS: 'SET_NOTIFICATIONS_PARAMS',
  SET_NOTIFICATIONS_RECENT_TIMESTAMP: 'SET_NOTIFICATIONS_RECENT_TIMESTAMP',
  SET_NOTIFICATIONS_ALL_UNREAD: 'SET_NOTIFICATIONS_ALL_UNREAD',
  SET_NOTIFICATIONS_SUMMARY_UNREAD: 'SET_NOTIFICATIONS_SUMMARY_UNREAD',
  ADD_RECENT_NOTIFICATIONS: 'ADD_RECENT_NOTIFICATIONS',
  UPDATE_NOTIFICATION: 'UPDATE_NOTIFICATION',
  BULK_UPDATE_NOTIFICATIONS: 'BULK_UPDATE_NOTIFICATIONS',
};

const taskActionTypes = {
  CREATE_TASK: 'CREATE_TASK',
  UPDATE_TASK: 'UPDATE_TASK',
  REMOVE_TASK: 'REMOVE_TASK',
  UPDATE_TASKS: 'UPDATE_TASKS',
  UPDATE_RELATED_ENTITY: 'UPDATE_RELATED_ENTITY',
  TOGGLE_TASK_EDIT_MODAL: 'TOGGLE_TASK_EDIT_MODAL',
  TOGGLE_TASK_DETAIL_MODAL: 'TOGGLE_TASK_DETAIL_MODAL',
};

const appointmentActionTypes = {
  CREATE_APPOINTMENT: 'CREATE_APPOINTMENT',
  UPDATE_APPOINTMENT: 'UPDATE_APPOINTMENT',
  REMOVE_APPOINTMENT: 'REMOVE_APPOINTMENT',
  UPDATE_APPOINTMENTS: 'UPDATE_APPOINTMENTS',
  UPDATE_SUBMISSION_APPOINTMENTS: 'UPDATE_SUBMISSION_APPOINTMENTS',
  REFRESH_APPOINTMENTS_LIST: 'REFRESH_APPOINTMENTS_LIST',
};

const dealsActionTypes = {
  GET_DEALS: 'GET_DEALS',
  UPDATE_DEAL_KANBAN_FILTER_SETTINGS: 'UPDATE_DEAL_KANBAN_FILTER_SETTINGS',
  UPDATE_DEAL_SEARCH_BAR_INPUT: 'UPDATE_DEAL_SEARCH_BAR_INPUT',
  UPDATE_DEAL: 'UPDATE_DEAL',
  UPDATE_DEAL_KANBAN_SORT_BY: 'UPDATE_DEAL_KANBAN_SORT_BY',
  CREATE_DEAL: 'CREATE_DEAL',
  GET_DEAL_BY_ID: 'GET_DEAL_BY_ID',
  GET_DEAL_ATTACHMENTS: 'GET_DEAL_ATTACHMENTS',
  GET_DEAL_ATTACHMENT_DETAILS: 'GET_DEAL_ATTACHMENT_DETAILS',
  SET_DEALS: 'SET_DEALS',
  DROP_DEAL: 'DROP_DEAL',
  SET_ADDITIONAL_DEALS: 'SET_ADDITIONAL_DEALS'
};

const actionTypes = {
  commonActionTypes,
  locationActionTypes,
  activityActionTypes,
  entityActionTypes,
  submissionActionTypes,
  notificationActionTypes,
  reportActionTypes,
  taskActionTypes,
  appointmentActionTypes,
  dealsActionTypes
};

export default actionTypes;

