import React, {useState} from 'react';
import { Route, Switch} from 'react-router-dom';
import {RouteWithSubs, SideNav, Routes} from '../../compoments';
import {AtsConfirmModal, AtsErrorBoundary} from '../../compoments/common';
import useModal from '../../hooks/useModal';
import ErrorHandler from '../../services/errorHandler';
import apiUserStatus from '../../services/apiUserStatus';
import utils from '../../libs/utils';
import {get, has, set} from '../../libs/lodash';
import {retrieveOriginalPathname} from '../../services/pathHandler';
import Dropzone from '../../compoments/common/Dropzone';
import ApiClient from '../../services/ApiClient';
import useLookup from '../../hooks/useLookup';
import SuspenseWrap from '../../compoments/common/SuspenseWrap';

const Dashboard = React.lazy(() => import('../../compoments/Dashboard'));
const MyTargets = React.lazy(() => import('../../compoments/MyTargets'));
const Logout = React.lazy(() => import('../index'));
const NoMatch = React.lazy(() => import('../../compoments/common/AtsErrorIndicator/NoMatch'));

export default function Home({history}) {
  const user = apiUserStatus.get();
  const {userPrimaryBusinessUnitId = null, userId = null, settings = {} } = user || {};

  const openNewTab = false;
  const {modalVisible, toggleModal} = useModal();
  const [resume, setResume] = useState({});

  const lookups = useLookup({names: ['countries']});

  const handleChange = async (resume) => {
    const value = resume['drop-resume-anywhere'];
    setResume({resume: value});
    try {
      const response = await ApiClient.resumesParse({attachmentId: value.attachmentId});
      const launch = response.body.parsed.launch;
      const {contactInfo = {} } = launch;
      const candidateCountry = get(contactInfo, 'address.country');

      if (!candidateCountry) {
        set(contactInfo, 'address.country', settings.defaultAutofillCountry);
      } else {
        let matchedCountry = lookups.countries.find(country => country.name === candidateCountry || country.code === candidateCountry);
        set(contactInfo, 'address.country', matchedCountry.name);
      }

      let jobTitle = Array.isArray(launch.jobTitles) ? launch.jobTitles[0] : launch.jobTitles;
      jobTitle = utils.titleCase(jobTitle);
      let company =  Array.isArray(launch.companies) ? launch.companies[0] : launch.companies;
      company = utils.titleCase(company);

      const firstName = has(launch, 'contactInfo.name.firstName') ? get(launch, 'contactInfo.name.firstName') : '';
      const lastName = has(launch, 'contactInfo.name.lastName') ? get(launch, 'contactInfo.name.lastName') : '';

      const newCandidate = {
        resume: value,
        jobTitle: jobTitle,
        currentCompany: company,
        candidateName: contactInfo ? {firstName: utils.titleCase(firstName), lastName: utils.titleCase(lastName)} : {},
        additionalInfo: {
          primaryEmail: contactInfo ? contactInfo.email : null,
          mobilePhone: contactInfo && contactInfo.phone ? contactInfo.phone.replace(/\D/g, '') : null,
          address: contactInfo.address
        },
        recruiter: {
          userId: userId
        },
        businessUnitId: userPrimaryBusinessUnitId,
        status: 'UNSCREENED',
        expectedSalary: {},
        prescreenInfo: [],
      };

      setResume(newCandidate);
      toggleModal();
    } catch (error) {
      const errorMsg = ErrorHandler(error);
      if(errorMsg.indexOf('not supported') !== -1) {
        toggleModal();
      }
    }
  };

  const openNewCandidateTab = (resume) => {
    window.amplitudeLogEvent('create', {action: 'add candidate', object: 'candidate', globalNav: '', method: 'drop resume', timer: 'start' });

    const redirectPath = '/new-candidate';
    window['drop-resume-anywhere'] = resume;

    if (openNewTab) {
      const scheme = window.REACT_APP_SCHEME || 'https';
      const newCandidateUrl = `${scheme}://${window.location.host}${redirectPath}`;
      window.open(newCandidateUrl, '_blank');
    } else {
      history.push(redirectPath);
    }
  };

  const confirmAddCandidate = () => {
    toggleModal();
    openNewCandidateTab(resume);
  };

  const confirmModal =
    <AtsConfirmModal
      modalVisible={modalVisible}
      modalCancel={toggleModal}
      confirmCallback={confirmAddCandidate}>
      <div className='pb-5 font-size-18'>Create a new candidate with this resume?</div>
    </AtsConfirmModal>;

  const prepareHomePage = () => {
    const viewType = get(apiUserStatus.get(), 'settings.calendarSettings.viewType') || 'calendar';
    if (viewType === 'calendar') {
      return (
        <SuspenseWrap>
          <Dashboard history={history} />
        </SuspenseWrap>
      );
    }

    if (viewType === 'targets') {
      return (
        <SuspenseWrap>
          <MyTargets history={history} />
        </SuspenseWrap>
      );
    }
  };

  return (
    <>
      {retrieveOriginalPathname(history)}
      <Dropzone
        required={false}
        multiple={false}
        name='drop-resume-anywhere'
        entityType='CANDIDATE'
        dropType='drop-resume-anywhere'
        defaultValue={null}
        //acceptFileTypeText='(PDF, Word, HTML)'
        //accept={candidateConst.acceptFileType}
        callback={handleChange}>
        <SideNav history={history} />
        <div id='content' className='d-flex align-items-start'>
          <AtsErrorBoundary>
            <SuspenseWrap>
              <Switch>
                { Routes().map((route, i) => (
                  <RouteWithSubs key={i} {...route} />
                ))}
                <Route exact strict path='/' component={prepareHomePage} />
                <Route path='/sign-out' component={() => <Logout /> }/>
                <Route>
                  <NoMatch />
                </Route>
              </Switch>
            </SuspenseWrap>
          </AtsErrorBoundary>
        </div>
      </Dropzone>
      {confirmModal}
    </>
  );
};
