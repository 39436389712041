import React from 'react';
import PropTypes from 'prop-types';
import tinymce from 'tinymce/tinymce';
import Dropdown from 'react-bootstrap/Dropdown';

import './style.scss';

export default function AtsPersonalize(props) {
  const insertPersonalize = (e, pickedPersonalize) => {
    e.preventDefault();

    try {
      const editor = tinymce.EditorManager.get(props.id);
      let newAddPickedPersonalize = pickedPersonalize.replace(/</gi, '&lt;').replace(/>/gi, '&gt;');

      // get bookmark
      const atsTinyMce = window['atsTinyMce-' + props.id];
      if (!atsTinyMce.editor) {
        editor.setContent(editor.getContent() + newAddPickedPersonalize);
        return;
      }

      // focus on the rich text
      atsTinyMce.editor.execCommand('mceFocus', false, 'tinymce');

      // restore bookmark
      atsTinyMce.editor.selection.moveToBookmark(atsTinyMce.bm);

      try {
        // get new bookmark location
        // check if there are same
        // somehow tinymce trim trail blank space and new line
        const orgStart = atsTinyMce.bm.start;
        const newStart = editor.selection.getBookmark(2, true).start;

        if (orgStart.length === 1 && newStart.length === 1 && orgStart[0] === newStart[0] + 1) {
          newAddPickedPersonalize = '<br />' + newAddPickedPersonalize;      // new line removed, add new line back
        } else if (orgStart.length === 2 && newStart.length === 2 && orgStart[0] === newStart[0] + 1) {
          newAddPickedPersonalize = '&nbsp;' + newAddPickedPersonalize;     // space remove, add space back
        } else if (orgStart.length > newStart.length) {
          newAddPickedPersonalize = '<br /> &nbsp;' + newAddPickedPersonalize; // new line and space removed, add new line and space back
        }

        atsTinyMce.editor.execCommand('mceInsertContent', false, newAddPickedPersonalize);
      } catch (error) {
        console.log(error);
        atsTinyMce.editor.execCommand('mceInsertContent', false, newAddPickedPersonalize);
      }

      // move bookmark to the end of new insert
      atsTinyMce.bm = atsTinyMce.editor.selection.getBookmark(2, true);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Dropdown as='div' className={'atsDropdown'}>
      <Dropdown.Toggle as='div' className="select-personalize-icon">
        Personalize
      </Dropdown.Toggle>

      <Dropdown.Menu>
        {(props.personalizeVariables || []).map((p, pIndex) => {
          return <Dropdown.Item as='a' href="#/" key={`personalize-${pIndex}`} onClick={(e) => insertPersonalize(e, p.id)}>{p.name}</Dropdown.Item>;
        })}
      </Dropdown.Menu>
    </Dropdown>
  );
}

AtsPersonalize.propTypes = {
  id: PropTypes.string.isRequired,
  personalizeVariables: PropTypes.array.isRequired
};
