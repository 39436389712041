import React from 'react';
import apiUserStatus from '../services/apiUserStatus';
import {difference} from '../libs/lodash';
const CandidateForm = React.lazy(() => import('../compoments/Candidates/Form'));
const CandidateDetails = React.lazy(() => import('../compoments/Candidates/Details'));
const ContactForm = React.lazy(() => import('../compoments/Contacts/Form'));
const ContactDetails = React.lazy(() => import('../compoments/Contacts/Details'));
const PlacementForm = React.lazy(() => import('../compoments/Placements/Form'));
const PlacementDetails = React.lazy(() => import('../compoments/Placements/Details'));
const JobForm = React.lazy(() => import('../compoments/Jobs/Form'));
const JobDetails = React.lazy(() => import('../compoments/Jobs/Details'));
const CompanyForm = React.lazy(() => import('../compoments/Companies/Form'));
const CompanyDetails = React.lazy(() => import('../compoments/Companies/Details'));
const SearchList = React.lazy(() => import('../compoments/Search/SearchList'));
const SavedSearches = React.lazy(() => import('../compoments/Search/SavedSearches'));
const ReportList = React.lazy(() => import('../compoments/Reports/index'));
const EmbedContent = React.lazy(() => import('./common/EmbedContent'));
const Setting = React.lazy(() => import('./Setting'));
const Template = React.lazy(() => import('./Template'));
const List = React.lazy(() => import('./Lists'));
const Task = React.lazy(() => import('./Tasks'));
const SavedReports = React.lazy(() => import('./Reports/SavedReports'));
const Metrics = React.lazy(() => import('./Metrics' ));
const Targets = React.lazy(() => import('./Targets'));
const UserDetail = React.lazy(() => import('./User/UserDetail'));
const UserForm =  React.lazy(() => import('./User/UserForm'));
const UserSetting = React.lazy(() => import('./User/UserSetting'));
const ImportsCreate = React.lazy(() => import('./Imports/ImportsCreate'));
const ImportsUpdate = React.lazy(() => import('./Imports/ImportsUpdate'));
const ImportsValidate = React.lazy(() => import('./Imports/ImportsValidate'));
const ImportsSearch = React.lazy(() => import('./Imports/ImportsSearch'));
const UserAppForm = React.lazy(() => import('./User/App/Form'));
const UserAppDetails = React.lazy(() => import('./User/App/Details'));
const UserAppList = React.lazy(() => import('./User/App'));
const BulkEmailList = React.lazy(() => import('./BulkEmails/Search'));
const Dashboard = React.lazy(() => import('./Dashboard'));
const MyTargets = React.lazy(() => import('./MyTargets'));
const SearchSubmission = React.lazy(() => import('../compoments/Submissions/Search'));
const SearchDeal = React.lazy(() => import('../compoments/Deals/Search'));
const MassEmail = React.lazy(() => import('./BulkEmails/Details/MassEmail'));
const ErrorForbidden = React.lazy(() => import('./common/AtsErrorIndicator/ErrorForbidden'));

const Routes = function () {
  const componentMapping = (component, permissions) => {
    const {userPermissionGroupIds = [] } = apiUserStatus.get() || {};
    const allow = difference(userPermissionGroupIds, permissions).length < userPermissionGroupIds.length;
    return allow ? component : ErrorForbidden;
  };

  return [
    {
      path: '/new-candidate',
      component: componentMapping(CandidateForm, ['CREATE_EDIT_PRIMARY_OBJECTS']),
      title: 'Add New Candidate',
    },
    {
      path: '/edit-candidate/:id',
      component: componentMapping(CandidateForm, ['CREATE_EDIT_PRIMARY_OBJECTS']),
      title: 'Edit Candidate'
    },
    {
      path: '/candidate/:id',
      component: componentMapping(CandidateDetails, ['VIEW_PRIMARY_OBJECTS']),
      objType: 'Candidate',
      // title: 'View Candidate Details'
    },
    {
      path: '/candidates',
      component: componentMapping(SearchList, ['VIEW_PRIMARY_OBJECTS']),
      objType: 'Candidate',
      title: 'Search Candidates'
    },
    {
      path: '/new-job',
      component: componentMapping(JobForm, ['CREATE_EDIT_PRIMARY_OBJECTS']),
      title: 'Add New Job'
    },
    {
      path: '/edit-job/:id',
      component: componentMapping(JobForm, ['CREATE_EDIT_PRIMARY_OBJECTS']),
      title: 'Edit Job'
    },
    {
      path: '/job/:id',
      component: componentMapping(JobDetails, ['VIEW_PRIMARY_OBJECTS']),
      objType: 'Job',
      // title: 'View Job Details'
    },
    {
      path: '/jobs',
      component: componentMapping(SearchList, ['VIEW_PRIMARY_OBJECTS']),
      objType: 'Job',
      title: 'Search Jobs'
    },
    {
      path: '/new-contact',
      component: componentMapping(ContactForm, ['CREATE_EDIT_PRIMARY_OBJECTS']),
      title: 'Add New Contact',
    },
    {
      path: '/edit-contact/:id',
      component: componentMapping(ContactForm, ['CREATE_EDIT_PRIMARY_OBJECTS']),
      title: 'Edit Contact'
    },
    {
      path: '/contact/:id',
      component: componentMapping(ContactDetails, ['VIEW_PRIMARY_OBJECTS']),
      objType: 'Contact',
      // title: 'View Contact Details'
    },
    {
      path: '/contacts',
      component: componentMapping(SearchList, ['VIEW_PRIMARY_OBJECTS']),
      objType: 'Contact',
      title: 'Search Contacts'
    },
    {
      path: '/new-placement',
      component: componentMapping(PlacementForm, ['CREATE_EDIT_PRIMARY_OBJECTS']),
      title: 'Add New Placement',
    },
    {
      path: '/edit-placement/:id',
      component: componentMapping(PlacementForm, ['CREATE_EDIT_PRIMARY_OBJECTS']),
      title: 'Edit Placement'
    },
    {
      path: '/placement/:id',
      component: componentMapping(PlacementDetails, ['VIEW_PRIMARY_OBJECTS']),
      objType: 'Placement',
      // title: 'View Placement Details'
    },
    {
      path: '/placements',
      component: componentMapping(SearchList, ['VIEW_PRIMARY_OBJECTS']),
      objType: 'Placement',
      title: 'Search Placements'
    },
    {
      path: '/new-company',
      component: componentMapping(CompanyForm, ['CREATE_EDIT_PRIMARY_OBJECTS']),
      title: 'Add New Company'
    },
    {
      path: '/edit-company/:id',
      component: componentMapping(CompanyForm, ['CREATE_EDIT_PRIMARY_OBJECTS']),
      title: 'Edit Company'
    },
    {
      path: '/edit-company-operations/:id',
      component: componentMapping(CompanyForm, ['CREATE_EDIT_PRIMARY_OBJECTS']),
      title: 'Edit Company Operations',
      edit: 'operations'
    },
    {
      path: '/company/:id',
      component: componentMapping(CompanyDetails, ['VIEW_PRIMARY_OBJECTS']),
      objType: 'Company',
      // title: 'View Company Details'
    },
    {
      path: '/companies',
      component: componentMapping(SearchList, ['VIEW_PRIMARY_OBJECTS']),
      objType: 'Company',
      title: 'Search Companies'
    },
    {
      path: '/reports',
      component: componentMapping(ReportList, ['VIEW_REPORTS_FOR_PRIMARY_OBJECTS']),
      objType: 'Company',
      title: 'Report'
    },
    {
      path: '/templates/list',
      component: componentMapping(Template, ['VIEW_PRIMARY_OBJECTS']),
      objType: 'Templates',
      title: 'Templates'
    },
    {
      path: '/lists/list',
      component: componentMapping(List, ['VIEW_PRIMARY_OBJECTS']),
      objType: 'Lists',
      title: 'Lists'
    },
    {
      path: '/savedReports/list',
      component: componentMapping(SavedReports, ['VIEW_REPORTS_FOR_PRIMARY_OBJECTS']),
      objType: 'SavedReport',
      title: 'Saved Reports'
    },
    {
      path: '/savedSearches/list',
      component: componentMapping(SavedSearches, ['VIEW_PRIMARY_OBJECTS']),
      objType: 'SavedSearch',
      title: 'Saved Searches'
    },
    {
      path: '/tasks/list',
      component: componentMapping(Task, ['VIEW_PRIMARY_OBJECTS']),
      objType: 'Tasks',
      title: 'Tasks'
    },
    {
      path: '/businessunits/list',
      component: componentMapping(Setting, ['MANAGE_SYSTEM_CONFIGURATION']),
      objType: 'Businessunits',
      title: 'Business Units'
    },
    {
      path: '/practicearea/list',
      component: componentMapping(Setting, ['MANAGE_SYSTEM_CONFIGURATION']),
      objType: 'Practiceareas',
      title: 'Job Categories'
    },
    {
      path: '/teams/list',
      component: componentMapping(Setting, ['VIEW_USERS_TEAMS', 'MANAGE_SYSTEM_TEAMS']),
      objType: 'Teams',
      title: 'Teams'
    },
    {
      path: '/badges/list',
      component: componentMapping(Setting, ['MANAGE_SYSTEM_CONFIGURATION']),
      objType: 'Badges',
      title: 'Badges'
    },
    {
      path: '/sourceoptions/list',
      component: componentMapping(Setting, ['MANAGE_SYSTEM_CONFIGURATION']),
      objType: 'Sourceoptions',
      title: 'Source'
    },
    {
      path: '/metrics/list',
      component: componentMapping(Metrics, ['MANAGE_SYSTEM_CONFIGURATION']),
      objType: 'Metrics',
      title: 'Metrics'
    },
    {
      path: '/users/list',
      component: componentMapping(Setting, ['VIEW_USERS_TEAMS', 'MANAGE_SYSTEM_USERS']),
      objType: 'Users',
      title: 'Users'
    },
    {
      path: '/user/:id',
      component: componentMapping(UserDetail, ['VIEW_USERS_TEAMS', 'MANAGE_SYSTEM_USERS']),
      objType: 'User',
      title: 'View User Details'
    },
    {
      path: '/new-user',
      component:  componentMapping(UserForm, ['MANAGE_SYSTEM_USERS']),
      objType: 'User',
      title: 'New User'
    },
    {
      path: '/edit-user/:id',
      component: componentMapping(UserForm, ['MANAGE_SYSTEM_USERS']),
      objType: 'User',
      title: 'Edit User'
    },
    {
      path: '/appusers/list',
      component: componentMapping(UserAppList, ['MANAGE_EXTERNAL_APP_SETTINGS']),
      objType: 'Users',
      title: 'Application Users'
    },
    {
      path: '/appuser/:id',
      component: componentMapping(UserAppDetails, ['MANAGE_EXTERNAL_APP_SETTINGS']),
      objType: 'User',
      title: 'View Application User Details'
    },
    {
      path: '/new-appuser',
      component:  componentMapping(UserAppForm, ['MANAGE_EXTERNAL_APP_SETTINGS']),
      objType: 'User',
      title: 'New Application User'
    },
    {
      path: '/edit-appuser/:id',
      component: componentMapping(UserAppForm, ['MANAGE_EXTERNAL_APP_SETTINGS']),
      objType: 'User',
      title: 'Edit Application User'
    },
    {
      path: '/my-profile',
      component: UserDetail,
      objType: 'User',
      title: 'My Profile'
    }
    , {
      path: '/my-settings',
      component: UserSetting,
      objType: 'User',
      title: 'My Profile'
    },
    {
      path: '/dashboard',
      component: Dashboard,
      objType: 'User',
      title: 'Dashboard'
    },
    {
      path: '/my-targets',
      component: MyTargets,
      objType: 'User',
      title: 'My Targets'
    },
    {
      path: '/file/:id',
      component: EmbedContent,
      title: 'Supplemental Documents'
    },
    {
      path: '/new-import',
      component: componentMapping(ImportsCreate, ['IMPORT_PRIMARY_OBJECTS']),
      title: 'Mass Imports Create'
    },
    {
      path: '/imports/:id/update',
      component: componentMapping(ImportsUpdate, ['IMPORT_PRIMARY_OBJECTS']),
      title: 'Mass Imports Update'
    },
    {
      path: '/imports/:id/validate',
      component: componentMapping(ImportsValidate, ['IMPORT_PRIMARY_OBJECTS']),
      title: 'Mass Imports Validate'
    },
    {
      path: '/imports/:id/process',
      component: componentMapping(ImportsValidate, ['IMPORT_PRIMARY_OBJECTS']),
      title: 'Mass Imports Bulk Actions'
    },
    {
      path: '/imports/search',
      component: componentMapping(ImportsSearch, ['IMPORT_PRIMARY_OBJECTS']),
      title: 'Mass Imports Search List'
    },
    {
      path: '/bulkEmails/list',
      component: componentMapping(BulkEmailList, ['VIEW_BULK_EMAIL_RESULTS']),
      title: 'Manage Emails'
    },
    {
      path: '/bulkEmail/:id',
      component: componentMapping(MassEmail, ['VIEW_BULK_EMAIL_RESULTS']),
      title: 'Bulk Email'
    },
    {
      path: '/targets/list',
      component: componentMapping(Targets, ['VIEW_PRIMARY_OBJECTS', 'CREATE_EDIT_USER_TARGETS']),
      title: 'User Targets'
    },
    {
      path: '/submissions',
      component: SearchSubmission,
      title: 'Search Submissions',
      objType: 'Submission'
    },
    {
      path: '/deals',
      component: SearchDeal,
      title: 'Search Deals',
      objType: 'Deal'
    },
  ];
};

export default Routes;
