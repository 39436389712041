import {differenceBy, keyBy, isEmpty} from '../../../../libs/lodash';

function resetFiltersAndSelected () {
  try {
    // clean filter and checkboxes
    const clearAllObj = document.getElementById('clearAllFilter');
    const headerSelectedObj = document.querySelector('.ag-header-select-all .ag-checkbox-input');

    if (clearAllObj && !clearAllObj.classList.contains('d-none')) {
      clearAllObj.click();
    }

    if (headerSelectedObj && headerSelectedObj.checked) {
      headerSelectedObj.click();
    } else {
      const checkboxObjs = document.querySelectorAll('.ag-checkbox-input:checked');
      checkboxObjs.forEach((obj) => obj.click());
    }
  } catch (err) {
    console.log(err);
  }
}

function agColumnDefs(defaultColmnDefs, colSettings, resetColumns) {
  if (resetColumns || isEmpty(colSettings)) {
    return defaultColmnDefs;
  } else {
    const newColIds = differenceBy(defaultColmnDefs, colSettings, 'colId').map((c) => {
      c.hide = true;
      return c;
    });
    const defaultColsById = keyBy(defaultColmnDefs, 'colId');
    return colSettings.filter((c) => defaultColsById[c.colId]).map((c) => {
      const showCols = {...defaultColsById[c.colId], ...c,  hide: false};
      return showCols;
    }).concat(newColIds);
  }
}

export {
  resetFiltersAndSelected,
  agColumnDefs
};
