import {allEntityTypes} from '../../../constants/templateConst';
import utils from '../../../libs/utils';


const prepareForRenderTemplates = (data) => {
  const visibility = utils.prepareRecordVisibility(data);
  const objectTypes = data.entityTypes.length === allEntityTypes.length ? ['ALL'] : data.entityTypes;
  const usersAndTeamsInfo = utils.prepareUsersAndTeamsInfo(data);

  return {
    ...data,
    visibility,
    status: data.isActive ? 'Active' : 'Inactive',
    id: data.templateId,
    objectTypes,
    ...usersAndTeamsInfo
  };
};

export {
  prepareForRenderTemplates,
};
