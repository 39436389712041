const { statusOptions, acceptFileType, entityTypesOptions } = {
  statusOptions : [
    {id: 'Active', name: 'Active'},
    {id: 'Inactive', name: 'Inactive'}
  ],
  roles : [
    {id: 'SUPER_ADMIN', name: 'Super Admin'},
    {id: 'MANAGER_USER', name: 'Manager User'},
    {id: 'RECRUITER', name: 'Recruiter / Sales User'},
    {id: 'OPS_USER', name: 'Ops User'},
    {id: 'OPS_ADMIN_USER', name: 'Ops Admin User'},
  ],
  acceptFileType: 'image/gif, image/png, image/jpeg, image/jpg',
  entityTypesOptions: [
    {id: 'ALL', name: 'All'},
  ]
};

const isRecruiterOptions = [
  {value: true, label: 'Yes'},
  {value: false, label: 'No'}
];

const defaultUser = {
  email: '',
  firstName: '',
  lastName: '',
  preferredName: '',
  phoneNumber: '',
  isActive: true,
  status: 'Active',
  isRecruiter: true,
  teamIds: '',
  primaryBusinessUnitId: '',
  additionalBusinessUnitType: 'NONE',
  additionalBusinessUnitIds: [],
  permissionGroupIds: ['VIEW_PRIMARY_OBJECTS', 'CREATE_EDIT_PRIMARY_OBJECTS', 'VIEW_REPORTS_FOR_PRIMARY_OBJECTS', 'BULK_EMAILS_CANDIDATES', 'MERGE_PRIMARY_OBJECTS', 'MERGE_CANDIDATES', 'MERGE_CONTACTS', 'MERGE_JOBS'],
  settings : {
    timeZone : 'America/New_York'
  },
  exportLimit: 1000,
};

const globalEntityAbbreviations = {
  CANDIDATE: 'CAN',
  JOB: 'JOB',
  CONTACT: 'CNT',
  PLACEMENT: 'PLC',
  COMPANY: 'CMP',
  SUBMISSION: 'SUB',
  USER: 'USR',
};

const settingConst = {
  acceptFileType,
  entityTypesOptions,
  statusOptions,
  isRecruiterOptions,
  globalEntityAbbreviations,
  defaultUser
};

export default settingConst;
