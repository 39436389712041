import {cloneDeep, get, pick} from '../../../libs/lodash';
import utils from '../../../libs/utils';
import apiUserStatus from '../../../services/apiUserStatus';

function prepareForRenderDocs(data) {
  return {
    ...data,
    id: data.attachmentId,
    entityType: 'APPOINTMENT',
    name: data.name || get(data, 'metaInfo.originalName'),
    attachmentName: data.name || get(data, 'metaInfo.originalName'),
    attachmentType: data.attachmentType || 'GENERAL',
    scope: data.scope || 'PUBLIC',
  };
}

function prepareForApiDocs(data) {
  const apiData = pick(cloneDeep(data), [
    'name',
    'status',
    'effectiveDate',
    'expirationDate',
    'attachmentType',
    'attachmentAnnualFormSubType',
    'attachmentVisaDocumentationSubType',
    'scope',
  ]);

  if (get(apiData, 'effectiveDate')) {
    apiData.effectiveDate = utils.dateOnlyForApi(apiData.effectiveDate);
  }

  if (get(apiData, 'expirationDate')) {
    apiData.expirationDate = utils.dateOnlyForApi(apiData.expirationDate);
  }

  if (get(apiData, 'attachmentAnnualFormSubType')) {
    apiData.subType = apiData.attachmentAnnualFormSubType;
  }

  if (get(apiData, 'attachmentVisaDocumentationSubType')) {
    apiData.subType = apiData.attachmentVisaDocumentationSubType;
  }

  if (get(apiData, 'attachmentType') === 'ANNUAL_FORM' ) {
    apiData.attachmentVisaDocumentationSubType = null;
  }

  if (get(apiData, 'attachmentType') === 'VISA_DOCUMENTATION' ) {
    apiData.attachmentAnnualFormSubType = null;
  }

  if (!['ANNUAL_FORM', 'VISA_DOCUMENTATION'].includes(get(apiData, 'attachmentType'))) {
    apiData.attachmentAnnualFormSubType = null;
    apiData.attachmentVisaDocumentationSubType = null;
  }

  return apiData;
}

function checkPermissionDocs(attachment) {
  const { userPermissionGroupIds = [] } = apiUserStatus.get() || {};

  const entityType = get(attachment, 'entity.entityType', '').toLowerCase();
  return attachment.scope !== 'RESTRICTED' ||
    (entityType !== 'company' && userPermissionGroupIds.includes('VIEW_RESTRICTED_DOCUMENTS')) ||
    (entityType === 'company' && userPermissionGroupIds.includes('VIEW_COMPANY_RESTRICTED_DOCUMENTS'));
}

function bytesToSize(bytes) {
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  if (bytes === 0) {return '0 Byte';}
  let i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
  return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
}

export {
  prepareForRenderDocs,
  prepareForApiDocs,
  checkPermissionDocs,
  bytesToSize,
};
