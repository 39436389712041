const before = 'before';
const after = 'after';
const between = 'between';
const inTheLast = 'inTheLast';
const inTheNext = 'inTheNext';
const inTheLastCalendar = 'inTheLastCalendar';
const inTheCurrentCalendar = 'inTheCurrentCalendar';
const inTheNextCalendar = 'inTheNextCalendar';

const dateRangeChoices = [
  {id: before, name: 'Through'},
  {id: after, name: 'Since'},
  {id: between, name: 'Between'},
  {id: inTheLast, name: 'In Last Days'},
  {id: inTheNext, name: 'In Next Days'},
  {id: inTheLastCalendar, name: 'In Last Calendar'},
  {id: inTheCurrentCalendar, name: 'In Current Calendar'},
  {id: inTheNextCalendar, name: 'In Next Calendar'},
];

const dateRangeOptions = [
  {id: 1 * 24 * 60, name: '1 Day'},
  {id: 7 * 24 * 60, name: '7 Days'},
  {id: 30 * 24 * 60, name: '30 Days'},
  {id: 90 * 24 * 60, name: '90 Days'},
  {id: 'custom', name: 'Custom'}
];

const dateSearchConst = {
  before,
  after,
  between,
  inTheLast,
  inTheNext,
  inTheLastCalendar,
  inTheCurrentCalendar,
  inTheNextCalendar,
  dateRangeChoices,
  dateRangeOptions
};
export default dateSearchConst;
