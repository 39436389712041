import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';

import {AtsErrorBoundary } from '../common';
import Header from './Header';
import QuickSearch from './QuickSearch';
import Add from './Add';
import RecentActivity from './RecentActivity';
import MoreOptions from './MoreOptions';
import Notification from './Notification';

import sideDrawerConfig from '../../config/sideDrawerConfig';
import apiUserStatus from '../../services/apiUserStatus';

import './style.scss';
import {compact, has} from '../../libs/lodash';
import Lookups from '../../services/Lookups';

export default function SideDrawer({history, drawer, closeDrawer}) {
  const [currDrawer, setCurrDrawer] = useState(drawer);
  const lookups = Lookups.lookups;

  useEffect(() => {
    setCurrDrawer(drawer);
  }, [drawer]);

  const logout = () => {
    closeDrawer();
    window.amplitudeLogEvent('user logout');
    apiUserStatus.remove('user');
    window.location.href = '/login';
  };

  const openLinkAndLogEvent = (item) => {
    closeDrawer();
    if(item.eventType) {
      window.amplitudeLogEvent(item.eventType, item.eventProperties);
    }
  };

  const drawerContent = (activeDrawer, item, index)  => {
    if (item.disable) {
      return <div to={item.url} id={index} className='pl-1 ant-timeline-item-head-gray'>{item.text}</div>;
    }

    switch(item.url){
    case '/sign-out':
      return <Link to={item.url} id={index} className='pl-1' onClick={logout}>{item.text}</Link>;
    default:
      return <Link to={item.url} id={index} className='pl-1' onClick={() => openLinkAndLogEvent(item)}>{item.text}</Link>;
    }
  };

  const drawerCompoment = (currDrawer)  => {
    try {
      const {userPermissionGroupIds = []} = apiUserStatus.get() || {};
      switch (currDrawer.name) {
      case 'search':
        window.scrollTo(0, 0);
        return <QuickSearch drawer={currDrawer} closeDrawer={closeDrawer}/>;
      case 'notification':
        return <Notification drawer={currDrawer} closeDrawer={closeDrawer} history={history} lookups={lookups}/>;
      case 'candidate':
      case 'job':
      case 'contact':
      case 'company':
      case 'placement':
        return <RecentActivity history={history} drawer={currDrawer} closeDrawer={closeDrawer}/>;
      case 'new':
        return <Add drawer={currDrawer} closeDrawer={closeDrawer} history={history}/>;
      case 'more-option':
        return <MoreOptions history={history} drawer={currDrawer} closeDrawer={closeDrawer} setCurrDrawer={setCurrDrawer}/>;
      default:
        return (
          <div className='pb-4'>
            <div className='divider divider-horizontal divider-100 my-4'/>
            <Header drawer={currDrawer} closeDrawer={closeDrawer}/>
            <div className='drawer-content px-4'>
              {compact(sideDrawerConfig()[currDrawer.name]).map((item, index) => {
                return (
                  !has(item, 'permission') || (has(item, 'permission') && userPermissionGroupIds.includes(item.permission)) ?
                    <div className='flex col-auto pt-3 pl-5' key={`drawer-${currDrawer.name}-${index}`}>
                      {drawerContent(currDrawer.name, item, index)}
                    </div>
                    :
                    ''
                );
              })}
            </div>
          </div>
        );
      }
    } catch (err) {
      console.log(err);
      return '';
    }
  };

  return  (
    <>
      <div id='overlay' style={currDrawer.show ? {'display': 'block'} : {display: 'none'} } onClick={closeDrawer}/>
      <div id='side-drawer'>
        <div className='logoText mt-4'>
          Launch ATS
        </div>
        <AtsErrorBoundary>
          {drawerCompoment(currDrawer)}
        </AtsErrorBoundary>
      </div>
    </>
  );
}

SideDrawer.propTypes = {
  history: PropTypes.object.isRequired,
  drawer: PropTypes.object.isRequired,
  closeDrawer: PropTypes.func.isRequired
};
