import React from 'react';
import {isEmpty} from '../../../libs/lodash';

export default function NoResultsMsg(activeTab, isFetched, items) {

  function renderMsg() {
    if(!isFetched && isEmpty(items)){
      return <div className='pt-4 mr-2 col-auto font-size-14 biotif'>No records found</div>;
    } else {
      return '';
    }
  }

  return (
    <>
      {renderMsg()}
    </>
  );
}
