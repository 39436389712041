import {compact, difference, pick} from '../../../libs/lodash';

const prepareTeamsName = (teams) => {
  let teamsName = '';
  for (const team of teams) {
    if (teamsName.length > 0) {
      teamsName = `${teamsName}, ${team.name}`;
    } else {
      teamsName = team.name;
    }
  }
  return teamsName;
};

const prepareForRenderUsers = async (data, permissionLabels) => {
  // quick fix data inconsistency issue for teamIds
  if (data.teams && data.teams.length > 0) {
    data.teamIds = data.teams.map((team) => {
      return team.teamId;
    });
  } else {
    data.teamIds = [];
    data.teams = [];
  }

  if (data.permissionGroupIds && permissionLabels) {
    data.role = prepareRole(data.permissionGroupIds, permissionLabels).value || '';
  }

  return {
    ...data,
    registration: data.isRegistered ? 'Registered': 'Unregistered',
    status: data.isActive ? 'Active' : 'Inactive',
    id: data.userId,
    businessUnit: data.primaryBusinessUnit ? data.primaryBusinessUnit.name : '',
    primaryBusinessUnitId: data.primaryBusinessUnit ? data.primaryBusinessUnit.businessUnitId : '',
    teamsName: prepareTeamsName(data.teams),
    entityType: 'USER',
    entityId: data.userId,
  };
};

const prepareForApiUser = (data) => {
  data.isActive = data.status === 'Active';
  const fData = pick(data, [
    'email',
    'firstName',
    'lastName',
    'preferredName',
    'phoneNumber',
    'jobTitle',
    'linkedInProfileUrl',
    'isActive',
    'isRecruiter',
    'teamIds',
    'primaryBusinessUnitId',
    'additionalBusinessUnitType',
    'additionalBusinessUnitIds',
    'permissionGroupIds',
    'allowMigrateEmails',
    'exportLimit',
    'settings'
  ]);

  if (fData.phoneNumber) {
    fData.phoneNumber = fData.phoneNumber.replace(/[^0-9]/g, '');
  }

  if (fData.additionalBusinessUnitIds && fData.additionalBusinessUnitIds.length > 0) {
    fData.additionalBusinessUnitType = 'SELECTED';
  } else {
    fData.additionalBusinessUnitType = 'NONE';
  }

  if (fData.permissionGroupIds.length > 0 && fData.permissionGroupIds.indexOf('GUEST') >= 0) {
    fData.permissionGroupIds.splice(fData.permissionGroupIds.indexOf('GUEST'), 1);
  }

  for (const key of Object.keys(fData)) {
    if (fData[key]==='') {
      delete fData[key];
    }
  }

  return fData;
};

const prepareUserStatus = (user) => {
  return ({
    userName: user.firstName + ' ' + user.lastName,
    userType: user.isRecruiter ? 'Recruiter' : 'Not Recruiter',
    userId: user.userId,
    userEmail: user.email,
    userTeams: user.teams,
    userPrimaryBusinessUnitId: user.primaryBusinessUnitId,
    additionalBusinessUnitIds: user.additionalBusinessUnitIds,
    userBusinessUnitIds: compact((user.additionalBusinessUnitIds || []).concat([user.primaryBusinessUnitId])),
    userPermissionGroupIds: user.permissionGroupIds,
    settings: user.settings,
  });
};

const prepareRole = (userPermissionGroupIds=[], permissionLabels=[]) => {
  let newRole = {
    label: 'CUSTOMIZED_ROLE',
    value: 'Customized Role'
  };

  for (const permissionLabel of permissionLabels) {
    if (permissionLabel.permissionGroups && permissionLabel.permissionGroups.length === userPermissionGroupIds.length) {
      const diffArr = difference(userPermissionGroupIds, permissionLabel.permissionGroups.map(permissionGroup => permissionGroup.id));
      if (diffArr.length === 0) {
        newRole = {
          value: permissionLabel.name,
          label: permissionLabel.id,
        };
      }
    }
  }
  return newRole;
};

export {
  prepareForRenderUsers,
  prepareForApiUser,
  prepareUserStatus,
  prepareRole,
};
