import './style.scss';
import { Button } from '../../compoments/common';
import React from 'react';

export default ({checkEmailLoginPath, switchType, isLoading, onlySSO}) => {
  return (
    <>
      <Button type='submit' id='microsoft-login-button' variant='my-4 w-100' disabled={isLoading}>
        <span className='office-365'/> Sign In with Office 365
      </Button>
      {
        checkEmailLoginPath() || !onlySSO ?
          <div className='d-block switch-sso'>
            <span id='switch-sso-link' className='cursor' onClick={() => switchType('email')}>Use Email Instead</span>
          </div>
          : ''
      }
    </>
  );
};
