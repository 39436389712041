import React, {useState} from 'react';
import Header from './Header';
import useModal from '../../hooks/useModal';
import NoteFormModal from '../Notes/NoteFormModal';
import TemplateModal from '../Template/EditModal';
import TaskFormModal from '../Tasks/EditModal';
import SubmissionFormModal from '../Submissions/Form/SubmissionFormModal';
import {Link} from 'react-router-dom';
import utils from '../../libs/utils';

export default function Add({drawer, closeDrawer, history}) {
  const addNewList = [
    {
      text: 'Candidate',
      url: '/new-candidate',
      eventType: 'create',
      eventProperties: {
        action: 'add candidate',
        object: 'candidate',
        globalNav: 'global add',
        method: 'global add',
        timer: 'start'
      }
    },
    {
      text: 'Job',
      url: '/new-job',
      eventType: 'create',
      eventProperties: {
        action: 'add job',
        object: 'job',
        globalNav: 'global add',
        method: 'global add',
        timer: 'start'
      }
    },
    {
      text: 'Note',
      url: '#/',
      eventType: 'create',
      eventProperties: {
        action: 'add note',
        object: 'note',
        type: '',
        globalNav: 'global add',
        method: 'global add',
        timer : 'start'
      }
    },
    {
      text: 'Submission',
      url: '#/',
      eventType: 'create',
      eventProperties: {
        action: 'add submission',
        object: 'submission',
        type: '',
        globalNav: 'global add',
        method: 'global add',
        timer : 'start'
      },
    },
    {
      text: 'Placement',
      url: '/new-placement',
      eventType: 'create',
      eventProperties: {
        action: 'add placement',
        object: 'placement',
        globalNav: 'global add',
        method: 'global add',
        timer: 'start'
      }
    },
    {
      text: 'Contact',
      url: '/new-contact',
      eventType: 'create',
      eventProperties: {
        action: 'add contact',
        object: 'contact',
        globalNav: 'global add',
        method: 'global add',
        timer: 'start'
      }
    },
    {
      text: 'Company',
      url: '/new-company',
      eventType: 'create',
      eventProperties: {
        action: 'add company',
        object: 'company',
        globalNav: 'global add',
        method: 'global add',
        timer: 'start'
      }
    },
    {
      text: 'Task',
      url: '#/',
      eventType: 'create',
      eventProperties: {
        action: 'add task',
        object: 'task',
        type: '',
        globalNav: 'global add',
        method: 'global add',
        timer : 'start'
      }
    },
    /*{
      text: 'Template',
      url: '#/'
    }*/
  ];

  const {modalVisible, toggleModal} = useModal();
  const [modalId, setModalId] = useState('');

  const openModal = (contentType, event) => {
    event.preventDefault();
    setModalId(contentType);
    toggleModal();
    window.amplitudeLogEvent('create', {
      action: 'add ' + contentType.toLowerCase(),
      object: contentType.toLowerCase(),
      type: '',
      globalNav: 'global add',
      method: 'global add',
      timer: 'start'
    });
  };

  const createTaskFromData = (data) => {
    setTask(utils.prepareDefaultTaskFromData(data));
    setModalId('Task');
  };

  const noteModal = <NoteFormModal
    modalVisible={modalVisible}
    modalCancel={toggleModal}
    history={history}
    createTaskFromData={createTaskFromData}
    closeDrawer={closeDrawer} />;

  const templateModal = <TemplateModal
    contentType={'Templates'}
    modalVisible={modalVisible}
    modalCancel={toggleModal}
    actionType={'Add'}
  />;

  const submissionModal = <SubmissionFormModal
    modalVisible={modalVisible}
    modalCancel={toggleModal}
    history={history}
    createTaskFromData={createTaskFromData}
    closeDrawer={closeDrawer}
  />;

  const [task, setTask] = useState(utils.prepareDefaultTask());
  const taskModal = <TaskFormModal
    modalVisible={modalVisible}
    modalCancel={toggleModal}
    defaultValue={task}
    closeDrawer={closeDrawer} />;

  const clickAddLink = (item) => {
    window.amplitudeLogEvent('create', { action: 'add ' + item.text.toLowerCase(), object: item.text.toLowerCase(), globalNav: 'global add', method: 'global add', timer: 'start'});
    closeDrawer();
  };

  const checkModalAction = (item) => {
    return item.text === 'Note' || item.text === 'Template' || item.text === 'Submission' || item.text === 'Task';
  };

  const renderListItem = (item) => {
    if (item.disable) {
      return (
        <div className='flex col-auto pt-3 pl-5' key={`new-${item.text.toLowerCase()}`}>
          <div id={`new-${item.text.toLowerCase()}`} className='pl-1 ant-timeline-item-head-gray'>{item.text}</div>
        </div>
      );
    } else {
      return (
        <div className='flex col-auto pt-3 pl-5' key={`new-${item.text.toLowerCase()}`}>
          <Link to={item.url} id={`new-${item.text.toLowerCase()}`} className='pl-1' onClick={(e) => checkModalAction(item) ? openModal(item.text, e) : clickAddLink(item)}>{item.text}</Link>
        </div>
      );
    }
  };

  return (
    <>
      <div className='pb-4'>
        <div className='divider divider-horizontal divider-100 my-4' />
        <Header drawer={drawer} closeDrawer={closeDrawer} />
        <div className='drawer-content px-4'>
          {addNewList.map((item, index) => (
            renderListItem(item)
          ))}
        </div>
      </div>
      { modalVisible && modalId === 'Note' ? noteModal : '' }
      { modalVisible && modalId === 'Template' ? templateModal : '' }
      { modalVisible && modalId === 'Submission' ? submissionModal : '' }
      { modalVisible && modalId === 'Task' ? taskModal : '' }
    </>
  );
};
