import React, {useState} from 'react';
import {toast} from 'react-toastify';

import {AtsModal, AtsTypeAheadSearchSelect, Button} from '../common';
import useModal from '../../hooks/useModal';
import EditModal from './EditModal';

import {entityTypeOptions} from '../../constants/listConst';
import {isEmpty, keyBy, get} from '../../libs/lodash';
import utils from '../../libs/utils';
import searchConst from '../../constants/searchConst';

import './style.scss';
import ApiClient from '../../services/ApiClient';


export default function SelectModal({selectModalVisible, selectModalCancel, checkedItemsNum, entityType, entityIds}) {
  const [selectedLists, setSelectedLists] = useState({});
  const {modalVisible, toggleModal} = useModal();
  const [modalId, setModalId] = useState('');

  const addToExistingList= async (e) => {
    e.preventDefault();
    utils.prepareDisabledButton(null, '#conformAddToExistingList', true);
    try {
      const requestBody = {entityType: entityType, listIds: selectedLists.selectedLists.map((list) => list.listId), entityIds: entityIds};

      if(checkedItemsNum > (entityIds || []).length) {
        const response = await ApiClient.searchesRecentList({entityType: entityType});
        if (response.body.length) {
          delete requestBody.entityIds;
          requestBody.recentSearchId = get(response.body[0], 'recentSearchId');
        }
      }

      await ApiClient.listsBulkAdd({}, requestBody);

      const msg = `Selected ${checkedItemsNum === 1 ? utils.camelize(entityType) + ' has' : (keyBy(entityTypeOptions, 'id')[entityType].name || '').toLowerCase() + ' have'} been added to the chosen lists`;
      toast(msg, {
        className: 'toast-response-background',
        bodyClassName: 'toast-response-body',
        position: 'top-right'
      });

      selectModalCancel();
    } catch (error) {
      console.log(error);
    } finally {
      utils.prepareDisabledButton(null, 'conformAddToExistingList', false);
    }
  };

  const addNewFromBulkActionCallback =(list) => {
    setSelectedLists({selectedLists: (selectedLists.selectedLists || []).concat([list])});
  };

  const addNewList =(event) => {
    event.preventDefault();
    setModalId('add-list-modal');
    toggleModal();
  };

  const showModal = () => {
    if (!modalVisible || modalId !== 'add-list-modal') {
      return;
    }

    const defaultListValue = {
      name: '',
      isPublic: false,
      entityType: entityType,
      category: 'STANDARD',
      desc: ''
    };
    return <EditModal contentType={'Lists'} modalVisible={modalVisible} modalCancel={toggleModal} actionType={'Add'} addNewFromBulkActionCallback={addNewFromBulkActionCallback} defaultValue={defaultListValue}/>;
  };

  return (
    <AtsModal
      id='select-list-modal'
      modalTitle='Add to Lists'
      modalSize='md'
      modalVisible={selectModalVisible}
      modalCancel={selectModalCancel}
    >
      <div className='w-100'>
        <div className='light-grey-text pb-1'>Select the lists you want to add objects to:</div>
        <div className='form-group col-12 px-0'>
          <AtsTypeAheadSearchSelect
            isMulti={true}
            required={false}
            clearButton={false}
            defaultValue={selectedLists.selectedLists}
            id='selectedLists'
            name='selectedLists'
            maxLengthToApi={searchConst.minLength}
            delay={0}
            entityType={entityType}
            placeholder='Type to search'
            searchOperation='listsSearchMy'
            filterBy={['name']}
            callback={setSelectedLists}
            checkedItemsNum={checkedItemsNum}
          />
        </div>

        <div className='form-group col-12 px-0 d-flex'>
          {
            checkedItemsNum ? <div className='pt-2'>{`${checkedItemsNum} Selected ${utils.camelize(checkedItemsNum === 1 ? entityType : searchConst.entityMap[entityType.toLowerCase()])}`}</div> : ''
          }
          <div className='ml-auto'>
            Create a new list:
            <Button id={'list-add-btn'} type='submit' variant='btn-primary ats-sm-btn' click={(e) => addNewList(e)}>List</Button>
          </div>
        </div>

        <div className="d-flex justify-content-end pt-5">
          <Button type='button' variant='mb-4 btn-secondary mr-4' click={() => selectModalCancel()}>Cancel</Button>
          <Button type='submit' id='conformAddToExistingList' variant='mb-4 btn-primary ats-md-btn' disabled={isEmpty(selectedLists.selectedLists) ? true : false} click={(e) => addToExistingList(e)}>Confirm</Button>
        </div>

        {showModal()}
      </div>
    </AtsModal>
  );
};
