import {keyBy} from './lodash';

const isValidatePersonalize = function (content, variables, setInvalidPersonalizeMsg) {
  try {
    const result = content.match(/&lt;.*?&gt;/gi);
    const validPersonalizeVariableStr = ((Object.keys(keyBy(variables, 'id')) || []).join(', ') || '').toLowerCase();
    const inValidPersonalizeVariables = [];
    if (result) {
      result.forEach(function (v) {
        v = v.replace(/&lt;/gi, '<').replace(/&gt;/gi, '>');
        if (validPersonalizeVariableStr.indexOf(v.toLowerCase()) === -1 && !inValidPersonalizeVariables.includes(v)) {
          inValidPersonalizeVariables.push(v);
        }
      });
      if (inValidPersonalizeVariables.length) {
        const msg  = `Personalization field${inValidPersonalizeVariables.length === 1 ? '' : 's'} ${inValidPersonalizeVariables.join(', ')} not recognized.`;
        setInvalidPersonalizeMsg(msg);
        return false;
      }
    }
    return true;
  } catch (err) {
    console.log(err);
    return false;
  }
};

export {
  isValidatePersonalize
};
