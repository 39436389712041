import SwaggerClient from 'swagger-client';
import ErrorHandler from './errorHandler';
import {toast} from 'react-toastify';
import apiUserStatus from './apiUserStatus';
import {reserveOriginalPathname} from './pathHandler';
import {isEmpty} from '../libs/lodash';
import TokenService from './TokenService';

export default class ApiClient {
  static #isReady = false;

  static loadSwaggerSpecFromLocalStorage() {
    return JSON.parse(window.localStorage.getItem('swaggerSpec'));
  }

  static saveSwaggerSpecToLocalStorage(swaggerSpec) {
    window.localStorage.setItem('swaggerSpec', JSON.stringify(swaggerSpec));
  }

  static removeSwaggerSpecToLocalStorage() {
    window.localStorage.removeItem('swaggerSpec');
    this.#isReady = false;
  }

  static get isReady(){
    return this.#isReady;
  }

  static async initialize() {
    try {
      const spec = await this.pullSpec();
      await this.prepareApis(spec);
      this.#isReady = true;
    } catch (error) {
      const errorMsg = ErrorHandler(error);
      toast.error(errorMsg, {
        position: toast.POSITION.TOP_RIGHT
      });
      throw error;
    }
  }

  static async prepareApis(spec) {
    const scheme        = window.REACT_APP_SCHEME || 'https';
    const swaggerClient = await SwaggerClient({ spec });
    for (const path in swaggerClient.spec.paths) {
      for (const method in swaggerClient.spec.paths[path]) {
        const { operationId, security = [], tags } = swaggerClient.spec.paths[path][method];
        const tagName = tags[0];
        const operation = async(parameters = {}, requestBody = {}, options = { headers: {} }) => {

          const { headers = {} } = options;

          if (!headers['Content-Type']) {
            headers['Content-Type']   = 'application/json';
          }

          try {
            const requestInterceptor = req => {
              req.headers = {...req.headers, ...headers};
              return req;
            };

            const requestOptions = isEmpty(requestBody) ? { scheme, requestInterceptor } : { scheme, requestBody, requestInterceptor };

            // only private endpoints require authenticationToken
            if (security.length) {
              const authenticationToken = await TokenService.getAccessToken();
              if (authenticationToken) {
                requestOptions.securities = {
                  authorized: { authenticationToken }
                };
              }
            }

            // need to resolve promise and try to catch error
            return await swaggerClient.apis[tagName][operationId](parameters, requestOptions);

          } catch (error) {
            const errorMsg = ErrorHandler(error);
            console.log('get error from api request:', errorMsg);
            if (operationId !== 'identityLogin') {
              toast.error(errorMsg, {
                position: toast.POSITION.TOP_RIGHT
              });
            }
            throw error;
          }
        };

        ApiClient[operationId] = operation;
      }
    }
  }

  static async pullSpec() {
    const options = {};

    let swaggerSpec = this.loadSwaggerSpecFromLocalStorage();
    if (swaggerSpec) {
      return swaggerSpec;
    }

    const authorization = TokenService.accessToken ? TokenService.accessToken.token : null;

    if (authorization) {
      options.headers = { authorization };
    }

    const specUrl = window.REACT_APP_API_BASE_PATH + '/swagger';
    const response = await fetch(specUrl, options);

    if (authorization && response.status === 401) {
      throw new Error('User inactive or not found');
    }

    if(response.status === 401 || response.status === 403) {
      reserveOriginalPathname(window.location.pathname);
      apiUserStatus.remove('user');
      window.location.href = '/login';
      return;
    } else if (response.status !== 200) {
      throw new Error('Specification loading error');
    }

    swaggerSpec = await response.json();

    // only cache private swagger spec
    if (authorization) {
      this.saveSwaggerSpecToLocalStorage(swaggerSpec);
    }

    return swaggerSpec;
  }
}
