import React from 'react';
import './style.scss';

export default function AtsRadioSelect({name, options, defaultValue, handleChange, required}) {
  return (
    <div className={`ats-radio-select select form-control d-flex ${ defaultValue === undefined ? 'error' : '' }`} >
      {
        options.map( option => (
          <label className={`radio-container ${ option.value === defaultValue ? 'checked' : 'unchecked' }`} key={`inline-radio-${option.value}`}>
            {option.label}
            <input
              name={name}
              type="radio"
              id={`inline-radio-${option.value}`}
              value={option.value}
              className="form-check-input"
              checked={option.value === defaultValue}
              onChange={(e) => handleChange({[name]: option.value})}
              required={required}
            />
            <span htmlFor={`inline-radio-${option.value}`} className="checkmark">
            </span>
          </label>
        ))
      }
    </div>
  );
}
