import actionTypes from '../constants/actionTypes';

const initialState = {
  isFetched: false,
  parameters: {criteria: {ownerUserIds: []}},
  data: {summary: {}, deals: []}
};

export default function dealReducer(state = initialState, action){
  switch (action.type) {
  case actionTypes.dealsActionTypes.SET_DEALS:
    return {
      ...state,
      data: action.payload
    };
  case actionTypes.dealsActionTypes.SET_ADDITIONAL_DEALS:
    const { summary, deals } = state.data;
    const { summary: payloadSummary, deals: payloadDeals } = action.payload;
    return {
      ...state,
      data: {
        summary: {
          numResultsFound: summary.numResultsFound,
          numResultsReturned: summary.numResultsReturned + payloadSummary.numResultsReturned
        },
        deals: [...deals, ...payloadDeals] 
      }
    };
  case actionTypes.dealsActionTypes.DROP_DEAL:
    let dropedDeal = { ...action.payload };
    const dropedDealIndex = state.data.deals.findIndex(
      deal => deal['dealId'] === dropedDeal.dealId
    );

    if (dropedDealIndex !== -1) {
      dropedDeal = {...state.data.deals[dropedDealIndex], ...dropedDeal};

      const updatedDeals = [
        ...state.data.deals.slice(0, dropedDealIndex),
        dropedDeal,
        ...state.data.deals.slice(dropedDealIndex + 1)
      ];
      return {
        ...state,
        data: {
          summary: state.data.summary,
          deals: updatedDeals
        }
      };
    } else {
      return {
        ...state,
        data: state.data
      };
    }
  case actionTypes.commonActionTypes.FETCH_REQUEST:
    return {
      ...state,
      isFetched: true
    };
  case actionTypes.commonActionTypes.FETCH_SUCCESS:
    return {
      ...state,
      isFetched: false
    };
  case actionTypes.commonActionTypes.FETCH_FAIL:
    return {
      ...state,
      isFetched: false
    };
  case actionTypes.commonActionTypes.SET_PARAMS:
    return {
      ...state,
      parameters: action.payload
    };
  default:
    return state;
  }
}
