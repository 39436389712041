import utils from '../libs/utils';
import {compact, get} from '../libs/lodash';
import settingConst from '../constants/settingConst';
import candidateConst from '../constants/candidateConst';
import {checkPermissionDocs} from '../compoments/Documents/libs/utils';

export default class SearchResult {
  static prepareForQuickSearch(data) {
    if (Array.isArray(data) || !data) {
      return data;
    }
    return Object.keys(data).reduce((acc, curKey) => {
      const tempArr = data[curKey].map((item) => {
        return {
          ...item,
          entityType: curKey.toUpperCase()
        };
      });
      return [...acc, ...tempArr];
    }, []);
  }

  static prepareForAppointments(data) {
    const id = data.appointmentId || '';
    const entityId = data.appointmentId || '';
    const entityType = 'APPOINTMENT';
    const ownerUserName = utils.shortName(data.owner, candidateConst.maxFullNameLength, true);
    const activityTypeName = get(data, 'activityType.name');

    const relatedEntityId = get(data, 'entity.id');
    const relatedEntityType = get(data, 'entity.type');
    const name = get(data, 'entity.name') || get (data, 'attendeesByEntity.candidates[0].name');
    const relatedEntityShortName = settingConst.globalEntityAbbreviations[relatedEntityType] || relatedEntityType;
    const relatedEntityName = relatedEntityId ? name + ` (${relatedEntityShortName}${relatedEntityId})` : '';
    return {
      ...data,
      id,
      entityId,
      entityType,
      ownerUserName,
      activityTypeName,
      relatedEntityName,
    };
  }

  static prepareForAttachments(data, resume={}) {
    const id = data.attachmentId || '';
    const docID = data.attachmentId ? `DOC${data.attachmentId}` : '';
    const attachmentName = data.name || get(data, 'metaInfo.originalName');
    const entityId = data.attachmentId || '';
    const entityType = 'ATTACHMENT';
    const isPrimaryResume = get(resume, 'attachmentId') === id;
    const isAllowed = checkPermissionDocs(data);
    const subType = get(data, 'attachmentVisaDocumentationSubType') || get(data, 'attachmentAnnualFormSubType') || '';

    return {
      ...data,
      id,
      docID,
      entityId,
      entityType,
      name: data.name || get(data, 'metaInfo.originalName'),
      attachmentName,
      attachmentType: data.attachmentType || 'GENERAL',
      scope: data.scope || 'PUBLIC',
      isPrimaryResume,
      isAllowed,
      subType
    };
  }

  static prepareForCompanies(data) {
    try {
      data.id = data.companyId;
      data.entityId = data.companyId;
      data.entityType = 'COMPANY';
      data.companyIdString = `CMP${data.companyId}`;

      data.programLink =
        <a href={`/company/${data.companyId}`}
          target='_blank'
          rel="noreferrer"
          onClick={() => window.amplitudeLogEvent('view', { action: 'view company', object: 'company', detailsTab: utils.activeTabName()})}
        >
          {utils.contentForRender(data.name, 20)}
        </a>;
      data.statusDisplay = utils.camelize(data.status || '');
      data.createdAtDisplay = utils.dateForRender(data.createdAt);

      if (data.programInfo && data.programInfo.mspContact) {
        data.programInfo.mspContact.name = compact([get(data, 'programInfo.mspContact.contactName.firstName'), get(data, 'programInfo.mspContact.contactName.lastName')]).join(' ');
        data.mspContactLink =
          <a href={`/contact/${get(data, 'programInfo.mspContact.contactId')}`}
            target='_blank'
            rel="noreferrer"
            onClick={() => window.amplitudeLogEvent('view', { action: 'view contact', object: 'contact', detailsTab: utils.activeTabName()})}
          >
            {data.programInfo.mspContact.name}
          </a>;
      }

      if (data.programInfo && data.programInfo.clientContact) {
        data.programInfo.clientContact.name = compact([get(data, 'programInfo.clientContact.contactName.firstName'), get(data, 'programInfo.clientContact.contactName.lastName')]).join(' ');
        data.clientContactLink =
          <a href={`/contact/${get(data, 'programInfo.clientContact.contactId')}`} target='_blank' rel="noreferrer">
            {data.programInfo.clientContact.name}
          </a>;
      }

      data.ownerName = compact([get(data, 'owner.firstName'), get(data, 'owner.lastName')]).join(' ');
      return data;
    } catch (err) {
      console.log(err);
      return data;
    }
  }
}
