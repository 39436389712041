import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';

import { BadgeList, Metadata } from '../../../common';
import RecruiterInitialName from './RecruiterInitialName';
import Location from './Location';
import UpdatedCreatedInfo from './UpdatedCreatedInfo';

import utils from '../../../../libs/utils';
import {isEmpty} from '../../../../libs/lodash';
import LinkToNewTabForEntity from './LinkToNewTabForEntity';
import candidateConst from '../../../../constants/candidateConst';

export default function Candidate({entity, isActive, lookups, openNewTab}) {
  return (
    <>
      {isActive ?
        <div className='active-item d-flex align-items-center'>
          {utils.shortName(entity, candidateConst.maxFullNameLength, true)}
          <BadgeList badges={entity.badges || []} width='13' height='13' />
          <RecruiterInitialName recruiter={entity.recruiter} />
        </div>
        :
        <div className='d-flex align-items-center'>
          { openNewTab ? <LinkToNewTabForEntity entity={entity}/> : <Link to='#' className='biotif-semi-bold font-size-16 d-flex'> {utils.shortName(entity, candidateConst.maxFullNameLength, true)}</Link> }
          <BadgeList badges={entity.badges} width='11' height='13' />
          <RecruiterInitialName recruiter={entity.recruiter} />
        </div>
      }

      <div className={`pt-1 text-wrap${!isEmpty(entity.jobTitle) ? '' : ' d-none'}`}>
        {entity.jobTitle ? utils.contentForRender(entity.jobTitle, 28) : ''}
      </div>
      <div className={`pt-1${!isEmpty(entity.currentCompany) ? '' : ' d-none'}`}>
        {entity.currentCompany ? utils.contentForRender(entity.currentCompany, 28) : ''}
      </div>

      <Location entity={entity} />
      <div className='pt-1'>
        {utils.convertIdToName(lookups, 'candidateStatuses', entity.status)}
      </div>

      <Metadata entityType='candidate' metadata={entity.metadata || {}} />

      <UpdatedCreatedInfo entityType='candidate' createdAt={entity.createdAt} updatedAt={entity.updatedAt} />
    </>
  );
};

Candidate.propTypes = {
  entity: PropTypes.object.isRequired,
  isActive: PropTypes.bool
};

