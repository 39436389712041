import React, {useState} from 'react';
import {toast} from 'react-toastify';
import PropTypes from 'prop-types';

import Button from '../../common/Button';
import AtsRichTextArea from '../../common/AtsRichTextArea';
import AtsInput from '../../common/AtsInput';
import DetailModal from '../Details/DetailModal';

import useForm from '../../../hooks/useForm';
import {isValidatePersonalize} from '../../../libs/validate';
import useModal from '../../../hooks/useModal';

import apiUserStatus from '../../../services/apiUserStatus';
import { prepareForApiBulkEmails } from '../libs/utils';

import {get} from '../../../libs/lodash';
import ApiClient from '../../../services/ApiClient';
import Lookups from '../../../services/Lookups';

export default function Form(props) {
  const user = apiUserStatus.get();
  const {userName='', userEmail=''} = user || {};
  const [ignoreInvalidPersonalize, setIgnoreInvalidPersonalize] = useState(false);
  const [invalidPersonalizeMsg, setInvalidPersonalizeMsg] = useState('');
  const [email] = useState({
    candidates: props.defaultCandidates || [],
    candidateIds: (props.defaultCandidates || []).map((obj) => obj['candidateId']),
    contacts: props.defaultContacts || [],
    contactIds: (props.defaultContacts || []).map((obj) => obj['contactId']),
    candidateLists: props.defaultCandidateLists || [],
    candidateListIds: (props.defaultCandidateLists || []).map((list) => list['listId']),
    contactLists: props.defaultContactLists || [],
    contactListIds: (props.defaultContactLists || []).map((list) => list['listId'])
  });
  const lookups = Lookups.lookups;
  const {modalVisible, toggleModal} = useModal();
  const [mail, setMail] = useState('');

  const sendBulkEmail = async () => {
    try {
      toggleModal();
      if (ignoreInvalidPersonalize || isValidatePersonalize(values.body, lookups.emailVariables, setInvalidPersonalizeMsg)) {
        const bulkEmail = prepareForApiBulkEmails(values, lookups.emailVariables);
        await ApiClient.bulkEmailCreate({}, bulkEmail);

        if (props.saveBulkEmailCallback) {
          props.saveBulkEmailCallback();
        } else {
          window.amplitudeLogEvent('create', {
            action: 'save new bulk email',
            object: 'bulk email',
            method: 'list details',
            timer: 'end'
          });
        }

        const msg = 'Bulk Email Sent';
        toast(msg, {
          className: 'toast-response-background',
          bodyClassName: 'toast-response-body',
          position: 'top-right'
        });
        props.modalCancel();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const previewEmail = async () => {
    try {
      if (ignoreInvalidPersonalize || isValidatePersonalize(values.body, lookups.emailVariables, setInvalidPersonalizeMsg)) {
        const bulkEmail = prepareForApiBulkEmails(values, lookups.emailVariables);
        const res = await ApiClient['bulkEmailPreview']({}, bulkEmail);
        setMail(get(res, 'body'));
        toggleModal();
      }
    } catch (error) {
      console.log(error);
    }
  };


  let selectedCount = 0;
  function toDisplay() {
    try {
      selectedCount = (email.candidateIds || []).length;
      if( selectedCount === 0) {
        selectedCount = (props.defaultCandidateLists || []).reduce( (sum, list) => {
          return sum + list.numEntities;
        }, 0);
      }

      if (selectedCount) {
        return `${selectedCount} Selected ${selectedCount ===1 ? 'Candidate' : 'Candidates'}`;
      }

      selectedCount = (email.contactIds || []).length;
      if( selectedCount === 0) {
        selectedCount = (props.defaultContactLists || []).reduce( (sum, list) => {
          return sum + list.numEntities;
        }, 0);
      }
      if(selectedCount) {
        return `${selectedCount} Selected ${selectedCount ===1 ? 'Contact' : 'Contacts'}`;
      }

      return '';
    } catch (err) {
      console.log(err);
      return '';
    }
  }

  const {values, formValidated, handleChange, handleSubmit} = useForm(email, previewEmail);

  return (
    <>
      <form name='emailForm' noValidate onSubmit={handleSubmit} autoComplete='off'>
        <div className='form-group col-12 px-0'>
          <label>
            From<span className='black-text font-size-16 biotif-semi-bold' style={{'paddingLeft': '20px'}}> {userName} ({userEmail})</span>
          </label>
        </div>

        <div className='form-group col-12 px-0'>
          <label>
            To
            <span className='black-text font-size-16 biotif-semi-bold' style={{'paddingLeft': '42px'}}>
              {props.listName ? `${props.listName} (${toDisplay()})` : toDisplay()}
            </span>
          </label>
        </div>

        <div className='form-group col-12 px-0 pb-3'>
          <label htmlFor='subject'>
            Subject Line
            <span className='required'>*</span>
          </label>
          <AtsInput
            id='subject'
            name='subject'
            required={true}
            validated={formValidated}
            defaultValue={values.subject || ''}
            callback={handleChange}
          />
        </div>

        {!ignoreInvalidPersonalize && invalidPersonalizeMsg ?
          <div className='col-12 row px-0 mx-0'>
            <div className='col-10 px-0 red-text'>
              {invalidPersonalizeMsg}
            </div>
            <div className='col-2 px-0 text-right'>
              <Button type='button' id='ignore' variant='ats-sm-btn btn-primary' click={() => setIgnoreInvalidPersonalize(true)}>
                Ignore
              </Button>
            </div>
          </div>
          :
          ''
        }

        <div className='form-group'>
          <AtsRichTextArea
            id='bulk-email-body'
            label={<label>Message<span className='required'>*</span></label>}
            initialValue={values.body || ''}
            name='body'
            handleChange={handleChange}
            category='BULK_EMAIL'
            personalizeVariables={lookups.emailVariables || []}
            personalize={true}
          />
        </div>

        <div className='form-group'>
          <div className='d-flex justify-content-end pt-3'>
            <Button type='button' id='close-modal' variant='btn-secondary mr-4' click={() => props.modalCancel()}>
              Cancel
            </Button>
            <Button type="submit" id='send-email' variant='btn-primary ats-md-btn'>
              Next
            </Button>
          </div>
        </div>
      </form>

      {modalVisible ?
        <DetailModal mail={mail} title={<div>Preview Email<div className='pl-3 d-inline-block font-size-20 light-grey-text'>1 of {selectedCount}</div></div>} modalVisible={modalVisible} modalCancel={toggleModal} sendBulkEmail={sendBulkEmail} />
        :
        ''
      }
    </>
  );
}

Form.propTypes = {
  modalCancel: PropTypes.func.isRequired
};
