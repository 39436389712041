export const addHotKey = (id, handler) => {
  return (dispatch, getState) => {
    const eventListener = (event) => {
      for (const id of Object.keys(getState().hotKeyReducer)) {
        if (document.getElementById(id)) {
          getState().hotKeyReducer[id](event);
        }
      }
    };

    if (getState().hotKeyReducer[id]) {
      window.removeEventListener('keydown', eventListener);
    }
    dispatch({ type: 'ADD_HOT_KEY',  payload: {id, handler} });
    window.addEventListener('keydown', eventListener);
  };
};

export const updateRecentNavId = (recentNavId) => {
  return ({
    type: 'UPDATE_RECENT_NAV_ID',
    payload: {recentNavId}
  });
};
