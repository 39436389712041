import React from 'react';
import { Route } from 'react-router-dom';

export default function RouteWithSubs(route) {
  return (
    <Route
      path={route.path}
      render={props => (
        // pass the sub-routes down to keep nesting
        <route.component {...props}
          routes={route.routes}
          objType={route.objType}
          title={route.title}
          edit={route.edit}
        />
      )}
    />
  );
}
