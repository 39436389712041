import React from 'react';
import {Link} from 'react-router-dom';
import AtsHighlighter from '../AtsHighlighter';

import utils from '../../../libs/utils';
import { getTermFromTerms, checkMatch, addEscapeForString } from './libs/utils';
import {compact, has, get, isEmpty} from '../../../libs/lodash';
import detailsConst from '../../../constants/detailsConst';
import searchConst from '../../../constants/searchConst';
import AtsTooltip from '../AtsTooltip';

import './style.scss';

export default function QuickSearchItem({item, searchTerm, usedFor, filterValue, closeDrawer}) {
  // update the code based on different object json object.
  // there are only one candidate right now
  const entityType = (item.entityType || item.type || '').toLowerCase();
  const entityTypePlural = searchConst.entityMap[entityType];
  const quickItem = has(item, 'itemDetails') ? item.itemDetails : item;
  const isTypeahead = usedFor === 'typeahead';
  const withTooltip =  usedFor !== 'typeahead';
  const globalNav = usedFor === 'quicksearch' ? 'quick search' : 'quick search slide-in';
  const isUSA = get(quickItem, 'country') === 'United States';

  const clickToOpenview = () => {
    window.amplitudeLogEvent('quick search', { action: 'view object', filterValue: filterValue, searchString: (searchTerm || '').toLowerCase(), object: entityType, timer : 'end' });
    window.amplitudeLogEvent('view', { action: 'view ' + entityType, object: entityType, globalNav: globalNav, detailsTab: detailsConst.defaultTab[entityType] || 'details', from: 'quick search' });
    closeDrawer();
  };

  function renderItem() {
    // there are only candidate right now, if there are more other objects, we need to check how object looks like, then update it.
    // update the code based on entityType
    const itemId  = has(quickItem, 'itemId') ? quickItem.itemId : get(quickItem, entityType + 'Id') || get(quickItem, 'id');

    const {firstName ='', preferredName='', lastName='', middleName=''} = quickItem.candidateName || quickItem.contactName || quickItem || {};
    const selectFirstName = (checkMatch(searchTerm, preferredName) && preferredName) || (checkMatch(searchTerm, firstName) && firstName) || firstName || preferredName;
    const selectMiddleName = searchTerm ? (checkMatch(searchTerm, middleName) && middleName) || '' : '';

    let origFullName =compact([selectFirstName, selectMiddleName, lastName]).join(' ');
    let fullName = (isTypeahead ? utils.contentForRender(origFullName, 65, false).trim() : utils.contentForRender(origFullName, 35, false)).trim();
    try {
      if (!isEmpty(searchTerm)) {
        let term = searchTerm || ' ';
        if (term.startsWith('+') ) {
          term = '^[+]' + term.substr(1);
        }
        const pattern = new RegExp(term.split(' ').map(str => addEscapeForString(str)).join('|'), 'gi');

        fullName = fullName.replace(pattern, function(matched, offset){
          if (offset === 0 || fullName[offset-1] === ' ') {
            const matchedTerm = getTermFromTerms(fullName, offset);
            if (matchedTerm && matchedTerm === matched) {
              return `<mark class='rbt-highlight-text'>${matched}</mark>`;
            } else if (matchedTerm && matchedTerm.length > 2 && matched.length > 2) {
              return `<mark class='rbt-highlight-text'>${matched}</mark>`;
            } else {
              return matched;
            }
          } else {
            return matched;
          }
        });
      }
    } catch (error){
      console.log(error);
    }
    fullName = fullName ? <span dangerouslySetInnerHTML={{__html:  fullName || ''}} /> : '';

    const jobTitle = has(quickItem, 'jobTitle') ? quickItem.jobTitle : '';
    let companyName = get(quickItem, 'company.name', quickItem.companyName || '');
    const currentCompany = get(quickItem, 'currentCompany', quickItem.currentCompany || '');
    const currentJob = get(quickItem, 'currentJob', quickItem.currentJob || '');
    const openStatusValue = has(quickItem, 'openStatusValue') ? quickItem.openStatusValue : '';

    const { isRemote = false } = quickItem || {};
    const city = has(quickItem, 'city') ? get(quickItem, 'city') : '';
    const state = has(quickItem, 'state') ? get(quickItem, 'state') : '';
    const location = isRemote ? 'Remote' : compact([city, state]).join(', ');

    const email = get(quickItem, 'primaryEmail', '') || get(quickItem, 'generalEmail', '') || get(quickItem, 'candidateAdditionalInfo.primaryEmail', '') || '';
    const otherEmail = get(quickItem, 'otherEmail', '') || get(quickItem, 'candidateAdditionalInfo.otherEmail', '') || '';
    const mobilePhone = get(quickItem, 'mobilePhone', '') || get(quickItem, 'candidateAdditionalInfo.mobilePhone', '') || '';
    const homePhone = get(quickItem, 'homePhone', '') || get(quickItem, 'candidateAdditionalInfo.homePhone', '') || '';
    const workPhone = get(quickItem, 'workPhone', '') || get(quickItem, 'candidateAdditionalInfo.workPhone', '') || '';

    const status =  has(quickItem, 'statusValue') ? quickItem.statusValue : '';

    const name = has(quickItem, 'name') ? quickItem.name : '';

    const stringEntityIdFieldName = `string${utils.camelize(entityType)}Id`;
    const stringEntityId = get(quickItem, stringEntityIdFieldName, '') || '';
    const vmsJobId = get(quickItem, 'vmsJobId', '') || '';
    const vmsPlacementId = get(quickItem, 'vmsPlacementId', '') || '';
    const bullhornId = has(quickItem, 'bullhornId') ? get(quickItem, 'bullhornId', '')+'' : '';

    const prepareDynamicAdditionalFields = () => {
      return (
        <>
          {searchTerm && (otherEmail.toLowerCase()).includes(searchTerm.toLowerCase()) &&
          <div className='otherEmail'>
            <AtsHighlighter search={searchTerm} target={otherEmail} /> (otherEmail)
          </div>
          }
          {searchTerm && (homePhone).includes(searchTerm.replace(/[^A-Za-z0-9,.@]+/g, '')) &&
          <div className='homePhone'>
            {utils.phoneHighlighter(searchTerm, homePhone, isUSA)} (homePhone)
          </div>
          }
          {searchTerm && (workPhone).includes(searchTerm.replace(/[^A-Za-z0-9,.@]+/g, '')) &&
          <div className='workPhone'>
            {utils.phoneHighlighter(searchTerm, workPhone, isUSA)} (workPhone)
          </div>
          }
        </>
      );
    };

    const prepareDynamicIDFields = () => {
      return (
        <>
          {searchTerm && (stringEntityId.toLowerCase()).includes(searchTerm.toLowerCase()) &&
          <div className='stringEntityId'>
            <AtsHighlighter search={searchTerm} target={stringEntityId} /> (ID)
          </div>
          }
          {searchTerm && (vmsJobId.toLowerCase()).includes(searchTerm.toLowerCase()) &&
          <div className='vmsJobId'>
            <AtsHighlighter search={searchTerm} target={vmsJobId} /> (vmsJobID)
          </div>
          }
          {searchTerm && (vmsPlacementId.toLowerCase()).includes(searchTerm.toLowerCase()) &&
          <div className='vmsPlacementId'>
            <AtsHighlighter search={searchTerm} target={vmsPlacementId} /> (vmsPLC)
          </div>
          }
          {searchTerm && (bullhornId.toLowerCase()).includes(searchTerm.toLowerCase()) &&
          <div className='bullhornId'>
            <AtsHighlighter search={searchTerm} target={bullhornId} /> (BullhornID)
          </div>
          }
        </>
      );
    };

    switch (entityType.toLowerCase()) {
    case 'job':
      return (
        <div className={`d-flex item${isTypeahead ? ' typeahead' : ''}`}>
          <div className='px-2 pt-1'>
            <div className={`circle ${entityTypePlural}`} />
          </div>
          <div className='py-2 flex-grow-1'>
            {isTypeahead ?
              <div className='biotif-semi-bold font-size-16'>
                <AtsHighlighter search={searchTerm} target={utils.contentForRender(jobTitle || name, 50, false)} />
              </div>
              :
              <div>
                <Link to={`/${entityType}/${itemId}`} id={`${entityType}-${itemId}`} onClick={clickToOpenview}>
                  <AtsHighlighter search={searchTerm} target={utils.contentForRender(jobTitle || name, 35, false)} {...(jobTitle || name || '').length > 35 ? {targetFull: jobTitle || name} : {}} />
                </Link>
              </div>
            }

            {companyName &&
              <div className='company'>
                <AtsHighlighter search={searchTerm} target={utils.contentForRender(companyName, isTypeahead ? 50 : 35, false)} {...withTooltip && companyName.length > 35 ? {targetFull: companyName} : {}} />
              </div>
            }

            {location &&
              <div className='location'>
                {location}
              </div>
            }

            {openStatusValue &&
              <div className='status'>
                {openStatusValue}
              </div>
            }

            {prepareDynamicIDFields()}
          </div>
        </div>
      );
    case 'company':
      return (
        <div className={`d-flex item${isTypeahead ? ' typeahead' : ''}`}>
          <div className='px-2 pt-1'>
            <div className={`circle ${entityTypePlural}`} />
          </div>
          <div className='py-2 flex-grow-1'>
            {isTypeahead ?
              <div className='biotif-semi-bold font-size-16'>
                <AtsHighlighter search={searchTerm} target={utils.contentForRender(name, 50, false)} />
              </div>
              :
              <div>
                <Link to={`/${entityType}/${itemId}`} id={`${entityType}-${itemId}`} onClick={clickToOpenview}>
                  <AtsHighlighter search={searchTerm} target={utils.contentForRender(name, 35, false)} {...(name || '').length > 35 ? {targetFull: name} : {}} />
                </Link>
              </div>
            }

            {quickItem.companyType &&
              <div className='status'>
                {quickItem.companyType === 'MSP' ? 'MSP': utils.titleCase(quickItem.companyType)}
              </div>
            }

            {location &&
            <div className='location'>
              {location}
            </div>
            }

            {status &&
            <div className='status'>
              {status}
            </div>
            }

            {prepareDynamicIDFields()}
          </div>
        </div>
      );
    case 'placement':
      return (
        <div className={`d-flex item${isTypeahead ? ' typeahead' : ''}`}>
          <div className='px-2 pt-1'>
            <div className={`circle ${entityTypePlural}`} />
          </div>
          <div className='py-2 flex-grow-1'>
            {isTypeahead ?
              <div className='biotif-semi-bold font-size-16'>
                {fullName || name}
              </div>
              :
              <div>
                <Link to={`/${entityType}/${itemId}`} id={`${entityType}-${itemId}`} onClick={clickToOpenview}>
                  {fullName ? (origFullName.length > 35 ? <AtsTooltip placement='bottom' tips={origFullName}><span>{fullName}</span></AtsTooltip> : fullName) : name}
                </Link>
              </div>
            }

            <div className='job'>
              <AtsHighlighter search={searchTerm} target={utils.contentForRender(jobTitle, isTypeahead ? 50 : 35, false)} {...withTooltip && (jobTitle || '').length > 35 ? {targetFull: jobTitle}  : {}} />
            </div>

            {companyName &&
            <div className='company'>
              <AtsHighlighter search={searchTerm} target={utils.contentForRender(companyName, isTypeahead ? 50 : 35, false)} {...withTooltip && companyName.length > 35 ? {targetFull: companyName}  : {}} />
            </div>
            }

            {email &&
            <div className='email'>
              <AtsHighlighter search={searchTerm} target={utils.contentForRender(email, isTypeahead ? 50 : 35, false)} {...withTooltip && email.length > 35 ? {targetFull: email}  : {}} />
            </div>
            }

            {mobilePhone &&
            <div className='phone'>
              {utils.phoneHighlighter(searchTerm, mobilePhone, isUSA)} (mobilePhone)
            </div>
            }

            {location &&
            <div className='location'>
              {location}
            </div>
            }

            {status &&
            <div className='status'>
              {status}
            </div>
            }

            {prepareDynamicAdditionalFields()}
            {prepareDynamicIDFields()}
          </div>
        </div>
      );
    case 'candidate':
      return (
        <div className={`d-flex item${isTypeahead ? ' typeahead' : ''}`}>
          <div className='px-2 pt-1'>
            <div className={`circle ${entityTypePlural}`} />
          </div>
          <div className='py-2 flex-grow-1'>
            {isTypeahead ?
              <div className='biotif-semi-bold font-size-16'>
                {fullName || name}
              </div>
              :
              <div>
                <Link to={`/${entityType}/${itemId}`} id={`${entityType}-${itemId}`} onClick={clickToOpenview}>
                  {fullName ? (origFullName.length > 35 ? <AtsTooltip placement='bottom' tips={origFullName}><span>{fullName}</span></AtsTooltip> : fullName) : name}
                </Link>
              </div>
            }

            {currentJob &&
            <div className='job'>
              {currentJob}
            </div>
            }

            {currentCompany &&
            <div className='company'>
              <AtsHighlighter search={searchTerm} target={utils.contentForRender(currentCompany, isTypeahead ? 50 : 35, false)} {...withTooltip && currentCompany.length > 35 ? {targetFull: currentCompany}  : {}} />
            </div>
            }

            {email &&
            <div className='email'>
              <AtsHighlighter search={searchTerm} target={utils.contentForRender(email, isTypeahead ? 50 : 35, false)} {...withTooltip && email.length > 35 ? {targetFull: email}  : {}} />
            </div>
            }

            {mobilePhone &&
            <div className='phone'>
              {utils.phoneHighlighter(searchTerm, mobilePhone, isUSA)} (mobilePhone)
            </div>
            }

            {location &&
            <div className='location'>
              {location}
            </div>
            }

            {status &&
            <div className='status'>
              {status}
            </div>
            }

            {prepareDynamicAdditionalFields()}
            {prepareDynamicIDFields()}
          </div>
        </div>
      );
    default:
      return (
        <div className={`d-flex item${isTypeahead ? ' typeahead' : ''}`}>
          <div className='px-2 pt-1'>
            <div className={`circle ${entityTypePlural}`} />
          </div>
          <div className='py-2 flex-grow-1'>
            {isTypeahead ?
              <div className='biotif-semi-bold font-size-16'>
                {fullName || name}
              </div>
              :
              <div>
                <Link to={`/${entityType}/${itemId}`} id={`${entityType}-${itemId}`} onClick={clickToOpenview}>
                  {fullName ? (origFullName.length > 35 ? <AtsTooltip placement='bottom' tips={origFullName}><span>{fullName}</span></AtsTooltip> : fullName) : name}
                </Link>
              </div>
            }

            {companyName &&
            <div className='company'>
              <AtsHighlighter search={searchTerm} target={utils.contentForRender(companyName, isTypeahead ? 50 : 35, false)} {...withTooltip && companyName.length > 35 ? {targetFull: companyName}  : {}} />
            </div>
            }

            {email &&
              <div className='email'>
                <AtsHighlighter search={searchTerm} target={utils.contentForRender(email, isTypeahead ? 50 : 35, false)} {...withTooltip && email.length > 35 ? {targetFull: email}  : {}} />
              </div>
            }

            {mobilePhone &&
              <div className='phone'>
                {utils.phoneHighlighter(searchTerm, mobilePhone, isUSA)} (mobilePhone)
              </div>
            }

            {location &&
            <div className='location'>
              {location}
            </div>
            }

            {status &&
            <div className='status'>
              {status}
            </div>
            }

            {prepareDynamicAdditionalFields()}
            {prepareDynamicIDFields()}
          </div>
        </div>
      );
    }
  }

  return (
    renderItem()
  );
}

