function getTermFromTerms(terms, offset) {
  for (let i = offset; i <= terms.length; i++) {
    if (terms[i] === ' ' || i === terms.length) {
      return terms.slice(offset, i);
    }
  }
}

function checkMatch(searchTerm, target) {
  if (!target || !searchTerm) {
    return false;
  }

  try {
    for (const term of searchTerm.split(' ')) {
      if (new RegExp(addEscapeForString(target), 'gi').test(term) || new RegExp(addEscapeForString(term), 'gi').test(target)) {
        return true;
      }
    }
  } catch (error) {
    console.log(error);
  }

  return false;
}

function addEscapeForString(string) {
  // Escape characters with special meaning either inside or outside character sets.
  if (typeof string !== 'string') {
    console.log(new TypeError('Expected a string'), string);
  }

  return string
    .replace(/[|\\{}()[\]^$+*?.]/g, '\\$&')
    .replace(/-/g, '\\x2d');
}

export {
  getTermFromTerms,
  checkMatch,
  addEscapeForString,
};
