import React, {useState} from 'react';

import {AtsModal, AtsInput, Button, AtsSelect} from '../common';

import useForm from '../../hooks/useForm';
import useLookup from '../../hooks/useLookup';

import {cloneDeep, has, uniqBy} from '../../libs/lodash';
import utils from '../../libs/utils';
import { prepareForRenderLists, prepareForApiList } from './libs/utils';
import { entityTypeOptions, categoryOptions } from '../../constants/listConst';
import maxMinConst from '../../constants/maxMinConst';
import ApiClient from '../../services/ApiClient';
import AtsCheckBox from '../common/AtsCheckBox';
import apiUserStatus from '../../services/apiUserStatus';

export default function EditModal({actionType, modalVisible, modalCancel, formData, saveContent, defaultValue, addNewFromBulkActionCallback}) {
  const { userPermissionGroupIds = [] } = apiUserStatus.get() || {};
  const [isLoading, setIsLoading] = useState(false);
  const fromBulkAction = addNewFromBulkActionCallback;
  const [initData] = useState(
    actionType=== 'Edit' ?
      {
        ...formData,
        sharedWithIds: utils.prepareCombinedIds({userIds: formData.userIds || [], teamIds: formData.teamIds || []})
      }
      :
      defaultValue
  );

  const lookups = useLookup({names: ['teams', 'users']});
  const combinedLookups = utils.prepareCombinedLookups({
    userIds: uniqBy(((formData || {}).users || []).concat(lookups.users || []), 'id'),
    teamIds: uniqBy(((formData || {}).teams || []).concat(lookups.teams || []), 'id')
  });

  const checkBoxCallback = () => {
    const updatedValues = {isPublic: !values.isPublic};
    if (!values.isPublic) {
      updatedValues.sharedWithIds = [];
    }

    handleChange(updatedValues);
  };

  const formSubmit = async() => {
    if (!isLoading) {
      try {
        let fn;
        const requestBody = prepareForApiList(values);
        if (actionType === 'Add') {
          fn = ApiClient.listsCreate;
        }
        if (actionType === 'Edit') {
          fn = ApiClient.listsUpdate;
        }

        setIsLoading(true);
        const res = await fn(values.id ? {id: values.id} : {}, requestBody);

        const updateValues = cloneDeep(values);
        if(values.id) {
          updateValues.userIds = requestBody.userIds;
          updateValues.teamIds = requestBody.teamIds;
          updateValues.users = utils.convertIdsToOptionFormat(uniqBy((formData || {}).users.concat(lookups.users), 'id'), updateValues.userIds);
          updateValues.teams = utils.convertIdsToOptionFormat(uniqBy((formData || {}).teams.concat(lookups.teams), 'id'), updateValues.teamIds);

          delete updateValues.sharedWithIds;
        }

        // add new from list
        if(saveContent) {
          saveContent(!values.id ? prepareForRenderLists(res.body) : prepareForRenderLists({...updateValues, updatedAt: (new Date()).toISOString()}));
        }

        // add new from bulk action
        if(addNewFromBulkActionCallback) {
          addNewFromBulkActionCallback(res.body);
        }

        setIsLoading(false);
        modalCancel();
      } catch (error) {
        console.log('list submit error:', error);
        setIsLoading(false);
      }
    }
  };

  const {values, formValidated, handleChange, handleSubmit} = useForm(initData, formSubmit);

  const updateCallback = (updatedValues) => {
    if(has(updatedValues, 'entityType')) {
      if(updatedValues.entityType !== values.entityType) {
        handleChange({...updatedValues, entities: [], entityIds: []});
      } else {
        handleChange(updatedValues);
      }
    } else if (has(updatedValues, 'entities')) {
      handleChange({...updatedValues, entityIds: updatedValues.entities.map((obj) => obj[(values.entityType || '').toLowerCase() + 'Id'])});
    } else {
      handleChange(updatedValues);
    }
  };

  return (
    <AtsModal
      id='edit-modal'
      modalTitle={`${actionType} ${fromBulkAction ? 'to' : ''} ${values.id ? 'List Details' : 'New List'}`}
      modalSize='md'
      modalVisible={modalVisible}
      modalCancel={modalCancel}
    >
      <form name='listForm' noValidate onSubmit={(e) => handleSubmit(e)} autoComplete='off'>
        {fromBulkAction ?
          <div className='row col-12'>
            <div className='value' style={{'marginTop': '-20px'}}>{utils.camelize(values.entityType)} List</div>
          </div>
          :
          ''
        }

        <div className='form-row col-12 mx-0 px-0 pt-3'>
          <div className={`form-group pl-0 ${fromBulkAction ? 'col-12 pr-0' : 'col-6 pr-3'}`}>
            <label htmlFor='name'>
              List Name
              <span className='required'>*</span>
            </label>
            <AtsInput
              id='name'
              name='name'
              required={true}
              validated={formValidated}
              defaultValue={values.name || ''}
              callback={handleChange}
            />
          </div>

          {fromBulkAction ? '' :
            <div className='form-group col-6 pr-0 pl-3'>
              <label htmlFor='entityType'>
                Object Type
                <span className='required'>*</span>
              </label>

              <AtsSelect
                id='entityType'
                name='entityType'
                isClearable={false}
                isDisabled={values.id}
                placeholder='Select Object Type'
                defaultValue={values.entityType}
                required={true}
                options={entityTypeOptions || []}
                validated={formValidated}
                callback={updateCallback}
              />
            </div>
          }
        </div>

        <div className='form-row col-12 mx-0 px-0 pt-3'>
          <div className='form-group col-9 mb-0'>
            <label className='pr-4'>
              Shared with
            </label>
          </div>

          {userPermissionGroupIds.includes('MANAGE_SYSTEM_SHARED_DATA') ?
            <div className='form-group col-3 float-right mb-0'>
              Public
              <AtsCheckBox
                name='isPublic'
                id='isPublic'
                defaultValue={values.isPublic ? ['CHECKED'] : []}
                options={[{label: '', value: 'CHECKED'}]}
                handleChange={checkBoxCallback}
              />
            </div>
            :
            ''
          }
        </div>

        <div className='form-group'>
          <AtsSelect
            id='sharedWithIds'
            name='sharedWithIds'
            isDisabled={values.isPublic}
            placeholder='Search users and teams'
            isMulti={true}
            required={false}
            options={combinedLookups}
            callback={handleChange}
            validated={formValidated}
            defaultValue={values.sharedWithIds || []}
          />
        </div>

        <div className={`form-row col-12 mx-0 px-0${values.scope === 'TEAM' ? '' : ' pt-3'}`}>
          <div className='form-group col-6 pl-0 pr-3'>
            <label htmlFor='category'>
              Type
              <span className='required'>*</span>
            </label>
            <AtsSelect
              id='category'
              name='category'
              isClearable={false}
              placeholder='Select Type'
              defaultValue={values.category || 'STANDARD'}
              required={true}
              options={categoryOptions || []}
              validated={formValidated}
              callback={handleChange}
            />
          </div>
        </div>

        <div className='form-row col-12 mx-0 px-0 pt-3'>
          <div className='form-group col-12 px-0'>
            <label htmlFor='desc'>
              Summary
            </label>
            <AtsInput
              id='desc'
              name='desc'
              maxLength={maxMinConst.MediumTextMaxlength}
              required={false}
              validated={formValidated}
              defaultValue={values.desc || ''}
              callback={handleChange}
            />
          </div>
        </div>

        {fromBulkAction || values.id ? '' : <div className='light-grey-text'>You can add objects to this list later</div>}

        <div className="d-flex justify-content-end pt-5">
          <Button type='button' variant='mb-4 btn-secondary mr-4' click={modalCancel}>Cancel</Button>
          <Button type='submit' id='save-list' variant='mb-4 btn-primary ats-md-btn'>Save</Button>
        </div>
      </form>
    </AtsModal>
  );
}
