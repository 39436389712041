import React, { useState, useEffect } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import './style.scss';

export default function AtsDropdown(props) {
  const [selected, setSelected] = useState(props.default);
  const ops = props.dropdownChoices;

  useEffect(() => {
    setSelected(props.default);
  }, [props.default]);

  function onHandleClick(userSelected) {
    if(userSelected !=='NEW') {
      setSelected(userSelected);
    }

    if (props.callback) {
      if (!isNaN(parseInt(userSelected) ) ) {
        props.callback('maxDistance', parseInt(userSelected));
      } else {
        props.callback(props.id, userSelected);
      }
    }
  }

  return (
    <>
      <div className={`atsDropdown-title d-inline-block ${props.labelVariant || ''}`}>{props.label}</div>
      <Dropdown className='d-inline-block atsDropdown'>
        <Dropdown.Toggle className='atsDropdownButton' id={`${props.id}-dropdown`}
          variant={`light dark-blue-text ${props.variant ? props.variant : ''}`}
          style={{'marginTop': isNaN(parseInt(selected))  ? '-4px' : ''}}>
          { !props.hideSelected ? ops[selected] : <i className='fa fa-chevron-down' />}
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {Object.keys(ops).map((opKey) =>
            <Dropdown.Item key={opKey}
              disabled={props.disabled  || '' + selected === '' + opKey}
              className={'' + selected === '' + opKey ? 'selected' : ''}
              onClick={() => onHandleClick(opKey)}>
              {ops[opKey]}
            </Dropdown.Item>
          )}
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
}
