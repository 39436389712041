import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import useModal from '../../../hooks/useModal';
import {get, has, isEqual, set} from '../../../libs/lodash';
import ErrorHandler from '../../../services/errorHandler';
import AtsConfirmModal from '../AtsModal/AtsConfirmModal';

import apiUserStatus from '../../../services/apiUserStatus';
import './style.scss';
import DropzoneFilesList from './DropzoneFilesList';
import searchConst from '../../../constants/searchConst';
import {getMetadata} from '../../../actions/searchEntity';
import {useDispatch} from 'react-redux';
import useEditMode from '../../../hooks/useEditMode';
import usePrevious from '../../../hooks/usePrevious';
import utils from '../../../libs/utils';
import DropzoneArea from './DropzoneArea';
import DropzoneActions from './DropzoneActions';
import ApiClient from '../../../services/ApiClient';

export default function Dropzone(props) {
  const dispatch = useDispatch();
  const user = apiUserStatus.get();
  const {settings = {} } = user || {};

  const [files, setFiles] = useState([]);
  const {modalVisible, toggleModal} = useModal();
  const [resume, setResume] = useState({});

  const [supplementalDocuments, setSupplementalDocuments] = useState((props.supplementalDocuments || []).map(doc => { return { attachmentId: doc.attachmentId }; }));

  // prevent hotkey trigger during attachment upload form opening
  const [addEditMode, removeEditMode] = useEditMode();

  const prevDefaultFiles = usePrevious(props.defaultValue);
  useEffect(() => {
    if (props.multiple) {
      if (!isEqual(props.defaultValue, prevDefaultFiles)) {
        setFiles(props.defaultValue || []);
      }
    } else {
      setFiles(props.defaultValue ? [props.defaultValue] : []);
    }
  }, [props.multiple, props.defaultValue, prevDefaultFiles]);

  const bulkUploadFiles = async (e) => {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }

    try {
      utils.prepareDisabledButton(null, '#done-drop-zone', true);

      const newDocuments = [];
      let getError = false;
      for (let fileIndex = 0; fileIndex < files.length; fileIndex++) {
        try {
          const file = files[fileIndex];
          if (get(file, 'effectiveDate')) {
            file.effectiveDate = utils.dateOnlyForApi(file.effectiveDate).toISOString();
          }
          if (get(file, 'expirationDate')) {
            file.expirationDate = utils.dateOnlyForApi(file.expirationDate).toISOString();
          }
          const response = await ApiClient.attachmentsCreate({}, file, { headers: { 'Content-Type': 'multipart/form-data' } });
          const newFile = {...file, attachmentId: response.body.attachmentId};

          window.amplitudeLogEvent('create', {
            action: 'upload documents',
            object: (props.entityType || '').toLowerCase()
          });
          removeFile(fileIndex);
          newDocuments.push({attachmentId: newFile.attachmentId});
        } catch (error) {
          console.log(error);
          getError = !getError;
        }
      }

      const entityTypePlural = searchConst.entityMap[props.entityType.toLowerCase()];
      const operationId = `${entityTypePlural}Update`;

      const updatedSupplementalDocuments = [...supplementalDocuments, ...newDocuments];


      if (newDocuments.length) {
        await ApiClient[operationId](
          {
            id: props.entityId
          },
          {
            supplementalDocuments: updatedSupplementalDocuments
          }
        );

        setSupplementalDocuments(updatedSupplementalDocuments);
        
        if (!props.noMetaData) {
          dispatch(getMetadata({entityType: props.entityType.toLowerCase(), id: props.entityId}));
        }
        
        props.callback({supplementalDocuments: updatedSupplementalDocuments, getError});
      }
    } catch (err) {
      console.log(err);
    } finally {
      utils.prepareDisabledButton(null, '#done-drop-zone', false);
    }
  };

  const removeFile = (index) => {
    if (props.multiple) {
      const updatedFiles = [
        ...files.slice(0, index),
        ...files.slice(index+1)
      ];
      setFiles(updatedFiles);
    } else {
      setFiles([]);
      props.callback({[props.name]: null});
    }
  };

  const updateFiles = (data) => {

    if (has(data, 'remove')) {
      const fileIndex = parseInt(data.remove);
      removeFile(fileIndex);
    } else {
      const keyName = Object.keys(data)[0];
      const fileIndex = parseInt(keyName.split('-')[0]);
      const fieldName = keyName.split('-')[1];
      const value = data[keyName];
      const newFile = {
        ...files[fileIndex],
        [fieldName]: value
      };
      setFiles([...files.slice(0, fileIndex), newFile, ...files.slice(fileIndex+1)]);
    }
  };

  const cancelBulkUpload = (e) => {
    e.preventDefault();
    removeEditMode('files-list-body');
    props.callback();
  };

  const parseResume = async (resume) => {
    try {
      const value = resume['resume'];
      setResume(value);
      if ( ApiClient.resumesParse ) {
        const res = await ApiClient.resumesParse({attachmentId: value.attachmentId});
        const launch = res.body.parsed.launch;
        const {contactInfo = {} } = launch;
        if (!get(contactInfo, 'address.country')) {
          set(contactInfo, 'address.country', settings.defaultAutofillCountry);
        }

        let jobTitle = Array.isArray(launch.jobTitles) ? launch.jobTitles[0] : launch.jobTitles;
        jobTitle = utils.titleCase(jobTitle);
        let company = Array.isArray(launch.companies) ? launch.companies[0] : launch.companies;
        company = utils.titleCase(company);

        const firstName = has(launch, 'contactInfo.name.firstName') ? get(launch, 'contactInfo.name.firstName') : '';
        const lastName = has(launch, 'contactInfo.name.lastName') ? get(launch, 'contactInfo.name.lastName') : '';

        const newCandidate = {
          'resume': value,
          'jobTitle': jobTitle,
          'currentCompany': company,
          'candidateName': contactInfo ? {
            firstName: utils.titleCase(firstName),
            lastName: utils.titleCase(lastName)
          } : {},
          'additionalInfo': {
            'primaryEmail': contactInfo ? contactInfo.email : null,
            'mobilePhone': contactInfo && contactInfo.phone ? contactInfo.phone.replace(/\D/g, '') : null,
            'address': contactInfo.address,
            'dupesCheckAt': (new Date()).toISOString()
          }
        };
        window.amplitudeLogEvent('create', {action: 'drop resume', object: 'candidate'});

        props.callback(newCandidate);
      }
    } catch (error) {
      console.log(error);

      const errorMsg = ErrorHandler(error);
      if(errorMsg.indexOf('not supported') !== -1) {
        toggleModal();
      }
    }
  };

  const confirmAddCandidate = () => {
    toggleModal();
    props.callback({'resume': resume});
  };

  const confirmModal =
    <AtsConfirmModal
      modalVisible={modalVisible}
      modalCancel={toggleModal}
      confirmCallback={confirmAddCandidate}>
      <div className='pb-5 font-size-18'>Are you sure that you want to add this resume?</div>
    </AtsConfirmModal>;

  return (
    <>
      { props.dropType === 'supplementalDocuments' ? <DropzoneFilesList files={files} updateFiles={updateFiles} /> : ''}
      <DropzoneArea props={props} files={files} setFiles={setFiles} removeFile={removeFile} addEditMode={addEditMode} parseResume={parseResume} />
      { props.dropType === 'supplementalDocuments' ? <DropzoneActions files={files} bulkUploadFiles={bulkUploadFiles} cancelBulkUpload={cancelBulkUpload} /> : ''}
      { props.dropType === 'resume-parsing' ? confirmModal : ''}
    </>
  );
}

Dropzone.propTypes = {
  name: PropTypes.string.isRequired,
  entityType: PropTypes.string.isRequired,
  required: PropTypes.bool,
  multiple: PropTypes.bool,
  defaultValue: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  acceptFileTypeText: PropTypes.string,
  accept: PropTypes.string,
  buttonName: PropTypes.string,
  validated: PropTypes.bool,
  callback: PropTypes.func.isRequired,
  noMetaData: PropTypes.bool
};
