import React from 'react';
import { get, has } from '../../../libs/lodash';
import AtsDatePicker from '../AtsDatePicker';
import AtsInput from '../AtsInput';
import AtsSelect from '../AtsSelect';
import Lookups from '../../../services/Lookups';

export default ({ files, updateFiles }) => {
  const lookups = Lookups.lookups;

  const headers = [
    {
      key: 'name',
      title: 'Name',
      className: 'col-2 p-1 name',
      component: AtsInput,
      required: true
    },
    {
      key: 'scope',
      title: 'Visibility',
      className: 'col-2 p-1 visibiltyWidth',
      component: AtsSelect,
      options: lookups.attachmentScopes,
      required: true
    },
    {
      key: 'attachmentType',
      title: 'Type',
      className: 'col-2 p-1 attachmentType',
      component: AtsSelect,
      options: lookups.attachmentTypes,
      required: true
    },
    {
      key: 'attachmentAnnualFormSubType',
      title: 'SubType',
      className: 'col-2 p-1',
      component: AtsSelect,
      options: lookups.attachmentAnnualFormSubTypes,
    },
    {
      key: 'attachmentVisaDocumentationSubType',
      title: 'SubType',
      className: 'col-2 p-1',
      component: AtsSelect,
      options: lookups.attachmentVisaDocumentationSubTypes,
    },
    {
      key: 'effectiveDate',
      className: 'col-2 p-1 dateType',
      title: 'Effective Date',
      component: AtsDatePicker,
    },
    {
      key: 'expirationDate',
      className: 'col-2 p-1 dateType',
      title: 'Expiration Date',
      component: AtsDatePicker,
    },
  ];


  const renderHeaders = () => {

    return <div className='d-flex w-100 row col-12' id='files-list-header'>
      {

        headers.map((header, index) => {
          //Skip duplicated header
          if(headers[index-1] && header.title === headers[index-1].title){
            return false;
          }
          return (
            <div className={`${get(header, 'className', '')} file-list-${header.key}`} key={`col-${header.key}-${index}`} >
              {get(header, 'title', '').toUpperCase()} &nbsp;
            </div>
          );
        })
      }
    </div>;
  };

  const updateCallback = (data) => {
    updateFiles(data);

  };

  const prepareColumn = (header, file, fileIndex) => {

    if (header.key === 'remove') {
      return <i className='fa fa-times dark-grey-text' onClick={() => updateCallback({ remove: fileIndex })} />;
    }

    if ((header.key === 'attachmentAnnualFormSubType' || header.key === 'attachmentVisaDocumentationSubType') && (file.attachmentType !== 'VISA_DOCUMENTATION' && file.attachmentType !== 'ANNUAL_FORM')) {

      return <AtsInput id={`${fileIndex}-${header.key}`} name={`${fileIndex}-${header.key}`}
        disabled={true} placeholder='Select' callback={updateCallback} />;
    }

    if (has(header, 'component')) {
      return <header.component
        id={`${fileIndex}-${header.key}`}
        name={`${fileIndex}-${header.key}`}
        defaultValue={get(file, header.key) || header.defaultValue}
        options={header.options || []}
        required={header.required}
        isClearable={false}
        callback={updateCallback}
        maxDate={header.key === 'effectiveDate' && file.expirationDate && new Date(file.expirationDate)}
        minDate={header.key === 'expirationDate' && file.effectiveDate && new Date(file.effectiveDate)}
      />;
    }
  };

  const renderList = () => {
    return (
      <div className='w-100' id='files-list-body'>
        {


          files.map((file, fileIndex) => {
            return <div className='row col-12' key={`row-${fileIndex}`}>
              {
                (headers.concat({ key: 'remove', title: '' })).map((header, headerIndex) => {

                  if (file.attachmentType !== 'VISA_DOCUMENTATION' && header.key === 'attachmentVisaDocumentationSubType') {
                    return false;
                  }
                  if (file.attachmentType === 'VISA_DOCUMENTATION' && header.key === 'attachmentAnnualFormSubType') {
                    return false;
                  }

                  return (
                    <div className={`files-list-col ${header.className || ''} files-list-${header.key}`} key={`col-${fileIndex}-${header.key}`}>
                      {prepareColumn(header, file, fileIndex)}
                    </div>
                  );
                })
              }
            </div>;
          })
        }
      </div>
    );
  };

  return <>
    {
      files.length > 0 ?
        <>
          {renderHeaders()}
          {renderList()}
        </> : ''
    }
  </>;
};
