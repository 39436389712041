export default {
  lunarNewYear: {
    startDate: '2021-02-11',
    endDate: '2021-02-14',
    themeBackground: '#a83527',
    themeButtonBG: '#f60808',
    themeFooterBG: '#661012',
    themeImage: '/img/holiday/lunar-new-year-fu.png',
    themeAnimation: true,
    themeTopic: 'Lunar New Year',
    rainbowText: 'Happy Lunar New Year!',
  },
  internationalWomenHoliday: {
    startDate: '2021-03-07',
    endDate: '2021-03-09',
    themeBackground: '#4d1e53',
    themeButtonBG: '#9E17D5',
    themeFooterBG: '#1c032b',
    themeImage: '/img/holiday/international-women-holiday-lion.png',
    themeTopic: 'International Women\'s Day',
    themeAnimation: true,
  },
  holiHoliday: {
    startDate: '2021-03-28',
    endDate: '2021-03-30',
    themeBackground: '#942667',
    themeButtonBG: '#f8cd46',
    themeFooterBG: '#4e1034',
    themeFooterColor: '#e3e3b4',
    themeImage: '/img/holiday/holi-holiday.png',
    themeTopic: 'Holi',
    themeAnimation: true,
  },
  christmas: {
    startDate: '2021-12-24',
    endDate: '2021-12-28',
    themeBackground: '',
    themeButtonBG: '',
    themeFooterBG: '',
    themeImage: '/assets/img/holiday/christmas.png',
    themeAnimation: true,
  },
};
