import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';

export default function Button ({type, id, variant, click, disabled=false, children}) {
  return (
    <button type={type} className={`btn ${variant}`} id={id} onClick={click} disabled={disabled}>
      {children}
    </button>
  );
}

Button.propTypes = {
  type: PropTypes.string.isRequired,
  id: PropTypes.string,
  variant: PropTypes.string,
  click: PropTypes.func
};
