import React from 'react';
import {get, has, isEmpty} from '../../libs/lodash';

export default function AtsTab({object, tabName, metadata}) {
  function renderTab () {

    if(isEmpty(metadata)) {
      return tabName;
    }

    switch (tabName) {
    case 'Submissions': {
      const submissions = metadata.submissions || {};
      let subTabInfo;
      if(object === 'candidate') {
        subTabInfo = submissions.numTotal ? submissions.numTotal : <i className='fas fa-minus light-grey-text'/>;
      } else {
        subTabInfo = submissions.numActive ? submissions.numActive : <i className='fas fa-minus light-grey-text'/>;
      }
      return (
        <span>
          <span className='tabName'>Submissions</span>
          <span id='submissionsCount' className='badge ml-3'>
            {subTabInfo}
          </span>
        </span>
      );
    }
    case 'Resume': {
      const documents = metadata.documents || {};
      if (documents.numResumes) {
        return 'Resume';
      } else {
        return (
          <span>
            <span className='tabName'>Resume</span>
            <span id='resumeCount' className='badge ml-3'>
              <i className='fas fa-minus light-grey-text'/>
            </span>
          </span>
        );
      }
    }
    case 'Prescreens': {
      return (
        <span>
          <span className='tabName'>Prescreens</span>
          <span id='prescreensCount' className='badge ml-3'>
            {metadata.numPrescreens ? metadata.numPrescreens : <i className='fas fa-minus light-grey-text'/>}
          </span>
        </span>
      );
    }
    case 'Placements': {
      return (
        <span>
          <span className='tabName'>Placements</span>
          <span id='placementsCount' className='badge ml-3'>
            {has(metadata, 'placements.numTotal') ? get(metadata, 'placements.numTotal') : <i className='fas fa-minus light-grey-text'/>}
          </span>
        </span>
      );
    }
    case 'Jobs': {
      return (
        <span>
          <span className='tabName'>Jobs</span>
          <span id='jobsCount' className='badge ml-3'>
            {has(metadata, 'jobs.numTotal') ? get(metadata, 'jobs.numTotal') : <i className='fas fa-minus light-grey-text'/>}
          </span>
        </span>
      );
    }
    case 'Contacts': {
      return (
        <span>
          <span className='tabName'>Contacts</span>
          <span id='contactsCount' className='badge ml-3'>
            {has(metadata, 'contacts.numTotal') ? get(metadata, 'contacts.numTotal') : <i className='fas fa-minus light-grey-text'/>}
          </span>
        </span>
      );
    }
    case 'Appointments': {
      return (
        <span>
          <span className='tabName'>Appointments</span>
          <span id='appointmentsCount' className='badge ml-3'>
            {has(metadata, 'appointments.numTotal') ? get(metadata, 'appointments.numTotal') : <i className='fas fa-minus light-grey-text'/>}
          </span>
        </span>
      );
    }
    case 'Attachments': {
      return (
        <span>
          <span className='tabName'>Documents</span>
          <span id='appointmentsCount' className='badge ml-3'>
            {get(metadata, 'documents.numTotal') ? get(metadata, 'documents.numTotal') : <i className='fas fa-minus light-grey-text'/>}
          </span>
        </span>
      );
    }
    case 'Starts': {
      return (
        <span>
          <span id='startsCount' className='badge'>
            { get(metadata, 'numPlacementStarts') || <i className='fas fa-minus'/>}
          </span>
          <span className='tabName'>{tabName}</span>
        </span>
      );
    }
    case 'Ends': {
      return (
        <span>
          <span id='endsCount' className='badge'>
            { get(metadata, 'numPlacementEnds') || <i className='fas fa-minus'/>}
          </span>
          <span className='tabName'>{tabName}</span>
        </span>
      );
    }
    case 'Appts': {
      return (
        <span>
          <span id='apptsCount' className='badge'>
            { get(metadata, 'numAppointments') || <i className='fas fa-minus'/>}
          </span>
          <span className='tabName'>Appts.</span>
        </span>
      );
    }
    case 'Tasks': {
      return (
        <span>
          <span id='tasksCount' className='badge'>
            { get(metadata, 'numTasksDue') || <i className='fas fa-minus'/>}
          </span>
          <span className='tabName'>{tabName}</span>
        </span>
      );
    }
    case 'Programs': {
      return (
        <span>
          <span className='tabName'>Programs</span>
          <span id='programCount' className='badge ml-3'>
            {has(metadata, 'programs.numTotal') ? get(metadata, 'programs.numTotal') : <i className='fas fa-minus light-grey-text'/>}
          </span>
        </span>
      );
    }
    case 'Deals': {
      return (
        <span>
          <span className='tabName'>Deals</span>
          <span id='dealsCount' className='badge ml-3'>
            {has(metadata, 'deals.numTotal') ? get(metadata, 'deals.numTotal') : <i className='fas fa-minus light-grey-text'/>}
          </span>
        </span>
      );
    }
    default: {
      return '';
    }
    }
  }

  return (
    renderTab()
  );
}
