import React, {useState} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import NotificationItem from './NotificationItem';
import InfiniteScroll from 'react-infinite-scroll-component';
import {AtsConfirmModal} from '../common';
import NewNoteModal from '../Notes/NoteFormModal';
import useModal from '../../hooks/useModal';
import * as notificationActions from '../../actions/notification';
import {useDispatch} from 'react-redux';
import {prepareStatusParam} from './libs/utils';

export default function NotificationList({filteredSearchResult=[], notificationFilter, summary, notifications, fetchMoreData, history, lookups, closeDrawer}) {
  const dispatch = useDispatch();
  const prepareNewItemsList = (lists) => {
    return lists.filter((item) => moment(item.createdAt).isAfter(moment(new Date()).add(-1, 'days')));
  };

  const prepareEarlierItemsList = (lists) => {
    return lists.filter((item) => moment(item.createdAt).isBefore(moment(new Date()).add(-1, 'days')));
  };

  const renderNotificationList = () => {
    return (
      <div className='notification-list-items'>
        {
          prepareNewItemsList(filteredSearchResult).length>0?
            <>
              <div className='items-group-title'>NEW</div>
              {
                prepareNewItemsList(filteredSearchResult).map((item, index) =>
                  <NotificationItem
                    key={`notification-item-${item.notificationId}`}
                    item={{...item, entityType: item.entity.type}}
                    notificationFilter={notificationFilter}
                    history={history}
                    lookups={lookups}
                    closeDrawer={closeDrawer}
                    replyNotification={replyNotification}
                  />
                )
              }
            </> :''
        }
        {
          prepareEarlierItemsList(filteredSearchResult).length>0?
            <>
              <div className='items-group-title'>EARLIER</div>
              {
                prepareEarlierItemsList(filteredSearchResult).map((item, index) =>
                  <NotificationItem
                    key={`notification-item-${item.entity.type}-${index}`}
                    item={{...item, entityType: item.entity.type}}
                    notificationFilter={notificationFilter}
                    history={history}
                    lookups={lookups}
                    closeDrawer={closeDrawer}
                    replyNotification={replyNotification}
                  />
                )
              }
            </> :''
        }
      </div>
    );
  };

  const {modalVisible, toggleModal} = useModal();
  const [modalId, setModalId] = useState('note-form-modal');
  const [activityEntity, setActivityEntity] = useState({});
  const [notifyTargetIds, setNotifyTargetIds] = useState([]);

  const openModal = (modalId) => {
    setModalId(modalId);
    toggleModal();
  };

  const replyNotification = (item) => {
    setActivityEntity({
      entityType: item.entity.type,
      entityId: item.entity.id,
      primaryLink: item.entity.name,
      notification: item,
    });
    setNotifyTargetIds([`notifyUserIds_${item.createdBy.userId}`]);
    openModal('note-form-modal');

    window.amplitudeLogEvent(
      'global notifications',
      {
        action: 'reply notification',
        filterValue: (notificationFilter.searchEntityType || '').toLowerCase(),
        statusSelected: (notificationFilter.statusSelected || '').toLowerCase(),
        searchString: (notificationFilter.searchTerm || '').toLowerCase(),
        object: (item.entity.type || '').toLowerCase()
      }
    );
  };

  const markAllNotificationsAsRead = () => {
    const statusSelected = prepareStatusParam(notificationFilter.statusSelected);
    const parameters = { entityType: notificationFilter.searchEntityType.toUpperCase(), action: 'READ', ...statusSelected};
    dispatch(notificationActions.bulkUpdateNotifications(parameters));
  };

  const clearAllNotifications = () => {
    const statusSelected = prepareStatusParam(notificationFilter.statusSelected);
    const parameters = { entityType: notificationFilter.searchEntityType.toUpperCase(), action: 'DELETE', ...statusSelected};
    dispatch(notificationActions.bulkUpdateNotifications(parameters));
  };

  return (
    <div id='notification-list' className='notification-list'>
      {
        summary.numUnRead > 0 ?
          <span className='notification-bulk-update cursor biotif-semi-bold d-none' onClick={markAllNotificationsAsRead}>Mark all as read</span> :
          <span className='notification-bulk-update cursor biotif-semi-bold d-none' onClick={() => openModal('confirm-modal')}>Clear all</span>
      }
      <InfiniteScroll
        scrollThreshold={0.9}
        dataLength={notifications.length}
        next={fetchMoreData}
        hasMore={summary.numResultsFound > summary.numResultsReturned}
        loader={<div/>}
        scrollableTarget='notification-list'
      >
        {renderNotificationList()}
      </InfiniteScroll>
      {
        modalVisible && modalId === 'note-form-modal'?
          <NewNoteModal
            modalVisible={modalVisible}
            modalCancel={toggleModal}
            history={history}
            disableLink={true}
            entity={activityEntity}
            activityTypeId={97}
            notifyTargetIds={notifyTargetIds}
          /> : ''
      }
      {
        modalVisible && modalId === 'confirm-modal'?
          <AtsConfirmModal
            modalVisible={modalVisible}
            modalCancel={toggleModal}
            confirmCallback={clearAllNotifications}>
            <div className='pb-5 font-size-18'>Are you sure that you want to clear all notifications?</div>
          </AtsConfirmModal> : ''
      }
    </div>
  );
};

NotificationList.propTypes = {
  filteredSearchResult: PropTypes.array.isRequired,
  lookups: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  notificationFilter: PropTypes.object.isRequired,
};
