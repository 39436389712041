import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';

import { BadgeList, Metadata } from '../../../common';
import RecruiterInitialName from './RecruiterInitialName';
import Location from './Location';
import UpdatedCreatedInfo from './UpdatedCreatedInfo';

import utils from '../../../../libs/utils';
import {isEmpty} from '../../../../libs/lodash';
import LinkToNewTabForEntity from './LinkToNewTabForEntity';

export default function Company({entity, isActive, lookups, openNewTab}) {
  return (
    <>
      {isActive ?
        <div className='active-item d-flex align-items-center'>
          {utils.contentForRender(entity.name, (entity.badges || []).length === 0 ? 25: 20)}
          <BadgeList badges={entity.badges || []} width='11' height='13' />
          <RecruiterInitialName recruiter={entity.owner || {firstName: '', lastName: ''}} />
        </div>
        :
        <div className='d-flex align-items-center'>
          {openNewTab ?
            <LinkToNewTabForEntity entity={entity}/>
            :
            <Link to='#' className='biotif-semi-bold font-size-16 d-flex'>{utils.contentForRender(entity.name, (entity.badges || []).length === 0 ? 25 : 20)}</Link>
          }
          <BadgeList badges={entity.badges} width='11' height='13' />
          <RecruiterInitialName recruiter={entity.owner || {firstName: '', lastName: ''}} />
        </div>
      }

      {entity.companyType && <div className='pt-1'>{utils.convertIdToName(lookups, 'companyTypes', entity.companyType)}</div>}

      <Location entity={entity} />

      <div className={`pt-1${!isEmpty(entity.jobTypes) ? '' : ' d-none'}`}>
        {utils.convertIdsToOptionFormat(lookups.jobTypes || [], entity.jobTypes || []).map((type) => type.name).join(', ')}
      </div>

      <div className='pt-1'>
        {utils.convertIdToName(lookups, 'companyStatuses', entity.status)}
      </div>

      <Metadata entityType='company' metadata={entity.metadata || {}} />

      <UpdatedCreatedInfo entityType='company' createdAt={entity.createdAt} updatedAt={entity.updatedAt} />
    </>
  );
};

Company.propTypes = {
  entity: PropTypes.object.isRequired,
  isActive: PropTypes.bool
};

