import React from 'react';
import AtsModal from '../AtsModal';
import Button from '../Button';

export default function AtsConfirmModal({modalId, modalTitle, modalVisible, modalCancel, confirmCallback, children, disabled=false, showCancelBtn=true, confirmButtonText='Confirm'}) {
  return (
    <AtsModal
      id={modalId? modalId : 'confirm-modal'}
      modalTitle={modalTitle ? modalTitle : ''}
      modalSize='md'
      modalVisible={modalVisible}
      modalCancel={modalCancel}
    >
      <>
        {children}
        <div className='form-group'>
          <div className='d-flex justify-content-end pt-3'>
            {showCancelBtn ?
              <Button type='button' variant='btn-secondary mr-4' click={() => modalCancel()}>
                Cancel
              </Button>
              :
              ''
            }
            <Button type='button' variant='btn-primary ats-md-btn mr-4' disabled={disabled} click={() => confirmCallback()}>
              {confirmButtonText}
            </Button>
          </div>
        </div>
      </>
    </AtsModal>
  );
}
