import React, {useLayoutEffect} from 'react';
import apiUserStatus from '../services/apiUserStatus';

export default function Logout() {
  useLayoutEffect(() => {
    apiUserStatus.remove('user');
    window.location.href = '/login';
  }, []);

  return (<div>&nbsp;</div>);
}
