import { useState } from 'react';

export default function useModal(defaultState = false) {
  const [modalVisible, setModalVisible] = useState(defaultState);
  const toggleModal = () => {
    setModalVisible(!modalVisible);
  };

  return {
    modalVisible,
    toggleModal
  };
};
