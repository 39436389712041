import React, {useEffect, useState} from 'react';
import Select, {createFilter} from 'react-select';
import AtsHighlighter from '../AtsHighlighter';
import PropTypes from 'prop-types';
import {keyBy, isEmpty, flattenDeep, get, has} from '../../../libs/lodash';
import './style.scss';

export default function AtsSelect(props) {
  const [selectedOptions, setSelectedOptions] = useState(!props.isMulti ? '' : []);
  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    const optionMap = keyBy(props.showRecentViewed && !isEmpty(props.options) && has(props.options[0], 'options') ? flattenDeep(props.options.map((p) => p.options)) : props.options, 'id');
    const isDefaultObject = typeof (props.defaultValue) === 'object';
    if (props.defaultValue !== false && ((isDefaultObject && isEmpty(props.defaultValue)) || (!isDefaultObject && !props.defaultValue))) {
      setSelectedOptions(props.isMulti ? [] : '');
    } else {
      if (props.isMulti) {
        setSelectedOptions(props.defaultValue.map((v) => {
          return typeof (v) === 'object' ? v : optionMap[v];
        }));
      } else {
        setSelectedOptions(isDefaultObject ? props.defaultValue : optionMap[props.defaultValue]);
      }
    }
  }, [props.isMulti, props.defaultValue, props.options, props.showRecentViewed]);

  const handleChange = selectedOptions => {
    try {
      props.callback({[props.name]: props.isMulti ? (selectedOptions || []).map(p => p.id) : ((selectedOptions || {}).id !== undefined ? (selectedOptions || {}).id : null)});

      // for Recruiter
      if (props.showRecentViewed) {
        let selected;
        if (props.isMulti) {
          selected = !isEmpty(selectedOptions) ? selectedOptions[0] : {};
        } else {
          selected = selectedOptions || {};
        }

        if(has(selected, 'isRecruiter') && !isEmpty(selected)){
          const entity = selected.isRecruiter ? {entityType: 'RECRUITER'} : {entityType: 'USER'};
          window.updateRecentViewedEntities({...selected, ...entity, recentViewedEntity: true, entityId: get(selected, 'id')});
          if (selected.isRecruiter) {
            window.updateRecentViewedEntities({...selected, ...entity, recentViewedEntity: true, entityId: get(selected, 'id')});
          }

          const eventProperties = {object: 'user'};
          if (selected.recentViewedEntity) {
            eventProperties.action = 'select recent';
          } else {
            eventProperties.action = 'search';
          }
          window.amplitudeLogEvent('search entity field', eventProperties);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleInputChange = inputValue => {
    setInputValue(inputValue);
  };

  const formatLabel = data => {
    if(isEmpty(data)) {
      return '';
    }
    return <AtsHighlighter search={inputValue} target={data.name} />;
  };

  const stringify = option => `${option.label}`;
  const filterOption = createFilter({ stringify});// matchFrom: 'start' });

  const selectClass =
    (!props.required ||
      !props.validated ||
      (props.isMulti && !isEmpty(selectedOptions)) ||
      (!props.isMulti && selectedOptions)) ? '' : ' error';

  return (
    <div className='select form-control'>
      <Select
        isClearable={(props.isClearable === undefined || props.isClearable === true) ? true : props.isClearable}
        isSearchable={true}
        closeMenuOnSelect={props.isMulti ? false : true}
        placeholder={props.placeholder || 'Select'}
        classNamePrefix="ats-select"
        {...props}
        getOptionValue={option => option['id']}
        getOptionLabel={option => option['name']}
        value={selectedOptions}
        onChange={handleChange}
        onInputChange={handleInputChange}
        formatOptionLabel={formatLabel}
        filterOption={filterOption}
        className={`${props.isMulti ? ' multi' : ''}${selectClass}`}
        theme={theme => ({
          ...theme,
          borderRadius: '0.25rem',
          colors: {
            ...theme.colors,
            primary25: '#f3f4f8',
            primary: '#2dcaff',
            neutral50: '#9399B2',
            neutral10: '#e2f8ff',
            danger: '#cd1d58'
          }
        })}
        styles={{
          option: (baseStyles, state) => ({
            ...baseStyles,
            color: (state.data.isInactive) && !state.isSelected ? '#B8BCCD' : baseStyles.color,
          }),
        }}
      />
      <input id={`hidden-${props.id}`}
        disabled={props.disabled || props.isDisabled}
        tabIndex={-1}
        style={{
          opacity: 0,
          height: 0,
          position: 'absolute',
        }}
        defaultValue={selectedOptions}
        required={props.required}
      />
    </div>
  );
};

AtsSelect.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  callback: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array, PropTypes.object, PropTypes.bool]),
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  isMulti: PropTypes.bool,
  validated: PropTypes.bool
};
