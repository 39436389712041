import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import SuspenseWrap from '../SuspenseWrap';
import 'react-phone-number-input/style.css';
import useNotification from '../../../hooks/useNotification';

const Input = React.lazy(() => import('react-phone-number-input/input'));

export default function AtsPhoneNumber(props) {
  const [value, setValue] = useState('');
  const [inputVal, setInputVal] = useState('');
  const {showNotification} = useNotification();

  useEffect(() => {
    if ( props.country=== 'United States' && props.defaultValue) {
      if (props.defaultValue.startsWith('1')) {
        setValue('+' + props.defaultValue);
      } else if (!props.defaultValue.startsWith('+1')) {
        setValue('+1' + props.defaultValue);
      } else {
        setValue(props.defaultValue);
      }
    } else {
      setValue(props.defaultValue || '');
    }
  }, [props.defaultValue, props.country]);

  const handleChange = (value) => {
    // not sure what next if value[0]==='0' .. do, keep crash when field is empty, so add this to fix
    if(!value) {
      setValue('');
      return;
    }
    if (value[0]==='0' || (value[0]==='1' && value[1]==='1') || (value[0]==='1' && value[1]==='0')) {
      showNotification('error', 'Wrong Phone Format!');
      setValue('');
    } else {
      setValue(value);
    }
  };

  const handleKeyPress = (e, value) => {
    const inputVal = e.target.value;
    setInputVal(inputVal);

    if (e.key === 'Enter') {
      props.callback({[props.name]: value ? value : null});
    }
  };

  const handleInputChange = event => {
    setValue(event.target.value);
  };

  return (
    <>
      { props.country === 'United States' ?
        <SuspenseWrap>
          <Input
            id={props.id}
            name={props.name}
            value={value}
            required={props.required}
            minLength={!inputVal || inputVal.indexOf('1') === 0 ?  16 : 14}
            maxLength={!inputVal || inputVal.indexOf('1') === 0 ?  16 : 14}
            country="US"
            onChange={handleChange}
            onBlur={() => props.callback({[props.name]: value ? value : null})}
            onKeyDown={(e) => handleKeyPress(e, value)}
            autoComplete='off'
            className={'form-control'}
          />
        </SuspenseWrap>
        :
        <input type='tel'
          id={props.id}
          placeholder={props.placeholder}
          name={props.name}
          required={props.required}
          disabled={props.disabled}
          value={value}
          onBlur={() => {
            props.callback({[props.name]: value ? value : null});
          }}
          onKeyDown={(e) => handleKeyPress(e, value)}
          onChange={handleInputChange}
          className={`form-control ${props.classname}`}
          autoComplete='off'
        />
      }
    </>
  );
}

AtsPhoneNumber.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  defaultValue:PropTypes.string,
  required: PropTypes.bool
};


