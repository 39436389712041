import React, {useState} from 'react';
import Header from './Header';
import {Link} from 'react-router-dom';
import apiUserStatus from '../../services/apiUserStatus';
import {difference, has} from '../../libs/lodash';
import useModal from '../../hooks/useModal';
import MergeRecordsFormModal from '../MergeRecords/MergeRecordsFormModal';

export default function MoreOptions({drawer, closeDrawer, setCurrDrawer, history}) {
  const { userPermissionGroupIds = [] } = apiUserStatus.get() || {};
  const {modalVisible, toggleModal} = useModal();
  const [modalId, setModalId] = useState('');

  const moreList = [
    {
      text: 'Submissions',
      url: '/submissions',
      id: 'nav-submissions',
      title: 'Submissions',
      permissions:['VIEW_PRIMARY_OBJECTS'],
      eventType: 'global recent',
      eventProperties: {
        action: 'initiate',
        globalNav: 'submission slide-in',
        timer : 'start'
      }
    },
    {
      text: 'Deals',
      url: '/deals',
      id: 'nav-deals',
      title: 'Deals',
      permissions:['VIEW_PRIMARY_OBJECTS'],
      eventType: 'global recent',
      eventProperties: {
        action: 'initiate',
        globalNav: 'deal slide-in',
        timer : 'start'
      }
    },
    {
      text: 'Placements',
      url: '#',
      id: 'nav-placement',
      title: 'Placements',
      permissions:['VIEW_PRIMARY_OBJECTS'],
      eventType: 'global recent',
      eventProperties: {
        action: 'initiate',
        globalNav: 'placement slide-in',
        timer : 'start'
      }
    },
    {
      text: 'Companies',
      url: '#',
      id: 'nav-company',
      title: 'Companies',
      permissions:['VIEW_PRIMARY_OBJECTS'],
      eventType: 'global recent',
      eventProperties: {
        action: 'initiate',
        globalNav: 'company slide-in',
        timer : 'start'
      }
    },
    {
      text: 'Tasks',
      url: '/tasks/list',
      permissions:['VIEW_PRIMARY_OBJECTS'],
      eventType: 'view list',
      eventProperties: {
        action: 'view list',
        object: 'task'
      }
    },
    {
      text: 'Reports',
      url: '/reports',
      id: 'nav-report',
      title: 'Reports',
      permissions:['VIEW_REPORTS_FOR_PRIMARY_OBJECTS'],
      eventType: 'reports',
      eventProperties: {
        action: 'initiate',
        timer : 'start'
      }
    },
    {
      text: 'Import Data',
      url: '/imports/search',
      id: 'nav-import',
      title: 'Import Data',
      permissions:['IMPORT_PRIMARY_OBJECTS'],
      eventType: 'Imports',
      eventProperties: {
        action: 'initiate',
        timer : 'start'
      }
    },
    {
      text: 'Merge Duplicates',
      url: '#/',
      id: 'nav-merge-duplicates',
      title: 'Merge Duplicates',
      eventType: 'mergeDuplicates',
      permissions:['MERGE_COMPANIES', 'MERGE_CONTACTS', 'MERGE_CANDIDATES', 'MERGE_JOBS'],
      eventProperties: {
        action: 'initiate',
        timer : 'start'
      }
    },
    {
      text: 'Mass Email Center',
      url: '/bulkEmails/list',
      id: 'bulkEmails',
      title: 'Mass Email Center',
      permissions:['VIEW_BULK_EMAIL_RESULTS']
    }
  ];

  const myPreferences = [
    {
      text: 'Targets',
      url: '/targets/list',
      permissions:['VIEW_PRIMARY_OBJECTS', 'CREATE_EDIT_USER_TARGETS']
    },
    {
      text: 'Lists',
      url: '/lists/list',
      permissions:['VIEW_PRIMARY_OBJECTS'],
    },
    {
      text: 'Templates',
      url: '/templates/list',
      permissions:['VIEW_PRIMARY_OBJECTS'],
    },
    {
      text: 'Saved Searches',
      url: '/savedSearches/list',
      permissions:['VIEW_PRIMARY_OBJECTS'],
    },
    {
      text: 'Saved Reports',
      url: '/savedReports/list',
      permissions:['VIEW_REPORTS_FOR_PRIMARY_OBJECTS'],
    }
  ];

  const openRecentActivities =(e, item) => {
    try {
      e.preventDefault();
      setCurrDrawer({
        name: item.id.replace('nav-', ''),
        title: item.title,
        showInputBox: item.showInputBox,
        show: true
      });

      if (item.eventType) {
        window.amplitudeLogEvent(item.eventType, item.eventProperties);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const openLinkAndLogEvent = (item) => {
    try {
      closeDrawer();
      if (item.eventType) {
        window.amplitudeLogEvent(item.eventType, item.eventProperties);
      }

      if (item.id === 'bulkEmails') {
        apiUserStatus.update({...apiUserStatus.get(), mailId: ''});

        const massEmailObj = document.querySelector('.massEmail');
        const massEmailListObj = document.querySelector('.massEmailList');
        const massMailBackObj = document.getElementById('mass-mail-back');
        if (massEmailObj) {
          massEmailObj.classList.add('d-none');
        }

        if (massEmailListObj) {
          massEmailListObj.classList.remove('d-none');
        }

        if (massMailBackObj) {
          massMailBackObj.classList.add('d-none');
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const openModal = (event, actionType) => {
    try {
      event.preventDefault();
      setModalId(actionType);
      toggleModal();
      window.amplitudeLogEvent(`create ${actionType}`, {
        action: actionType.toLowerCase(),
        object: actionType.toLowerCase(),
        type: '',
        globalNav: `global create ${actionType}`,
        method: `global create ${actionType}`,
        timer: 'start'
      });
    } catch (err) {
      console.log(err);
    }
  };

  const renderListItem = (item) => {
    try {
      if (has(item, 'permissions') && difference(userPermissionGroupIds, item.permissions).length === userPermissionGroupIds.length) {
        return '';
      }

      if (item.text === 'Merge Duplicates') {
        return (
          <div className='flex col-auto pt-3 pl-5' key={`more-${item.text.toLowerCase()}`}>
            <Link
              to={item.url}
              id={`more-${item.text.toLowerCase()}`}
              className='pl-1'
              onClick={(e) => openModal(e, item.eventType)}>
              {item.text}
            </Link>
          </div>
        );
      }

      return (
        <div className='flex col-auto pt-3 pl-5' key={`more-${item.text.toLowerCase()}`}>
          {(item.text === 'Companies' || item.text === 'Placements') ?
            <Link
              to={item.url}
              id={`more-${item.text.toLowerCase()}`}
              className='pl-1'
              onClick={(e) => openRecentActivities(e, item)}>
              {item.text}
            </Link>
            :
            <Link
              to={item.url}
              id={`more-${item.text.toLowerCase()}`}
              className='pl-1'
              onClick={() => openLinkAndLogEvent(item)}>
              {item.text}
            </Link>
          }
        </div>
      );
    } catch (err) {
      console.log(err);
      return '';
    }
  };

  const mergeRecordsModal = <MergeRecordsFormModal modalVisible={modalVisible} modalCancel={toggleModal} history={history} />;

  return (
    <div className='pb-4'>
      <div className='divider divider-horizontal divider-100 my-4' />
      <Header drawer={drawer} closeDrawer={closeDrawer} />
      <div className='drawer-content px-4' style={{'marginTop': '45px'}}>
        {moreList.map((item) => (
          renderListItem(item)
        ))}

        <div className='flex col-auto pt-5 pl-5'>
          <span className='font-size-22'>My Preferences</span>
        </div>

        {myPreferences.map((item) => (
          renderListItem(item)
        ))}
      </div>
      { modalVisible && modalId === 'mergeDuplicates' ? mergeRecordsModal : '' }
    </div>
  );
};
