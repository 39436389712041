import utils from '../../../libs/utils';
import {compact, get, has, isEmpty, uniq} from '../../../libs/lodash';

const prepareForRenderSubmissionStatus = (entityType, entityData) => {
  if (!entityData) {
    return '';
  }

  if (entityData.stageStatus) {
    return entityData.stageStatus.includes('REJECTED')?'REJECTED':entityData.stageStatus;
  } else {
    return utils.camelize(entityData.status || entityData.openStatus);
  }
};

const prepareForRenderSubmissionUpdatedFieldNames = (activity) => {
  let before = !isEmpty(activity.entity.difference.before) ? Object.keys(activity.entity.difference.before) : [];
  let after = !isEmpty(activity.entity.difference.after) ? Object.keys(activity.entity.difference.after) : [];

  if (activity.entityType === 'COMPANY') {
    before = before.map((fieldValue) => fieldValue === 'owner' || fieldValue === 'coOwners' ? fieldValue === 'owner' ? 'accountManagers' : 'coAccountManagers' : fieldValue);
    after = after.map((fieldValue) => fieldValue === 'owner' || fieldValue === 'coOwners' ? fieldValue === 'owner' ? 'accountManagers' : 'coAccountManagers' : fieldValue);
  }

  return uniq([...before, ...after]).filter((fieldName) => {
    return fieldName !== 'stageStatus' && fieldName !== 'status' && fieldName !== 'openStatus' && fieldName !== 'stage';
  }).map((fieldName) => fieldName === 'commission' ? 'Management Fees' : utils.convertCamelCaseToTitle(fieldName)).join(', ');
};

const prepareForRenderTimelineRelatedEntitiesInfo = (activity, entityType) => {
  let candidateName = utils.shortName(get(activity, 'relatedEntities.candidate.candidateName'));
  let contactName = utils.shortName(get(activity, 'relatedEntities.contact.contactName'));
  let jobTitle = get(activity, 'relatedEntities.job.jobTitle');
  let companyName = get(activity, 'relatedEntities.company.name');
  const dealTitle = get(activity, 'relatedEntities.deal.title', '');

  if (has(activity, 'relatedEntities.placement')) {
    candidateName = utils.shortName(get(activity, 'relatedEntities.placement.candidate.candidateName'));
    contactName = utils.shortName(get(activity, 'relatedEntities.placement.contact.contactName'));
    jobTitle = get(activity, 'relatedEntities.placement.job.jobTitle');
    companyName = get(activity, 'relatedEntities.placement.company.name');
  }

  if (has(activity, 'relatedEntities.job')) {
    companyName = get(activity, 'relatedEntities.job.company.name');
  } else if (has(activity, 'relatedEntities.contact')) {
    companyName = get(activity, 'relatedEntities.contact.company.name');
  }

  const relatedEntitiesInfo = compact([candidateName, contactName, jobTitle, companyName]).join('\n');

  switch (activity.entityType) {
  case 'SUBMISSION':
    if (entityType === 'JOB' && activity.relatedEntities.candidate) {
      return candidateName;
    } else if (entityType === 'CANDIDATE' && activity.relatedEntities.job) {
      return companyName ? `${jobTitle}\n${companyName}` : `${jobTitle}`;
    } else {
      return '';
    }
  case 'CANDIDATE':
    return candidateName;
  case 'JOB':
    return companyName ? `${jobTitle}\n${companyName}` : `${jobTitle}`;
  case 'CONTACT':
    return companyName ? `${contactName}\n${companyName}` : `${contactName}`;
  case 'DEAL':
    return dealTitle;
  default:
    return relatedEntitiesInfo;
  }
};

const isContentMatchSearchFilter = (recordContent, searchFilter, recordEntityType) => {
  try {
    if (!searchFilter) {
      return true;
    }

    searchFilter = (searchFilter || '').toLowerCase();
    const {entity, entityType, relatedEntities, activityType = {}, activityTypeFilter} = recordContent || {};
    const {subject, bodyPreview, content, comment, createdAt, result, entityPhoneNumber} = entity || {};
    const {createdBy = {}, difference} = entity || {};
    const {firstName, lastName} = createdBy;
    const {before, after} = difference || {};
    const {name = ''} = activityType;

    // deal with undefined or null before convert to lowercase
    for (const val of [subject, bodyPreview, comment, content, firstName, lastName, result, name, activityTypeFilter]) {
      if ((val || '').toLowerCase().includes(searchFilter)) {
        return true;
      }
    }

    return ((firstName || '').charAt(0) + (lastName || '').charAt(0)).toLowerCase().includes(searchFilter)
      || (createdAt && (utils.timeForRender(createdAt)).toLowerCase().includes(searchFilter))
      || (entityPhoneNumber || '').toLowerCase().includes(searchFilter.replace(/[^A-Za-z0-9,.@]+/g, ''))
      || `this ${(entityType || '').toLowerCase()}`.includes(searchFilter)
      || (before && (prepareForRenderSubmissionStatus(entityType, before) || '').toLowerCase().includes(searchFilter))
      || (after && (prepareForRenderSubmissionStatus(entityType, after) || '').toLowerCase().includes(searchFilter))
      || (difference && (prepareForRenderSubmissionUpdatedFieldNames(recordContent) || '').toLowerCase().includes(searchFilter))
      || (entity && relatedEntities && (prepareForRenderTimelineRelatedEntitiesInfo(recordContent, recordEntityType) || '').toLowerCase().includes(searchFilter));
  } catch (err) {
    console.log(`isContentMatchSearchFilter ${err}`);
    return false;
  }
};

function getIdValue(data, entityType) {
  if (entityType) {
    return data[entityType.toLowerCase()+'Id'];
  } else {
    const idName = Object.keys(data).find(key => /id$/ig.test(key) );
    return data[idName];
  }
}

function removeDuplicateEntities(mostRecentTimeStamp, prevEntities, recentEntities, entityType) {
  const duplicatedEntityIds = [];
  for (let i = 0; i < prevEntities.length; i++) {
    const prevEntity = prevEntities[i];
    const createdAt = prevEntity.createdAt ? prevEntity.createdAt : (prevEntity.entity && prevEntity.entity.createdAt);
    const receivedDateTime = prevEntity.receivedDateTime ? prevEntity.receivedDateTime : ( prevEntity.entity && prevEntity.entity.receivedDateTime);
    const activityStartDate = prevEntity.activityStartDate ? prevEntity.activityStartDate : (prevEntity.entity && prevEntity.entity.activityStartDate);

    if (createdAt === mostRecentTimeStamp || receivedDateTime === mostRecentTimeStamp || activityStartDate === mostRecentTimeStamp) {
      const idValue = prevEntity.entity && !entityType ? getIdValue(prevEntity.entity) : getIdValue(prevEntity, entityType);
      if (idValue) {
        duplicatedEntityIds.push(idValue);
      }
    } else {
      break;
    }
  }

  if (duplicatedEntityIds.length>0) {
    return recentEntities.filter((recentEntity) => {
      const idValue = recentEntity.entity && !entityType ? getIdValue(recentEntity.entity) : getIdValue(recentEntity, entityType);
      return !duplicatedEntityIds.includes(idValue);
    });
  } else {
    return recentEntities;
  }
}

function convertFilterValuesToStr(activityTypes) {
  return activityTypes.map(type => type.toLowerCase() );
}

export {
  prepareForRenderSubmissionStatus,
  prepareForRenderSubmissionUpdatedFieldNames,
  prepareForRenderTimelineRelatedEntitiesInfo,
  isContentMatchSearchFilter,
  removeDuplicateEntities,
  convertFilterValuesToStr,
};
