import './style.scss';
import React, {useEffect, useState} from 'react';
import usePrevious from '../../../hooks/usePrevious';
import submissionConst from '../../../constants/submissionConst';
import utils from '../../../libs/utils';
import apiUserStatus from '../../../services/apiUserStatus';

export default (props) => {
  const [stages] = useState(submissionConst.stages);
  const prevStage = usePrevious(props.stage);
  const prevStatus = usePrevious(props.status);

  useEffect(() => {
    if (props.stage !== prevStage || props.status !== prevStatus) {
      for (const stage of stages) {
        if (props.status !== 'REJECTED') {
          document.getElementById(stage).classList.remove('inactive');
          document.getElementById(stage).classList.add('active');
        } else {
          document.getElementById(stage).classList.remove('active');
          document.getElementById(stage).classList.add('inactive');
        }

        if (stages.indexOf(stage) < stages.indexOf(props.stage)) {
          document.getElementById(stage).classList.remove('select');
          document.getElementById(stage).classList.add('done');
        } else if (stages.indexOf(stage) === stages.indexOf(props.stage)) {
          document.getElementById(stage).classList.remove('done');
          document.getElementById(stage).classList.add('select');
        } else {
          document.getElementById(stage).classList.remove('select');
          document.getElementById(stage).classList.remove('done');
        }
      }
    }
  }, [stages, props.stage, prevStage, props.status, prevStatus]);

  const selectStage = (e, id) => {
    e.preventDefault();
    const {userPermissionGroupIds=[] } = apiUserStatus.get() || {};
    if (userPermissionGroupIds.includes('CREATE_EDIT_PRIMARY_OBJECTS') && props.status !== 'REJECTED') {
      props.handleChange({stage: id});
    }
  };

  return (
    <>
      <svg id="submission-stages" width="800px" height="40px" viewBox="0 0 800 40" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <title>Select Submission Stage</title>
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g transform="translate(-57.000000, -494.000000)">
            <g transform="translate(57.000000, 494.000000)">
              <g className='stages-container' id={submissionConst.stages[0]} onClick={(e)=>{selectStage(e, submissionConst.stages[0]);}}>
                <path className='rectangle' d="M5,0 L125.094122,0 L125.094122,0 L134,20 L125.094122,40 L5,40 C2.23857625,40 3.38176876e-16,37.7614237 0,35 L0,5 C-3.38176876e-16,2.23857625 2.23857625,5.07265313e-16 5,0 Z" fill="#9399B2"/>
                <text className='text' fontFamily="Biotif-Regular, Biotif" fontSize="14" fontWeight="normal" fill="#FFFFFF">
                  <tspan x="17.119" y="24">{utils.camelize(submissionConst.stages[0])}</tspan>
                </text>
              </g>
              <g className='stages-container' id={submissionConst.stages[1]} onClick={(e)=>{selectStage(e, submissionConst.stages[1]);}}>
                <polygon className='rectangle' fill="#9399B2" points="127 8.67361738e-18 260.445783 8.67361738e-18 269 19.94 260.445783 40 127 40 135.554217 19.9394531"/>
                <text className='text' fontFamily="Biotif-Regular, Biotif" fontSize="14" fontWeight="normal" fill="#FFFFFF">
                  <tspan x="150.74" y="24">{utils.camelize(submissionConst.stages[1])}</tspan>
                </text>
              </g>
              <g className='stages-container' id={submissionConst.stages[2]} onClick={(e)=>{selectStage(e, submissionConst.stages[2]);}}>
                <polygon className='rectangle' fill="#2DCAFF" points="262 8.67361738e-18 395.445783 8.67361738e-18 404 19.94 395.445783 40 262 40 270.554217 19.9394531"/>
                <text className='text' fontFamily="Biotif-SemiBold, Biotif" fontSize="14" fontWeight="500" fill="#FFFFFF">
                  <tspan x="293.037" y="24">{utils.camelize(submissionConst.stages[2])}</tspan>
                </text>
              </g>
              <g className='stages-container' id={submissionConst.stages[3]} onClick={(e)=>{selectStage(e, submissionConst.stages[3]);}}>
                <polygon className='rectangle' fill="#272668" points="397 8.67361738e-18 530.445783 8.67361738e-18 539 19.94 530.445783 40 397 40 405.554217 19.9394531"/>
                <text className='text' fontFamily="Biotif-Regular, Biotif" fontSize="14" fontWeight="normal" fill="#FFFFFF">
                  <tspan x="432.104" y="24">{utils.camelize(submissionConst.stages[3])}</tspan>
                </text>
              </g>
              <g className='stages-container' id={submissionConst.stages[4]} onClick={(e)=>{selectStage(e, submissionConst.stages[4]);}}>
                <polygon className='rectangle' fill="#F3F4F8" points="532 8.67361738e-18 665.445783 8.67361738e-18 674 19.94 665.445783 40 532 40 540.554217 19.9394531"/>
                <text className='text' fontFamily="Biotif-Regular, Biotif" fontSize="14" fontWeight="normal" fill="#4D536D">
                  <tspan x="578.605" y="24">{utils.camelize(submissionConst.stages[4])}</tspan>
                </text>
              </g>
              <g className='stages-container' id={submissionConst.stages[5]} onClick={(e)=>{selectStage(e, submissionConst.stages[5]);}}>
                <path className='rectangle' d="M667,0 L795,0 C797.761424,-5.07265313e-16 800,2.23857625 800,5 L800,35 C800,37.7614237 797.761424,40 795,40 L667,40 L667,40 L675.896744,20 L667,0 Z" fill="#F3F4F8"/>
                <text className='text' fontFamily="Biotif-Regular, Biotif" fontSize="14" fontWeight="normal" fill="#4D536D">
                  <tspan x="713.426" y="24">{utils.camelize(submissionConst.stages[5])}</tspan>
                </text>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </>
  );
};
