import actionTypes from '../constants/actionTypes';
import {compact} from '../libs/lodash';

const initialState = {
  unSavedReports: []
};

export default function unSavedReportsReducer(state = initialState, action){
  switch (action.type) {
  case actionTypes.reportActionTypes.ADD_REPORT:
    const newReports = [
      action.payload,
      ...state.unSavedReports
    ];

    return {
      ...state,
      unSavedReports: newReports
    };
  case actionTypes.reportActionTypes.UPDATE_REPORT:
    const updateReports = compact([
      ...state.unSavedReports.slice(0, action.payload.position),
      action.payload,
      ...state.unSavedReports.slice(action.payload.position + 1)
    ]);

    return {
      ...state,
      unSavedReports: updateReports
    };

  case actionTypes.reportActionTypes.REMOVE_REPORT:
    return {
      ...state,
      unSavedReports: compact([
        ...state.unSavedReports.slice(0, action.payload),
        ...state.unSavedReports.slice(action.payload + 1)
      ])
    };
  default:
    return state;
  }
}
