import actionTypes from '../constants/actionTypes';
import {removeDuplicateEntities} from '../compoments/Activities/libs/utils';
import {get, cloneDeep, isEmpty, has} from '../libs/lodash';
import ApiClient from '../services/ApiClient';
import { activityRelationshipSettings } from '../constants/activityConst';
import utils from '../libs/utils';

export const fetchActivities = (parameters) => {
  return async (dispatch, getState) => {
    try {
      if (!parameters) {
        dispatch({
          type: actionTypes.activityActionTypes.SET_ACTIVITIES,
          payload: { summary: {}, activities: [], prescreens: []}
        });

        return;
      }

      dispatch({
        type: actionTypes.activityActionTypes.FETCH_ACTIVITIES_REQUEST
      });

      parameters.offset = parameters.offset === undefined ? 0 :  parameters.offset;
      parameters.maxResults = parameters.maxResults === undefined ? 50 :  parameters.maxResults;

      if (parameters.offset === 0) {
        dispatch( {
          type: actionTypes.activityActionTypes.SET_ACTIVITIES,
          payload: {summary: getState().activityReducer.data.summary, activities: []}
        });
      }

      const categories = cloneDeep(parameters.categories) || utils.getActivityFilterCategories(parameters.entityType);
      const res = await ApiClient.activityList({...parameters, ...generateActivityFilterCategories(categories, parameters.entityType)});

      dispatch({
        type: actionTypes.activityActionTypes.SET_ACTIVITIES_PARAMS,
        payload: parameters
      });

      res.body.activity = filterDuplicated(categories, res.body.activity);
      if (parameters && parameters.offset !== 0) {
        res.body.activity = getState().activityReducer.data.activities.concat(res.body.activity);
        res.body.summary.numResultsReturned = getState().activityReducer.data.summary.numResultsReturned + res.body.summary.numResultsReturned;
      }

      dispatch({
        type: actionTypes.activityActionTypes.SET_ACTIVITIES,
        payload: res.body
      });

      dispatch({
        type: actionTypes.activityActionTypes.FETCH_ACTIVITIES_SUCCESS,
      });

      // Only need to fetch Prescreens once
      if (parameters.entityId && parameters.entityType === 'CANDIDATE' && !has(parameters, 'categories') && parameters.offset === 0 ) {
        const params = {
          entityId: parameters.entityId,
          entityType: parameters.entityType,
          activityTypeIds: [26],
          offset: 0,
          maxResults: 500,
        };
        const res = await ApiClient.activityList(params);

        dispatch({
          type: actionTypes.activityActionTypes.SET_PRESCREENS,
          payload: res.body
        });
      }

    } catch (error) {
      console.log(error);
      dispatch({
        type: actionTypes.activityActionTypes.FETCH_ACTIVITIES_FAIL
      });
    }
  };
};

const checkRefreshStatusScreened = (processedActivities) => {
  let check = false;
  processedActivities.forEach((activity) => {
    const beforeStatus = get(activity, 'entity.difference.before.status', '');
    const afterStatus = get(activity, 'entity.difference.after.status', '');
    if (beforeStatus === 'UNSCREENED' && afterStatus === 'SCREENED') {
      check = true;
    }
  });
  return check;
};

export const fetchRecentActivities = () => {
  return async (dispatch, getState) => {
    try {
      const startDate = getState().activityReducer.mostRecentTimeStamp;
      const entityType = getState().activityReducer.parameters.entityType;
      const entityId = getState().activityReducer.parameters.entityId;

      if (entityId && entityType) {
        const parameters = {entityId, entityType, startDate};
        const categories = utils.getActivityFilterCategories(entityType);
        const res = await ApiClient.activityList({...parameters, ...generateActivityFilterCategories(categories, entityType)});

        const mostRecentTimeStamp = getState().activityReducer.mostRecentTimeStamp;
        const prevActivities = getState().activityReducer.data.activities;
        const recentActivities = res.body.activity;

        const processedActivities = removeDuplicateEntities(mostRecentTimeStamp, prevActivities, recentActivities);

        dispatch({
          type: actionTypes.activityActionTypes.ADD_RECENT_ACTIVITIES,
          payload: {
            activity: processedActivities,
            summary: res.body.summary
          }
        });

        // check if any backend update of candidate status from UNSCREENED to SCREENED
        if (checkRefreshStatusScreened(processedActivities)) {
          dispatch(refreshStatusScreened(true));
        }
      }
    } catch (error) {
      console.log(error);
      dispatch({
        type: actionTypes.activityActionTypes.FETCH_ACTIVITIES_FAIL
      });
    }
  };
};

export const refreshStatusScreened = (refreshStatusScreened) => {
  return ({
    type: actionTypes.activityActionTypes.REFRESH_STATUS_SCREENED,
    payload: {
      refreshStatusScreened
    }
  });
};

function filterDuplicated(activityFilterCategories, activities) {
  try {
    if (!(isEmpty(activityFilterCategories) || activityFilterCategories.includes('CALL'))) {
      return activities;
    }

    const internetMessageIds = [];

    return activities.filter((content) => {
      // filter duplicated office 365 emails
      // 103 - Inbound
      // 104 - Outbound
      const matched = activityFilterCategories.includes(content.activityTypeFilter) || isEmpty(activityFilterCategories);

      if ([103, 104].includes(get(content, 'activityType.activityTypeId'))) {
        const internetMessageId = get(content, 'entity.internetMessageId');
        let duplicated = true;
        if (!internetMessageIds.includes(internetMessageId)) {
          internetMessageIds.push(internetMessageId);
          duplicated = false;
        }
        return matched && !duplicated;
      } else {
        return matched;
      }
    });
  } catch (err) {
    console.log(err);
  }
}

function generateActivityFilterCategories(categories, entityType) {
  try {
    const relatedCats = activityRelationshipSettings[entityType];

    const filterParams = {categories: categories.filter(cat => !(relatedCats || []).includes(cat))};
    filterParams.relatedEntityTypes = (relatedCats || []).filter(cat => categories.includes(cat));

    return filterParams;
  } catch (err) {
    console.log(`generateActivityFilterCategories ${err}`);
  }
}

