const acceptFileType = 'application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.wordprocessingml.template, text/html';

// get name/label
const maxFullNameLength   = 40;
const canEmbedFileTypes = ['.htm', '.html', '.pdf', '.png', '.jpg', '.jpeg', '.gif'];
const referredByDependedValue = ['REFERRAL', 'REFERRAL_CLIENT', 'REFERRAL_CANDIDATE', 'REFERRAL_OTHER'];
const phonePriorityList = ['mobilePhone', 'homePhone', 'workPhone'];
const dojoRating = [
  {
    id: 1,
    name: 1
  },
  {
    id: 2,
    name: 2
  },
  {
    id: 3,
    name: 3
  },
  {
    id: 4,
    name: 4
  },
  {
    id: 5,
    name: 5
  },
];

const candidateConst = {
  referredByDependedValue,
  acceptFileType,
  maxFullNameLength,
  canEmbedFileTypes,
  phonePriorityList,
  dojoRating
};
export default candidateConst;
