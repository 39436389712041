const defaultTab = {
  'candidate': 'details',
  'company': 'details',
  'contact': 'details',
  'job': 'details',
  'placement': 'details'
};

const detailsConst = {
  defaultTab
};

export default detailsConst;
