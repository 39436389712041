import apiUserStatus from './apiUserStatus';

export const reserveOriginalPathname = (pathname) => {
  if (!pathname.includes('/login') && !pathname.includes('/authorize')) {
    window.localStorage.setItem('reserveOriginalPathname', pathname);
  }
};

export const retrieveOriginalPathname = (history) => {
  const user =  apiUserStatus.get();
  const reserveOriginalPathname = window.localStorage.getItem('reserveOriginalPathname');
  if (user && reserveOriginalPathname) {
    window.localStorage.removeItem('reserveOriginalPathname');
    history.push(reserveOriginalPathname);
  }
};

export const preparePathname = () => {
  return window.localStorage.getItem('reserveOriginalPathname') || '/';
};
