const exportPermissionLookup = {
  'company': 'EXPORT_COMPANIES',
  'contact': 'EXPORT_CONTACTS',
  'candidate': 'EXPORT_CANDIDATES',
  'job': 'EXPORT_JOBS',
  'submission': 'EXPORT_SUBMISSIONS',
  'sub': 'EXPORT_SUBMISSIONS',
  'placement': 'EXPORT_PLACEMENTS',
  'job_coverage': 'EXPORT_REPORTS_JOB_COVERAGE',
  'activity_report': 'EXPORT_REPORTS_ACTIVITY',
  'recruiting_analytics': 'EXPORT_REPORTS_RECRUITING_ANALYTICS',
  'user': 'EXPORT_USERS',
  'admin_settings' : 'EXPORT_ADMIN_DATA',
  'deal': 'EXPORT_DEALS',
};

export default exportPermissionLookup;
