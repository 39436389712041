const countryCurrencyLookup = {
  'United States': 'USD',
  'United Kingdom': 'GBP',
  'Canada': 'CAD',
  'India': 'INR'
};

export {
  countryCurrencyLookup
};
