import React from 'react';
import {compact, isEmpty} from '../../libs/lodash';

export default function FullAddress({addresses, idPrefix='', isRemote=false}) {
  function genFullAddress() {
    if (!addresses) {
      return '';
    }

    // filter all empty fields
    let addrAttrs = ['address1', 'address2', 'city', 'state', 'country'].map((addrKey) => {
      if (addrKey === 'state') {
        return isEmpty(compact([addresses[addrKey], addresses.postalCode])) ? '' : addrKey;
      } else {
        return addresses[addrKey] ? addrKey : '';
      }
    });
    addrAttrs = compact(addrAttrs);

    // build addr html
    let addressInfo =  addrAttrs.map((addrKey, index) => {
      let addrVal = addrKey === 'state' ? compact([addresses[addrKey], addresses.postalCode]).join(' ') : addresses[addrKey];
      addrVal = addrKey === 'country' && addresses[addrKey + 'Name'] ? addresses[addrKey + 'Name'] : addrVal;
      if (addrKey === 'country' && isRemote) {
        addrVal = `${addrVal} (Remote)`;
      }

      return  (
        addrVal  &&
        <span key={addrKey} id={`${idPrefix}${addrKey}`}>
          {addrVal}{index !== addrAttrs.length - 1  ? ', ' : ''}
        </span>
      );
    });

    addressInfo = compact(addressInfo);

    return isEmpty(addressInfo) ? '' : addressInfo;
  }

  return genFullAddress();
};
