import actionTypes from '../constants/actionTypes';
import utils from '../libs/utils';

const initialState = {
  isFetched: false,
  mostRecentTimeStamp: '',
  parameters: {},
  data: {summary: {}, activities: [], prescreens: []},
  refreshStatusScreened: false,
};

export default function activityReducer(state = initialState, action){
  switch (action.type) {
  case actionTypes.activityActionTypes.SET_ACTIVITIES:
    return {
      ...state,
      mostRecentTimeStamp: utils.prepareEntitiesRecentTimeStamp(action.payload.activity),
      data: {
        ...state.data,
        summary: action.payload.summary,
        activities: action.payload.activity ? action.payload.activity : [],
      }
    };
  case actionTypes.activityActionTypes.SET_PRESCREENS:
    return {
      ...state,
      data: {
        ...state.data,
        prescreens: action.payload.activity,
      }
    };
  case actionTypes.activityActionTypes.ADD_RECENT_ACTIVITIES:
    const newPrescreens = action.payload.activity.length>0 ? action.payload.activity.filter((activity) => {return activity.activityType.activityTypeId === 26;}) : [];
    return {
      ...state,
      mostRecentTimeStamp: utils.prepareEntitiesRecentTimeStamp(action.payload.activity) ? utils.prepareEntitiesRecentTimeStamp(action.payload.activity) : state.mostRecentTimeStamp,
      parameters: {
        ...state.parameters,
        offset: action.payload.activity && action.payload.activity.length>0 ? state.parameters.offset + action.payload.activity.length : state.parameters.offset,
        maxResults: action.payload.activity && action.payload.activity.length>0 ? state.parameters.maxResults + action.payload.activity.length : state.parameters.maxResults,
      },
      data: {
        summary: {
          numResultsFound: action.payload.summary ? state.data.summary.numResultsFound + action.payload.summary.numResultsFound : state.data.summary.numResultsFound,
          numResultsReturned: action.payload.summary ? state.data.summary.numResultsReturned + action.payload.summary.numResultsReturned : state.data.summary.numResultsReturned,
        },
        activities: action.payload.activity ? [...action.payload.activity, ...state.data.activities] : state.data.activities,
        prescreens: action.payload.activity ? [...newPrescreens, ...state.data.prescreens] : state.data.prescreens
      }
    };
  case actionTypes.activityActionTypes.FETCH_ACTIVITIES_REQUEST:
    return {
      ...state,
      isFetched: true
    };
  case actionTypes.activityActionTypes.FETCH_ACTIVITIES_SUCCESS:
    return {
      ...state,
      isFetched: false
    };
  case actionTypes.activityActionTypes.FETCH_ACTIVITIES_FAIL:
    return {
      ...state,
      isFetched: false
    };
  case actionTypes.activityActionTypes.SET_ACTIVITIES_PARAMS:
    return {
      ...state,
      parameters: action.payload
    };
  case actionTypes.activityActionTypes.REFRESH_STATUS_SCREENED:
    return {
      ...state,
      refreshStatusScreened: action.payload.refreshStatusScreened,
    };
  case actionTypes.activityActionTypes.ADD_PRESCREEN:
    return {
      ...state,
      data: {
        ...state.data,
        prescreens: [action.payload.prescreen, ...state.data.prescreens]
      }
    };
  default:
    return state;
  }
}

