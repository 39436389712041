import apiUserStatus from '../services/apiUserStatus';
import { get } from '../libs/lodash';
import searchConst from './searchConst';

export const fromLookups = {
  candidates: 'search candidates',
  contacts: 'search contacts',
  jobs: {...searchConst.viewTypeOptions['job'], 'jobDetail': 'search jobs'},
  placements: {...searchConst.viewTypeOptions['placement'], 'placementDetail': 'search placements' },
  companies: 'search companies',
  submissions: {'subTable': 'submission table', 'subKanban': 'submission kanban'},
  deals: {'dealTable': 'deal table', 'dealKanban': 'deal kanban'},
  candidate: 'candidate details',
  contact: 'contact details',
  job: 'job details',
  placement: 'placement details',
  company: 'company details',

  bulkemail: 'mass mail details',
  tasks: 'task list',

  'my-targets': 'targets',
  dashboard: 'dashboard',
  '' : (get(apiUserStatus.get(), 'settings.calendarSettings.viewType') || 'calendar') === 'calendar' ? 'dashboard' : 'targets'
};
