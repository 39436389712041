import ActivityTypeSelect from '../ActivityTypeSelect';
import CreatableInputOnly from '../../../common/AtsSelect/CreatableInputOnly';
import AtsInputNumber from '../../../common/AtsInputNumber';
import AtsSelect from '../../../common/AtsSelect';
import JobFunctionsSkillsSelect from '../../../common/Categories/JobFunctionsSkillsSelect';
import AtsTypeAheadSearchSelect from '../../../common/AtsTypeAheadSearchSelect';
import AtsDatePicker from '../../../common/AtsDatePicker';

import searchConst from '../../../../constants/searchConst';

const executiveTypes = ['None', 'Admin', 'Accounting', 'Business_Dev', 'Executive', 'Financial', 'General', 'IT', 'Learning', 'Marketing', 'Operations'];
const executiveTypeOptions = executiveTypes.map(function(type) {
  return {id: type, name: type};
});

const includeAll = searchConst.searchOptions.includeAll;
const includeAny = searchConst.searchOptions.includeAny;
const exclude = searchConst.searchOptions.exclude;
const withValue = searchConst.searchOptions.withValue;
const withoutValue = searchConst.searchOptions.withoutValue;

const Candidate = [
  {
    value: 'activity',
    label: 'Activity',
    searchOptions: [searchConst.searchOptions.includeAnyActivity, searchConst.searchOptions.includeAllActivity, searchConst.searchOptions.excludeActivity, searchConst.searchOptions.activityDefaultValue],
    searchOptionComponent: ActivityTypeSelect
  },
  {
    value: 'parsedResumeExecutiveType',
    label: 'Area of Management',
    searchOptions: [includeAny, includeAll, exclude, withValue, withoutValue],
    dropdownOptions: executiveTypeOptions,
    searchOptionComponent: AtsSelect
  },
  {
    value: 'parsedResumeAreasOfStudy',
    label: 'Areas of Study',
    searchOptions: [includeAny, includeAll, exclude, withValue, withoutValue],
    searchOptionComponent: CreatableInputOnly
  },
  {
    value: 'parsedResumeAverageMonthsPerEmployer',
    label: 'Average Years per Job',
    searchOptions: searchConst.searchOptions.number,
    searchOptionComponent: AtsInputNumber
  },
  {
    value: 'badgeIds',
    label: 'Badges',
    searchOptions: [includeAny, includeAll, exclude, withValue, withoutValue],
    dropdownOptionsLookupId: 'badges',
    searchOptionComponent: AtsSelect
  },
  {
    value: 'bullhornIds',
    label: 'Bullhorn ID',
    searchOptions: [includeAny, exclude, withValue, withoutValue],
    searchOptionComponent: CreatableInputOnly
  },
  {
    value: 'businessUnitIds',
    label: 'Business Units',
    searchOptions: [includeAny, includeAll],
    dropdownOptionsLookupId: 'businessUnits',
    searchOptionComponent: AtsSelect
  },
  {
    value: 'phcCareerCodeIds',
    label: 'Career Codes',
    searchOptions: [includeAny, includeAll, exclude],
    dropdownOptionsLookupId: 'phcCareerCodes',
    searchOptionComponent: AtsSelect
  },
  {
    value: 'parsedResumeCertifications',
    label: 'Certifications',
    searchOptions: [includeAny, includeAll, exclude, withValue, withoutValue],
    searchOptionComponent: CreatableInputOnly
  },
  {
    value: 'company',
    label: 'Company',
    searchOptions: [includeAny, exclude, withValue, withoutValue],
    searchOptionComponent: CreatableInputOnly
  },
  {
    value: 'parsedResumeCompanies',
    label: 'Company History',
    searchOptions: [includeAny, includeAll, exclude, withValue, withoutValue],
    searchOptionComponent: CreatableInputOnly
  },
  {
    value: 'dateCreatedRange',
    label: 'Date Added',
    searchOptions: searchConst.searchOptions.date.concat([searchConst.searchOptions.inTheLast, searchConst.searchOptions.inTheLastCalendar, searchConst.searchOptions.inTheCurrentCalendar]),
    searchOptionComponent: AtsDatePicker,
  },
  {
    value: 'dateUpdatedRange',
    label: 'Date Last Updated',
    searchOptions: searchConst.searchOptions.date.concat([searchConst.searchOptions.inTheLast, searchConst.searchOptions.inTheLastCalendar, searchConst.searchOptions.inTheCurrentCalendar]),
    searchOptionComponent: AtsDatePicker,
  },
  {
    value: 'desiredLocation',
    label: 'Desired Location',
    searchOptions: [includeAny, exclude, withValue, withoutValue],
    searchOptionComponent: CreatableInputOnly
  },
  {
    value: 'desiredEmploymentTypes',
    label: 'Desired Employment',
    searchOptions: [includeAny, includeAll, exclude, withValue, withoutValue],
    dropdownOptionsLookupId: 'candidateDesiredEmploymentTypes',
    searchOptionComponent: AtsSelect
  },
  {
    value: 'parsedResumeDegree',
    label: 'Degrees',
    searchOptions: [includeAny, includeAll, exclude, withValue, withoutValue],
    searchOptionComponent: CreatableInputOnly
  },
  {
    value: 'departmentIds',
    label: 'Departments',
    searchOptions: [includeAny, includeAll, exclude],
    dropdownOptionsLookupId: 'departments',
    searchOptionComponent: AtsSelect
  },
  {
    value: 'supplementalDocumentsKeywords',
    label: 'Documents',
    searchOptions: [includeAny, includeAll, exclude, withValue, withoutValue],
    searchOptionComponent: CreatableInputOnly
  },
  {
    value: 'bulkEmailOptOut',
    label: 'Email Opted Out',
    searchOptions: [includeAll],
    dropdownOptionsLookupId: 'bulkEmailOptOuts',
    searchOptionComponent: AtsSelect,
    hideSearchOptionField: true,
    singleSearchField: true
  },
  {
    value: 'emailValidity',
    label: 'Email Validity',
    searchOptions: [includeAny, exclude],
    dropdownOptionsLookupId: 'emailAddressValidities',
    searchOptionComponent: AtsSelect
  },
  {
    value: 'experienceLevel',
    label: 'Experience Level',
    searchOptions: [includeAny, exclude, withValue, withoutValue],
    dropdownOptionsLookupId: 'candidateExperienceLevels',
    searchOptionComponent: AtsSelect
  },
  {
    value: 'phcFacilityTypeIds',
    label: 'Facility Types',
    searchOptions: [includeAny, includeAll, exclude],
    dropdownOptionsLookupId: 'phcFacilityTypes',
    searchOptionComponent:AtsSelect
  },
  {
    value: 'highlightedSkills',
    label: 'Highlighted Skills',
    searchOptions: [includeAny, includeAll, exclude, withValue, withoutValue],
    searchOptionComponent: CreatableInputOnly
  },
  {
    value: 'industryIds',
    label: 'Industries',
    searchOptions: [includeAny, includeAll, exclude],
    dropdownOptionsLookupId: 'industries',
    searchOptionComponent: AtsSelect
  },
  {
    value: 'jobFunctionIds',
    label: 'Job Functions',
    searchOptions: [includeAny, includeAll, exclude, withValue, withoutValue],
    dropdownOptionsLookupId: 'jobFunctions',
    searchOptionComponent: JobFunctionsSkillsSelect
  },
  {
    value: 'jobTitle',
    label: 'Job Title',
    searchOptions: [includeAny, exclude, withValue, withoutValue],
    searchOptionComponent: CreatableInputOnly
  },
  {
    value: 'parsedResumeLanguages',
    label: 'Languages',
    searchOptions: [includeAny, includeAll, exclude, withValue, withoutValue],
    searchOptionComponent: CreatableInputOnly
  },
  {
    value: 'listIds',
    label: 'Lists',
    searchOptions: [includeAny, includeAll, exclude, withValue, withoutValue],
    searchOptionComponent: AtsTypeAheadSearchSelect,
    entityType: 'CANDIDATE',
    clearButton: false,
    maxLengthToApi: searchConst.minLength, // typeAhead min input length, send to api, then filter in the frontEnd
    delay: 0,
    searchOperation:'listsSearchMy',
    filterBy: ['name']
  },
  {
    value: 'parsedResumeHighestManagementScore',
    label: 'Management Level',
    searchOptions: searchConst.searchOptions.number,
    searchOptionComponent: AtsInputNumber
  },
  {
    value: 'name',
    label: 'Name',
    searchOptions: [includeAny, exclude],
    searchOptionComponent: CreatableInputOnly
  },
  {
    value: 'recruiterUserIds',
    label: 'Owner',
    searchOptions: [includeAny, exclude],
    dropdownOptionsLookupId: ['users', 'teams'],
    dropdownOptionsMapTo: ['recruiterUserIds', 'recruiterTeamIds'],
    searchOptionComponent: AtsSelect,
  },
  {
    value: 'parsedResumeJobTitles',
    label: 'Previous Job Titles',
    searchOptions: [includeAny, includeAll, exclude, withValue, withoutValue],
    searchOptionComponent: CreatableInputOnly
  },
  {
    value: 'parsedResumeQualificationsSummary',
    label: 'Qualifications Summary',
    searchOptions: [includeAny, includeAll, exclude, withValue, withoutValue],
    searchOptionComponent: CreatableInputOnly
  },
  {
    value: 'baseSalaryRange',
    label: 'Rate - Base Salary',
    searchOptions: searchConst.searchOptions.number.concat([withValue, withoutValue]),
    searchOptionComponent: AtsInputNumber
  },
  {
    value: 'hourlyRateRange',
    label: 'Rate - Hourly',
    searchOptions: searchConst.searchOptions.number.concat([withValue, withoutValue]),
    searchOptionComponent: AtsInputNumber
  },
  {
    value: 'totalCompensationRange',
    label: 'Rate - Total Compensation',
    searchOptions: searchConst.searchOptions.number.concat([withValue, withoutValue]),
    searchOptionComponent: AtsInputNumber
  },
  {
    value: 'referredBy',
    label: 'Referred By',
    searchOptions: [includeAny, exclude, withValue, withoutValue],
    searchOptionComponent: CreatableInputOnly
  },
  {
    value: 'parsedResumeSkills',
    label: 'Resume Skills',
    searchOptions: [includeAny, includeAll, exclude, withValue, withoutValue],
    searchOptionComponent: CreatableInputOnly
  },
  {
    value: 'parsedResumeSchools',
    label: 'Schools',
    searchOptions: [includeAny, includeAll, exclude, withValue, withoutValue],
    searchOptionComponent: CreatableInputOnly
  },
  {
    value: 'skillIds',
    label: 'Skills',
    searchOptions: [includeAny, includeAll, exclude, withValue, withoutValue],
    dropdownOptionsLookupId: 'skills',
    searchOptionComponent: JobFunctionsSkillsSelect
  },
  {
    value: 'sourceOptionIds',
    label: 'Source',
    searchOptions: [includeAny, exclude],
    dropdownOptionsLookupId: 'sourceOptions',
    orderBy: 'id',
    searchOptionComponent: AtsSelect
  },
  {
    value: 'status',
    label: 'Status',
    searchOptions: [includeAny, exclude],
    dropdownOptionsLookupId: 'candidateStatuses',
    searchOptionComponent: AtsSelect
  },
  {
    value: 'parsedResumeFulltimeDirectHireIndex',
    label: 'Temp vs Perm Experience (%)',
    searchOptions: searchConst.searchOptions.number,
    searchOptionComponent: AtsInputNumber
  },
  {
    value: 'parsedResumeMonthsOfExperience',
    label: 'Total Years of Experience',
    searchOptions: searchConst.searchOptions.number,
    searchOptionComponent: AtsInputNumber
  },
  {
    value: 'parsedResumeMonthsOfManagement',
    label: 'Years of Management',
    searchOptions: searchConst.searchOptions.number,
    searchOptionComponent: AtsInputNumber
  }
];

export default Candidate;
