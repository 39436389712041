import React, {Suspense} from 'react';
import PropTypes from 'prop-types';

export default function SuspenseWrap({children}) {
  return (
    <Suspense fallback={<div></div>}>
      {children}
    </Suspense>
  );
}


SuspenseWrap.propTypes = {
  children: PropTypes.object.isRequired
};
