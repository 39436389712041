import {AtsModal} from '../common';
import {shortCuts} from '../../constants/hotKeys';
import React from 'react';
import './style.scss';

export default ({modalVisible, modalCancel}) => {
  return (
    <AtsModal
      id='hot-keys-detail-modal'
      modalTitle={'Keyboard Shortcuts'}
      modalSize='md'
      modalVisible={modalVisible}
      modalCancel={modalCancel}
      backdrop={true}
    >
      <>
        <table className="table table-striped">
          <thead>
            <tr>
              <th scope="col">Hot Key</th>
              <th scope="col">Shortcut Name</th>
            </tr>
          </thead>
          <tbody>
            {
              Object.keys(shortCuts).map((hotKey, index) => {
                return (
                  <tr key={`hot-key-${index}-${hotKey}`}>
                    <td>{hotKey}</td>
                    <td>{shortCuts[hotKey]}</td>
                  </tr>
                );
              })
            }
          </tbody>
        </table>

      </>
    </AtsModal>
  );
};
