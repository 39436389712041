import actionTypes from '../constants/actionTypes';

const initialState = {
  isFetched: false,
  parameters: {criteria: {jobIds: [], candidateIds:[]}},
  data: {summary: {}, submissions: []}
};

export default function submissionReducer(state = initialState, action){
  switch (action.type) {
  case actionTypes.submissionActionTypes.SET_SUBMISSIONS:
    return {
      ...state,
      data: action.payload
    };

  case actionTypes.submissionActionTypes.SET_ADDITIONAL_SUBMISSIONS:
    return {
      ...state,
      data: action.payload
    };

  case actionTypes.submissionActionTypes.DROP_SUBMISSION:
    let dropedSubmission = { ...action.payload };
    const dropedSubmissionIndex = state.data.submissions.findIndex(
      submission => submission['submissionId'] === dropedSubmission.submissionId
    );

    if (dropedSubmissionIndex !== -1) {
      dropedSubmission = {...state.data.submissions[dropedSubmissionIndex], ...dropedSubmission};

      const updatedSubmissions = [
        ...state.data.submissions.slice(0, dropedSubmissionIndex),
        dropedSubmission,
        ...state.data.submissions.slice(dropedSubmissionIndex + 1)
      ];
      return {
        ...state,
        data: {
          summary: state.data.summary,
          submissions: updatedSubmissions
        }
      };
    } else {
      return {
        ...state,
        data: state.data
      };
    }
  case actionTypes.commonActionTypes.FETCH_REQUEST:
    return {
      ...state,
      isFetched: true
    };
  case actionTypes.commonActionTypes.FETCH_SUCCESS:
    return {
      ...state,
      isFetched: false
    };
  case actionTypes.commonActionTypes.FETCH_FAIL:
    return {
      ...state,
      isFetched: false
    };
  case actionTypes.commonActionTypes.SET_PARAMS:
    return {
      ...state,
      parameters: action.payload
    };
  default:
    return state;
  }
}
