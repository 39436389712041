import searchConst from '../constants/searchConst';
import actionTypes from '../constants/actionTypes';
import {cloneDeep, has, omit, isEmpty} from '../libs/lodash';
import utils from '../libs/utils';
import { prepareForRenderInTableJobs, prepareForRenderSavedSearches } from '../compoments/Search/libs/utils';
import { searchResultsFieldsListMap } from '../compoments/Search/libs/utils';

import {
  prepareForApiSearch,
  prepareForRenderSearch,
  prepareForRenderPlacementsTableView,
  sortBySynchronize
} from '../compoments/Search/libs/utils';
import ApiClient from '../services/ApiClient';
import apiUserStatus from '../services/apiUserStatus';

export const searchEntities = (parameters, callback) => {
  return async (dispatch, getState) => {
    const entityType = parameters.entityType.toLowerCase();
    const entityTypePlural = searchConst.entityMap[entityType];
    const form = document.querySelector('form[name=\'search-modal\']');
    const buttonSelector = 'button[type=\'submit\']';
    utils.prepareDisabledButton(form, buttonSelector, true);

    try {
      delete parameters.entityType;

      if (!parameters) {
        dispatch( {
          type: actionTypes.entityActionTypes.SET_ENTITIES,
          entityType,
          payload: {summary: {}, entities: []}
        });

        if(callback) {
          callback();
        }
        return;
      }

      dispatch( {
        type: actionTypes.entityActionTypes.SEARCH_ENTITIES_REQUEST,
        entityType
      });

      parameters.offset = parameters.offset === undefined ? 0 :  parameters.offset;
      parameters.maxResults =  parameters.maxResults || searchConst.maxResults;

      if (parameters.offset === 0) {
        dispatch( {
          type: actionTypes.entityActionTypes.SET_ENTITIES,
          entityType,
          payload: {summary: getState().searchEntityReducer[entityType].data.summary, entities: []}
        });

        await sortBySynchronize(entityType, parameters.criteria.sortBy);
      }

      // start remove empty additional criteria
      // Swagger client spec is setting to no passed in additionalCriteria or not empty
      let cleanParameters = cloneDeep(parameters);
      cleanParameters.criteria = prepareForApiSearch(parameters.criteria);
      const savedTableColumnsToDb = searchConst.savedTableColumnsToDbLookup[entityType];

      if (savedTableColumnsToDb) {
        const tableViewSettings = utils.getTableViewSettings();
        parameters.settings = {
          viewType: tableViewSettings[`${entityType}ViewType`],
          columns: tableViewSettings[`${entityType}Columns`]
        };
      }

      if (!cleanParameters.sortBy) {
        delete cleanParameters.sortBy;
      }

      dispatch({
        type: actionTypes.entityActionTypes.UPDATE_PARAMETERS,
        entityType,
        payload: parameters
      });

      cleanParameters.criteria = {...cleanParameters.criteria, ...savedTableColumnsToDb && searchResultsFieldsListMap(entityType, (parameters.settings || {}).columns)};

      const response = await ApiClient[entityTypePlural+'Search'](omit(cleanParameters, ['criteria']), cleanParameters.criteria);
      const data = response.body;
      processSearchResults(parameters, data, getState, dispatch, callback, entityType);

    } catch (error) {
      console.log(error);
      dispatch( {
        type: actionTypes.entityActionTypes.SEARCH_ENTITIES_FAIL,
        entityType,
      });
      utils.prepareDisabledButton(form, buttonSelector, false);
    }
  };
};

export const searchEntitiesBySaved = (parameters, callback) => {
  return async (dispatch, getState) => {
    const entityType = parameters.entityType.toLowerCase();
    const entityTypePlural = searchConst.entityMap[entityType];
    delete parameters.entityType;
    try {
      const savedTableColumnsToDb = searchConst.savedTableColumnsToDbLookup[entityType];

      dispatch( {
        type: actionTypes.entityActionTypes.SEARCH_ENTITIES_REQUEST,
        entityType
      });

      if (parameters.offset === 0) {
        const response = await ApiClient.searchesSavedDetails({id: parameters.id});
        dispatch(setSavedSearch(entityType, response.body));

        parameters.criteria = response.body.criteria;
        parameters.criteria.additionalCriteria = await prepareForRenderSearch(parameters.criteria.additionalCriteria || []);

        if(savedTableColumnsToDb) {
          parameters.settings = response.body.settings || {};

          if(!isEmpty(parameters.settings)) {
            apiUserStatus.updateSettings({
              ...parameters.settings.viewType && {[`${entityType}ViewType`]: parameters.settings.viewType},
              ...!isEmpty(parameters.settings.columns) && {[`${entityType}Columns`]: parameters.settings.columns}
            });
          }
        } else {
          delete parameters.settings;
        }

        dispatch( {
          type: actionTypes.entityActionTypes.SET_ENTITIES,
          entityType,
          payload: {summary: getState().searchEntityReducer[entityType].data.summary, entities: []}
        });

        if(savedTableColumnsToDb) {
          await sortBySynchronize(entityType, parameters.criteria.sortBy);
        }
      }

      const response = await ApiClient[entityTypePlural+'SavedSearch']({
        id: parameters.id,
        offset: parameters.offset || 0,
        maxResults: parameters.maxResults || searchConst.maxResults,
        saveActivity: parameters.saveActivity,
        ...savedTableColumnsToDb && searchResultsFieldsListMap(entityType, parameters.settings.columns),
      });
      const data = response.body;
      processSearchResults(parameters, data, getState, dispatch, callback, entityType);
    } catch (error) {
      console.log(error);
      dispatch( {
        type: actionTypes.entityActionTypes.SEARCH_ENTITIES_FAIL,
        entityType,
      });
    }
  };
};

export const getMetadata = (parameters) => {
  return async (dispatch) => {
    const entityType = parameters.entityType.toLowerCase();
    const entityTypePlural = searchConst.entityMap[entityType];
    try {
      delete parameters.entityType;
      const response = await ApiClient[entityTypePlural+'Metadata']({id: parameters.id});
      dispatch( {
        type: actionTypes.entityActionTypes.UPDATE_METADATA,
        entityId: parameters.id,
        entityType,
        payload: response.body
      });
    } catch (error) {
      console.log(error);
    }
  };
};

export const setEntityId = (id, entityType) => {
  return ({
    type: actionTypes.entityActionTypes.SET_ENTITY_ID,
    entityType,
    payload: id
  });
};

export const updateEntitySearchResult = (entity, entityType) => {
  return ({
    type: actionTypes.entityActionTypes.UPDATE_ENTITY,
    entityType,
    payload: entity
  });
};

export const updateViewSettings = (entityType, newSettings) => {
  return (dispatch, getState) => {
    const params = getState().searchEntityReducer[entityType].parameters;
    dispatch({
      type: actionTypes.entityActionTypes.UPDATE_PARAMETERS,
      entityType,
      payload: {...params, settings: {...params.settings, ...newSettings}}
    });
  };
};

export const setSavedSearch = (entityType, parameters) => {
  return ({
    type: actionTypes.entityActionTypes.SET_SAVED_SEARCH,
    entityType,
    payload: isEmpty(parameters) ? parameters : prepareForRenderSavedSearches(parameters)
  });
};

function processSearchResults(parameters, data, getState, dispatch, callback, entityType) {
  const entityTypePlural = searchConst.entityMap[entityType.toLowerCase()];
  delete parameters.id;

  dispatch({
    type: actionTypes.entityActionTypes.UPDATE_PARAMETERS,
    entityType,
    payload: parameters
  });

  if (parameters && parameters.offset === 0) {
    const count = has(data, 'summary.numResultsReturned') ? data.summary.numResultsReturned : 0;
    dispatch({
      type: actionTypes.entityActionTypes.SET_ENTITY_ID,
      entityType,
      payload: count !== 0 ? data[entityTypePlural][0][entityType.toLowerCase()+'Id'] : null
    });
  }

  if (entityType === 'placement') {
    data[entityTypePlural] = prepareForRenderPlacementsTableView(data[entityTypePlural]);
  } else if (entityType === 'job') {
    data[entityTypePlural] = prepareForRenderInTableJobs(data[entityTypePlural]);
  } 

  if (parameters && parameters.offset !== 0) {
    data[entityTypePlural] = getState().searchEntityReducer[entityType].data.entities.concat(data[entityTypePlural]);
    data.summary.numResultsReturned = getState().searchEntityReducer[entityType].data.summary.numResultsReturned + data.summary.numResultsReturned;
  }

  data.summary.hasMore = data.summary.numResultsFound &&
    data.summary.numResultsFound !== data.summary.numResultsReturned;

  dispatch( {
    type: actionTypes.entityActionTypes.SET_ENTITIES,
    entityType,
    payload: {summary: data.summary, entities: data[entityTypePlural]}
  });

  dispatch( {
    type: actionTypes.entityActionTypes.SEARCH_ENTITIES_SUCCESS,
    entityType,
  });

  if(callback) {
    callback();
  }
}
