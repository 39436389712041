import actionTypes from '../constants/actionTypes';
import * as activityActions from './activity';
import * as searchEntityActions from './searchEntity';
import { get, isEmpty } from '../libs/lodash';
import utils from '../libs/utils';
import submissionConst from '../constants/submissionConst';
import ApiClient from '../services/ApiClient';
import { sendAmplitudeData } from '../libs/amplitude';

export const fetchSubmission = (parameters) => {
  return async (dispatch, getState) => {
    try {
      const { criteria = {} } = parameters;

      if (isEmpty(criteria.jobIds) && isEmpty(criteria.candidateIds) && isEmpty(criteria.userIds) && isEmpty(criteria.teamIds)) {
        dispatch({
          type: actionTypes.submissionActionTypes.SET_SUBMISSIONS,
          payload: { summary: {}, submissions: [] }
        });

        dispatch({
          type: actionTypes.commonActionTypes.SET_PARAMS,
          payload: { criteria: { jobIds: [], candidateIds: [], userIds: [], teamIds: [] } }
        });

        return;
      }

      dispatch({
        type: actionTypes.commonActionTypes.FETCH_REQUEST
      });

      dispatch({
        type: actionTypes.commonActionTypes.SET_PARAMS,
        payload: parameters
      });

      if (!parameters.offset) {
        dispatch({
          type: actionTypes.submissionActionTypes.SET_SUBMISSIONS,
          payload: { summary: {}, submissions: [] }
        });
      }

      const res = await ApiClient.submissionsList({ 
        offset: parameters.offset || 0, 
        maxResults: parameters.maxResults || submissionConst.maxResults, 
        sortBy: parameters.sortBy || submissionConst.sortBy, 
        sortOrder: parameters.sortOrder || submissionConst.sortOrder 
      }, parameters.criteria);

      if (parameters.offset !== 0) {
        res.body.submissions = getState().submissionReducer.data.submissions.concat(res.body.submissions);
        res.body.summary.numResultsReturned = getState().submissionReducer.data.summary.numResultsReturned + res.body.summary.numResultsReturned;
      }

      dispatch({
        type: actionTypes.submissionActionTypes.SET_SUBMISSIONS,
        payload: res.body
      });

      dispatch({
        type: actionTypes.commonActionTypes.FETCH_SUCCESS,
      });

    } catch (error) {
      console.log(error);
      dispatch({
        type: actionTypes.commonActionTypes.FETCH_FAIL
      });
    }
  };
};

export const fetchMoreSubmission = (parameters) => {
  return async (dispatch, getState) => {
    try {
      const { criteria = {} } = parameters;

      if (isEmpty(criteria.jobIds) && isEmpty(criteria.candidateIds) && isEmpty(criteria.userIds) && isEmpty(criteria.teamIds)) {
        dispatch({
          type: actionTypes.submissionActionTypes.SET_ADDITIONAL_SUBMISSIONS,
          payload: { summary: {}, submissions: [] }
        });

        dispatch({
          type: actionTypes.commonActionTypes.SET_PARAMS,
          payload: { criteria: { jobIds: [], candidateIds: [], userIds: [], teamIds: [] } }
        });

        return;
      }

      dispatch({
        type: actionTypes.commonActionTypes.FETCH_REQUEST
      });

      dispatch({
        type: actionTypes.commonActionTypes.SET_PARAMS,
        payload: parameters
      });

      if (!parameters.offset) {
        dispatch({
          type: actionTypes.submissionActionTypes.SET_ADDITIONAL_SUBMISSIONS,
          payload: { summary: {}, submissions: [] }
        });
      }

      const res = await ApiClient.submissionsList({ 
        offset: parameters.offset || 0, 
        maxResults: parameters.maxResults || submissionConst.maxResults,
        sortBy: parameters.sortBy || submissionConst.sortBy, 
        sortOrder: parameters.sortOrder || submissionConst.sortOrder 
      }, parameters.criteria);
      
      if (parameters.offset !== 0) {
        res.body.submissions = getState().submissionReducer.data.submissions.concat(res.body.submissions);
        res.body.summary.numResultsReturned = getState().submissionReducer.data.summary.numResultsReturned + res.body.summary.numResultsReturned;
      }

      dispatch({
        type: actionTypes.submissionActionTypes.SET_ADDITIONAL_SUBMISSIONS,
        payload: res.body
      });

      dispatch({
        type: actionTypes.commonActionTypes.FETCH_SUCCESS,
      });

    } catch (error) {
      console.log(error);
      dispatch({
        type: actionTypes.commonActionTypes.FETCH_FAIL
      });
    }
  };
};
export const createNewPlacement = ({ stage, candidateId, jobId, history }) => {
  return () => {
    if (stage === 'PLACED') {
      const newPlacementLink = `/new-placement?candidateId=${candidateId}&jobId=${jobId}`;
      history.push(newPlacementLink);
    }
  };
};

export const changeStageByDrop = ({ submission, toStage, entityId, method, notifyTargetIds, history, lookups }) => {
  return async (dispatch, getState) => {
    try {
      let updated = false;

      try {
        // ATS-925
        // due to slow network issue, submission tile delay some seconds, then go to the destination
        // fixed: move submission tile to the destination when waiting for api return
        dispatch({
          type: actionTypes.submissionActionTypes.DROP_SUBMISSION,
          payload: {
            submissionId: submission.submissionId,
            stage: toStage,
            stageStatus: toStage,
            latestActivity: {
              activityCreatedAt: (new Date()).toISOString(),
              activityTypeName: utils.convertIdToName(lookups, 'submissionStages', toStage),
            },
            updatedAt: (new Date()).toISOString(),
          }
        });
        const notifyCollection = utils.notifyCollection(notifyTargetIds);
        await ApiClient.submissionsUpdate({ id: submission.submissionId }, { stage: toStage, auditLogComment: (submission.auditLogComment ? encodeURIComponent(submission.auditLogComment) : submission.auditLogComment), ...notifyCollection });
        sendAmplitudeData('matchsubworkflow', { stage: toStage, object: method ? method.toLowerCase() + ' submissions search table' : 'submission search kanban' });
        window.amplitudeLogEvent('edit', {
          action: 'drag drop',
          object: 'submission',
          detailsTab: 'submissions',
          method: method ? method.toLowerCase() + ' submissions tab' : 'submissions kanban'
        });

        updated = true;
      } catch (error) {
        console.log(error);
        // if updated failed, then move back submission to the original stage
        dispatch({
          type: actionTypes.submissionActionTypes.DROP_SUBMISSION,
          payload: { submissionId: submission.submissionId, stage: submission.stage, updatedAt: submission.updatedAt }
        });
      }

      if (updated) {
        if (entityId) {
          dispatch(searchEntityActions.getMetadata({ entityType: method.toLowerCase(), id: entityId }));

          if ((utils.getActivityFilterCategories(method) || []).includes('SUBMISSION')) {
            dispatch(activityActions.fetchRecentActivities());
          }
        }

        const candidateId = get(submission, 'candidate.candidateId');
        const jobId = get(submission, 'job.jobId');
        dispatch(createNewPlacement({ stage: toStage, candidateId, jobId, history }));
      }
    } catch (error) {
      console.log(error);
    }
  };
};
