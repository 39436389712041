import React from 'react';
import ReactDOM from 'react-dom';
import {createStore, applyMiddleware} from 'redux';
import {Provider} from 'react-redux';
import thunkMiddleware from 'redux-thunk';
import reducer from './reducers';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { ToastContainer, Zoom } from 'react-toastify';
import { composeWithDevTools } from 'redux-devtools-extension';

import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap-css-only/css/bootstrap.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'antd/es/tabs/style/css';
import './index.scss';

const store = createStore(
  reducer,
  composeWithDevTools(
    applyMiddleware(thunkMiddleware),
  )
);


ReactDOM.render(
  <Provider store={store}>
    <App />
    <ToastContainer autoClose={10000} transition={Zoom} hideProgressBar={true}/>
  </Provider>,
  document.getElementById('ats')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
