import ApiClient from './ApiClient';
import Lookups from './Lookups';
import { tableViewSavedAttrs } from '../constants/tableViewAttrsConst';
import TabsResourceLock from './TabsResourceLock';
import {prepareUserStatus} from '../compoments/User/libs/utils';
import {prepareForRecentViewedEntityCandidates} from '../compoments/Candidates/libs/utils';
import {prepareForRecentViewedEntityCompanies} from '../compoments/Companies/libs/utils';
import {prepareForRecentViewedEntityContacts} from '../compoments/Contacts/libs/utils';
import {prepareForRecentViewedEntityJobs} from '../compoments/Jobs/libs/utils';
import {get} from '../libs/lodash';
import TokenService from './TokenService';
import amplitude from 'amplitude-js';

const logInEventData = (userData) => {
  if(window.NODE_ENV === 'development') {
    return;
  }
  const identify = new amplitude.Identify().set('userType', userData.userType).set('teams', (userData.userTeams || []).map((team) => team.name));
  const amplitudeInstance = amplitude.getInstance();
  amplitudeInstance.init(window.REACT_APP_AMPLITUDE_KEY, userData.userName);
  amplitudeInstance.identify(identify);
  amplitudeInstance.logEvent('user login');
};

const apiUserStatus =  {
  get(){
    return JSON.parse(window.localStorage.getItem('user'));
  },
  update(userData){
    window.localStorage.setItem('user', JSON.stringify(userData));
  },
  remove(){
    TabsResourceLock.cleanup();
    TokenService.flashTokens();
    ApiClient.removeSwaggerSpecToLocalStorage();
    Lookups.removeLookupsToLocalStorage();
    window.localStorage.removeItem('user');
  },
  async updateSettings(updatedAttrs={}) {
    try {
      const user = this.get() || {};
      const {settings = {}} = user;
      const {jobWidgetSettings = {}} = settings;
      const {calendarSettings = {}} = settings;
      const {tableViewSettings = {}} = settings;

      Object.keys(updatedAttrs).forEach((key) => {
        if (['preferredSavedSearchIds', 'activityFilterSettings'].includes(key)) {
          settings[key] = updatedAttrs[key];
          return;
        }

        // dashboard job widget remember attrs
        if (['savedSearchId', 'dataType', 'header', 'sortOrder'].includes(key)) {
          jobWidgetSettings[key] = updatedAttrs[key];
          return;
        }

        // calendar remember attrs
        if (['assignedToType', 'assignedToId', 'viewType'].includes(key)) {
          calendarSettings[key] = updatedAttrs[key];
          return;
        }

        // tableview remember attrs
        if (tableViewSavedAttrs.includes(key)) {
          tableViewSettings[key] = updatedAttrs[key];
        }
      });

      const newSettings = {...settings, jobWidgetSettings, calendarSettings, tableViewSettings};
      this.update({...user, settings: newSettings});
      await ApiClient['usersUpdateMySettings']({}, newSettings);
    } catch (err) {
      console.log(err);
    }
  },
  async prepareUserDetails() {
    await ApiClient.initialize();

    const res = await ApiClient.usersMyDetails();
    const user = res.obj;

    let userData = prepareUserStatus(user);
    logInEventData(userData);
    await apiUserStatus.update(userData);
  },
  async prepareUserSettings() {
    const res = await ApiClient.searchesRecentList({'type': 'ITEM'});

    const renderServiceMap = {
      'CANDIDATE': prepareForRecentViewedEntityCandidates,
      'COMPANY': prepareForRecentViewedEntityCompanies,
      'CONTACT': prepareForRecentViewedEntityContacts,
      'JOB': prepareForRecentViewedEntityJobs
    };

    let recentViewedEntities = {'RECRUITER': get(apiUserStatus.get(), 'recentViewedEntities.RECRUITER')};
    res.body.forEach((item) => {
      let recentViewedEntity = {
        ...item.itemDetails,
        [item.entityType.toLowerCase() + 'Id']: get(item, 'itemDetails.itemId')
      };

      switch (item.entityType) {
      case 'CONTACT':
      case 'JOB':
        recentViewedEntity = {
          ...recentViewedEntity,
          'company': {
            'companyId': get(item, 'itemDetails.companyId'),
            'name': get(item, 'itemDetails.companyName')
          }
        };
        break;
      default:
        break;
      }
      if (item.entityType !== 'PLACEMENT') {
        recentViewedEntity = renderServiceMap[item.entityType](recentViewedEntity);
        recentViewedEntities[item.entityType] = (recentViewedEntities[item.entityType] || []).concat([recentViewedEntity]).slice(0, 3);
      }
    });

    await apiUserStatus.update({...apiUserStatus.get(), recentViewedEntities: recentViewedEntities});
  },
};


export default apiUserStatus;
