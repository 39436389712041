import React, {useEffect, useRef, useState} from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import NoResultsMsg from './NoResultsMsg';
import RecentList from './RecentList';
import {AtsErrorBoundary} from '../../common';

import useModal from '../../../hooks/useModal';
import { Spinner } from '../../common';
import SearchModal from '../../Search/SearchModal';

import {searchEntities, searchEntitiesBySaved, setSavedSearch} from '../../../actions/searchEntity';
import { lastSortBy } from '../../Search/libs/utils';
import {connect} from 'react-redux';

import {filter, isEmpty, find, get, difference, compact} from '../../../libs/lodash';
import searchConst from '../../../constants/searchConst';
import maxMinConst from '../../../constants/maxMinConst';
import detailsConst from '../../../constants/detailsConst';
import utils from '../../../libs/utils';
import Button from '../../common/Button';
import AtsSearchBar from '../../common/AtsSearchBar';
import {prepareForRenderSearch} from '../../Search/libs/utils';
import ApiClient from '../../../services/ApiClient';
import Lookups from '../../../services/Lookups';
import apiUserStatus from '../../../services/apiUserStatus';

function RecentActivity({history, drawer, closeDrawer, search, saveSearch, setSavedSearch}) {
  const {modalVisible, toggleModal} = useModal();
  const [isFetched, setIsFetched] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [filterResult, setFilterResult] = useState([]);
  const [activeTab, setActiveTab] = useState('RECENT');
  const [recent, setRecent] =  useState([]);
  const [savedSearches, setSavedSearches] =  useState({summary: {}, savedSearches:[]});

  const RECENT = 'RECENT', ITEM = 'ITEM', SAVED ='SAVED';
  const entityType =  drawer.name;
  const entityTypePlural = searchConst.entityMap[entityType];
  let offset = 0;
  let timeout = null;
  const inputRef = useRef();
  const lookups = Lookups.lookups;
  const {settings={}} = apiUserStatus.get() || {};
  let currentViewType = get(settings, 'tableViewSettings.' + entityType + 'ViewType');
  currentViewType = currentViewType && {viewType: (searchConst.viewTypeOptions[currentViewType] || '').toLowerCase()};

  const activityTabs = [
    {value: RECENT, label: 'Recent'},
    {value: SAVED, label: 'Saved'}
  ];

  useEffect(() => {
    // when drawer changed job, candidate.....
    // Make 'Recent' as active tab
    // reset recent
    setActiveTab('RECENT');
    setSearchTerm('');
    inputRef.current.value = '';
    setFilterResult([]);
    setSavedSearches({summary: {}, savedSearches:[]});

    const apiCall = async () => {
      setIsFetched(true);
      try {
        const res = await ApiClient.searchesRecentList({'entityType': drawer.name.toUpperCase()});
        setRecent(res.body);
        setIsFetched(false);
      } catch (error) {
        setRecent([]);
        setSavedSearches({summary: {}, savedSearches:[]});
        setIsFetched(false);
        console.log(error);
      }
    };
    apiCall();
  }, [drawer]);

  const searchTermChange = event => {
    const query = event.target.value;
    clearTimeout(timeout);

    if(!query) {
      setSearchTerm(query);
      return;
    }

    if (query && query.length >= searchConst.minLength) {
      timeout = setTimeout(function () {
        setFilterResult(doFilter(query));
        setSearchTerm(query);
        window.amplitudeLogEvent('global recent', { action: 'text filter', filterText: (query || '').toLowerCase(), globalNav: entityType + ' slide-in', tab: activeTab.toLowerCase() });
      }, searchConst.delay);
    }
  };

  const toggleActivityTab = (e, currTab) => {
    e.preventDefault();
    window.amplitudeLogEvent('global recent', { action: 'tab filter', globalNav: entityType + ' slide-in', tab: currTab.toLowerCase(), filterText: searchTerm });

    setSearchTerm('');
    setFilterResult([]);
    document.getElementById('search-input').value = '';

    if (currTab===SAVED && isEmpty(savedSearches.savedSearches)) {
      fetchSaveSearch(0);
    }

    setActiveTab(currTab);
  };

  const doSearch = async (e, item) => {
    try {
      e.preventDefault();

      if (get(item, 'searchDetails.isSavedSearch')) {
        doSavedSearch(e, {...item, savedSearchId: get(item, 'searchDetails.savedSearchId')});
      } else {
        item.searchDetails.criteria.additionalCriteria = await prepareForRenderSearch(item.searchDetails.criteria.additionalCriteria || []);
        window.amplitudeLogEvent('global recent', {
          action: 'recent search',
          globalNav: entityType + ' slide-in',
          object: entityType,
          tab: activeTab.toLowerCase(),
          searchString: searchTerm,
          page: 'search results',
          timer: 'end',
          ...currentViewType
        });

        window.amplitudeLogEvent('search', {
          action: 'run recent search',
          object: entityType,
          ...currentViewType
        });

        const lastSortByFromSetting = lastSortBy(entityType);
        search({
          criteria: { ...item.searchDetails.criteria, ...lastSortByFromSetting && {sortBy: lastSortByFromSetting}},
          entityType,
          offset: 0,
          maxResults: searchConst.maxResults
        }, function () {
          setSavedSearch(entityType, {});
          history.push('/' + entityTypePlural);
          closeDrawer();
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const doSavedSearch = (e, item) => {
    try {
      e.preventDefault();
      window.amplitudeLogEvent('global recent', {
        action: 'saved search',
        globalNav: entityType + ' slide-in',
        object: entityType,
        tab: activeTab.toLowerCase(),
        searchString: searchTerm,
        page: 'search results',
        timer: 'end',
        ...currentViewType
      });

      window.amplitudeLogEvent('search', {
        action: 'run saved search',
        object: entityType,
        ...currentViewType
      });

      saveSearch({
        id: item.savedSearchId,
        entityType,
        offset: 0,
        maxResults: searchConst.maxResults
      }, function () {
        history.push('/' + entityTypePlural);
        closeDrawer();
      });
    } catch (err) {
      console.log(err);
    }
  };

  const doFilter = (searchTerm) => {
    const originItems = (activeTab === RECENT) ? recent : get(savedSearches, 'savedSearches', []);

    return filter(originItems, function (item) {
      let type = item.type;
      let searchProperties = [];

      switch (item.type) {
      case ITEM:
        const entityAbbreviation = (utils.convertIdToName(lookups, 'entityAbbreviationList', (drawer.name || '').toUpperCase()) || '').toLowerCase();
        const searchText = (searchTerm || '').toLowerCase();
        const searchObjIds = ['' + get(item, 'itemDetails.itemId', ''), (entityAbbreviation || '') + get(item, 'itemDetails.itemId', '')];

        if (searchObjIds.includes(searchText)) {
          return true;
        }
        searchProperties = [
          'firstName', 'preferredName', 'middleName', 'lastName', 'jobTitle', 'fullName',
          'city', 'state', 'bullhornId', 'candidateName.firstName',
          'candidateName.preferredName', 'candidateName.middleName', 'candidateName.firstName',
          'contactName.preferredName', 'contactName.middleName', 'contactName.firstName',
          'name', 'companyName', 'statusValue', 'openStatusValue'
        ];
        break;
      default:
        searchProperties = ['keywords', 'location', 'additionalCriteria', 'savedSearchName', 'name'];
        break;
      }

      //const criteria = item.itemDetails.
      return find(searchProperties, function(property) {
        const searchObj = item.itemDetails || item.savedSearches || item.searchDetails || item;
        const {firstName, lastName} = get(searchObj, 'candidateName') || get(searchObj, 'contactName') || get(searchObj, 'owner') || searchObj;
        searchObj.fullName = compact([firstName, lastName]).join(' ');

        switch (type) {
        case ITEM:
          const propertyValue = (get(searchObj, property) || '').toLowerCase();
          const searchWord = (searchTerm || '').toLowerCase();
          return propertyValue.includes(searchWord);
        default:
          if(['name', 'savedSearchName'].includes(property)) {
            return searchObj[property] && searchObj[property].toLowerCase().includes(searchTerm.toLowerCase());
          } else if(['keywords', 'location'].includes(property)) {
            return searchObj.criteria[property] && searchObj.criteria[property].toLowerCase().includes(searchTerm.toLowerCase());
          } else {
            const additionalCriteria = searchObj.criteria.additionalCriteria;
            if (additionalCriteria) {
              additionalCriteria.forEach(function(additional){
                const values = Object.values(additional);
                return values.join(' ').toLowerCase().includes(searchTerm.toLowerCase());
              });
            }
          }
          break;
        }
      });
    });
  };

  const searchModal = <SearchModal
    history={history}
    entityType={entityType}
    initialCriteria={{...searchConst.defaultSearchCriteria, additionalCriteria: searchConst.defaultAdditionalCriteriaLookups[entityType] || [] }}
    modalTitle={`New ${utils.camelize(entityType)} Search`}
    modalVisible={modalVisible}
    modalCancel={toggleModal}
    closeDrawer={closeDrawer} />;

  const displayItems = () => {
    if (activeTab===RECENT){
      return searchTerm ? filterResult : recent;
    } else {
      return searchTerm ? filterResult : savedSearches.savedSearches;
    }
  };

  const fetchSaveSearch = async (offset) => {
    const predefinedSearches = [
      'View My Candidates',
      'View My Contacts',
      'View My Jobs',
      'View Jobs Assigned to Me',
      'View My Companies',
      'View My Placements (Recruiter)',
      'View My Placements (Back Office)'
    ];

    try {
      setIsFetched(true);
      const response = await ApiClient.searchesSavedListMy({
        'entityType': entityType.toUpperCase(),
        offset: offset,
        maxResults: searchConst.maxResults
      });

      const data = response.body;
      if (offset !== 0) {
        data.savedSearches = savedSearches.savedSearches.concat(data.savedSearches);
        data.summary.numResultsReturned = savedSearches.summary.numResultsReturned + data.summary.numResultsReturned;
      }

      if (!isEmpty(data.savedSearches)) {
        const myViewSavedSearches = data.savedSearches.filter((entity) => {
          return predefinedSearches.includes(get(entity, 'name', ''));
        });

        const otherSavedSearches = difference(data.savedSearches, myViewSavedSearches);

        data.savedSearches = [
          ...myViewSavedSearches,
          ...otherSavedSearches
        ];
      }

      setSavedSearches(data);
      setIsFetched(false);
    } catch (error) {
      setSavedSearches({summary: {}, savedSearches:[]});
      console.log(error);
      setIsFetched(false);
    }
  };

  const fetchMoreData = () => {
    offset = offset + searchConst.maxResults;
    fetchSaveSearch(offset);
  };

  const clickNewSearch = () => {
    window.amplitudeLogEvent('global recent', { action: 'new search', globalNav: entityType + ' slide-in', object: entityType, tab: activeTab.toLowerCase(), searchString: searchTerm, timer: 'end', ...currentViewType });
    window.amplitudeLogEvent('search', { action: entityType + ' new search', object: entityType, timer : 'start', ...currentViewType});
    toggleModal();
  };

  const openItem = () => {
    window.amplitudeLogEvent('global recent', { action: 'view recent item', globalNav: entityType + ' slide-in', object: entityType, tab: activeTab.toLowerCase(), searchString: searchTerm, page: 'search results', timer: 'end' });
    window.amplitudeLogEvent('view', { action: 'view ' + entityType, globalNav: entityType + ' slide-in', object: entityType, detailsTab: detailsConst.defaultTab[entityType] || 'details', from: (searchConst.entityMap[entityType] || '') + ' slide-in'});
    closeDrawer();
  };

  return (
    <div id='recent-activity-drawer' className='pb-4'>
      <div className='divider divider-horizontal divider-100 my-4' />
      <div className='mt-4 pb-4 px-4'>
        <div className='mt-4 mb-2'>
          <i className='fa fa-chevron-left cursor' onClick={() => closeDrawer(true)} />

          <span className='title'>{drawer.title}</span>
        </div>

        <div className='cursor'>
          <Button type='button' id='new-search-btn' variant='btn-primary ats-sm-btn' click={() => clickNewSearch()}>New Search</Button>
        </div>

        {modalVisible && searchModal}

      </div>

      <div className='px-4 mb-3'>
        <ol id='recent-activity-header'>
          {activityTabs.map((tab) => {
            return (
              <li className={`flex-fill${activeTab === tab.value ? ' active-recent-tab' : ' cursor'}`}
                key={tab.value}
                onClick={(e) => toggleActivityTab(e, tab.value)}
              >
                {tab.label}
              </li>);
          })}
        </ol>


        <form onSubmit={(e) => e.preventDefault()} autoComplete='off'>
          <AtsSearchBar
            inputId='search-input'
            placeholder='Filter'
            maxLength={maxMinConst.ShortTextMaxlength}
            inputRef={inputRef}
            trackHotKey={false}
            defaultValue={searchTerm}
            onChange={searchTermChange}
          />
        </form>

        <div id='recent-activity-items'>
          {isFetched && offset === 0 ? <Spinner variant='fa-4x pt-3 pr-5 mr-4' /> : ''}

          <NoResultsMsg isFetched={isFetched} items={displayItems()} />

          {activeTab !== SAVED && !isFetched ?
            <AtsErrorBoundary><RecentList recentItems={displayItems()} openItem={openItem} doSearch={doSearch} doSavedSearch={doSavedSearch} activeTab={activeTab} /></AtsErrorBoundary>
            :
            ''
          }

          {/* SAVED tab*/}
          { activeTab === SAVED && displayItems().length > 0  ?
            <AtsErrorBoundary>
              <InfiniteScroll
                scrollThreshold={0.6}
                dataLength={displayItems().length}
                next={fetchMoreData}
                hasMore={savedSearches.summary.numResultsFound > savedSearches.summary.numResultsReturned}
                loader={<Spinner variant='text-center'/>}
                scrollableTarget='recent-activity-items'>
                <RecentList recentItems={displayItems()} openItem={openItem} doSearch={doSearch} doSavedSearch={doSavedSearch} activeTab={activeTab} />
              </InfiniteScroll>
            </AtsErrorBoundary>
            :
            ''
          }
        </div>
      </div>
    </div>
  );
}

const mapDispatchToProps = {
  search: searchEntities,
  saveSearch: searchEntitiesBySaved,
  setSavedSearch: setSavedSearch
};

export default connect(
  null,
  mapDispatchToProps
)(RecentActivity);
