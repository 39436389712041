import React from 'react';
import './style.scss';
import AtsTooltip from '../AtsTooltip';

export default function AtsCheckBox({id, name, options, defaultValue, handleChange, tooltipLookups, tooltipIcon}) {
  function checkboxLabel(option) {
    const showTooltips = tooltipLookups && tooltipLookups[option.value];

    if(showTooltips) {
      return ( tooltipIcon ?
        <>
          <span>{option.label} </span>
          <AtsTooltip placement='top' tips={tooltipLookups[option.value]}>
            <img src={tooltipIcon} alt={`${option.label} Tool Tips`} width='auto' height='20' />
          </AtsTooltip>
        </>
        :
        <AtsTooltip placement='top' tips={tooltipLookups[option.value]}>
          <span>{option.label}</span>
        </AtsTooltip>
      );
    } else {
      return option.label;
    }
  }

  return (
    <div id={id} className='select form-control'>
      {
        options.map((option) => (
          <label className={`checkbox-container${option.identClass ? ' ' + option.identClass : ''}${option.disabled ? ' disabled' : ''}`} key={`inline-radio-${option.value}`}>
            {checkboxLabel(option)}
            <input
              name={name}
              type="checkbox"
              id={`${name}-inline-radio-${option.value}`}
              value={option.value}
              className="form-check-input"
              disabled={option.disabled}
              checked = {defaultValue ? defaultValue.includes(option.value) : false}
              onChange={(e) => handleChange({[name]: option.value})}
            />
            <span htmlFor={`inline-radio-${option.value}`} className="checkboxmark">
            </span>
          </label>
        ))
      }
    </div>
  );
}
