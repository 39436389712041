export const priorityOptions = [
  {id: 'LOW', name: 'Low'},
  {id: 'NORMAL', name: 'Normal'},
  {id: 'HIGH', name: 'High'},
];

export const statusOptions = [
  {id: 'TO_DO', name: 'To Do'},
  {id: 'DONE', name: 'Done'},
];

export const nextTaskIndicatorPages = [
  'candidate',
  'candidates',
  'job',
  'jobs',
  'contact',
  'contacts',
  'company',
  'companies',
  'placement',
  'placements'
];
