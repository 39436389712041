import actionTypes from '../constants/actionTypes';

const initialState = {
  entity: {},
  tasks: [],
  showTaskEditModal: false,
  showTaskDetailModal: false,
  dataForTask: {},
};

export default function taskReducer(state = initialState, action){
  switch (action.type) {
  case actionTypes.taskActionTypes.UPDATE_TASKS:
    return {
      ...state,
      tasks: action.payload.tasks
    };
  case actionTypes.taskActionTypes.CREATE_TASK:
    return {
      ...state,
      tasks: [action.payload.task, ...state.tasks],
    };
  case actionTypes.taskActionTypes.UPDATE_TASK:
    return {
      ...state,
      tasks: [...state.tasks.map((task) => {
        if (task.taskId === action.payload.task.taskId) {
          task = action.payload.task;
        }
        return task;
      })],
    };
  case actionTypes.taskActionTypes.REMOVE_TASK:
    return {
      ...state,
      tasks: [...state.tasks.filter(task => task.taskId !== action.payload.taskId)],
    };
  case actionTypes.taskActionTypes.TOGGLE_TASK_EDIT_MODAL:
    return {
      ...state,
      showTaskEditModal: action.payload.showTaskEditModal,
      dataForTask: action.payload.dataForTask,
    };
  case actionTypes.taskActionTypes.TOGGLE_TASK_DETAIL_MODAL:
    return {
      ...state,
      showTaskDetailModal: action.payload.showTaskDetailModal,
    };
  case actionTypes.taskActionTypes.UPDATE_RELATED_ENTITY:
    return {
      ...state,
      entity: action.payload.entity
    };
  default:
    return state;
  }
}
