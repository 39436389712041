const placementTypesOption = [
  {id: 'CONTRACT', name: 'Contract'},
  {id: 'PERMANENT', name: 'Permanent'},
];

const payModelOptions = [
  {id: 'TIMECARD', name: 'Timecard - Per Hour'},
  {id: 'TRAVEL', name: 'Travel - Per Hour'},
  {id: 'SALARIED', name: 'Salaried - Per Day'},
];

const statusOptions = [
  {id: 'PENDING', name: 'Pending'},
  {id: 'CLEARED_TO_START', name: 'Cleared to Start'},
  {id: 'ACTIVE', name: 'Active'},
  {id: 'EXPIRED', name: 'Expired'},
  {id: 'CLOSED', name: 'Closed'},
  {id: 'NO_START', name: 'No Start'},
  {id: 'TERMINATED', name: 'Terminated for Cause'},
  {id: 'ARCHIVED', name: 'Archived'},
];

const hireTypeOptions = [
  {id: 'W2', name: 'W2'},
  {id: 'IC', name: 'IC'},
  {id: 'C2C', name: 'C2C'},
  {id: '1099', name: '1099'},
];

const perDiemOptions = [
  {value: false, label: 'No'},
  {value: true, label: 'Yes'},
];

const defaultPlacement = {
  job: {}, candidate: {}, placementType: 'CONTRACT', jobTitle: '', workLocation: {}, status: 'PENDING',
  commission:{
    primaryRecruiterPercentage: 50,
    coRecruiterPercentage: 0,
    primarySalespersonPercentage: 50,
    accountManagerPercentage: 0
  },
  permanentRateInfo: {},
  contractRateInfo: {
    perDiem: false,
    stage: 'ONBOARDING',
  },
  workMode: 'WORK_FROM_OFFICE',
  billingLocationSameAsWorkLocation: true,
};

const fieldsRequiredEffectiveDate = [
  'status',
  'scheduledStartDate', 'scheduledEndDate',
  'placementType', 'contractRateInfo.', 'permanentRateInfo.', 'currencyType', 'numHoursPerWeek',
  'workLocation.', 'commission.',
  'billingLocationSameAsWorkLocation', 'billingLocation.', 'taxCity', 'taxState',
  'timeApproverContact', 'backupApproverContact'
];

const searchResultsMinFieldsList =  [
  'placementId', 'jobTitle', 'status', 'placementType',
  'candidate.id', 'candidate.candidateName', 'candidate.accountingWorkerId', 'candidate.source',
  'contact', 'job', 'commission.primaryRecruiter',
  'updatedAt', 'createdAt', 'scheduledStartDate'
];

const placementSearchResultsFieldsList = searchResultsMinFieldsList.concat([
  'division',  'costCenter', 'purchaseOrder', 'glAccount', 'accountingCompanyId', 'complianceStatus',
  'programInfo', 'billingContact', 'vmsInfo.name', 'vmsInfo.jobId', 'vmsInfo.placementId',
  'timeApproverContact', 'backupApproverContact',
  'commission.primaryRecruiterPercentage',
  'commission.primarySalesperson', 'commission.primarySalespersonPercentage',
  'commission.coRecruiter', 'commission.coRecruiterPercentage',
  'commission.accountCoordinator', 'commission.accountManager', 'commission.accountManagerPercentage',
  'scheduledEndDate', 'effectiveDate', 'originalStartDate',
  'backOfficeOwnerUser', 'businessUnit', 'currencyType', 'numHoursPerWeek', 'patientCareType',
  'contractorEngagementUser', 'contractRateInfo.hireType', 'contractRateInfo.payModel',
  'contractRateInfo.payModelDetails.billRate', 'contractRateInfo.payModelDetails.payRate', 'contractRateInfo.payModelDetails.markup',
  'contractRateInfo.payModelDetails.holidayBillRate', 'contractRateInfo.payModelDetails.holidayPayRate', 'contractRateInfo.payModelDetails.holidayMarkup',
  'contractRateInfo.payModelDetails.onCallBillRate', 'contractRateInfo.payModelDetails.onCallPayRate', 'contractRateInfo.payModelDetails.onCallMarkup',
  'contractRateInfo.payModelDetails.callBackBillRate', 'contractRateInfo.payModelDetails.callBackPayRate', 'contractRateInfo.payModelDetails.callBackMarkup',
  'contractRateInfo.payModelDetails.orientationBillRate', 'contractRateInfo.payModelDetails.orientationPayRate', 'contractRateInfo.payModelDetails.orientationMarkup',
  'contractRateInfo.payModelDetails.doubleTimeBillRate', 'contractRateInfo.payModelDetails.doubleTimePayRate', 'contractRateInfo.payModelDetails.doubleTimeMarkup',
  'contractRateInfo.overtimeRate.billRate', 'contractRateInfo.overtimeRate.payRate', 'contractRateInfo.overtimeRate.markup',
  'contractRateInfo.billingSchedule', 'contractRateInfo.overtimeType',
  'permanentRateInfo.flatFee', 'permanentRateInfo.permanentFee', 'permanentRateInfo.payModel', 'permanentRateInfo.payModelDetails.payRate',
  'workLocation.city', 'workLocation.state', 'billingLocation.city', 'billingLocation.state', 'doesWorkFromHome',
]);

export {
  placementTypesOption,
  payModelOptions,
  perDiemOptions,
  hireTypeOptions,
  statusOptions,
  defaultPlacement,
  fieldsRequiredEffectiveDate,
  placementSearchResultsFieldsList
};
