import './style.scss';
import React from 'react';
import PropTypes from 'prop-types';
import {AtsModal} from '../common';
import NoteForm from './NoteForm';

export default function NoteFormModal(props) {
  const {
    modalVisible,
    modalCancel,
  } = props;

  return (
    <>
      { modalVisible ? (
        <AtsModal
          id='note-form-modal'
          modalTitle='Add Note'
          modalSize='lg'
          modalVisible={modalVisible}
          modalCancel={modalCancel}
        >
          <NoteForm {...props} />
        </AtsModal> 
      ) : (
        null
      )}
    </>
  );
}

NoteFormModal.propTypes = {
  modalVisible: PropTypes.bool.isRequired,
  modalCancel: PropTypes.func.isRequired
};
