import './style.scss';
import React from 'react';

export default () => {
  return (
    <div className="fireworks-animation">
      <div className="before" />
      <div className="after" />
    </div>
  );
};
