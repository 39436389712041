import {get, has} from '../../../libs/lodash';
import utils from '../../../libs/utils';

export default ({submission, lookups}) => {
  const jobType = get(submission, 'job.jobType');
  const baseSalary = get(submission, 'rateInfo.baseSalary');
  const billRate = get(submission, 'rateInfo.billRate');
  const rateTypes = has(submission, 'rateInfo.rateType');
  const currencyType = has(submission, 'rateInfo.currencyType');

  let payInfo = '';
  if (jobType === 'PERMANENT') {
    payInfo = baseSalary && currencyType ? utils.dollarForRender(submission.rateInfo.baseSalary, submission.rateInfo.currencyType, 'compact') : '';
  } else {
    payInfo = billRate && currencyType ? utils.dollarForRender(submission.rateInfo.billRate, submission.rateInfo.currencyType, 'compact') : '';
    payInfo += billRate && rateTypes ? (' ' + utils.convertIdToName(lookups, 'jobRateTypes', submission.rateInfo.rateType)).toLowerCase() : '';
  }

  return payInfo;
};
