import React from 'react';
import AtsModal from '../AtsModal';
import Button from '../Button';

export default function AtsInlineEditConfirmModal({modalVisible, modalCancel, saveBtnType='button', saveForm, discardForm}) {
  const discardChange = () => {
    modalCancel();
    const cancelBtns = document.querySelectorAll('button#cancel-inline, button#cancel-section');
    if (cancelBtns.length) {
      cancelBtns[0].click();
    }
  };

  const saveChange = () => {
    modalCancel();
    const saveBtns = document.querySelectorAll('button#save-inline, button#save-section');
    if (saveBtns.length) {
      saveBtns[0].click();
    }
  };

  const handleButtonAction = (primaryFunction, secondaryFunction) => {
    if (!!primaryFunction) {
      return primaryFunction();
    }
    return secondaryFunction();
  };

  return (
    <AtsModal
      id='confirm-inlineEdit-modal'
      modalTitle=''
      modalSize='md'
      modalVisible={modalVisible}
      modalCancel={modalCancel}
    >
      <>
        <div className='pb-5 font-size-18'>Your changes have not been saved. Are you sure you want to leave?</div>
        <div className='form-group'>
          <div className='d-flex justify-content-end pt-3'>
            <Button type='button' variant='btn-secondary ats-md-btn mr-4' click={() => handleButtonAction(discardForm, discardChange)}>
              Discard
            </Button>
            <Button type='button' variant='btn-secondary ats-md-btn mr-4' click={() => modalCancel()}>
              Return
            </Button>
            <Button type={saveBtnType} variant='btn-primary ats-md-btn mr-4' click={() => handleButtonAction(saveForm, saveChange)}>
              Save
            </Button>
          </div>
        </div>
      </>
    </AtsModal>
  );
}
