import utils from '../../../libs/utils';
import {cloneDeep, pick} from '../../../libs/lodash';

const prepareForRenderLists = (data) => {
  const visibility = utils.prepareRecordVisibility(data);
  const usersAndTeamsInfo = utils.prepareUsersAndTeamsInfo(data);

  return {
    ...data,
    visibility,
    id: data.listId,
    numEntities: data.entities !== undefined ? (data.entities || []).length : data.numEntities,
    ...usersAndTeamsInfo
  };
};

const prepareForApiList = (data) => {
  const apiData = pick(cloneDeep(data),
    ['name', 'desc', 'isPublic', 'category', 'entityType', 'isActive', 'bullhornId']
  );
  apiData.entityIds = (data.entities || []).map((entity) => entity[(data.entityType || '').toLowerCase() + 'Id']);
  apiData.userIds = [];
  apiData.teamIds = [];

  if ((data.sharedWithIds || []).length > 0 && !data.isPublic) {
    utils.splitIdsForApi(data.sharedWithIds, apiData);
  }

  return apiData;
};

export {
  prepareForRenderLists,
  prepareForApiList,
};
