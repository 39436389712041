import {saveAs} from 'file-saver';
import candidateConst from '../constants/candidateConst';
import utils from '../libs/utils';
import ApiClient from '../services/ApiClient';

export default function useFile(){
  const downloadFile = async (attachmentId) => {
    try {
      const { data, headers } = await ApiClient.attachmentsFile({id: attachmentId});

      const fileType = headers['content-type'].split(';')[0];
      const file = new Blob([data], {type: fileType});
      const fileName = headers['content-disposition'].split('"')[1];

      saveAs.saveAs(file,  utils.parsedFileName(fileName));
    } catch (error) {
      console.log(error);
    }
  };

  const downloadImportFile = async (importId, type) => {
    try {
      let requestApi = ApiClient.importsFile;

      if (type === 'successful') {
        requestApi = ApiClient.importsSuccessFile;
      }

      if (type === 'failed') {
        requestApi = ApiClient.importsFailedFile;
      }

      if (type === 'errors') {
        requestApi = ApiClient.importsErrorsFile;
      }

      const { data, headers } = await requestApi({id: importId});

      const fileType = headers['content-type'].split(';')[0];
      const file     = new Blob([data], {type: fileType});
      const fileName = headers['content-disposition'].split('"')[1];

      saveAs.saveAs(file,  fileName);
    } catch (error) {
      console.log(error);
    }
  };

  const openFileInNewTab = async (attachmentId) => {
    try {
      const { data, headers } = await ApiClient.attachmentsFile({id: attachmentId});
      const fileType = headers['content-type'].split(';')[0];
      const file = new Blob([data], {type: fileType});
      window.open(URL.createObjectURL(file));
    } catch (error) {
      console.log(error);
    }
  };

  const embedFile = async (attachmentId) => {
    try {
      const {data, headers} = await ApiClient.attachmentsFile({id: attachmentId});
      const fileType = headers['content-type'].split(';')[0];
      const file = new Blob([data], {type: fileType});

      // chrome, firefox
      const embed = document.querySelector('embed');
      if (embed) {
        embed.type = fileType;
        embed.src = window.URL.createObjectURL(file) + '#toolbar=1';
      }

      // firefox, safari
      const obj = document.querySelector('object');
      if (obj) {
        obj.type = fileType;
        obj.data = window.URL.createObjectURL(file) + '#toolbar=1';

        // only for safari
        if (window.safari) {
          const reader = new FileReader();
          reader.onload = () => {
            obj.data = reader.result;
          };
          reader.readAsDataURL(file);
        }
      }

      const img = document.querySelector('img#embed-img');
      if (img) {
        img.type = fileType;
        img.src = window.URL.createObjectURL(file);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const canPreview = (fileObject) => {
    try {
      const fileExtensionPattern = /\.([0-9a-z]+)(?=[?#])|(\.)(?:[\w]+)$/gmi;
      const fileFormat = (fileObject.fileName || '').toLowerCase().match(fileExtensionPattern);
      if (fileFormat) {
        return candidateConst.canEmbedFileTypes.includes(fileFormat[0]);
      } else {
        return false;
      }
    } catch (err) {
      console.log(err);
      return false;
    }
  };

  const isImage = (fileName) => {
    const attachFileName = fileName ? fileName.toLowerCase() : '';
    const images = ['.png', '.jpg', '.jpeg', '.gif'];
    let found = false;
    for (let i = 0; i < images.length && !found; i++) {
      if (attachFileName.indexOf(images[i]) !== -1) {
        found = true;
      }
    }
    return found;
  };

  return {
    isImage,
    canPreview,
    downloadFile,
    embedFile,
    openFileInNewTab,
    downloadImportFile,
  };
};
