const daysOpenLookups = {
  '0-3': {
    default: '#D7D7FF',
    selected: '#2DCAFF',
    inactive: '#E4E4EB',
    filterByLabel: '0-3 days',
    days: 4,
    from: -4,
    order: 0
  },
  '4-7': {
    default: '#B7B7FC',
    selected: '#2DCAFF',
    inactive: '#E4E4EB',
    filterByLabel: '4-7 days',
    days: 4,
    from: -8,
    to: -5,
    order: 1
  },
  '8-14': {
    default: '#9291FF',
    selected: '#2DCAFF',
    inactive: '#C9CBD3',
    filterByLabel: '8-14 days',
    days: 7,
    from: -15,
    to: -9,
    order: 2
  },
  '15-30': {
    default: '#504EC9',
    selected: '#2DCAFF',
    inactive: '#9EA3BC',
    filterByLabel: '15-30 days',
    days: 16,
    from: -31,
    to: -16,
    order: 3
  },
  '31+': {
    default: '#272668',
    selected: '#2DCAFF',
    inactive: '#6F7489',
    filterByLabel: '31+ days',
    days: 31,
    to: -32,
    order: 4
  }
};

export default daysOpenLookups;
