import moment from 'moment-timezone';
import {get, uniq} from '../libs/lodash';
import apiUserStatus from './apiUserStatus';

export default function timezoneList() {
  const user = apiUserStatus.get() || {};

  const timezoneList = uniq([get(user, 'settings.timeZone')].concat(moment.tz.names())).map((z) => {
    return {id: z, name: z};
  });

  return timezoneList;
}
