import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';

import { BadgeList } from '../../../common';
import RecruiterInitialName from './RecruiterInitialName';
import UpdatedCreatedInfo from './UpdatedCreatedInfo';

import utils from '../../../../libs/utils';
import {get, has, isEmpty} from '../../../../libs/lodash';
import LinkToNewTabForEntity from './LinkToNewTabForEntity';
import candidateConst from '../../../../constants/candidateConst';

export default function Placement({entity, isActive, lookups, openNewTab, eventLogCallback}) {
  entity = isEmpty(entity?.candidate?.candidateName) ? entity : {...entity, ...entity?.candidate?.candidateName};
  const recruiter = get(entity, 'primaryRecruiter') || get(entity, 'commission.primaryRecruiter');
  return (
    <>
      {isActive ?
        <div className='active-item d-flex align-items-center'>
          {utils.shortName(entity?.candidate?.candidateName, candidateConst.maxFullNameLength, true)}
          <BadgeList badges={entity.badges || []} width='13' height='13' />
          <RecruiterInitialName recruiter={recruiter} />
        </div>
        :
        <div className='d-flex align-items-center'>
          { openNewTab ? <LinkToNewTabForEntity entity={entity} eventLogCallback={eventLogCallback} /> : <Link to='#' className='biotif-semi-bold font-size-16 d-flex'> {utils.shortName(entity.candidate.candidateName, candidateConst.maxFullNameLength, true)}</Link> }
          <BadgeList badges={entity.badges} width='11' height='13' />
          <RecruiterInitialName recruiter={recruiter} />
        </div>
      }

      <div className={`pt-1 text-wrap${!isEmpty(entity.placementType) ? '' : ' d-none'}`}>
        {`${utils.camelize(entity.placementType)} - ${utils.originalDate(entity.scheduledStartDate)} ${has(entity, 'scheduledEndDate')?'-> '+utils.originalDate(entity.scheduledEndDate):''}`}
      </div>

      <div className={`pt-1 text-wrap${!isEmpty(entity.jobTitle) ? '' : ' d-none'}`}>
        {entity.jobTitle ? utils.contentForRender(entity.jobTitle, 28) : ''}
      </div>

      <div className={`pt-1${!isEmpty(get(entity, 'job.companyName')) ? '' : ' d-none'}`}>
        {utils.contentForRender(get(entity, 'job.companyName') || '', 28)}
      </div>

      <div className='pt-1'>
        {utils.convertIdToName(lookups, 'placementStatuses', entity.status)}
      </div>

      <UpdatedCreatedInfo entityType='placement' createdAt={entity.createdAt} updatedAt={entity.updatedAt} />
    </>
  );
};

Placement.propTypes = {
  entity: PropTypes.object.isRequired,
  isActive: PropTypes.bool
};

