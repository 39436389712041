const emailRejectedReasonLookups = [
  {id: 'EMAIL_STATUS', name: 'The email status is Rejected, Hard Bounce, or Spam.',  displayName: 'Rejected, Hard Bounce, or Spam'},
  {id: 'EMAIL_VALIDITY', name: 'The email is Invalid.', displayName: 'Email Invalid'},
  {id: 'ENTITY_OPT_OUT', name: 'The recipient has opted out of mass emails.', displayName: 'Opted Out'},
  {id: 'ENTITY_STATUS', name: 'The entity status is Archived or Do Not Use.', displayName: 'Archived or Do Not Use'},
  {id: 'HARD_BOUNCE', name: 'The email sending service responded with Hard Bounce delivery status', displayName: 'Hard Bounce'},
  {id: 'LIST_NOT_ACTIVE', name: 'The list is inactive.', displayName: 'List Inactive'},
  {id: 'NOT_ENOUGH_DATA', name: 'Not enough data to replace all variables from email body and subject.', displayName: 'Not enough data for personalize'},
  {id: 'SOFT_BOUNCE', name: 'The email sending service responded with Soft Bounce delivery status', displayName: 'Soft Bounce'},
  {id: 'SPAM', name: 'The recipient marked this email as a spam', displayName: 'Spam'}
];

export default emailRejectedReasonLookups;
