const highlights = [
  {value: 'NUM_POSITIONS', label: 'Total Positions', highlightUI: 'Positions'},
  {value: 'NUM_REMAINING_POSITIONS', label: 'Remaining Positions', highlightUI: 'Remaining Positions'},
  {value: 'NUM_COVERAGE', label: 'Coverage %', highlightUI: 'Covered',  filter: true, filterCriteria: {coverage: true}},
  {value: 'NUM_NEW_LEAD', label: 'Status: New Lead', highlightUI: 'New Lead', filter: true, filterCriteria: {openStatus: 'NEW_LEAD'}},
  {value: 'NUM_OPEN', label: 'Status: Open', highlightUI: 'Open', filter: true, filterCriteria: {openStatus: 'OPEN'}},
  {value: 'NUM_COVERED', label: 'Status: Covered', highlightUI: 'Covered', filter: true, filterCriteria: {openStatus: 'COVERED'}},
  {value: 'NUM_ONHOLD', label: 'Status: On Hold', highlightUI: 'On Hold', filter: true, filterCriteria: {openStatus: 'ONHOLD'}},
  {value: 'NUM_PIPELINE', label: 'Status: Pipeline', highlightUI: 'Pipeline', filter: true, filterCriteria: {openStatus: 'PIPELINE'}},
  {value: 'NUM_EXPIRED', label: 'Status: Expired', highlightUI: 'Expired', filter: true, filterCriteria: {openStatus: 'EXPIRED'}},
  {value: 'NUM_CLOSED', label: 'Status: Closed', highlightUI: 'Closed', filter: true, filterCriteria: {openStatus: 'CLOSED'}},
  {value: 'DAYS_CLIENT_REVIEW', label: 'Avg Days to 1st Client Review', highlightUI: 'Days to submit', borderLeft: '4px solid #f2bc12', isAverage: true},
  {value: 'DAYS_INTERVIEW', label: 'Avg Days to 1st Interview', highlightUI: 'Days to interview', borderLeft: '4px solid #f2bc12', isAverage: true},
  {value: 'DAYS_OFFER', label: 'Avg Days to 1st Offer', highlightUI: 'Days to Offer', borderLeft: '4px solid #f2bc12', isAverage: true},
  {value: 'DAYS_HIRE', label: 'Avg Days to 1st Placed', highlightUI: 'Days to Hire', borderLeft: '4px solid #f2bc12', isAverage: true},
  {value: 'NUM_PRE_SUBMISSION', label: 'Total Presubmissions', highlightUI: 'Presubs', borderLeft: '4px solid #f2bc12'},
  {value: 'NUM_INTERNAL_REVIEW', label: 'Total Internal Review', highlightUI: 'Internal Review', borderLeft: '4px solid #f2bc12'},
  {value: 'NUM_CLIENT_REVIEW', label: 'Total Client Review', highlightUI: 'Client Review', borderLeft: '4px solid #f2bc12'},
  {value: 'NUM_INTERVIEWING', label: 'Total Interviewing', highlightUI: 'Interviewing', borderLeft: '4px solid #f2bc12'},
  {value: 'NUM_OFFERED', label: 'Total Offered', highlightUI: 'Offered', borderLeft: '4px solid #f2bc12'},
  {value: 'NUM_PLACED', label: 'Total Placed', highlightUI: 'Placed', borderLeft: '4px solid #f2bc12'},
  {value: 'NUM_PAST_INTERVIEWS', label: 'Past Interviews', highlightUI: 'Past Interviews', borderLeft: '4px solid #f2bc12'},
  {value: 'NUM_UPCOMING_INTERVIEWS', label: 'Upcoming Interviews', highlightUI: 'Upcoming Interviews', borderLeft: '4px solid #f2bc12'},
];

export default highlights;
