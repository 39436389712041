import React from 'react';
import {Link} from 'react-router-dom';
import utils from '../../../../libs/utils';
import {get, isArray} from '../../../../libs/lodash';

function billRate(props) {
  try {
    const {job = {}, jobType, rateInfo = {}} = props.data || {};

    const baseSalary = rateInfo.baseSalary;
    const billRate = rateInfo.billRate;
    const currencyType = rateInfo.currencyType;

    let payInfo = '';
    if ((jobType || job.jobType)  === 'PERMANENT') {
      payInfo = baseSalary && currencyType ? utils.dollarForRender(baseSalary, currencyType, 'compact') : '';
    } else {
      payInfo = billRate && currencyType ? utils.dollarForRender(billRate, currencyType, 'compact') : '';
    }

    return payInfo;
  } catch (err) {
    console.log(err);
    return '';
  }
}

function personFullName(props) {
  try {
    let personArray = get(props.data, props.fullNameOf);

    if (personArray && !isArray(personArray)) {
      personArray = [personArray];
    }

    const names = (personArray || []).map((p) => {
      const {firstName, lastName} = p;
      return `${firstName} ${lastName}`;
    });
    return names.join(', ');
  } catch (err) {
    console.log(err);
    return '';
  }
}

function rateType(props) {
  try {
    const {job = {}, jobType, rateInfo = {}} = props.data || {};
    const billRate = rateInfo.billRate;
    const rateType = rateInfo.rateType;

    if ((jobType || job.jobType) !== 'PERMANENT') {
      return billRate && rateType ? utils.camelize(rateType) : '';
    } else {
      return '';
    }
  } catch (err) {
    console.log(err);
    return '';
  }
}

function companyLink(props) {
  const {companyName, companyId, companyType } = get(props.data, props.companyOf) || {};

  try {
    if (!props.companyType || companyType === props.companyType) {
      return <span><Link to={`/company/${companyId}`}>{companyName}</Link></span>;
    } else {
      return '';
    }
  } catch (error) {
    console.log('companyLink:', error);
    return '';
  }
}

function companyType(props) {
  const { companyType = null } = get(props.data, props.typeOf) || {};
  if (companyType === 'MSP') {
    return companyType;
  }

  return utils.camelize(companyType);
}

function contactInfo(props) {
  const company = get(props.data, props.companyOf);
  const { companyType } =  company || {};

  if ((props.companyType === 'CLIENT' && companyType === 'MSP')  || (props.companyType === 'MSP' && companyType === 'CLIENT')) {
    return '';
  }

  let contact;
  let contactNameAttr = '';
  if (props.companyType === 'CLIENT') {
    contactNameAttr = companyType === 'CLIENT' ? 'contact.contactName' : 'programInfo.clientContact.contactName';
    contact = companyType === 'CLIENT' ? props.data.contact : (props.data.programInfo || {}).clientContact;
  }

  if (props.companyType === 'MSP') {
    contactNameAttr = companyType === 'MSP' ? 'contact.contactName' : 'programInfo.mspContact.contactName';
    contact = companyType === 'MSP' ? props.data.contact : (props.data.programInfo || {}).mspContact;
  }

  const { contactId = null } = contact || {};

  if(props.returnLink) {
    return (
      <Link to={`/contact/${contactId}`}>
        {personFullName({...props, fullNameOf: contactNameAttr})}
      </Link>
    );
  } else {
    return personFullName({...props, fullNameOf: contactNameAttr});
  }
}

export {
  billRate,
  personFullName,
  rateType,
  companyLink,
  companyType,
  contactInfo
};

