import React, {useCallback, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import {compact, get, isEmpty} from '../../../libs/lodash';
import searchConst from '../../../constants/searchConst';
import QuickSearchItem from '../QuickSearchItem';
import utils from '../../../libs/utils';

import apiUserStatus from '../../../services/apiUserStatus';
import ApiClient from '../../../services/ApiClient';

export default function AtsTypeAheadSelect(props){
  const typeaheadRef = {};
  const filterBy = searchConst.entityTypeAheadFilterBy;

  const [entityType] = useState(props.entityType);
  const [originalEntity, setOriginalEntity] = useState({});
  const [entitySelected, setEntitySelected] = useState(!isEmpty(props.defaultValue) ? [props.defaultValue]:[]);
  const [entityOptions, setEntityOptions] = useState(
    !isEmpty(props.defaultValue) ?
      [props.defaultValue]
      :
      props.showRecentViewed && (get(apiUserStatus.get() || {}, 'recentViewedEntities.' + props.entityType) || [])
  );
  const [isLoading, setIsLoading] = useState(false);

  const prepareEntity = useCallback((item) => {
    const entityId = get(item, 'id') || get(item, 'entityId') || item[entityType.toLowerCase()+'Id'];
    return {
      ...item,
      entityType: item.entityType ? item.entityType : entityType,
      entityId: +entityId,
    };
  }, [entityType]);

  useEffect(() => {
    if (!isEmpty(props.defaultValue)) {
      setEntitySelected([props.defaultValue]);
      setEntityOptions([props.defaultValue]);

      const entity = prepareEntity(props.defaultValue);
      setOriginalEntity(entity);
    }
  }, [props.defaultValue, prepareEntity]);

  const revertBackOriginalEntity = async () => {
    if (!isEmpty(originalEntity)) {
      setEntitySelected([originalEntity]);
      setEntityOptions([originalEntity]);
      await typeaheadOnChange([originalEntity], originalEntity.entityType);
    }
  };

  const typeaheadOnSearch = async (query) => {
    if (!query || query.length < searchConst.minLength) {
      return;
    }
    setIsLoading(true);
    try {
      query = utils.cleanQuickSearchInput(query);
      const response = await ApiClient.searchQuick({query, entityType});
      const tempOptions = (response.body || []).map((item) => {
        item.entityType = item.entityType ? item.entityType : entityType;
        item.entityId = item.entityId ? +item.entityId : +item[entityType.toLowerCase()+'Id'];

        item.searchId = '' + (item.entityId || '');
        item.searchStringId = '' + get(item, 'string' + utils.camelize(item.entityType) +'Id', '');

        filterBy.forEach(function(field) {
          item[field] = item[field] || '';
        });

        const {firstName, lastName} = item.candidateName || item.contactName || item;
        if (firstName || lastName) {
          item.fullName = compact([firstName, lastName]).join(' ');
        }

        return item;
      }).filter((item) => {
        if (entitySelected.length > 0) {
          return item.entityId !== entitySelected[0].entityId;
        } else {
          return true;
        }
      }).filter((item) => {
        return item.entityId !== props.filterId;
      });

      setEntityOptions(tempOptions);
      setIsLoading(false);
      if (isEmpty(tempOptions)) {
        await revertBackOriginalEntity();
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const typeaheadOnBlur = async (e) => {
    e.preventDefault();
    if (isEmpty(entitySelected)) {
      await revertBackOriginalEntity();
    }
  };

  const fetchEntity = async (selectedEntity) => {
    try {
      const res = await ApiClient[searchConst.entityMap[selectedEntity.entityType.toLowerCase()]+'Details']({id: +selectedEntity.entityId});
      return res.body;
    } catch (error) {
      console.log(error);
      return selectedEntity;
    }
  };

  const typeaheadOnChange = async (selected, entityType) => {
    try {
      const fieldName = props.name ? props.name : entityType.toLowerCase();
      if (selected.length > 0) {
        const entity = await fetchEntity(selected[0]);
        const mergedEntity = {...selected[0], ...entity};
        props.callback({[fieldName + 'Id']: entity[entityType.toLowerCase() + 'Id'], [fieldName]: mergedEntity});
        setEntitySelected(selected);

        if (props.showRecentViewed) {
          const eventProperties = {object: (props.entityType || '').toLowerCase()};
          if (selected[0].recentViewedEntity) {
            eventProperties.action = 'select recent';
          } else {
            eventProperties.action = 'search';
          }
          window.amplitudeLogEvent('search entity field', eventProperties);
        }
      } else {
        props.callback({[fieldName + 'Id']: null, [fieldName]: {}});
        setEntitySelected([]);
        setEntityOptions([]);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const onFocus = (e) => {
    try {
      if (!props.showRecentViewed || !isEmpty(props.defaultValue)) {
        return false;
      }

      const inputObj = e.target;
      adjustClassName('', inputObj);
    } catch (err) {
      console.log(err);
    }
  };

  const onInputChange = (inputValue, e) => {
    try {
      if (!props.showRecentViewed) {
        return false;
      }

      const inputObj = e.target;
      adjustClassName(inputValue, inputObj);
    } catch (err) {
      console.log(err);
    }
  };

  function adjustClassName(inputValue, inputObj) {
    try {
      const ulObj = inputObj.closest('.rbt');
      const recentViewedOptions = get(apiUserStatus.get() || {}, 'recentViewedEntities.' + entityType) || [];

      if (inputValue) {
        ulObj.classList.remove('recent');
      } else {
        setEntityOptions(recentViewedOptions);
        if (!isEmpty(recentViewedOptions)) {
          ulObj.classList.add('recent');
        }
      }
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <>
      <AsyncTypeahead
        multiple={props.scope==='MASS_SUBMIT'}
        ref={(ref) => typeaheadRef[entityType.toLowerCase()] = ref}
        selected={entitySelected}
        required={props.required}
        isInvalid={isEmpty(entitySelected) && props.required && props.formValidated}
        clearButton={true}
        id={props.id ? props.id : `typeahead-${entityType}`}
        name={props.name ? props.name : `typeahead-${entityType}`}
        align='justify'
        labelKey={(option) => `${utils.prepareTypeAheadLabelKey(option)}`}
        minLength={isEmpty(props.showRecentViewed && get(apiUserStatus.get() || {}, 'recentViewedEntities.' + props.entityType)) ? searchConst.minLength : 0}
        isLoading={isLoading}
        options={entityType === 'COMPANY' && !isEmpty(props.companyTypes) ? (entityOptions || []).filter(item => props.companyTypes.includes(item.companyType)) : entityOptions}
        highlightOnlyResult={false}
        Highlighter={true}
        delay={searchConst.delay}
        onSearch={typeaheadOnSearch}
        onBlur={(e) => typeaheadOnBlur(e)}
        onChange={(selected) => typeaheadOnChange(selected, entityType)}
        onFocus={(e) => onFocus(e)}
        onInputChange={(inputValue, e) => onInputChange(inputValue, e)}
        placeholder='Type to search'
        promptText='Type to search...'
        searchText='Searching...'
        // filterBy={filterBy}
        filterBy={(option, props) => true}
        renderMenuItemChildren={(option, props) => {
          return <QuickSearchItem item={option} searchTerm={props.text} usedFor='typeahead' />;
        }}
      />
    </>
  );
};

AtsTypeAheadSelect.propTypes = {
  name: PropTypes.string.isRequired,
  entityType: PropTypes.string.isRequired,
};
