import React, {useEffect, useState} from 'react';
import CreatableSelect from 'react-select/creatable';
import PropTypes from 'prop-types';
import {isEmpty} from '../../../libs/lodash';
import './style.scss';

const components = {
  DropdownIndicator: null,
};

const createOption = (label) => ({
  label,
  value: label,
});

export default function CreatableInputOnly(props) {
  const [inputValue, setInputValue] = useState('');
  const [value, setValue] = useState([]);

  useEffect(() => {
    if (!isEmpty(props.defaultValue)) {
      setValue(props.defaultValue.map((v) => {
        return  createOption(v);
      }));
    } else {
      setValue([]);
    }
  }, [props.defaultValue]);

  const handleChange = (value, actionMeta) => {
    setValue(value || []);
  };

  const handleInputChange = (inputValue) => {
    setInputValue(inputValue);
  };

  const handleKeyDown = (event) => {
    if (!inputValue) {return;}
    switch (event.key) {
    case 'Enter':
    case 'Tab':
      setValue([...value, createOption(inputValue)]);
      setInputValue('');
      event.preventDefault();
      break;
    default:
    }
  };

  const handleOnBlur = (event) => {
    let newValue = '';
    if (inputValue) {
      newValue = [...value, createOption(inputValue)];
      setValue(newValue);
      setInputValue('');
    } else {
      newValue = value;
    }

    if (!isEmpty(newValue)) {
      props.callback({[props.name]: newValue.map((v) => { return  v.value; })});
    } else {
      props.callback({[props.name]: []});
    }
    event.preventDefault();
  };

  const selectClass =
    (!props.required || !props.validated || !isEmpty(value)) ? '' : ' error';

  return (
    <div className='select form-control'>
      <CreatableSelect
        components={components}
        id={props.id}
        name={props.name}
        required={true}
        inputValue={inputValue}
        isClearable={false}
        classNamePrefix="ats-select"
        className={selectClass}
        isMulti
        menuIsOpen={false}
        onChange={handleChange}
        onInputChange={handleInputChange}
        onKeyDown={handleKeyDown}
        onBlur={handleOnBlur}
        placeholder={props.placeholder ? props.placeholder : 'Type criteria and press enter...'}
        value={value}
        theme={theme => ({
          ...theme,
          borderRadius: '0.25rem',
          colors: {
            ...theme.colors,
            primary25: '#f3f4f8',
            primary: '#2dcaff',
            neutral50: '#9399B2',
            neutral10: '#e2f8ff',
            danger: '#cd1d58'
          }
        })}
      />

      <input id={`hidden-${props.id}`}
        tabIndex={-1}
        style={{
          opacity: 0,
          height: 0,
          position: 'absolute',
        }}
        defaultValue={value}
        required={props.required}
      />
    </div>
  );
}

CreatableInputOnly.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  required: PropTypes.bool,
  defaultValue:PropTypes.array,
  callback: PropTypes.func.isRequired,
  validated: PropTypes.bool
};

