import React from 'react';

export default () => {
  return(
    <div>
      <div className='pt-3 pb-2 font-size-18 dark-grey-text biotif-semi-bold'>Are you sure you want to merge these records? This will:</div>
      <div className='font-size-18 pt-3 pb-4'><span className='p-4'>&#9679;</span>Combine all data fields, deferring to the primary record;</div>
      <div className='font-size-18 pb-4'><span className='p-4'>&#9679;</span>Move all notes to the final record;</div>
      <div className='font-size-18 pb-4'><span className='p-4'>&#9679;</span>Reassign any submissions, jobs, placements, etc, to the final record;</div>
      <div className='font-size-18 pb-5'><span className='p-4'>&#9679;</span>Archive the original records;</div>
    </div>
  );
};
