import React, {useEffect, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import InfiniteScroll from 'react-infinite-scroll-component';
import Input from 'antd/es/input';
import {AtsModal, AtsSelect, Button, Spinner} from '../../common';
import { visibilityOptions } from '../../../constants/templateConst';
import { prepareForRenderTemplates } from '../libs/utils';
import './style.scss';
import 'antd/es/input/style/css';
import ApiClient from '../../../services/ApiClient';
import useApiQuery from '../../../hooks/useApiQuery';

const { Search } = Input;

export default function SelectModal({modalVisible, modalCancel, useTemplate, entityType, category, addTemplateCallback}) {
  const maxResults = 100;
  const [offset, setOffset] = useState(0);
  const [totalNum, setTotalNum] = useState(0);
  const [contents, setContents] = useState([]);
  const parameters = {offset: 0, maxResults: maxResults};

  const { data: templatesFetchedData, isFetched: templatesFetchIsCompleted } = useApiQuery(['templatesListMy'], 'templatesListMy', parameters);
  
  const newContent = useMemo(() => {
    if (templatesFetchIsCompleted) {
      const preparedTemplates = templatesFetchedData.body.templates.map((data) => {
        return prepareForRenderTemplates(data);
      });
      return preparedTemplates.filter((template) => {
        if (entityType) {
          return template.entityTypes.includes(entityType) && template.category === category;
        } else {
          return template.category === category;
        }
      });
    }
  }, [category, entityType, templatesFetchIsCompleted, templatesFetchedData?.body.templates]);

  useEffect(() => {
    if (templatesFetchIsCompleted) {
      setContents(newContent);
      setOffset(parameters.maxResults);
      setTotalNum(templatesFetchedData.body.summary.numResultsFound);
    };
  }, [maxResults, entityType, category, templatesFetchIsCompleted, newContent, parameters.maxResults, templatesFetchedData?.body.summary.numResultsFound]);

  const fetchMoreData = async () => {
    try {
      const parameters = {offset: offset, maxResults: maxResults};
      const res = await ApiClient.templatesListMy(parameters);
      const newContent = res.body.templates.map((data) => {
        return prepareForRenderTemplates(data);
      }).filter((template) => {
        if (entityType) {
          return template.entityTypes.includes(entityType) && template.category === category;
        } else {
          return template.category === category;
        }
      });
      setContents([...contents, ...newContent]);
      setOffset(offset+maxResults);

      if(filterTerms) {
        const newFilterContents = (newContent || []).filter((template) => {
          return filterTerms.length > 0 && template.name.toLowerCase().includes(filterTerms);
        });
        setFilterContents([...filterContents, ...newFilterContents]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const defaultContent = { name: '', status: 'Active', content: '', entityTypes: [], category: '' };
  const [content, setContent] = useState(defaultContent);

  const [filterContents, setFilterContents] = useState(contents);
  const [filterTerms, setFilterTerms] = useState('');
  const [filterScope, setFilterScope] = useState('ALL');
  const contentFilter = (event) => {
    const terms = event.target.value.toLowerCase();
    const newContents = contents.filter((template) => {
      return terms.length > 0 && template.name.toLowerCase().includes(terms);
    });
    setFilterTerms(terms);
    setFilterContents(newContents);
  };

  const searchOnFocus = () => {
    window.amplitudeLogEvent('templates', { action: 'text search' });
  };

  const changeVisibility = (updatedValues) => {
    setFilterScope(updatedValues['template-select-visibility']);
    window.amplitudeLogEvent('templates', { action: 'filter', filterValue: (updatedValues['template-select-visibility'] || '').toLowerCase()});
  };

  const selectTemplate = (template, e) => {
    e.preventDefault();
    setContent(template);
    window.amplitudeLogEvent('templates',
      {
        action: 'select template',
        filterValue: (filterScope || '').toLowerCase(),
        searchString: (filterTerms || '').toLowerCase(),
        timer : 'end'
      });
  };

  const filterTemplatesByScope = (template) => {
    switch(filterScope) {
    case 'TEAM':
      return template.users.length > 0;
    case 'PUBLIC':
      return template.isPublic === true;
    case 'PRIVATE':
    default:
      return template.isPublic !== true && template.users.length <= 0;
    }
  };

  const renderTemplateList = () => {
    let templates;
    if (filterContents.length > 0 || filterTerms.length > 0 ) {
      templates = filterContents;
    } else {
      templates = contents;
    }
    if (filterScope && filterScope !== 'ALL') {
      const filteredTemplates = templates.filter((template) => {
        return filterTemplatesByScope(template);
      });
      return filteredTemplates.map((template) => {
        return (
          <div className='template-item' key={`template-${template.templateId}`} onClick={selectTemplate.bind(this, template)}>
            {template.name}
          </div>
        );
      });
    } else {
      return templates.map((template) => {
        return (
          <div className='template-item' key={`template-${template.templateId}`} onClick={selectTemplate.bind(this, template)}>
            {template.name}
          </div>
        );
      });
    }
  };

  const addTemplate = (e) => {
    e.preventDefault();
    // modalCancel();
    addTemplateCallback();
  };

  return (
    <AtsModal
      id='select-template-modal'
      modalTitle='Saved Templates'
      modalSize='md'
      modalVisible={modalVisible}
      modalCancel={modalCancel}
      backdrop={true}
    >
      <div className='item-group w-100'>
        <div className='row col-12 pl-0'>
          <div className='search col-6'>
            <Search placeholder="Search" onFocus={searchOnFocus} onChange={contentFilter} style={{ width: 320, height: '45px'  }} />
          </div>

          <div className='filter col-6 d-flex'>
            <div className='label'>
                Filter by
            </div>
            <AtsSelect
              id='template-select-visibility'
              name='template-select-visibility'
              defaultValue={filterScope}
              required={false}
              isClearable={false}
              options={visibilityOptions}
              validated={true}
              callback={changeVisibility}
            />
            <Button id={'template-add-btn'} type='submit' variant='btn-primary ats-sm-btn' click={addTemplate}>Add</Button>
          </div>
        </div>

        <div className='row mt-4'>
          <div className='template-list' id='template-list'>
            {
              contents?.length > 0?
                <InfiniteScroll
                  scrollThreshold={0.6}
                  dataLength={filterContents.length}
                  next={fetchMoreData}
                  hasMore={totalNum > offset}
                  loader={<Spinner variant='text-center'/>}
                  scrollableTarget='template-list'>
                  { renderTemplateList() }
                </InfiniteScroll> : null
            }
          </div>
          <div id='column-divider'/>
          <div className='template-content' style={{'whiteSpace': 'pre-wrap'}}>
            <div dangerouslySetInnerHTML={{__html: (content.content ? content.content : '&nbsp;')}} />
          </div>
        </div>

        <div className="d-flex justify-content-end pt-5">
          <Button type='button' variant='mb-4 btn-secondary mr-4' click={() => modalCancel()}>Cancel</Button>
          <Button type='submit' variant='mb-4 btn-primary ats-md-btn' click={useTemplate.bind(this, content)}>Confirm</Button>
        </div>
      </div>

    </AtsModal>
  );
};

SelectModal.propTypes = {
  modalVisible: PropTypes.bool.isRequired,
  modalCancel: PropTypes.func.isRequired,
  useTemplate: PropTypes.func.isRequired,
  addTemplateCallback: PropTypes.func.isRequired,
  category: PropTypes.string.isRequired,
  entityType:  PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};
