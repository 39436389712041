import React from 'react';
import Modal from 'react-bootstrap/Modal';
import PropTypes from 'prop-types';
import './style.scss';

export default function AtsModal({id, modalTitle, modalSize, modalVisible, modalCancel, children, backdrop='static', modalFooter}) {
  return (
    <Modal id={id}
      enforceFocus={false}
      keyboard={false}
      show={modalVisible}
      onHide={modalCancel}
      size={modalSize}
      aria-labelledby={`${id}-title-vcenter`}
      centered
      backdrop={backdrop}
    >
      <Modal.Header closeButton>
        <Modal.Title id={`${id}-title-vcenter`}>
          {modalTitle}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {children}
      </Modal.Body>
      {
        modalFooter ?
          <Modal.Footer>
            {modalFooter}
          </Modal.Footer>
          : ''
      }
    </Modal>
  );
}

AtsModal.propTypes = {
  id: PropTypes.string.isRequired,
  modalTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  modalSize: PropTypes.string.isRequired,
  modalVisible: PropTypes.bool.isRequired,
  modalCancel: PropTypes.func.isRequired,
  children: PropTypes.object.isRequired
};
