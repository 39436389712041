import moment from 'moment';
import holidayConfig from '../holidayConfig';

function getHolidayTheme() {
  const holidays = Object.keys(holidayConfig);

  for (let holiday of holidays) {
    if (moment().isBetween(holidayConfig[holiday].startDate, holidayConfig[holiday].endDate)) {
      return holidayConfig[holiday];
    }
  }

  return null;
}

function updateTheme(themeConfig) {
  if (themeConfig.themeBackground) {
    document.documentElement.style.setProperty('--theme-background', themeConfig.themeBackground);
  }

  if (themeConfig.themeButtonBG) {
    document.documentElement.style.setProperty('--theme-button-color', themeConfig.themeButtonBG);
  }

  if (themeConfig.themeFooterBG) {
    document.documentElement.style.setProperty('--theme-footer-background', themeConfig.themeFooterBG);
  }

  if (themeConfig.themeImage) {
    document.documentElement.style.setProperty('--theme-image', `url(${themeConfig.themeImage})`);
  }

  if (themeConfig.themeFooterColor) {
    document.documentElement.style.setProperty('--theme-footer-color', themeConfig.themeFooterColor);
  }
}

export {
  getHolidayTheme,
  updateTheme
};
