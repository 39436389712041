import { useQuery, useQueryClient } from '@tanstack/react-query';
import ApiClient from '../services/ApiClient';

async function ApiServices(operationId, entityId, params) {
  const response = await ApiClient[operationId](entityId, params);
  return response;
}

function useApiQuery(queryKey, operationId, entityId, params, enabled, retry = 3) {
  const { data, error, isFetched, isSuccess, isLoading } = useQuery({queryKey: queryKey, queryFn: () => ApiServices(operationId, entityId, params), enabled: enabled, retry});
  if (error) {
    console.log(`${queryKey} error:`, error);
  }

  const queryClient = useQueryClient();

  const resetQuery = () => {
    queryClient.resetQueries({
      queryKey, exact: true
    });
  };

  return { data, isFetched, isSuccess, isLoading, error, resetQuery };
};

export default useApiQuery;
