import './style.scss';
import { AtsInput, Button } from '../../compoments/common';
import React, { useState } from 'react';
import patternConst from '../../constants/patternConst';
import {has} from '../../libs/lodash';
import { StatusCodes } from 'http-status-codes';
import ApiClient from '../../services/ApiClient';

export default ({ switchType }) => {
  const [urlParams] = useState(new URLSearchParams(window.location.search));
  const [password, setPassword] = useState('');
  const [confirmPwd, setConfirmPwd] = useState('');
  const [validationInfo, setValidationInfo] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const passwordRules = `Password must have:
Minimum 8 characters, Maximum 15 characters, Minimum 1 number,
Minimum 1 lowercase character, Minimum 1 uppercase character`;

  const updatePassword = (data) => {
    if (has(data, 'password')) {
      setPassword(data.password);
      validatePassword(data.password, confirmPwd);
    }

    if (has(data, 'confirm-password')) {
      setConfirmPwd(data['confirm-password']);
      validatePassword(password, data['confirm-password']);
    }
  };

  const validatePassword = (password, confirmPwd) => {
    if (!password) {
      setValidationInfo('Please enter your password');
    } if (password && !password.match(patternConst.passwordPattern)) {
      setValidationInfo(passwordRules);
    } else if (password && !confirmPwd) {
      setValidationInfo('Please confirm your password');
    } else if (password && password !== confirmPwd) {
      setValidationInfo('Passwords don\'t match');
    } else if (password && confirmPwd && password === confirmPwd && password.match(patternConst.passwordPattern)) {
      setValidationInfo('');
      return  true;
    }

    return false;
  };

  const submitPassword = async (e) => {
    e.preventDefault();
    if (validatePassword(password, confirmPwd) && password.match(patternConst.passwordPattern) && confirmPwd.match(patternConst.passwordPattern) && password === confirmPwd) {
      try {
        const token    = urlParams.get('token');
        setIsLoading(true);
        const request  = window.location.pathname.toLowerCase().startsWith('/register') ? ApiClient.usersRegister : ApiClient.usersResetPassword;
        const response = await request({token, password: encodeURIComponent(password)});
        if (response.status === StatusCodes.NO_CONTENT) {
          switchType('setPasswordSuccess');
        }
        setIsLoading(false);
      } catch (error) {
        console.log('setPassword error', error);
        setIsLoading(false);
      }
    }
    return false;
  };

  return (
    <div>
      <div className='reset-password'>
        <form onSubmit={submitPassword} autoComplete='off' noValidate>
          <div className='d-flex pb-4 mb-4'>
            <div className='ats-tile'>
              <div className='loginTitle'>
                Set Password
              </div>
            </div>
          </div>

          <div className='form-group'>
            <div className='label'>Please enter a password for your account:</div>
          </div>

          <div className='form-group'>
            <AtsInput id='password' name='password' placeholder="Password" required callback={updatePassword} type={'password'} pattern={patternConst.passwordPattern} />
          </div>

          <div className='form-group'>
            <div className='label'>Confirm your password:</div>
          </div>

          <div className='form-group'>
            <AtsInput id='confirm-password' name='confirm-password' placeholder="Confirm Password" required callback={updatePassword} type={'password'} />
          </div>

          <div className='form-group'>
            <div className='error'>
              { validationInfo }
            </div>
          </div>

          <Button type='submit' id='reset-password-button' variant='my-4 w-100' disabled={isLoading}>
            CONFIRM
          </Button>
        </form>
      </div>
    </div>

  );
};
