import React from 'react';
import RecentEntry from './RecentEntry';


export default function RecentList({recentItems, doSearch, doSavedSearch, openItem, activeTab}) {
  return (
    <>
      {
        recentItems.map((item, index) => {
          return (
            <div key={`recent-item-${index}`}>
              <RecentEntry item={item} doSearch={doSearch} doSavedSearch={doSavedSearch} openItem={openItem} activeTab={activeTab} />
            </div>
          );
        })
      }
    </>
  );
}
