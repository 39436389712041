import React from 'react';
import {AtsModal} from '../../common';
import {get} from '../../../libs/lodash';
import Button from '../../common/Button';

export default function BulkEmail({mail, title, modalVisible, modalCancel, sendBulkEmail}) {
  return (
    <AtsModal
      id='note-detail-modal'
      modalTitle={title}
      modalSize='md'
      modalVisible={modalVisible}
      modalCancel={modalCancel}
    >
      <>
        <div className='form-group col-12 px-0 mb-0'>
          <label className='light-grey-text col-2 px-0'>
            From
          </label>
          <span className='biotif-semi-bold'>{get(mail, 'sender.name')} ({get(mail, 'sender.email')})</span>
        </div>
        <div className='form-group col-12 px-0 mb-0'>
          <label className='light-grey-text col-2 px-0'>
            To
          </label>
          <span className='biotif-semi-bold'>{get(mail, 'recipients[0].name')} ({get(mail, 'recipients[0].email')})</span>
        </div>
        <div className='form-group col-12 px-0 mb-0'>
          <label className='light-grey-text col-2 px-0'>
            Subject
          </label>
          <span className='biotif-semi-bold'>{get(mail, 'subject')}</span>
        </div>
        <div className='form-group col-12 px-0 mb-0 pt-1'>
          <label className='light-grey-text'>
            Message
          </label>
        </div>
        <div className='p-3 mce-content-body' style={{'borderRadius': '5px', 'border': '1px solid #c7c7c9fa', height: '320px', overflowY: 'auto', minWidth: '764px'}} dangerouslySetInnerHTML={{__html: mail.body}} />

        {sendBulkEmail ?
          <div className='form-group'>
            <div className='d-flex justify-content-end pt-3'>
              <Button type='button' id='close-modal' variant='btn-secondary mr-4' click={() => modalCancel()}>
                Back
              </Button>
              <Button type="submit" id='send-all-email' variant='btn-primary ats-md-btn' click={() => sendBulkEmail()}>
                Send All
              </Button>
            </div>
          </div>
          :
          ''
        }
      </>
    </AtsModal>
  );
}
