import moment from 'moment';

export default function timelineLabels(desiredStartTime='05:45 AM', interval=15, period='minutes') {
  const periodsInADay = moment.duration(24, 'hours').as(period);

  const timeLabels = [];
  const startTimeMoment = moment(desiredStartTime, 'hh:mm A');

  for (let i = 1; i <= periodsInADay; i += interval) {
    startTimeMoment.add(i === 0 ? 0 : interval, period);
    timeLabels.push({id: startTimeMoment.format('hh:mm A'), name: startTimeMoment.format('hh:mm A')});
  }

  return timeLabels;
};
