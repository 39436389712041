import './style.scss';
import React, {useEffect, useState} from 'react';
import {AtsModal, AtsInput, Button, AtsEditor, AtsSelect, AtsPersonalize} from '../../common';
import useForm from '../../../hooks/useForm';
import { prepareForRenderTemplates } from '../libs/utils';
import { entityTypesOptions, categoryOptions, statusOption, allEntityTypes } from '../../../constants/templateConst';
import useLookup from '../../../hooks/useLookup';
import {cloneDeep, get, has, uniqBy} from '../../../libs/lodash';
import {isValidatePersonalize} from '../../../libs/validate';
import ApiClient from '../../../services/ApiClient';
import AtsCheckBox from '../../common/AtsCheckBox';
import utils from '../../../libs/utils';
import apiUserStatus from '../../../services/apiUserStatus';
import { PublicCheckBoxContainer } from './editModalStyled';

const initDataObjectValues = {
  name: '',
  status: 'Active',
  isPublic: false,
  entityTypes: allEntityTypes,
  objectTypes: ['ALL'],
  category: 'STANDARD',
  content: ''
};

export default function EditModal({actionType, modalVisible, modalCancel, formData, saveContent, defaultValue, selectTemplateCallback, personalizeVariables}) {
  const { userPermissionGroupIds = [] } = apiUserStatus.get() || {};
  const [ignoreInvalidPersonalize, setIgnoreInvalidPersonalize] = useState(false);
  const [invalidPersonalizeMsg, setInvalidPersonalizeMsg] = useState('');
  const [initData, setInitData] = useState(defaultValue || initDataObjectValues);

  useEffect(() => {
    if(actionType === 'Edit') {
      setInitData({
        ...formData,
        sharedWithIds: utils.prepareCombinedIds({
          userIds: formData.userIds || [],
          teamIds: formData.teamIds || []
        }),
      });
    }
  }, [actionType, formData]);

  const lookups = useLookup({names: ['teams', 'users']});
  const combinedLookups = utils.prepareCombinedLookups({
    userIds: uniqBy(((formData || {}).users || []).concat(lookups.users || []), 'id'),
    teamIds: uniqBy(((formData || {}).teams || []).concat(lookups.teams || []), 'id')
  });

  const checkBoxCallback = () => {
    const updatedValues = {isPublic: !values.isPublic};
    if (!values.isPublic) {
      updatedValues.sharedWithIds = [];
    }

    handleChange(updatedValues);
  };

  const cancelTemplateSelect = () => {
    if (selectTemplateCallback) {
      selectTemplateCallback();
    } else {
      modalCancel();
    }
  };

  const formSubmit = async() => {
    if (!isLoading) {
      try {
        if (values.category !== 'BULK_EMAIL' || ignoreInvalidPersonalize || isValidatePersonalize(values.content, personalizeVariables || lookups.emailVariables, setInvalidPersonalizeMsg)) {
          let fn;
          const defaultParams = {
            name: values.name,
            isActive: values.status === 'Active',
            isPublic: values.isPublic,
            category: values.category,
            entityTypes: values.entityTypes.includes('ALL') ? allEntityTypes : values.entityTypes,
            content: encodeURIComponent(values.content),
            userIds: [],
            teamIds: []
          };

          if ((values.sharedWithIds || []).length > 0 && !values.isPublic) {
            utils.splitIdsForApi(values.sharedWithIds, defaultParams);
          }

          setIsLoading(!isLoading);

          if (actionType === 'Add') {
            fn = ApiClient.templatesCreate;
          }
          if (actionType === 'Edit') {
            fn = ApiClient.templatesUpdate;
          }

          if (!saveContent) {
            cancelTemplateSelect();
          } else {
            const res = await fn(values.id ? {id : values.id} : {}, defaultParams);
            const updateValues = cloneDeep(values);
            if(values.id) {
              updateValues.isActive = defaultParams.isActive;
              updateValues.entityTypes = defaultParams.entityTypes;
              updateValues.userIds = defaultParams.userIds;
              updateValues.teamIds = defaultParams.teamIds;
              updateValues.users = utils.convertIdsToOptionFormat(uniqBy((formData || {}).users.concat(lookups.users), 'id'), updateValues.userIds);
              updateValues.teams = utils.convertIdsToOptionFormat(uniqBy((formData || {}).teams.concat(lookups.teams), 'id'), updateValues.teamIds);

              delete updateValues.sharedWithIds;
            }
            saveContent(!values.id ? prepareForRenderTemplates(res.body) : prepareForRenderTemplates({...updateValues, updatedAt: (new Date()).toISOString()}));
            setIsLoading(!isLoading);
            cancelTemplateSelect();
          }
        }
      } catch (error) {
        console.log('template submit error:', error);
        setIsLoading(false);
      }
    }
  };

  const formType = 'Template';
  const [isLoading, setIsLoading] = useState(false);
  const {values, formValidated, handleChange, handleSubmit} = useForm(initData, formSubmit);

  const updateCallback = (updatedValues) => {
    if (has(updatedValues, 'objectTypes')) {
      if (get(values, 'objectTypes', []).includes('ALL') && get(updatedValues, 'objectTypes').length>1) {
        const index = updatedValues['objectTypes'].indexOf('ALL');
        updatedValues['objectTypes'].splice(index, 1);
        updatedValues.entityTypes = updatedValues.objectTypes;
      } else if (get(updatedValues, 'objectTypes', []).includes('ALL')) {
        updatedValues['objectTypes'] = ['ALL'];
        updatedValues.entityTypes = allEntityTypes;
      } else {
        updatedValues.entityTypes = updatedValues.objectTypes;
      }
      handleChange(updatedValues);
    } else if (has(updatedValues, 'teamId')) {
      handleChange({...updatedValues, team: lookups.teams.find((team) => team.id === updatedValues.teamId)});
    } else if (has(updatedValues, 'status')) {
      handleChange({...updatedValues, isActive: updatedValues.status === 'Active'});
    } else {
      handleChange(updatedValues);
    }
  };

  return (
    <AtsModal
      id='edit-modal'
      modalTitle={`${actionType} ${formType}`}
      modalSize='lg'
      modalVisible={modalVisible}
      modalCancel={cancelTemplateSelect}
    >
      <form
        noValidate
        onSubmit={(e) => handleSubmit(e)}
        autoComplete='off'
      >
        <div className='form-row col-12 mx-0 px-0 pt-3'>
          <div className='form-group col-6'>
            <label htmlFor='name'>
              {`${formType} Name`}
              <span className='required'>*</span>
            </label>
            <AtsInput
              id='name'
              name='name'
              required={true}
              validated={formValidated}
              defaultValue={values.name || ''}
              callback={handleChange}
            />
          </div>
          <div className='form-group col-6'>
            <label htmlFor='status'>
              Active Status
              <span className='required'>*</span>
            </label>
            <AtsSelect
              id='status'
              name='status'
              isClearable={false}
              defaultValue={values.status || ''}
              placeholder='Select Status'
              options={statusOption}
              required={true}
              validated={formValidated}
              callback={updateCallback}
            />
          </div>
        </div>
        <div className='form-row col-12 mx-0 px-0 pt-3'>
          <div className='form-group col-6'>
            <label htmlFor='objectTypes'>
              Available on Objects
              <span className='required'>*</span>
            </label>
            <AtsSelect
              id='objectTypes'
              name='objectTypes'
              isClearable={false}
              placeholder='Select Objects'
              defaultValue={values.objectTypes || []}
              isMulti={true}
              required={true}
              options={entityTypesOptions || []}
              validated={formValidated}
              callback={updateCallback}
            />
          </div>
          <div className='form-group col-6'>
            <label htmlFor='category'>
              Template Category
              <span className='required'>*</span>
            </label>
            <AtsSelect
              id='category'
              name='category'
              isClearable={false}
              placeholder='Select Objects'
              defaultValue={values.category || 'STANDARD'}
              isMulti={false}
              required={true}
              options={categoryOptions || []}
              validated={formValidated}
              callback={handleChange}
            />
          </div>
        </div>
        <div className='form-row col-12 mx-0 px-0 pt-3'>
          <div className='form-group col-9 mb-0'>
            <label className='pr-4'>
              Shared with
            </label>
          </div>
          {
            userPermissionGroupIds.includes('MANAGE_SYSTEM_SHARED_DATA') ? (
              <PublicCheckBoxContainer
                className='form-group col-3 float-right mb-0'
              >
                <span className='col-2'>Public</span>
                <div className='col-10'>
                  <AtsCheckBox
                    name='isPublic'
                    id='isPublic'
                    defaultValue={values.isPublic ? ['CHECKED'] : []}
                    options={[{label: '', value: 'CHECKED'}]}
                    handleChange={checkBoxCallback}
                  />
                </div>
              </PublicCheckBoxContainer>
            ) : null
          }
        </div>
        <div className='form-group'>
          <AtsSelect
            id='sharedWithIds'
            name='sharedWithIds'
            isDisabled={values.isPublic}
            placeholder='Search users and teams'
            isMulti={true}
            required={false}
            options={combinedLookups}
            callback={handleChange}
            validated={formValidated}
            defaultValue={values.sharedWithIds || []}
          />
        </div>
        {
          !ignoreInvalidPersonalize && invalidPersonalizeMsg ? (
            <div className='col-12 row px-0 mx-0'>
              <div className='col-10 px-0 red-text'>
                {invalidPersonalizeMsg}
              </div>
              <div className='col-2 px-0 text-right'>
                <Button type='button' id='ignore' variant='ats-sm-btn btn-primary' click={() => setIgnoreInvalidPersonalize(true)}>
                  Ignore
                </Button>
              </div>
            </div>
          ) : null
        }
        <div className='form-row col-12 mx-0 px-0 pt-3'>
          <div className='form-group col-12'>
            <label htmlFor='content'>
              Text
            </label>
            {
              values.category === 'BULK_EMAIL' ? (
                <AtsPersonalize
                  id='content'
                  personalizeVariables={personalizeVariables || lookups.emailVariables || []}
                />
              ) : null
            }
            <AtsEditor
              id='content'
              name='content'
              initialValue={values.content || ''}
              required={false}
              personalize={values.category === 'BULK_EMAIL'}
              personalizeVariables={personalizeVariables || lookups.emailVariables || []}
              callback={handleChange}
            />
          </div>
        </div>
        <div className="d-flex justify-content-end pt-5">
          <Button type='button' variant='mb-4 btn-secondary mr-4' click={cancelTemplateSelect}>Cancel</Button>
          <Button type='submit' variant='mb-4 btn-primary ats-md-btn'>Save</Button>
        </div>
      </form>
    </AtsModal>
  );
};
