import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';
import sideNavConfig from '../../config/sideNavConfig';
import { Routes } from '../../compoments';
import {keyBy, isEmpty, difference} from '../../libs/lodash';
import './style.scss';
import {useDispatch, useSelector} from 'react-redux';
import {hotKeys} from '../../constants/hotKeys';
import * as hotKeyActions from '../../actions/hotkey';
import * as notificationActions from '../../actions/notification';
import utils from '../../libs/utils';
import useModal from '../../hooks/useModal';
import HotKeyDetailModal from '../HotKey/HotKeyDetailModal';
import useInterval from '../../hooks/useInterval';
import {toast} from 'react-toastify';
import apiUserStatus from '../../services/apiUserStatus';

const SideDrawer = React.lazy(() => import('../SideDrawer'));

export default function SideNav({history}) {
  const [drawer, setDrawer] = useState({show: false});
  const [activeNavId, setActiveNavId] = useState('');
  const [navIdFromRouter, setNavIdFromRouter] = useState('');
  const dispatch = useDispatch();
  const {modalVisible, toggleModal} = useModal();

  const hotKeyDetailModal = <HotKeyDetailModal
    modalVisible={modalVisible}
    modalCancel={toggleModal}
  />;

  const makeActiveNav = (pathName) => {
    const  navs = sideNavConfig.mainNav.concat(sideNavConfig.otherNavs);
    navs.reverse().forEach( function(nav) {
      if (nav.pathNames && Array.isArray(nav.pathNames) && nav.pathNames.length > 0 ) {
        for (const path of nav.pathNames) {
          if (pathName.includes(path)) {
            setActiveNavId(nav.id);
            setNavIdFromRouter(nav.id);

            // set page title
            const routesMap = keyBy(Routes(), 'path');
            const pathParts = pathName.split('/');
            const matchId = isNaN(pathParts[pathParts.length-1]) ? '' : pathParts[pathParts.length-1];
            const lookupKey = matchId ? pathName.replace('/' + matchId, '/:id') : pathName;
            if(routesMap[lookupKey]) {
              if(routesMap[lookupKey].title) {
                document.title = routesMap[lookupKey].title.replace('Add', '');
              }
            }
          }
        }
      }
    });
  };

  const notificationUnreadCount = useSelector(state => state.notificationReducer.allUnread);

  useEffect(() => {
    //fetching notification first
    dispatch(notificationActions.fetchNotifications({setAllUnread: true, offset: 0}));
  }, [dispatch]);

  const delayFetchRecentNotifications = 15*1000;
  const fetchRecentNotifications = () => {
    dispatch(notificationActions.fetchRecentNotifications());
  };
  useInterval(fetchRecentNotifications, delayFetchRecentNotifications);

  const showUnreadNotifications = () => {
    if (notificationUnreadCount>0) {
      const msg = `Reminder: You have ${notificationUnreadCount} unread notifications`;
      toast(msg, {
        className: 'toast-response-background',
        bodyClassName: 'toast-response-body',
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000,
      });
    }
  };
  const delayShowUnreadNotifications = 30*60*1000;
  useInterval(showUnreadNotifications, delayShowUnreadNotifications);

  useEffect(()=>{
    makeActiveNav(window.location.pathname);
  }, []);

  useEffect(() => {
    const unlisten = history.listen((location, action) => {
      setActiveNavId('');
      makeActiveNav(location.pathname);
    });

    return () => {
      unlisten();
    };
  }, [history]);

  const openDrawer = (e, nav) => {

    if (nav.to === '#' && nav.allow ) {
      const drawerName = (nav.id || '').replace('nav-', '');
      e.preventDefault();

      // ATS-2369 - Reset notification filters should be reset when a user clicks away and then returns to notifications
      if(drawerName === 'notification') {
        dispatch(notificationActions.fetchNotifications({setAllUnread: true, offset: 0}));
      }

      setDrawer({name: drawerName, title: nav.title, showInputBox: nav.showInputBox, show: true});
      dispatch(hotKeyActions.updateRecentNavId(nav.id));
      setActiveNavId(nav.id);
    } else {
      if(!nav.allow) {
        e.preventDefault();
      } else {
        setDrawer({...drawer, 'show' : false});
      }
    }

    if(nav.eventType) {
      window.amplitudeLogEvent(nav.eventType, nav.eventProperties);
    }
  };

  const closeDrawer = (clicked) => {
    const drawerName = drawer.name;
    setDrawer({...drawer, 'show' : false});
    if(clicked) {
      setActiveNavId(navIdFromRouter);
    }

    // reset all unread notification if user mark all read or delete in the one tab, then turn to another tab
    if(drawerName === 'notification') {
      dispatch(notificationActions.fetchNotifications({setAllUnread: true, offset: 0}));
    }
  };

  const handleKeyDown = (event) => {
    event.stopImmediatePropagation();
    if (utils.detectEditMode().length === 0) {
      switch (event.keyCode) {
      case hotKeys.KEY_Q:
        openDrawer(event, sideNavConfig.mainNav[1]);
        setTimeout(() => {
          const inputEl = document.getElementById('search-input');
          inputEl.focus();
          inputEl.value = '';
        }, 0);
        break;
      case hotKeys.KEY_EQUAL:
        openDrawer(event, sideNavConfig.mainNav[2]);
        break;
      case hotKeys.KEY_R:
        dispatch(notificationActions.fetchRecentNotifications());
        break;
      case hotKeys.KEY_H:
        toggleModal();
        break;
      default:
      }
    }
  };
  dispatch(hotKeyActions.addHotKey('sidebar', handleKeyDown));

  const prepareNotificationCount = () => {
    return notificationUnreadCount>9 ? '9+' : notificationUnreadCount;
  };

  return (
    <>
      <div id='sidebar' className='px-0 w-auto vh-100'>
        <div className='navbar'>
          <ul className='navbar-nav mt-2 mb-3 mx-2 align-items-center'>
            {sideNavConfig.mainNav.map((nav) => {
              const {userPermissionGroupIds = []} = apiUserStatus.get() || {};
              return (
                isEmpty(nav.permissionGroupIds) || difference(userPermissionGroupIds, nav.permissionGroupIds).length !== userPermissionGroupIds.length ?
                  <li className='nav-item' key={nav.id}>
                    <Link to={nav.to} id={nav.id} onClick={(e) => openDrawer(e, nav)}
                      className={`nav-link${activeNavId === nav.id ? ' active' : ' inactive'}`}
                    >
                      <img
                        src={require('../../assets/img/nav/' + (activeNavId === nav.id ? nav.activeIconName : nav.iconName) + '.png').default}
                        alt={nav.alt} title={nav.title}
                        width={nav.width || 22}
                        height={nav.height || 22}
                      />
                    </Link>
                  </li>
                  :
                  ''
              );
            })}
          </ul>
        </div>

        <div className='navbar other-navs'>
          <ul className='navbar-nav mb-4 mx-2 align-items-center'>
            {sideNavConfig.otherNavs.map((nav) => {
              const {userPermissionGroupIds = []} = apiUserStatus.get() || {};
              return (
                isEmpty(nav.permissionGroupIds) || difference(userPermissionGroupIds, nav.permissionGroupIds).length !== userPermissionGroupIds.length ?
                  <li className='nav-item' key={nav.id}>
                    <Link to={nav.to} id={nav.id} onClick={(e) => openDrawer(e, nav)}
                      className={`nav-link${activeNavId===nav.id ? ' active' : ' inactive'}`}>
                      {nav.title==='Notifications' && notificationUnreadCount>0 ? <div className='green-dot'>{prepareNotificationCount()}</div> : ''}
                      <img
                        src={require('../../assets/img/nav/' + (activeNavId===nav.id ? nav.activeIconName : nav.iconName) + '.png').default}
                        alt={nav.alt} title={nav.title} width={nav.width || 22}
                        height={nav.height || 22}
                      />
                    </Link>
                  </li> : ''
              );
            }
            )}
          </ul>
        </div>
      </div>
      {drawer.show && <SideDrawer drawer={drawer} closeDrawer={closeDrawer} history={history} /> }
      {hotKeyDetailModal}
    </>
  );
}

SideNav.propTypes = {
  history: PropTypes.object.isRequired
};
