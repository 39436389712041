import actionTypes from '../constants/actionTypes';
import {omit, get} from '../libs/lodash';
import ApiClient from '../services/ApiClient';

export const fetchTasks = (parameters) => {
  const type = actionTypes.taskActionTypes['UPDATE_TASKS'];

  return async (dispatch) => {
    try {
      if (!parameters) {
        dispatch({
          type: type,
          payload: {tasks: []}
        });

        return;
      }

      dispatch({
        type: actionTypes.taskActionTypes.UPDATE_RELATED_ENTITY,
        payload: {entity: get(parameters, 'criteria.entity')}
      });

      if (parameters.offset === 0) {
        dispatch({
          type: type,
          payload: {tasks: []}
        });
      }

      const res = await ApiClient.tasksSearch(omit(parameters, ['criteria']), parameters.criteria);

      dispatch({
        type: type,
        payload: {tasks: res.body.tasks}
      });

    } catch (error) {
      console.log(error);
    }
  };
};

export const updateTasks = (tasks) => {
  return (dispatch, getState)=>{
    dispatch({
      type: actionTypes.taskActionTypes.UPDATE_TASKS,
      payload: {tasks},
    });
  };
};

export const addTask = (task) => {
  return (dispatch)=>{
    dispatch({
      type: actionTypes.taskActionTypes.CREATE_TASK,
      payload: {task}
    });
  };
};

export const updateTask = (task) => {
  return (dispatch, getState)=>{
    dispatch({
      type: actionTypes.taskActionTypes.UPDATE_TASK,
      payload: {task},
    });
  };
};

export const removeTask = (taskId) => {
  return (dispatch, getState)=>{
    dispatch({
      type: actionTypes.taskActionTypes.REMOVE_TASK,
      payload: {taskId},
    });
  };
};

export const toggleTaskEditModal = (showTaskEditModal, dataForTask) => {
  return (dispatch, getState)=>{
    dispatch({
      type: actionTypes.taskActionTypes.TOGGLE_TASK_EDIT_MODAL,
      payload: {showTaskEditModal, dataForTask},
    });
  };
};
