import './style.scss';
import React from 'react';
import PropTypes from 'prop-types';
import {AtsModal} from '../common';
import MergeRecordsForm from './MergeRecordsForm';

export default function MergeRecordsFormModal(props) {
  return (
    <>
      {props.modalVisible ?
        <AtsModal
          id='merge-records-form-modal'
          modalTitle='Merge Records'
          modalSize='lg'
          modalVisible={props.modalVisible}
          modalCancel={props.modalCancel}
        >
          <MergeRecordsForm {...props} />
        </AtsModal> : ''
      }
    </>
  );
}

MergeRecordsFormModal.propTypes = {
  modalVisible: PropTypes.bool.isRequired,
  modalCancel: PropTypes.func.isRequired
};
