import actionTypes from '../constants/actionTypes';
import ApiClient from '../services/ApiClient';
import { toast } from 'react-toastify';

const {
  GET_DEALS,
  UPDATE_DEAL_KANBAN_FILTER_SETTINGS,
  UPDATE_DEAL_SEARCH_BAR_INPUT,
  UPDATE_DEAL_KANBAN_SORT_BY,
  CREATE_DEAL,
  GET_DEAL_BY_ID,
  GET_DEAL_ATTACHMENTS,
  GET_DEAL_ATTACHMENT_DETAILS
} = actionTypes.dealsActionTypes;

export const getDeals = (params) => {
  return async (dispatch) => {
    try {
      const requestParams = {
        companyId: params.companyId,
      };
      const getDealsResponse = await ApiClient.dealsList({}, requestParams);

      dispatch({
        type: GET_DEALS,
        payload: getDealsResponse.body.deals,
      });
    } catch (error) {
      console.log('getdeals action error:', error);
      console.error(error);
    }
  };
};

export const updateDealKanbanFilterSettings = (filter, value) => {
  const newValue = value === 'SHOW_ALL' ? false : value;
  return (dispatch) => {
    dispatch({
      type: UPDATE_DEAL_KANBAN_FILTER_SETTINGS,
      payload: {
        filter,
        value: newValue,
      }
    });
  };
};

export const updateDealsSearchBarInput = (inputValue) => {
  return (dispatch, getState) => {
    const dealsKanbanFilterSettingsOptions = Object.keys(getState().dealsReducer.dealKanbanFilterSettings);

    dealsKanbanFilterSettingsOptions.forEach(filterOption => {
      dispatch(updateDealKanbanFilterSettings(filterOption, 'SHOW_ALL'));
    });
    dispatch({
      type: UPDATE_DEAL_SEARCH_BAR_INPUT,
      payload: inputValue,
    });
  };
};

export const createDeal = (params) => {
  return async (dispatch) => {
    try {
      const response = await ApiClient.dealsCreate({}, params);
      const dealData = response.body;

      dispatch({
        type: CREATE_DEAL,
        payload: {dealData}
      });

      toast('Deal Created', {
        className: 'toast-response-background',
        bodyClassName: 'toast-response-body',
        position: 'top-right'
      });

      return dealData;
    } catch (error) {
      console.log('createDeal action error:', error);
    }
  };
};

export const updateDeal = (dealId, params) => {
  return async () => {
    try {
      const updateDealResponse = await ApiClient.dealsUpdate({ id: dealId }, params);
      if (updateDealResponse.status === 204) {
        toast('Deal Updated', {
          className: 'toast-response-background',
          bodyClassName: 'toast-response-body',
          position: 'top-right'
        });
      } else {
        throw new Error('Error while trying to update Deal');
      }
    } catch (error) {
      console.log('updatesDeal action error:', error);
      console.error(error);
    }
  };
};

export const updateDealKanbanSortBy = (sortBy) => {
  return {
    type: UPDATE_DEAL_KANBAN_SORT_BY,
    payload: sortBy,
  };
};

export const getDealById = (dealId) => {
  return async (dispatch) => {
    try {
      const getDealResponse = await ApiClient.dealsDetails({ id: dealId });

      dispatch({
        type: GET_DEAL_BY_ID,
        payload: getDealResponse.body,
      });

    } catch (error) {
      console.log('getdeal action error:', error);
      console.error(error);
    }
  };
};

export const getDealAttachments = (dealId) => {
  return async (dispatch) => {
    try {
      const response = await ApiClient.dealsDetails({ id: dealId });
      const dealAttachments = response.body.supplementalDocuments;

      dispatch({
        type: GET_DEAL_ATTACHMENTS,
        payload: dealAttachments,
      });

      return dealAttachments;
    } catch (error) {
      console.log('getDealAttachments error:', error);
    }
  };
};

export const getDealAttachmentDetails = (attachmentId) => {
  return async (dispatch) => {
    try {
      const attachmentDetails = await ApiClient.attachmentsDetails({ id: attachmentId });

      dispatch({
        type: GET_DEAL_ATTACHMENT_DETAILS,
        payload: attachmentDetails,
      });

      return attachmentDetails.body;
    } catch (error) {
      console.log('getDocumentDetails error:', error);
    }
  };
};
