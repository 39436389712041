import actionTypes from '../constants/actionTypes';
import {isEmpty, orderBy} from '../libs/lodash';

const initialState = {
  appointments: [],
  submissionAppts: [],
  refreshAppointmentsList: false,
};

export default function appointmentReducer(state = initialState, action) {
  let type = action.type;
  // if update, but date is out the scope, then remove it from list (next indicator)
  if(type === actionTypes.appointmentActionTypes.UPDATE_APPOINTMENT) {
    if (Date.parse(action.payload.appointment.startsAt) < new Date()) {
      type = actionTypes.appointmentActionTypes.REMOVE_APPOINTMENT;
    }
  }

  switch (type) {
  case actionTypes.appointmentActionTypes.CREATE_APPOINTMENT:
    return {
      ...state,
      appointments: orderBy([action.payload.appointment, ...state.appointments], (item) => Date.parse(item.startsAt), 'asc'),

      submissionAppts:
        action.payload.appointment.entity.type === 'SUBMISSION' ?
          orderBy([action.payload.appointment, ...state.submissionAppts], (item) => Date.parse(item.startsAt), 'asc') : state.submissionAppts
    };
  case actionTypes.appointmentActionTypes.UPDATE_APPOINTMENT:
    // if it's in the list, update it, otherwise add it
    let appointments;
    const updatedApptIndex = state.appointments.findIndex(
      appointment => appointment.appointmentId === action.payload.appointment.appointmentId
    );
    if (updatedApptIndex !== -1) {
      appointments =  [
        ...state.appointments.slice(0, updatedApptIndex),
        action.payload.appointment,
        ...state.appointments.slice(updatedApptIndex + 1)
      ];
    } else {
      appointments = [...state.appointments, action.payload.appointment];
    }

    let submissionAppts;
    if (action.payload.appointment.entity.type === 'SUBMISSION' && !isEmpty(state.submissionAppts)) {
      const updatedSubApptIndex = state.submissionAppts.findIndex(
        appointment => appointment.appointmentId === action.payload.appointment.appointmentId
      );
      if (updatedSubApptIndex !== -1) {
        submissionAppts = [
          ...state.submissionAppts.slice(0, updatedSubApptIndex),
          action.payload.appointment,
          ...state.submissionAppts.slice(updatedSubApptIndex + 1)
        ];
      } else {
        submissionAppts = [...state.submissionAppts, action.payload.appointment];
      }
    }

    return {
      ...state,
      appointments: orderBy(appointments, (item) => Date.parse(item.startsAt), 'asc'),
      submissionAppts: action.payload.appointment.entity.type === 'SUBMISSION' && !isEmpty(state.submissionAppts) ?
        orderBy(submissionAppts, (item) => Date.parse(item.startsAt), 'asc') : state.submissionAppts,
    };
  case actionTypes.appointmentActionTypes.REMOVE_APPOINTMENT:
    const removeId = action.payload.appointmentId || action.payload.appointment.appointmentId;
    return {
      ...state,
      appointments: [...state.appointments.filter(appointment => appointment.appointmentId !== removeId)],
      submissionAppts: !isEmpty(state.submissionAppts) ?
        [...state.submissionAppts.filter(appointment => appointment.appointmentId !== removeId)] : state.submissionAppts
    };
  case actionTypes.appointmentActionTypes.UPDATE_SUBMISSION_APPOINTMENTS:
    return {
      ...state,
      submissionAppts: orderBy(action.payload.submissionAppts, (item) => Date.parse(item.startsAt), 'asc')
    };
  case actionTypes.appointmentActionTypes.UPDATE_APPOINTMENTS:
    return {
      ...state,
      appointments: orderBy(action.payload.appointments, (item) => Date.parse(item.startsAt), 'asc')
    };
  case actionTypes.appointmentActionTypes.REFRESH_APPOINTMENTS_LIST:
    return {
      ...state,
      refreshAppointmentsList: action.payload.refreshAppointmentsList
    };
  default:
    return state;
  }
}
