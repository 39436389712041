import './style.scss';
import React from 'react';
import PropTypes from 'prop-types';
import notificationsIcon from '../../assets/img/nav/notifications.png';

export default function NotificationSelection({toggleModal}) {
  return (
    <>
      <div id='notification-selection' className='d-flex cursor' onClick={toggleModal}>
        <div className='notification-selection-title biotif-bold'>Notify</div>
        <img src={notificationsIcon} alt='notification-selection-icon' title='notification-selection-icon' height='23' width='23'/>
      </div>
    </>
  );
};

NotificationSelection.propTypes = {
  toggleModal: PropTypes.func.isRequired,
};

