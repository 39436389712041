export const notifyUserIdsList = ['recruiterUserIds', 'primaryRecruiterId', 'primaryRecruiterUserIds',
  'commission.primaryRecruiterId', 'commission.coRecruiterId', 'primaryRecruiterUserId',
  'primarySalespersonId', 'commission.primarySalespersonId', 'commission.accountCoordinatorUserId',
  'backOfficeOwnerUserId', 'contractorEngagementUserId', 'ownerUserId', 'ownerUserIds', 'relationshipOwnerUserId', 'recruiterUserId',
  'submissionOwnerUserId', 'jobOwnerUserId', 'jobRecruiterUserId', 'candidateRecruiterUserId',
  'assignedToUserId', 'assignedTo.userId', 'coRecruiterUserIds'
];

export const notifyTeamIdsList = ['primaryRecruiterTeamIds', 'ownerTeamIds', 'divisionTeamIds'];

export const notifyStatusesOption = {
  ANY: 'Any',
  UNREAD: 'Unread',
  READ: 'Read',
  FLAGGED: 'Flagged',
  UNFLAGGED: 'Not Flagged',
  REPLIED: 'Replied',
  UNREPLIED: 'Not Replied',
};

export const notifyFieldNameMap = {
  ANY: 'isDeleted',
  UNREAD: 'isRead',
  READ: 'isRead',
  FLAGGED: 'isFlagged',
  UNFLAGGED: 'isFlagged',
  REPLIED: 'hasReplied',
  UNREPLIED: 'hasReplied',
};

export const searchFilterTypes = {
  ALL: 'All',
  CANDIDATE: 'Candidate',
  JOB: 'Job',
  CONTACT: 'Contact',
  PLACEMENT: 'Placement',
  SUBMISSION: 'Submission',
  COMPANY: 'Company',
  TARGET: 'Target',
};
