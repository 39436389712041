import React from 'react';
import {Link} from 'react-router-dom';
import utils from '../../../libs/utils';
import {compact, keyBy, isEmpty, get} from '../../../libs/lodash';
import Index from '../../Search/SearchModal/additionalSearchFieldsConfig';
import searchConst from '../../../constants/searchConst';

export default function RecentEntry({item, openItem, doSearch, doSavedSearch, activeTab}) {
  const ITEM = 'ITEM', SEARCH = 'SEARCH', SAVED ='SAVED';
  const entityType = (item.entityType || '').toLowerCase();
  const entityTypePlural = searchConst.entityMap[entityType];

  function itemLink(item) {
    const type = item.type === SEARCH && get(item, 'searchDetails.isSavedSearch') ? SAVED : item.type;
    switch (type) {
    case ITEM:
      let itemLikText = [];
      if (item.itemDetails) {
        const fullName = utils.shortName(item.itemDetails.candidateName || item.itemDetails.contactName, 35, true);
        const companyName = get(item, 'itemDetails.name') || get(item, 'itemDetails.companyName');
        const jobTitle = get(item, 'itemDetails.jobTitle');
        const { isRemote = false } = item.itemDetails || {};
        const location = isRemote ? 'Remote' : compact([get(item, 'itemDetails.city'), get(item, 'itemDetails.state')]).join(', ');
        const ownerInfo = utils.shortName(get(item, 'itemDetails.owner'), 35, true);

        const openStatusValue = get(item, 'itemDetails.openStatusValue'); // job
        const statusValue = get(item, 'itemDetails.statusValue') || openStatusValue; // candidate, company

        switch (item.entityType) {
        case 'CONTACT':
          itemLikText.push(fullName);
          itemLikText.push(companyName);
          itemLikText.push(compact([statusValue, jobTitle]).join(' / '));
          break;
        case 'JOB':
          itemLikText.push(jobTitle);
          itemLikText.push(companyName);
          itemLikText.push(compact([statusValue, location]).join(' / '));
          break;
        case 'PLACEMENT':
        default:
          itemLikText.push(fullName);
          itemLikText.push(companyName);
          itemLikText.push(jobTitle);
          itemLikText.push(compact([statusValue, location]).join(' / '));
          itemLikText.push(ownerInfo);
        }
        itemLikText = compact(itemLikText);
        return (
          <Link to={`/${entityType}/${item.itemDetails.itemId}`} onClick={() => openItem()}>
            {itemLikText.map((linkText, index) => {
              return <span className={index !== 0 ? 'pl-4 d-block' : 'd-block'} key={`recent-item-${item.itemDetails.itemId}-${index}`}>{linkText}</span>;
            })}
          </Link>
        );

      } else {
        return 'No candidate detail info saved yet';
      }
    case SEARCH:
      const criteria = get(item, 'searchDetails.criteria');
      const location = get(criteria, 'location') ? 'In ' + criteria.location : '';
      const keywords = get(criteria, 'keywords') ? criteria.keywords.split(/,|, /).slice(0, 3).join(', ') : 'no keywords';
      let numOfOtherFilters = !isEmpty(get(criteria, 'additionalCriteria')) && criteria.additionalCriteria[0].name !== '' ? criteria.additionalCriteria.length : 0;
      const addSearchFieldKeyby = keyBy(criteria.additionalCriteria || [], 'name');
      ['ownerTeamIds', 'primaryRecruiterTeamIds', 'recruiterTeamIds', 'relationshipOwnerTeamIds', 'websiteRecruiterTeamIds', 'salespersonTeamIds'].forEach(function(field) {
        if(addSearchFieldKeyby[field] && addSearchFieldKeyby[field.replace('Team', 'User')]) {
          numOfOtherFilters = numOfOtherFilters - 1;
        }
      });

      let withText = '';
      if (numOfOtherFilters > 0) {
        let searchField = keyBy(Index[entityType], 'value')[criteria.additionalCriteria[0].name];
        if(!searchField) {
          searchField = keyBy(Index[entityType], 'value')[(criteria.additionalCriteria[0].name || '').replace('Team', 'User')];
        }
        withText += 'With ' + (typeof(searchField) !== 'undefined' ? searchField.label : '');
        withText += numOfOtherFilters > 1 ? ' +' + (numOfOtherFilters-1) + ' other filter' : '';
        withText += numOfOtherFilters > 2 ? 's' : '';
      }
      return (
        <Link to={`/${entityTypePlural}`} onClick={(e) => doSearch(e, item)}>
          <div className='d-block'>Search for {keywords}</div>
          {location ? <div className='pl-4 d-block'>{location}</div> : '' }
          {numOfOtherFilters > 0 ? <div className='pl-4 d-block'>{withText}</div> : '' }
        </Link>
      );
    default:
      const saveSearchName = activeTab === SAVED ?  item.name : get(item, 'searchDetails.savedSearchName');
      const createdBy = activeTab === SAVED ? item.createdBy : get(item, 'searchDetails.savedSearchCreatedBy');
      return (
        <Link to={`/${entityTypePlural}`} onClick={(e) => item.type === SEARCH ? doSearch(e, item) : doSavedSearch(e, item)}>
          <span className='d-block'>{saveSearchName}</span>
          <span className='d-block pl-4'>Created by {utils.shortName(createdBy, 20, true)}</span>
        </Link>
      );
    }
  };

  return (
    <div className='pt-4 mr-4'>{itemLink(item)}</div>
  );
}

