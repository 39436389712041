import actionTypes from '../constants/actionTypes';
import {toast} from 'react-toastify';
import {get} from '../libs/lodash';
import {notificationMsgInfo} from '../compoments/Notifications/libs/utils';
import utils from '../libs/utils';
import moment from 'moment';
import {confetti} from '../compoments/HolidayTheme/Confetti';
import ApiClient from '../services/ApiClient';

export const fetchNotifications = (parameters = {entityType: null}) => {
  return async (dispatch, getState) => {
    try {
      const recentTimestamp =  moment.utc().toISOString();
      const setAllUnread = get(parameters, 'setAllUnread');
      delete parameters.setAllUnread;

      // only pull all unread count for current filter
      let numUnRead = 0;

      dispatch({
        type: actionTypes.notificationActionTypes.FETCH_NOTIFICATIONS_REQUEST
      });

      parameters.isDeleted = parameters.isDeleted === undefined ? false :  parameters.isDeleted;
      parameters.offset = parameters.offset === undefined ? getState().notificationReducer.parameters.offset :  parameters.offset;
      parameters.maxResults = parameters.maxResults === undefined ? 20 :  parameters.maxResults;

      // pull all unread count
      if(setAllUnread) {
        await setAllUnreadCount(dispatch);
        numUnRead = getState().notificationReducer.allUnread;
      } else {
        if (parameters.offset === 0) {
          numUnRead = await prepareUnreadForNotifications(parameters);
        } else {
          numUnRead = getState().notificationReducer.data.summary.numUnRead;
        }
      }

      const res = await ApiClient.notificationsSearchMy(parameters);
      res.body.summary.numUnRead = numUnRead;

      if (parameters && parameters.offset !== 0) {
        res.body.notifications = getState().notificationReducer.data.notifications.concat(res.body.notifications);
        res.body.summary.numResultsReturned = getState().notificationReducer.data.summary.numResultsReturned + res.body.summary.numResultsReturned;
      }

      if(setAllUnread) {
        dispatch({
          type: actionTypes.notificationActionTypes.SET_NOTIFICATIONS_RECENT_TIMESTAMP,
          payload: utils.prepareEntitiesRecentTimeStamp(res.body.notifications) || recentTimestamp
        });
      }

      dispatch({
        type: actionTypes.notificationActionTypes.SET_NOTIFICATIONS,
        payload: res.body
      });

      dispatch({
        type: actionTypes.notificationActionTypes.SET_NOTIFICATIONS_PARAMS,
        payload: parameters
      });

      dispatch({
        type: actionTypes.notificationActionTypes.FETCH_NOTIFICATIONS_SUCCESS,
      });

      checkTargetNotifications(res.body.notifications, dispatch, true);

    } catch (error) {
      console.log(error);
      dispatch({
        type: actionTypes.notificationActionTypes.FETCH_NOTIFICATIONS_FAIL
      });
    }
  };
};

function checkTargetNotifications(notifications, dispatch, showMsg) {
  const targetNotifications = notifications.filter( notification => notification.entity.type === 'TARGET' && !notification.isRead );

  if (targetNotifications.length) {
    confetti();

    const parameters = { action: 'READ', ids: targetNotifications.map(d => d.notificationId)};
    dispatch(bulkUpdateNotifications(parameters));

    if (showMsg) {
      showNotificationsMsg(targetNotifications);
    }
  }
}

async function setAllUnreadCount(dispatch) {
  try {
    const res = await ApiClient.notificationsSearchMy({isDeleted: false, isRead: false, maxResults: 1});
    const allUnReadCount = res.body.summary.numResultsFound || 0;

    dispatch({
      type: actionTypes.notificationActionTypes.SET_NOTIFICATIONS_ALL_UNREAD,
      payload: allUnReadCount
    });
  } catch (err) {
    console.log(err);
    return 0;
  }
}

async function prepareUnreadForNotifications(parameters) {
  try {
    if (parameters.isRead) {
      return 0;
    }

    const res = await ApiClient.notificationsSearchMy({isDeleted: false, ...parameters, isRead: false, maxResults:1});
    return res.body.summary.numResultsFound || 0;

  } catch (err) {
    console.log(err);
    return 0;
  }
}

const showNotificationsMsg = (notifications) => {
  notifications.forEach((item) => {
    const msg = notificationMsgInfo(item);
    toast(msg, {
      className: 'toast-response-background',
      bodyClassName: 'toast-response-body',
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 7000,
    });
  });
};

export const fetchRecentNotifications = () => {
  return async (dispatch, getState) => {
    try {
      if (document.visibilityState === 'visible') {
        const mostRecentTimeStamp = getState().notificationReducer.mostRecentTimeStamp;
        const parameters = {};
        parameters.startDate = mostRecentTimeStamp;
        parameters.isDeleted = false;

        const res = await ApiClient.notificationsSearchMy(parameters);
        const recentNotifications = res.body.notifications;

        const processedNotifications = recentNotifications.filter((recentEntity) => {
          return recentEntity.createdAt > mostRecentTimeStamp;
        });

        const entitiesRecentTimeStamp = utils.prepareEntitiesRecentTimeStamp(processedNotifications);

        if (processedNotifications.length>0 && entitiesRecentTimeStamp && moment(entitiesRecentTimeStamp).isAfter(mostRecentTimeStamp)) {
          showNotificationsMsg(processedNotifications);
          dispatch({
            type: actionTypes.notificationActionTypes.ADD_RECENT_NOTIFICATIONS,
            payload: {
              notifications: processedNotifications,
              summary: {
                numResultsFound: processedNotifications.length,
                numResultsReturned: processedNotifications.length,
              }
            }
          });

          dispatch({
            type: actionTypes.notificationActionTypes.SET_NOTIFICATIONS_RECENT_TIMESTAMP,
            payload: entitiesRecentTimeStamp
          });
        }

        checkTargetNotifications(res.body.notifications, dispatch);
      } else {
        console.log('window is not focused. Stop fetching notification');
      }
    } catch (error) {
      console.log(error);
      dispatch({
        type: actionTypes.notificationActionTypes.FETCH_NOTIFICATIONS_FAIL
      });
    }
  };
};

export const updateNotification = (parameters) => {
  return async (dispatch, getState) => {
    try {
      await ApiClient.notificationsUpdate({id: parameters.id}, parameters.notification);

      dispatch({
        type: 'UPDATE_NOTIFICATION',
        payload: parameters,
      });

    } catch (error) {
      console.log(error);
    }
  };
};

export const bulkUpdateNotifications = (parameters) => {
  return async (dispatch, getState) => {
    try {
      const requestParams = {
        ...parameters,
        entityType: parameters.entityType !== 'ALL' ? parameters.entityType : null
      };

      await ApiClient.notificationsMassUpdate(requestParams);

      dispatch({
        type: 'BULK_UPDATE_NOTIFICATIONS',
        payload: parameters,
      });

    } catch (error) {
      console.log(error);
    }
  };
};
