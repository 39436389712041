import React, {useEffect, useState} from 'react';
import AtsRadioSelect from '../common/AtsRadioSelect';
import {AtsTypeAheadSearchSelect} from '../common';
import utils from '../../libs/utils';
import {compact, get, has, isEmpty} from '../../libs/lodash';
import settingConst from '../../constants/settingConst';
import apiUserStatus from '../../services/apiUserStatus';
import candidateConst from '../../constants/candidateConst';
import ApiClient from '../../services/ApiClient';

export default ({entity, callback, primaryEntity, index, entities}) => {
  const { userPermissionGroupIds = [] } = apiUserStatus.get() || {};
  const [record, setRecord] = useState(entity || {});
  const entityIdName = !isEmpty(entity) ? record.searchStringId || `${settingConst.globalEntityAbbreviations[record.entityType]}${record.entityId}` : '';
  const excludeEntityTypes = compact(['PLACEMENT']
    .concat(userPermissionGroupIds.includes('MERGE_COMPANIES') ? [] : ['COMPANY'])
    .concat(userPermissionGroupIds.includes('MERGE_CONTACTS') ? [] : ['CONTACT'])
    .concat(userPermissionGroupIds.includes('MERGE_CANDIDATES') ? [] : ['CANDIDATE'])
    .concat(userPermissionGroupIds.includes('MERGE_JOBS') ? [] : ['JOB']));

  const isExist = (entityId) => {
    return entities.find(entity => entity.entityId === entityId);
  };

  const fetchEntity = async (data) => {
    const entity = Object.values(data)[0];
    const { entityId, entityType } = entity || {};

    if (entityType && excludeEntityTypes.includes(entityType.toUpperCase())) {
      return ;
    }

    if (entityId && isExist(entityId)) {
      return ;
    }

    if (entityId) {
      const res = await ApiClient.activityList({
        entityId,
        entityType,
      });

      setRecord({ ...entity, numActivities: get(res, 'body.summary.numResultsFound')});
      callback({index, record: { ...entity, numActivities: get(res, 'body.summary.numResultsFound')}});
    } else {
      setRecord({});
      callback({index, record: null});
    }
  };

  const updatePrimaryEntity = (data) => {
    const primaryEntity = {
      index: data.index,
      entityType: record.entityType,
      entityId: record.entityId,
    };

    if (record.entityId) {
      callback({primaryEntity});
    }
  };

  useEffect(() => {
    const apiCall = async () => {
      try {
        const res = await ApiClient.activityList({
          entityId: entity.entityId,
          entityType: entity.entityType,
        });

        setRecord({ ...entity, numActivities: get(res, 'body.summary.numResultsFound')});
      } catch (error) {
        console.log(error);
      }
    };
    if (entity.entityId && entity.entityType && !has(entity, 'numActivities')) {
      apiCall();
    }
  }, [entity]);

  const openEntityTab = (e) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    const entityPath = `/${entity.entityType.toLowerCase()}/${entity.entityId}`;
    window.open(entityPath, '_blank');
  };

  return (
    <div className='form-row col-12 mx-0 px-0 pt-3'>
      <div className='form-group col-1 d-flex pl-0'>
        <AtsRadioSelect
          name='index'
          defaultValue={primaryEntity.index}
          options={[{value: index, label: ''}]}
          handleChange={updatePrimaryEntity}
        />
      </div>
      <div className='form-group col-6 px-0'>
        <AtsTypeAheadSearchSelect
          id={`record-${index}`}
          name={`record-${index}`}
          entityType={primaryEntity.entityType}
          clearButton={true}
          isMulti={false}
          defaultValue={!isEmpty(entity) ? entity : ''}
          callback={fetchEntity}
          excludeOptions={entities}
          excludeEntityTypes={excludeEntityTypes}
        />
      </div>
      {
        entityIdName ?
          <div className='form-group col-5 px-0 pl-5'>
            <div className='entityTypeShortID biotif-semi-bold'>
              <span className='cursor link-to-new-tab' onClick={openEntityTab}>{ entityIdName}</span>
            </div>
            <span className='pr-4'> Owner: {utils.shortName(record.createdBy, candidateConst.maxFullNameLength, true)} </span>
            <span className='pr-4'> Activities: {record.numActivities} </span>
            { record.companyType ? <span className='pr-4'> Type: {utils.titleCase(record.companyType)} </span> : '' }
          </div> : ''
      }
    </div>
  );
};
