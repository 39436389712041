import {useEffect, useState} from 'react';
import Lookups from '../services/Lookups';

export default function useLookup({ names=[], entityTypes = [] }) {
  const [lookups, setLookups] = useState({});
  const [apiNames] = useState(names);
  const [apiEntityTypes] = useState(entityTypes);

  useEffect(() => {
    let cancel = false;

    const fn = async()=> {
      let updatedLookups;
      try {
        Lookups.loadLookupsFromLocalStorage();
        const params = Lookups.prepareParams(apiNames, apiEntityTypes);
        updatedLookups = await Lookups.fetch(params);

        if (!cancel) {
          setLookups(updatedLookups);
        }
      } catch (err) {
        console.log('err', err);
        cancel = true;
      }
    };

    fn();

    return () => {
      cancel = true;
    };
  }, [apiNames, apiEntityTypes]);

  return lookups;
}
