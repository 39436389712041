import {get, isEmpty} from '../../../libs/lodash';
import modifySearchIcon from '../../../assets/img/Search Action Icons/Modify Search Icon.png';
import tableModifySearchIcon from '../../../assets/img/searchResultsTable/Search - Modify Icon.png';
import saveSearchIcon from '../../../assets/img/Search Action Icons/Save Search Icon.png';
import tableSaveSearchIcon from '../../../assets/img/searchResultsTable/Search - Save Icon - Active.png';
import saveSearchInactiveIcon from '../../../assets/img/Search Action Icons/Save Search Icon Inactive.png';
import tableSaveSearchInactiveIcon from '../../../assets/img/searchResultsTable/Search - Save Icon - Inactive.png';
import addNewIcon from '../../../assets/img/Search Action Icons/Add New Icon.png';
import Dropdown from 'react-bootstrap/Dropdown';
import moreOptionsIcon from '../../../assets/img/Search Action Icons/More Options Icon 3dot.png';
import tableMoreActions from '../../../assets/img/entityHeaders/more-actions.png';
import tableMoreActionsShow from '../../../assets/img/entityHeaders/more-actions-show.png';
import exportPermissionLookup from '../../../constants/exportPermissionLookup';
import React from 'react';
import apiUserStatus from '../../../services/apiUserStatus';

export default function Actions({actionFor, viewType, canEdit, exportPermissionKey, criteria, openModal, canSavecriteria, saveSavedCriteria}) {
  const {userPermissionGroupIds = []} = apiUserStatus.get() || {};
  const actionForIdAttr = actionFor === 'report' ? 'reportId' : 'savedSearchId';

  return (
    <>
      { !isEmpty(criteria) ?
        <div className='cursor modify align-self-center' onClick={(e) => openModal(e, `modify-${actionFor}`)}>
          <img src={viewType ? tableModifySearchIcon : modifySearchIcon} alt={'modify ' + actionFor} width='auto' height='16' style={{marginBottom: '3px'}}/> Modify
        </div>
        :
        ''
      }

      {get(criteria, actionForIdAttr) ?
        <>
          {canSavecriteria() ?
            <div className='cursor save align-self-center' onClick={() => saveSavedCriteria()}>
              <img src={viewType ? tableSaveSearchIcon : saveSearchIcon} alt={'save ' + actionFor} width='auto' height='16' style={{marginBottom: '3px'}}/> Save
            </div>
            :
            <div className='save disabled align-self-center'>
              <img src={viewType ? tableSaveSearchInactiveIcon : saveSearchInactiveIcon} alt={'save ' + actionFor} width='auto' height='16' style={{marginBottom: '3px'}}/> Save
            </div>
          }
        </>
        :
        <>
          {isEmpty(criteria) ? '' :
            <div className='cursor save align-self-center' onClick={(e) => openModal(e, `save-${actionFor}`)}>
              <img src={viewType ? tableSaveSearchIcon : saveSearchIcon} alt={'save ' + actionFor} width='auto' height='16' style={{marginBottom: '3px'}}/> Save As
            </div>
          }
        </>
      }

      {viewType ?
        ''
        :
        <div className='cursor new align-self-center' onClick={(e) => openModal(e, `new-${actionFor}`)}>
          <img src={addNewIcon} alt={'new ' + actionFor} width='auto' height='16' style={{marginBottom: '3px'}}/> New
        </div>
      }

      {!isEmpty(criteria) ?
        <Dropdown as='div' className={`atsDropdown align-self-center actions${viewType ? ' ml-auto' : ''}`}>
          <Dropdown.Toggle as='div' className='cursor' id='moreSearchOpen'>
            <img className='moreOptionImg' src={viewType ? tableMoreActions : moreOptionsIcon} alt='more' width='auto' height={viewType ? '44' : '8'} />
            {viewType ? <img className='moreOptionShowImg' src={tableMoreActionsShow} alt='more' width='auto' height={viewType ? '44' : '8'} /> : '' }
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {!viewType && userPermissionGroupIds.includes(exportPermissionLookup[exportPermissionKey]) ?
              <Dropdown.Item as='a' href='#/' onClick={(e) => openModal(e, 'export')}>Export Results</Dropdown.Item>
              :
              ''
            }
            {viewType && canEdit ?
              <Dropdown.Item as='a' href='#/' onClick={(e) => openModal(e, `edit-${actionFor}`)}>Rename Search</Dropdown.Item>
              :
              ''
            }
            <Dropdown.Item as='a' href='#/' onClick={(e) => openModal(e, `save-${actionFor}`)}>Save As New</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        :
        ''
      }
    </>
  );
}
