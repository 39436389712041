import React, {useLayoutEffect, useState} from 'react';
import { Router, Route, Redirect, Switch } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { Logout } from './containers';
import {AtsErrorBoundary} from './compoments/common';
import ApiClient from './services/ApiClient';
import apiUserStatus from './services/apiUserStatus';
import amplitude from 'amplitude-js';
import {fromLookups} from './constants/amplitudeLogFromLookups';
import {get} from './libs/lodash';
import {preparePathname} from './services/pathHandler';
import Lookups from './services/Lookups';
import searchConst from './constants/searchConst';
import TokenService from './services/TokenService';
import TabsResourceLock from './services/TabsResourceLock';
import SuspenseWrap from './compoments/common/SuspenseWrap';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

const Home = React.lazy(() => import('./containers/Home'));
const Login = React.lazy(() => import('./containers/Login'));

const history = createBrowserHistory();

function App () {
  const user = apiUserStatus.get();
  const [isLoggedIn, setIsLoggedIn] = useState(TokenService.accessToken && user && user.userId);
  const [isReady, setIsReady] = useState(ApiClient.isReady);
  const queryClient = new QueryClient();

  const scheme = window.REACT_APP_SCHEME || 'https';
  const checkUrlScheme = () => {
    const Url = window.location.href;
    const host = window.location.host;
    if (!Url.startsWith(`${scheme}://`)) {
      window.location.assign(`${scheme}://${host}`);
    }
  };
  checkUrlScheme();

  useLayoutEffect(() => {
    let cancel = false;
    const fetchApiClient = async () => {
      try {
        TabsResourceLock.cleanup();
        if (TokenService.accessToken && !apiUserStatus.get()) {
          await apiUserStatus.prepareUserDetails();
          if (!cancel) {
            setIsLoggedIn(true);
          }
        } else {
          await ApiClient.initialize();
        }

        if (!cancel) {
          setIsReady(ApiClient.isReady);

          if (TokenService.accessToken && apiUserStatus.get()) {
            await Lookups.initialize();
            await apiUserStatus.prepareUserSettings();
          }
        }
      } catch (e) {
        console.log(e);
        cancel = true;
        apiUserStatus.remove();
        setTimeout(() => window.location.href = '/login', 5000);
      }
    };

    fetchApiClient();

    return () => {
      cancel = true;
    };
  }, []);

  window.browserTitle = (updateBrowserTitle, pageTitle, entityAbbreviation, entityId) => {
    if (!updateBrowserTitle) {
      return;
    }

    if(entityAbbreviation && pageTitle) {
      document.title = entityAbbreviation + entityId + ' - ' + pageTitle;
    }
  };

  window.updateRecentViewedEntities = function (entity) {
    try {
      const currentUser = apiUserStatus.get() || {};
      let recentViewedEntities = get(currentUser, 'recentViewedEntities.' + entity.entityType) || [];
      const foundIndex = recentViewedEntities.findIndex(
        recentEntity => recentEntity.entityId === entity.entityId
      );

      // if it already exists, then move to the first
      if (foundIndex !== -1) {
        recentViewedEntities = [
          entity,
          ...recentViewedEntities.slice(0, foundIndex),
          ...recentViewedEntities.slice(foundIndex + 1),
        ].slice(0, 3);
      } else {
        // others add to the bottom
        recentViewedEntities = [entity, ...recentViewedEntities].slice(0, 3);
      }
      apiUserStatus.update({...currentUser, recentViewedEntities: {...currentUser.recentViewedEntities, [entity.entityType]: recentViewedEntities}});
    } catch (err) {
      console.log(err);
    }
  };

  const amplitudeInstance = amplitude.getInstance();
  amplitudeInstance.setOptOut(window.NODE_ENV === 'development');
  //amplitudeInstance.setOptOut(false);
  if(user) {
    amplitudeInstance.init(window.REACT_APP_AMPLITUDE_KEY, user.userName);
  }

  window.amplitudeLogEvent = function (eventType, eventProperties) {
    if(window.NODE_ENV === 'development') {
      return;
    }

    try {
      if (eventProperties) {
        const page = window.location.pathname.split('/')[1];
        eventProperties.page = page;
        if (['view'].includes(eventType) && !eventProperties.from) {
          eventProperties.from = fromLookups[page];
          if (typeof (eventProperties.from) === 'object') {
            eventProperties.from = eventProperties.from[get(apiUserStatus.get() || {}, 'settings.tableViewSettings.' + searchConst.entityTypePluralMap[page] + 'ViewType')] || page;
            eventProperties.from = (eventProperties.from || '').toLowerCase();
            delete eventProperties.detailsTab;
          }
        }

        amplitudeInstance.logEvent(eventType, eventProperties);
      } else {
        amplitudeInstance.logEvent(eventType);
      }
    } catch (err) {
      console.log(err);
    }
  };

  window.getLocalStorageSize = function () {
    let _lsTotal = 0, _xLen, _x;

    for (_x in localStorage) {
      if (!localStorage.hasOwnProperty(_x)) {
        continue;
      }

      _xLen = ((localStorage[_x].length + _x.length) * 2);
      _lsTotal += _xLen;

      console.info(`${_x.substr(0, 50)} = ${(_xLen / 1024).toFixed(2)} KB`);
    }

    console.info(`Total = ${(_lsTotal / 1024).toFixed(2)} KB`);
  };


  // for the @mention highlight current user (user id is dynamic)
  if (isLoggedIn) {
    const style = document.createElement('style');
    document.head.appendChild(style);
    style.sheet.insertRule('.mce-content-body .mention-user[data-mention-id=\'notifyUserIds_' + user.userId + '\'] { background: #b7b7fc; color: #ffffff;}');
  }

  return (
    <QueryClientProvider client={queryClient}>
      <div className='container-fluid row mx-0 px-0'>
        <AtsErrorBoundary>
          <SuspenseWrap>
            <Router history={history}>
              <Switch>
                <Route path='/login' component={() => isLoggedIn ? <Redirect to={preparePathname()}/> : <Login isReady={isReady} />}/>
                <Route path='/login-email' component={() => isLoggedIn ? <Redirect to={preparePathname()}/> : <Login isReady={isReady} />}/>
                <Route path='/reset-password' component={() => isLoggedIn ? <Redirect to={preparePathname()}/> : <Login isReady={isReady} />}/>
                <Route path='/register' component={() => isLoggedIn ? <Redirect to={preparePathname()}/> : <Login isReady={isReady} />}/>
                <Route path='/authorize' component={() => isLoggedIn ? <Redirect to={preparePathname()}/> : <Login isReady={isReady} />}/>
                <Route path='/sign-out' component={() => <Logout /> }/>
                <Route component={() => isLoggedIn && isReady ? <Home history={history} /> : isReady ? <Redirect to={'/login'}/> : <Login isReady={isReady} />}/>
              </Switch>
            </Router>
          </SuspenseWrap>
        </AtsErrorBoundary>
      </div>
    </QueryClientProvider>
  );
}

export default App;
