import React from 'react';
import AtsInput from './AtsInput';
import AtsSelect from './AtsSelect';
import FormFields from './FormFields';
import AtsCheckBox from '../common/AtsCheckBox';

import {compact, has} from '../../libs/lodash';
import layoutConst from '../../constants/layoutConst';
import {countryCurrencyLookup} from '../../constants/countryCurrencyLookup';
import useLookup from '../../hooks/useLookup';

function Country({object, rateInfo, namePrefix, formValidated, callback, idUniq, requiredFields, remoteOption=false, isRemote}) {
  const idPrefix = idUniq ? namePrefix.replace('.', '-') : '';
  const lookups = useLookup({names: ['countries']});

  function isOtherCountry() {
    return object.country === 'Other' || (!!object.country && !(lookups.countries || []).some(country => country.name === object.country));
  }

  const handleCountryChange = (updateValues) => {
    if (
      (updateValues[namePrefix + 'country'] === 'Other' && !object.countryName) ||
      (updateValues[namePrefix + 'country'] !== 'Other' && object.countryName)
    ) {
      updateValues = { ...updateValues, [namePrefix + 'countryName']: ''};
    }

    const value = countryCurrencyLookup[updateValues[namePrefix + 'country']];
    if (!value || !rateInfo) {
      callback(updateValues);
      return;
    }
    const key = Object.keys(rateInfo)[0];
    if (key === 'currencyType') {
      rateInfo.currencyType = value;
    } else {
      rateInfo[key].currencyType = value;
    }

    callback({...updateValues, ...rateInfo});
  };

  const remoteCheckBoxCallback = () => {
    callback({isRemote: !isRemote});
  };

  const countryFields = compact([
    { label: 'Country',
      className: layoutConst.ltColCss,
      component: AtsSelect,
      props: {
        id: idPrefix + 'country',
        name: namePrefix + 'country',
        defaultValue: isOtherCountry() ? 'Other' : object.country,
        options: lookups.countries || [],
        required: requiredFields.country,
        callback: handleCountryChange
      }
    },
    isOtherCountry() ?
      {
        label: 'Country Name',
        className: layoutConst.rtColCss,
        component: AtsInput,
        props: {
          id: idPrefix + 'countryName',
          name: namePrefix + 'countryName',
          defaultValue: !has(object, 'countryName') ?  object.country : object.countryName,
          required: requiredFields.country
        }
      } : null
  ]);


  return (
    <>
      <FormFields
        fields={[countryFields]}
        formValidated={formValidated}
        callback={callback}
      />

      { remoteOption ?
        <div className='form-group pl-0 pt-3'>
          <AtsCheckBox
            name='isRemote'
            defaultValue={isRemote ? ['CHECKED'] : []}
            options={[{label: 'This job is Remote', value: 'CHECKED', identClass: 'checkboxClass'}]}
            handleChange={remoteCheckBoxCallback}
          />
        </div>
        :
        ''
      }
    </>
  );
}

export default Country;
