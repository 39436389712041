import React from 'react';
import { Link } from 'react-router-dom';
import utils from '../../../../libs/utils';
import AtsTooltip from '../../../common/AtsTooltip';

export default function Deal({ entity }) {
  const deal = entity;
  const dealTitle = deal.title || '';

  return (
    <>
      <div className='dealTitleRelated'>
        <AtsTooltip
          targetQuery={`#dealTitle-${deal.dealId}`}
          placement='top'
          tips={dealTitle}
          checkSpace={true}
          checkWidth={true}
        >
          <div className='dealTitle' id={`dealTitle-${deal.dealId}`}>
            <Link to='#' className='biotif-semi-bold'>
              {dealTitle}
            </Link>
          </div>
        </AtsTooltip>
      </div>

      <div className='pt-2 light-grey-text font-size-12 align-text-bottom'>
        Updated {utils.dateDiff(deal.updatedAt, (new Date()).toISOString())} ago
        <span className='float-right'>
          {utils.initialName(deal.owner)}
        </span>
      </div>
    </>
  );
}
