import React from 'react';
import {has} from '../../../libs/lodash';

export default function Skills({parsedResumeInfo}){
  return (
    <>
      {parsedResumeInfo && has(parsedResumeInfo, 'launch.skills') ?
        <>
          <div className='label'>Resume Skills</div>
          <div className='value force-wrap mr-5'>
            {parsedResumeInfo && has(parsedResumeInfo, 'launch.skills') ? parsedResumeInfo.launch.skills.join(', ') : ''}
          </div>
        </>
        :
        ''
      }
    </>
  );
};
