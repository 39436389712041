import {cloneDeep, get, has, isArray, isEmpty, keyBy, orderBy, uniqBy} from '../../../libs/lodash';
import {AtsDatePicker, AtsInputNumber, AtsSelect, AtsTypeAheadSearchSelect, Button, CreatableInputOnly} from '../../common';
import React from 'react';
import searchConst from '../../../constants/searchConst';
import ActivityTypeSelect from './ActivityTypeSelect';
import apiUserStatus from '../../../services/apiUserStatus';
import utils from '../../../libs/utils';
import moment from 'moment';
import patternConst from '../../../constants/patternConst';
import additionalSearchFieldsConfig from './additionalSearchFieldsConfig';
import useLookup from '../../../hooks/useLookup';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Lookups from '../../../services/Lookups';
import useApiQuery from '../../../hooks/useApiQuery';
import { prepareInactiveUsers } from '../libs/utils';

export default ({additionalCriteria, entityType, handleChange, handleAddRemove, formValidated}) => {
  const { data: usersListResponse } = useApiQuery(['usersList' + 1], 'usersList', {}, null);
  const allUsers = usersListResponse?.obj?.users || [];

  const addCriteria = () => {
    handleAddRemove(additionalCriteria.concat([{name: ''}]));
  };


  const removeCriteria = (index) => {
    const isLast = (index === additionalCriteria.length -1);
    const newAdditionalCriteria = isLast ? additionalCriteria.slice(0, index) : [...additionalCriteria.slice(0, index), ...additionalCriteria.slice(index + 1)];
    handleAddRemove(newAdditionalCriteria);
  };

  /* general value field based on search field and search option */
  const additionalSearchFields = additionalSearchFieldsConfig[entityType];
  const additionalFieldMap = keyBy(additionalSearchFields, 'value');
  const lookups = useLookup({names: ['users', 'teams', 'businessUnits', 'practiceAreas', 'badges', 'industries', 'departments', 'jobFunctions', 'skills', 'phcCareerCodes', 'phcFacilityTypes', 'sourceOptions']});

  // add Current User Additional Filter (Search by "My")
  const currentUser = {id: 'current', name: 'Current User', displayName: 'A'};
  if(!isEmpty(lookups.users) && lookups.users[0].id !== 'current') {
    const users = [currentUser].concat(lookups.users || []);
    Lookups.set('users', users);
  }

  if(!isEmpty(lookups.recruiters) && lookups.recruiters[0].id !== 'current') {
    const recruiters = [currentUser].concat(lookups.recruiters || []);
    Lookups.set('recruiters', recruiters);
  }

  // bulkEmailOptOuts is not in the lookups
  if(['candidate', 'contact'].includes(entityType) && !isEmpty(lookups.emailAddressValidities)) {
    const bulkEmailOptOuts = [
      {id: 'true', name: 'Yes'},
      {id: 'false', name: 'No'}
    ];
    Lookups.set('bulkEmailOptOuts', bulkEmailOptOuts);
  }

  const getDropdownOptions = index => {
    const matched = additionalFieldMap[additionalCriteria[index].name];
    if (!matched) {
      return [];
    }

    if (matched.dropdownOptions) {
      return matched.dropdownOptions;
    }

    const lookupKey = matched.dropdownOptionsLookupId;
    const lookupKeyMapTo = matched.dropdownOptionsMapTo;
    if (!lookupKey ) {
      return [];
    }

    if (!isArray(lookupKey) && lookups[lookupKey]) {
      if(lookupKey === 'businessUnits') {
        const { userBusinessUnitIds = [] } = apiUserStatus.get() || {};
        return utils.convertIdsToOptionFormat(lookups[lookupKey], userBusinessUnitIds);
      }

      if(matched.orderBy) {
        return orderBy(lookups[lookupKey], 'id');
      }

      if (allUsers.length > 0 && (lookupKey === 'users' || lookupKey === 'recruiters')) {
        const inactiveUsers = prepareInactiveUsers(allUsers);
        return lookups[lookupKey].concat(inactiveUsers);
      };

      return lookups[lookupKey];
    }

    if (isArray(lookupKey)) {
      let combinedOptions = [];
      lookupKey.forEach(function(key, index) {
        if (lookups[key] && lookupKeyMapTo) {
          if (combinedOptions.length === 0) {
            combinedOptions = lookups[key];
          } else {
            const convertToOwnerUserIds = cloneDeep(lookups[key]);
            convertToOwnerUserIds.map(k => k.id = k.id + '_' + lookupKeyMapTo[index]);

            combinedOptions = combinedOptions.concat(convertToOwnerUserIds);
          }
          if (allUsers.length > 0 && (lookupKey.includes('users') || lookupKey.includes('recruiters'))) {
            const inactiveUsers = prepareInactiveUsers(allUsers);
            combinedOptions = combinedOptions.concat(inactiveUsers);
          };
        } else {
          combinedOptions = combinedOptions.concat(lookups[key]);
        }
      });

      return uniqBy(orderBy(combinedOptions, ['displayName', 'name']), 'name');
    }

    return [];
  };

  const getSearchOptions = index => {
    const matched = additionalFieldMap[additionalCriteria[index].name];
    if(matched) {
      return matched.searchOptions;
    } else {
      return [];
    }
  };

  const genFromToDateFields = (index, dateRangeOption) => {
    const sharedProps = {
      dateOnly: additionalFieldMap[additionalCriteria[index].name].dateOnly || false,
      required: true,
      form: false
    };
    const searchActivity = isSearchActivity(additionalCriteria[index]);
    const dateFieldAttr = searchActivity ? 'additionalCriteria[' + index + '].criteria.dateRange.value' : 'additionalCriteria[' + index + '].criteria.value';
    const value = searchActivity ? get(additionalCriteria[index], 'criteria.dateRange.value') : additionalCriteria[index].criteria.value;
    const uniqueProps = genBetweenSearchValueProps(dateFieldAttr);

    return ([
      <>
        <AtsDatePicker
          {...uniqueProps[0]} {...sharedProps}
          position='from'
          maxDate={!isEmpty(value) && value.length === 2 && !isNaN(Date.parse(value[1])) && moment(value[1]).isValid() ? value[1] : null}
          defaultValue={!isEmpty(value) && !isNaN(Date.parse(value[0])) && moment(value[0]).isValid() ? value[0]: null}
          callback={date =>handleChange(date)}
        />
      </>,
      <>
        <AtsDatePicker
          {...uniqueProps[1]} {...sharedProps}
          position='to'
          minDate={!isEmpty(value) && !isNaN(Date.parse(value[0])) && moment(value[0]).isValid() ? value[0] : null}
          defaultValue={!isEmpty(value) && value.length === 2 && !isNaN(Date.parse(value[1])) && moment(value[1]).isValid() ? value[1] : null}
          callback={date =>handleChange(date)}
        />
      </>,
      <AtsSelect
        {...uniqueProps[0]}
        isClearable={false}
        validated={formValidated}
        required={true}
        defaultValue={!isEmpty(value) ? value[0] : null}
        options={[searchConst.inTheLast, searchConst.inTheNext].includes(dateRangeOption) ? searchConst.dateRangeOptions : lookups.calendarTypes || []}
        callback={handleChange}
      />
    ]);
  };

  const genFromToNumberFields = (index) => {
    const sharedProps = {
      formatter: patternConst.DollarFormatPattern,
      parser: patternConst.DollarParsePattern,
      required: true,
      callback: handleChange
    };

    const uniqueProps = genBetweenSearchValueProps('additionalCriteria[' + index + '].criteria.value');
    const value = additionalCriteria[index].criteria.value;

    return ([
      <AtsInputNumber {...sharedProps} {...uniqueProps[0]}
        defaultValue={!isEmpty(value) && !isNaN(value[0]) ? value[0] : null}
        min={0}
      />,
      <AtsInputNumber {...sharedProps} {...uniqueProps[1]}
        defaultValue={!isEmpty(value) && value.length === 2 && !isNaN(value[1]) ? value[1] : null}
        min={!isEmpty(value) && !isNaN(value[0]) ? value[0] : 0}
      />]);
  };

  const genBetweenSearchValueProps = (attribute) => {
    return [0, 1].map((i) => {
      return {
        key: attribute + '[' + i + ']',
        id: attribute + '[' + i + ']',
        name: attribute + '[' + i + ']'
      };
    });
  };

  const genDateNumberSearchValueField = (index, option, isDatePicker) => {
    const criteriaEntry = additionalCriteria[index];
    const searchActivity = isSearchActivity(additionalCriteria[index]);
    const dateFieldAttr = searchActivity ? 'additionalCriteria[' + index + '].criteria.dateRange.value' : 'additionalCriteria[' + index + '].criteria.value';
    const fromTo = isDatePicker ? genFromToDateFields(index, option) : genFromToNumberFields(index);
    const value = isDatePicker ? (searchActivity ? get(criteriaEntry, 'criteria.dateRange.value') : get(criteriaEntry, 'criteria.value')) : [];

    switch (option) {
    case searchConst.between:
      return (
        <div className='col-12 px-0'>
          <div className='col-6 d-inline-block pl-0'>
            {fromTo[0]}
          </div>
          <div className='col-6 d-inline-block pr-0'>
            {fromTo[1]}
          </div>
        </div>
      );
    case searchConst.before:
    case searchConst.after:
    case searchConst.below:
    case searchConst.above:
      return fromTo[0];
    case searchConst.inTheLast:
    case searchConst.inTheNext:
      if (!isEmpty(value) && value[0] === 'custom') {
        return (
          <div className='col-12 px-0'>
            <div className='col-6 d-inline-block pl-0'>
              {fromTo[2]}
            </div>
            <div className='col-6 d-inline-block pr-0'>
              <AtsInputNumber
                key={dateFieldAttr + '[1]'}
                id={dateFieldAttr + '[1]'}
                name={dateFieldAttr + '[1]'}
                min={1}
                placeholder='Days'
                precision={0}
                required={true}
                validated={formValidated}
                defaultValue={!isEmpty(value) && value.length === 2 ? value[1] : null}
                callback={handleChange}
              />
            </div>
          </div>
        );
      } else {
        return fromTo[2];
      }
    case searchConst.inTheLastCalendar:
    case searchConst.inTheCurrentCalendar:
    case searchConst.inTheNextCalendar:
      return fromTo[2];
    default:
      return fromTo[0];
    }
  };

  const genSearchValueField = (index) => {
    const criteriaEntry = additionalCriteria[index];
    if (!criteriaEntry.name) {
      return '';
    }

    const matched = additionalFieldMap[criteriaEntry.name];
    if( !matched ) {
      return '';
    }

    const option = prepareOption(index);
    if (!option || [searchConst.with, searchConst.without].includes(option)) {
      return '';
    }
    const FieldComponent = additionalFieldMap[criteriaEntry.name].searchOptionComponent;

    switch(FieldComponent) {
    case AtsDatePicker:
    case AtsInputNumber:
      return genDateNumberSearchValueField(index, option, FieldComponent === AtsDatePicker);
    case ActivityTypeSelect:
      const activitySearchValueProps = genSearchValueProps(index, 'filterTypes.value');
      return (
        <div className='col-12 px-0'>
          {[searchConst.with, searchConst.without, 'null'].includes(option) ?
            <div className='blank-form-control' />
            :
            <ActivityTypeSelect
              {...activitySearchValueProps}
              options={lookups && lookups.activityTypeFilters ?  lookups.activityTypeFilters : []}
              entityType={entityType}
              defaultValue={activitySearchValueProps.defaultValue || searchConst.searchOptions.activityDefaultValue.id}
            />
          }
        </div>
      );
    default:
      const searchValueProps = genSearchValueProps(index, 'value');
      if (FieldComponent === AtsTypeAheadSearchSelect ) {
        searchValueProps.formValidated = formValidated;
        ['entityType', 'clearButton', 'labelKey', 'minLength', 'maxLengthToApi', 'delay', 'searchOperation', 'filterBy', 'companyTypes'].forEach((attr) => {
          if(get(matched, attr) !== undefined) {
            searchValueProps[attr] = get(matched, attr);
          }
        });
      }

      return (getDropdownOptions(index) ?
        <FieldComponent {...searchValueProps} options={getDropdownOptions(index) || []}/> :
        <FieldComponent {...searchValueProps} />
      );
    }
  };

  const genSearchOptionField = (index) => {
    const criteriaEntry = additionalCriteria[index];
    const searchField = criteriaEntry.name;
    if (!searchField) {
      return '';
    };
    const searchActivity = isSearchActivity(criteriaEntry);
    const searchOptionProps = genSearchOptionProps(index, searchActivity ? 'criteria.filterTypes.option' : 'criteria.option');
    return (
      <AtsSelect
        {...searchOptionProps}
        defaultValue={searchActivity ? (searchOptionProps.defaultValue || searchConst.searchOptions.activityDefaultValue.id) : searchOptionProps.defaultValue}
        options={getSearchOptions(index) || []}
      />
    );
  };

  const prepareOption = (index) => {
    let option = null;
    try {
      const criteriaEntry = additionalCriteria[index];
      const searchActivity = isSearchActivity(criteriaEntry);
      if (searchActivity && criteriaEntry.criteria && criteriaEntry.criteria.filterTypes) {
        option = criteriaEntry.criteria.filterTypes.option;
      } else if (criteriaEntry && criteriaEntry.criteria) {
        option = criteriaEntry.criteria.option;
      }
    } catch (err) {
      console.log(err);
    } finally {
      return option;
    }
  };

  const genSearchOptionProps = (index, attr) => {
    const criteriaEntry = additionalCriteria[index];
    const uniquePropsPrefix = 'additionalCriteria[' + index + '].';

    return {
      key: uniquePropsPrefix + attr,
      id: uniquePropsPrefix + attr,
      name: uniquePropsPrefix + attr,
      isClearable: false,
      placeholder: 'Select',
      validated: formValidated,
      required: true,
      defaultValue: has(criteriaEntry, attr) ? get(criteriaEntry, attr) : null,
      callback: handleChange
    };
  };

  const genSearchValueProps = (index, attr) => {
    const criteriaEntry = additionalCriteria[index];
    // api always return array values
    // have to convert to string if it's singleSearchField
    const matched = additionalFieldMap[(criteriaEntry || {}).name] || {};
    const selectValue = has(criteriaEntry, 'criteria.' + attr) ? get(criteriaEntry, 'criteria.' + attr) : [];
    const defaultValue = matched.singleSearchField ? (!isEmpty(selectValue) ? selectValue[0] : null) : (!isEmpty(selectValue) ? selectValue : []);
    const uniquePropsPrefix = 'additionalCriteria[' + index + '].criteria.';
    const defaultProps = {
      key: uniquePropsPrefix + attr,
      id: uniquePropsPrefix + attr,
      name: uniquePropsPrefix + attr,
      isClearable: false,
      placeholder: 'Select a Criteria',
      defaultValue: defaultValue,
      validated: formValidated,
      required: true,
      isMulti: matched.singleSearchField ? false : true,
      callback: handleChange
    };

    return {
      ...defaultProps,
      ...additionalFieldMap[criteriaEntry.name].props,
    };
  };

  const genActivityExtraRow = (index) => {
    const criteriaEntry = additionalCriteria[index];
    const dateSearchOptionProps = genSearchOptionProps(index, 'criteria.dateRange.option');
    const textSearchOptionProps = genSearchOptionProps(index, 'criteria.text.option');

    const textSearchValueProps = genSearchValueProps(index, 'text.value');
    return (
      <>
        <div className='form-row col-12 mx-0 px-0' key={`criteriaEntryRow${index}-activity-date-row`}>
          <div className='form-group col-4 px-0'>
            <div className='mx-0 px-0 text-right font-size-14 pr-3' style={{'lineHeight': '44px', 'verticalAlign': 'middle'}}>
              Date
            </div>
          </div>
          <div className='form-group col-2'>
            <AtsSelect
              {...dateSearchOptionProps}
              defaultValue={dateSearchOptionProps.defaultValue || searchConst.searchOptions.dateDefaultValue.id}
              options={searchConst.searchOptions.date.concat(
                [searchConst.searchOptions.inTheLast, searchConst.searchOptions.inTheLastCalendar, searchConst.searchOptions.inTheCurrentCalendar]
              ).concat([searchConst.searchOptions.dateDefaultValue])}
            />
          </div>
          <div className='form-group col-6'>
            <div className='col-12 mx-0 px-0'>
              {[searchConst.with, searchConst.without, 'null', undefined].includes(get(criteriaEntry, 'criteria.dateRange.option')) ?
                ''
                :
                <>
                  {genDateNumberSearchValueField(index, get(criteriaEntry, 'criteria.dateRange.option'), true)}
                </>
              }
            </div>
          </div>
        </div>
        <div className='form-row col-12 mx-0 px-0' key={`criteriaEntryRow${index}-activity-text-row`}>
          <div className='form-group col-4 px-0'>
            <div className='mx-0 px-0 text-right font-size-14 pr-3' style={{'lineHeight': '44px', 'verticalAlign': 'middle'}}>
              Text
            </div>
          </div>
          <div className='form-group col-2'>
            <AtsSelect
              {...textSearchOptionProps}
              defaultValue={textSearchOptionProps.defaultValue || searchConst.searchOptions.textDefaultValue.id}
              options={[searchConst.searchOptions.includeAny, searchConst.searchOptions.includeAll, searchConst.searchOptions.exclude, searchConst.searchOptions.textDefaultValue]}
            />
          </div>
          <div className='form-group col-6'>
            <div className='col-12 mx-0 px-0'>
              {[searchConst.with, searchConst.without, 'null', undefined].includes(get(criteriaEntry, 'criteria.text.option')) ?
                ''
                :
                <CreatableInputOnly
                  {...textSearchValueProps}
                  defaultValue={ has(criteriaEntry, 'criteria.text.value') ? criteriaEntry.criteria.text.value : []}
                />
              }
            </div>
          </div>
        </div>
      </>
    );
  };

  const isSearchActivity = (criteriaEntry) => {
    return criteriaEntry.name === 'activity';
  };

  return (
    <>
      {!isEmpty(additionalCriteria) && additionalCriteria.map((criteriaEntry, index) =>
        <div key={`criteriaEntryRow${index}`}>
          <div className={`form-row col-12 mx-0 px-0${index ===0 ? '' : ' pt-2'}`}>
            <div className='form-group col-4 px-0'>
              <div className='col-1 text-nowrap d-inline-block pl-0'>
                <div className='cursor circle-delete' onClick={() => removeCriteria(index)} />
              </div>
              <div className='col-11 d-inline-block'>
                <AtsSelect key={`additionalCriteria[${index}].name`}
                  id={`additionalCriteria[${index}].name`}
                  name={`additionalCriteria[${index}].name`}
                  isClearable={false}
                  defaultValue={criteriaEntry.name || ''}
                  placeholder='Select a Criteria'
                  options={(additionalSearchFields || []).map(p => {
                    return {
                      id: p.value,
                      name: p.label
                    };
                  })}
                  validated={formValidated}
                  callback={handleChange}
                  className='ats-select'
                />
              </div>
            </div>
            {
              additionalFieldMap[additionalCriteria[index].name] && additionalFieldMap[additionalCriteria[index].name].hideSearchOptionField ?
                <>
                  <div className='form-group col-2 d-none'>
                    {genSearchOptionField(index)}
                  </div>
                  <div className='form-group col-3'>
                    {genSearchValueField(index)}
                  </div>
                </>
                :
                <>
                  <div className='form-group col-2'>
                    {genSearchOptionField(index)}
                  </div>
                  <div className='form-group col-6' id={`${['jobFunctionIds', 'skillIds'].includes(criteriaEntry.name) ? criteriaEntry.name + '-' + index : ''}`}>
                    {genSearchValueField(index)}
                  </div>
                </>
            }
          </div>

          {isSearchActivity(criteriaEntry) && genActivityExtraRow(index)}
        </div>
      )}

      <Row>
        <Col className='addCriteria'>
          <Button type='button' id='new-criteria' variant='mb-3 btn-primary btn-no-form' click={addCriteria}>
            New Filter
          </Button>
        </Col>
      </Row>
    </>
  );
};
