import React, {useCallback, useEffect, useReducer, useState} from 'react';
import PropTypes from 'prop-types';
import usePrevious from '../../../hooks/usePrevious';
import utils from '../../../libs/utils';
import {notifyStatusesOption, searchFilterTypes} from '../../../constants/notificationConst';
import {isEmpty} from '../../../libs/lodash';
import HeaderSearch from '../HeaderSearch';
import NotificationList from '../../Notifications/NotificationList';
import {useDispatch, useSelector} from 'react-redux';
import * as notificationActions from '../../../actions/notification';
import {notificationBasicInfo, prepareStatusParam, getStatusSelected} from '../../Notifications/libs/utils';

export default function Notification(props) {
  const [searchEntityTypes] = useState(searchFilterTypes);
  const [statusesOption] = useState(notifyStatusesOption);
  const dispatch = useDispatch();

  const notifications = useSelector(state => state.notificationReducer.data.notifications);
  const prevNotificationsCount = usePrevious(notifications.length);
  const summary = useSelector(state => state.notificationReducer.data.summary);
  const parameters = useSelector(state => state.notificationReducer.parameters);
  const isFetched = useSelector(state => state.notificationReducer.isFetched);

  const [filteredSearchResult, setFilteredSearchResult] = useState(notifications || []);

  const initialFilter = {
    searchTerm: '',
    searchEntityType: 'ALL',
    statusSelected: 'ANY',
  };
  const init = (initialFilter) => initialFilter;
  const notificationReducer = (state, action) => {
    return {
      ...state,
      [action.type]: action.value
    };
  };
  const [notificationFilter, setNotificationFilter] = useReducer(notificationReducer, initialFilter, init);

  function fetchMoreData() {
    const newParams = {
      offset: parameters.offset + parameters.maxResults,
      maxResults: parameters.maxResults,
      entityType: notificationFilter.searchEntityType.toUpperCase() !== 'ALL' ? notificationFilter.searchEntityType.toUpperCase() : null,
      ...prepareStatusParam(notificationFilter.statusSelected),
    };
    dispatch(notificationActions.fetchNotifications(newParams));
  }

  const handleChange = (event) => {
    const query = event.target.value.toLowerCase();
    window.amplitudeLogEvent('global notifications', {action: 'text search'});
    setNotificationFilter({type: 'searchTerm', value: query});
  };

  const handleFilter = (name, value) => {
    if (value !== notificationFilter.searchEntityType) {
      window.amplitudeLogEvent('global notifications', {action: 'filter', filterValue: (value || '').toLowerCase()});
      setNotificationFilter({type: 'searchEntityType', value: value});

      const newParams = {
        offset: 0,
        maxResults: parameters.maxResults,
        entityType: value.toUpperCase() !== 'ALL' ? value.toUpperCase() : null,
        ...prepareStatusParam(notificationFilter.statusSelected),
      };
      dispatch(notificationActions.fetchNotifications(newParams));
    }
  };

  const handleStatusesFilter = (name, value) => {
    if (value !== notificationFilter.statusSelected) {
      window.amplitudeLogEvent('global notifications', {action: 'status filter', statusFilterValue: (value || '').toLowerCase()});
      setNotificationFilter({type: 'statusSelected', value: value});

      const newParams = {
        offset: 0,
        maxResults: parameters.maxResults,
        entityType: notificationFilter.searchEntityType.toUpperCase() !== 'ALL' ? notificationFilter.searchEntityType.toUpperCase() : null,
        ...prepareStatusParam(value),
      };
      dispatch(notificationActions.fetchNotifications(newParams));
    }
  };

  const prepareSearchTermFilter = useCallback((notifications, searchTerm) => {
    return notifications.filter((item) => {
      const notifyBasicInfo = notificationBasicInfo(item);
      const prepareNameField = (item) => {
        if (item.entity.type.toLowerCase() !== 'submission' || isEmpty(item.relatedEntities)) {
          return (`${item.entity.name} (${utils.convertIdToName(props.lookups, 'globalEntityAbbreviations', item.entity.type.toUpperCase())}${item.entity.id})`).toLowerCase().includes(searchTerm);
        } else {
          return (`${item.relatedEntities[0].name} (${utils.convertIdToName(props.lookups, 'globalEntityAbbreviations', item.relatedEntities[0].type.toUpperCase())}${item.relatedEntities[0].id})`).toLowerCase().includes(searchTerm) ||
            (`${item.relatedEntities[1].name} (${utils.convertIdToName(props.lookups, 'globalEntityAbbreviations', item.relatedEntities[1].type.toUpperCase())}${item.relatedEntities[1].id})`).toLowerCase().includes(searchTerm);
        }
      };
      return (notifyBasicInfo.toLowerCase()).includes(searchTerm)||
        prepareNameField(item)||
        utils.contentForRender(utils.convertHtmlToText(item.message), 100, false).toLowerCase().includes(searchTerm)||
        utils.timeForRender(item.createdAt).includes(searchTerm);
    });
  }, [props.lookups]);

  const prevEntityType = usePrevious(parameters.entityType);
  const prevSearchTerm = usePrevious(notificationFilter.searchTerm);
  const prevStatus = usePrevious(getStatusSelected(parameters));
  const currentStatus = getStatusSelected(parameters);

  useEffect(() => {
    let changed = notificationFilter.searchTerm !== prevSearchTerm || prevNotificationsCount !== notifications.length || parameters.entityType !== prevEntityType || prevStatus !== currentStatus;
    if (!changed && prevNotificationsCount === notifications.length && (parameters.entityType === undefined && currentStatus === 'ANY')) {
      changed = true;
    }

    if (changed) {
      if (notificationFilter.searchTerm) {
        setFilteredSearchResult(prepareSearchTermFilter(notifications, notificationFilter.searchTerm));
      } else {
        setFilteredSearchResult(notifications);
      }
    }
  }, [notifications, prevNotificationsCount, notificationFilter.searchTerm, prevSearchTerm, prepareSearchTermFilter, parameters.entityType, prevEntityType, prevStatus, currentStatus]);

  return (
    <div id='notification-drawer' className='pb-4'>
      <div className='divider divider-horizontal divider-100 my-4' />
      <HeaderSearch
        drawer={props.drawer}
        closeDrawer={props.closeDrawer}
        searchTerm={notificationFilter.searchTerm}
        handleChange={handleChange}
        handleFilter={handleFilter}
        searchEntityTypes={searchEntityTypes}
        searchEntityType={notificationFilter.searchEntityType}
        isFetched={isFetched}
        handleStatusesFilter={handleStatusesFilter}
        statusSelected={notificationFilter.statusSelected}
        statusesOption={statusesOption}
      />
      <div className='divider divider-horizontal divider-100 mt-4' />
      {
        isEmpty(notifications) ?
          <div className='d-flex item pl-4 ml-4'>No results found.</div>
          :
          <NotificationList
            history={props.history}
            notificationFilter={notificationFilter}
            filteredSearchResult={filteredSearchResult}
            notifications={notifications}
            summary={summary}
            fetchMoreData={fetchMoreData}
            lookups={props.lookups}
            closeDrawer={props.closeDrawer}
          />
      }
    </div>
  );
}

Notification.propTypes = {
  drawer: PropTypes.object.isRequired,
  closeDrawer: PropTypes.func.isRequired
};
