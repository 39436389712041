import React, {Component} from 'react';

export default class AtsErrorBoundary extends Component {
  state = { hasError: false };

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    console.log(error);
  }

  render() {
    if (this.state.hasError) {
      return <div className='red-text pl-2 biotif-semi-bold'>Something went wrong.</div>;
    }

    return this.props.children;
  }
}
