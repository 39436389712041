export const tableViewSavedAttrs = [
  'jobColumns',
  'jobViewType',
  'jobHighlights',
  'jobHighlightsOpen',
  'jobGraphOpen',
  'jobGraphType',
  'jobGraphBy',
  'subColumns',
  'subViewType',
  'subHighlights',
  'subHighlightsOpen',
  'subGraphOpen',
  'subGraphType',
  'subGraphBy',
  'subCriteria',
  'placementColumns',
  'placementViewType',
  'placementHighlights',
  'placementHighlightsOpen',
  'placementGraphOpen',
  'placementGraphType',
  'placementGraphBy',
  'dealViewType',
  'dealColumns',
  'dealHighlightsOpen',
  'dealHighlights',
  'dealHighlightsOpen',
  'dealGraphOpen',
  'dealGraphType',
  'dealGraphBy',
];


