const activityRelationshipSettings = {
  'CANDIDATE': ['SUBMISSION', 'PLACEMENT'],
  'JOB': [ 'SUBMISSION'],
  'CONTACT': [ 'JOB' ],
  'COMPANY': [ 'JOB', 'CONTACT', 'DEAL' ]
};

const activityFilterCategoriesDefault = {
  'CANDIDATE': ['BULK_ACTION', 'CALL', 'CONTRACTOR_CARE', 'COMPLIANCE', 'EMAIL', 'NOTE', 'PRESCREEN', 'SUBMISSION', 'PLACEMENT'],
  'JOB': [ 'NOTE', 'OPPORTUNITY', 'SUBMISSION'],
  'CONTACT': ['BULK_ACTION', 'CALL', 'EMAIL', 'NOTE'],
  'COMPANY': [ 'NOTE', 'CONTACT', 'UPDATE' ],
  'PLACEMENT': [ 'COMPLIANCE', 'NOTE', 'UPDATE' ]
};

export {
  activityRelationshipSettings,
  activityFilterCategoriesDefault
};
