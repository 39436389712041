import utils from '../../../libs/utils';
import apiUserStatus from '../../../services/apiUserStatus';

export const notificationBasicInfo = (item) => {
  let name = utils.shortName(item.createdBy);
  let action;
  switch (item.action) {
  case 'NOTE':
  case 'TASK':
    action = `added a ${item.action.toLowerCase()}`;
    break;
  case 'TARGET':
    const user = apiUserStatus.get();
    if (user.userId === item.createdBy.userId) {
      name = 'You';
    }
    action = `reached the ${item.action.toLowerCase()}`;
    break;
  default:
    action = item.action.toLowerCase()+'d';
  }
  return `${name} ${action}`;
};

export const notificationMsgInfo = (item) => {
  const name = utils.shortName(item.createdBy);
  const type = utils.camelize(item.entity.type);
  let action;
  switch (item.action) {
  case 'NOTE':
  case 'TASK':
    action = `added a ${item.action.toLowerCase()} for`;
    break;
  case 'TARGET':
    return item.message;
  default:
    action = item.action.toLowerCase()+'d';
  }
  return `${name} ${action} a ${type}`;
};

export const notificationNameField = (entity, lookups) => {
  return `${entity.name} (${utils.convertIdToName(lookups, 'globalEntityAbbreviations', entity.type.toUpperCase())}${entity.id})`;
};

export const prepareStatusParam = (statusSelected) => {
  if (statusSelected === 'READ') {
    return {
      isRead: true
    };
  }

  if (statusSelected === 'UNREAD') {
    return {
      isRead: false
    };
  }

  if (statusSelected === 'FLAGGED') {
    return {
      isFlagged: true
    };
  }

  if (statusSelected === 'UNFLAGGED') {
    return {
      isFlagged: false
    };
  }

  if (statusSelected === 'REPLIED') {
    return {
      hasReplied: true
    };
  }

  if (statusSelected === 'UNREPLIED') {
    return {
      hasReplied: false
    };
  }
};

export const getStatusSelected = (parameters) => {
  const fieldNames = Object.keys(parameters);

  if (fieldNames.includes('isRead')) {
    if (parameters.isRead) {
      return 'READ';
    } else {
      return 'UNREAD';
    }
  }

  if (fieldNames.includes('isFlagged')) {
    if (parameters.isFlagged) {
      return 'FLAGGED';
    } else {
      return 'UNFLAGGED';
    }
  }

  if (fieldNames.includes('hasReplied')) {
    if (parameters.hasReplied) {
      return 'REPLIED';
    } else {
      return 'UNREPLIED';
    }
  }

  return 'ANY';
};
