const groupByOptions = [
  {id: 'clientContact', name: 'Client Contact', entityTypeAttr: 'CNT'},
  {id: 'company.companyId', name: 'Company', valueAttr: 'company.name', entityTypeAttr: 'CMP'},
  {id: 'company.companyType', name: 'Company Type'},
  {id: 'rateInfo.currencyType', name: 'Currency Type', lookupsKey: 'currencyTypes'},
  {id: 'daysOpen', name:'Days Open'},
  {id: 'practiceArea.id', name: 'Job Category', valueAttr: 'practiceArea.name'},
  {id: 'jobType', name: 'Job Type', lookupsKey: 'jobTypes'},
  {id: 'stage', name: 'Latest Stage'},
  {id: 'location', name: 'Location'},
  {id: 'mspContact', name: 'MSP Contact', entityTypeAttr: 'CNT'},
  {id: 'rateInfo.overtimeType', name: 'Overtime Type', lookupsKey: 'jobOvertimeTypes'},
  {id: 'owner.userId', name: 'Owner'},
  {id: 'patientCareType', name: 'Patient Care Type', lookupsKey: 'jobPatientCareTypes'},
  {id: 'programInfo.clientCompany.companyId', name: 'Program Client', valueAttr: 'programInfo.clientCompany.name', entityTypeAttr: 'CMP'},
  {id: 'programInfo.mspCompany.companyId', name: 'Program MSP', valueAttr: 'programInfo.mspCompany.name', entityTypeAttr: 'CMP'},
  {id: 'rateInfo.rateType', name: 'Rate Type', lookupsKey: 'jobRateTypes'},
  {id: 'recruiters', name: 'Recruiters'},
  {id: 'openStatus', name: 'Status', lookupsKey: 'jobOpenStatuses'}
];

export default groupByOptions;
