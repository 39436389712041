import './style.scss';
import React from 'react';
import DatePicker from 'react-datepicker';
import Button from '../Button';
import utils from '../../../libs/utils';

function AtsEffectiveDatePicker({effectiveDate, required, handleChange, className, position, minDate, maxDate, handleOnCancel}) {
  return (
    <div className='ats-effective-date-picker'>
      <DatePicker
        id='effectiveDate'
        name='effectiveDate'
        required={required}
        placeholderText='Change Effective On'
        className={`form-control ${className?className:'col-6'}`}
        dateFormat='MMMM d, yyyy'
        selected={effectiveDate ? new Date(utils.dateOnlyForRender(effectiveDate, 'MM/DD/YY')) : null}
        minDate={minDate ? new Date(utils.dateOnlyForRender(minDate, 'MM/DD/YY')) : null}
        maxDate={maxDate ? new Date(utils.dateOnlyForRender(maxDate, 'MM/DD/YY')) : null}
        onChange={(date) => { handleChange({effectiveDate: date}); }} />
      <label className={`calendar-icon form ${position==='to'?'to':'from'}`} htmlFor='effectiveDate' />
      <Button type='button' id='cancel-inline' variant='effective-date-btn mr-4' click={handleOnCancel}/>
      <Button type='submit' id='save-inline' variant='effective-date-btn'/>
    </div>
  );
}

export default AtsEffectiveDatePicker;
