import React, {useEffect, useState} from 'react';
import {toast} from 'react-toastify';
import {useDispatch} from 'react-redux';
import DatePicker from 'react-datepicker';

import AtsCheckBox from '../common/AtsCheckBox';
import AtsInput from '../common/AtsInput';
import AtsModal from '../common/AtsModal';
import AtsSelect from '../common/AtsSelect';
import AtsRichTextArea from '../common/AtsRichTextArea';
import AtsTypeAheadSearchSelect from '../common/AtsTypeAheadSearchSelect';
import Button from '../common/Button';
import FormFields from '../common/FormFields';
import { prepareForRenderAppointments, prepareForApiAppointments } from './libs/utils';
import utils from '../../libs/utils';
import {get, has, isEmpty, compact} from '../../libs/lodash';
import useForm from '../../hooks/useForm';
import useNotifyUserIds from '../../hooks/useNotifyUserIds';
import useLookup from '../../hooks/useLookup';

import * as appointmentActions from '../../actions/appointment';

import timezoneList from '../../services/timezoneList';
import timelineLabels from '../../services/timelineLabels';

import './style.scss';
import ApiClient from '../../services/ApiClient';

export default function EditModal({modalVisible, modalCancel, defaultValue, lookups, saveCallback, createNoteFromData}) {
  const [activityTypeOptions, setActivityTypeOptions] = useState([]);
  const [noteCreation, setNoteCreation] = useState(false);
  const [startsAtChoices, setStartsAtChoices] = useState([]);
  const [endsAtChoices, setEndsAtChoices] = useState([]);

  useEffect(() => {
    setStartsAtChoices(timelineLabels('11:45 PM'));
  }, []);

  const appLookups = useLookup({ names: ['activityTypes'] });

  const dispatch = useDispatch();

  if (!isEmpty(appLookups.activityTypes) && isEmpty(activityTypeOptions)){
    const activityTypes = appLookups.activityTypes;
    const formattedActivityTypes = [];
    for (let i = 0; i < activityTypes.length; i++) {
      if ((activityTypes[i].entityTypes).includes(defaultValue.entity.type) &&
        ['MEETING', 'SUBMISSION_WORKFLOW'].includes(activityTypes[i].category) &&
        activityTypes[i].actions.includes('APPOINTMENT')) {
        const fItem = utils.prepareActivityTypes(activityTypes[i]);
        formattedActivityTypes.push(fItem);
      }
    }

    setActivityTypeOptions(formattedActivityTypes);
  }

  const formSubmit = async() => {
    try {
      let fn;
      const requestBody = prepareForApiAppointments(values);

      if (values.appointmentId) {
        requestBody.isCanceled = false;
        fn = ApiClient.appointmentsUpdate;
      } else {
        fn = ApiClient.appointmentsCreate;
      }

      const res = await fn(values.id ? {id: values.id} : {}, requestBody);

      const msg = `${utils.camelize(values.appointmentType || '')} ${values.appointmentId ? 'Updated' : 'Added'}`;
      toast(msg, {
        className: 'toast-response-background',
        bodyClassName: 'toast-response-body',
        position: 'top-right'
      });

      if(values.appointmentId) {
        dispatch(appointmentActions.updateAppointment({...values, ...requestBody}));
      } else {
        dispatch(appointmentActions.addAppointment(res.body));
      }

      if (saveCallback) {
        if(values.appointmentId) {
          // const entity = {...values, ...params.appointment, entityType: 'APPOINTMENT'};
          // entity.date = moment.utc(entity.date).format('MM/DD/YY');
          const entity = prepareForRenderAppointments({...values, ...requestBody});
          entity.entityType = 'APPOINTMENT';
          await saveCallback(entity);
        } else {
          const entity = prepareForRenderAppointments(res.body);
          entity.entityType = 'APPOINTMENT';
          await saveCallback(entity);
        }
      }

      if (noteCreation) {
        createNoteFromData(values);
      } else {
        modalCancel();
      }

    } catch (error) {
      console.log('appointment submit error:', error);
    }
  };

  const updateCallback = async(updatedValues) => {
    try {
      if (has(updatedValues, 'contacts')) {
        handleChange({...updatedValues, contactIds: updatedValues.contacts.map((obj) => obj.contactId)});
      } else if (has(updatedValues, 'sendInviteToCandidates')) {
        handleChange({sendInviteToCandidates: !values.sendInviteToCandidates});
      } else if (has(updatedValues, 'sendInviteToContacts')) {
        handleChange({sendInviteToContacts: !values.sendInviteToContacts});
      } else if (has(updatedValues, 'startsAt')) {
        if (values.endsAt && updatedValues.startsAt) {
          // startsAt
          const startsAt = new Date();
          const startsTime = updatedValues.startsAt.split(/:| /);
          const startsHourOnly = startsTime[2] === 'AM' ? startsTime[0] : 12 + parseInt(startsTime[0]);
          const startsMinOnly = startsTime[1];
          startsAt.setHours(startsHourOnly, startsMinOnly, 0, 0);

          // endsAt
          const endsAt = new Date();
          const endsTime = values.endsAt.split(/:| /);
          const endsHourOnly = endsTime[2] === 'AM' ? endsTime[0] : 12 + parseInt(endsTime[0]);
          const endsMinOnly = endsTime[1];
          endsAt.setHours(endsHourOnly, endsMinOnly, 0, 0);

          if (startsAt >= endsAt) {
            handleChange({...updatedValues, endsAt: ''});
          } else {
            handleChange(updatedValues);
          }
        } else {
          handleChange(updatedValues);
        }
      } else if (has(updatedValues, 'communicationMethod')) {
        if (get(updatedValues, 'communicationMethod') === 'ON_SITE' && !values.location && get(values, 'entity.type') === 'SUBMISSION') {
          let location = {};
          if(!get(values, 'entity.job')) {
            try {
              const res = await ApiClient.submissionsDetails({id: get(values, 'entity.id')});
              location = get(res, 'body.job.location');
            } catch (err) {
              console.log(err);
            }
          } else {
            location = get(values, 'entity.job.location');
          }
          const {country=null, state=null, city=null, address1=null, address2=null, postalCode=null} = location || {};
          const stateWithZipcode = compact([state, postalCode]).join(' ');
          handleChange({
            ...updatedValues,
            location: compact([address1, address2, city, stateWithZipcode, country]).join(', ')
          });
        } else {
          handleChange(updatedValues);
        }
      } else {
        handleChange(updatedValues);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const {values, formValidated, handleChange, handleSubmit} = useForm(defaultValue, formSubmit);
  const {notifyTargetIdsField} = useNotifyUserIds({lookups, handleChange, entity: values});

  useEffect(() => {
    setEndsAtChoices(timelineLabels(values.startsAt || '12:00 AM'));
  }, [values.startsAt]);

  return (
    <AtsModal
      id='edit-modal'
      modalTitle={`${values.appointmentId ? 'Reschedule' : 'Add'} ${utils.camelize(values.appointmentType || '')}`}
      modalSize='lg'
      modalVisible={modalVisible}
      modalCancel={modalCancel}
    >
      <form name='appointmentForm' noValidate onSubmit={(e) => handleSubmit(e)} autoComplete='off'>
        <div className='form-row col-12 mx-0 px-0 pt-3'>
          <div className='form-group col-6 pl-0 pr-3'>
            <label htmlFor='subject'>
              Subject
              <span className='required'>*</span>
            </label>
            <AtsInput
              id='subject'
              name='subject'
              required={true}
              validated={formValidated}
              defaultValue={values.subject || ''}
              callback={handleChange}
            />
          </div>
          <div className='form-group col-6 pl-3 pr-0'>
            <label htmlFor='activityTypeId'>
              Activity Type
              <span className='required'>*</span>
            </label>
            <AtsSelect
              id='activityTypeId'
              name='activityTypeId'
              isClearable={false}
              required={true}
              validated={formValidated}
              defaultValue={values.activityTypeId || ''}
              options={activityTypeOptions}
              callback={handleChange}
            />
          </div>
        </div>

        <div className='form-row col-12 mx-0 px-0 pt-3 d-flex'>
          <div className='form-group col-3 pl-0 pr-3'>
            <label htmlFor='date'>
              Date
              <span className='required'>*</span>
            </label>
            <div className='ats-date-picker'>
              <DatePicker
                id='date'
                name='date'
                className='form-control'
                dateFormat='MMMM d, yyyy'
                required={true}
                dateOnly={false}
                selected={values.date ? new Date(values.date) : null}
                minDate={values.date && (new Date(values.date)) < (new Date()) ? new Date(values.date) : new Date() }
                onChange={date => handleChange({'date': date})}
              />
              <label className='calendar-icon from' htmlFor='date'/>
            </div>
          </div>
          <div className='form-group col-3 px-3'>
            <label htmlFor='timeZone'>
              Timezone
              <span className='required'>*</span>
            </label>
            <AtsSelect
              id='timezone'
              name='timezone'
              required={true}
              isClearable={false}
              validated={formValidated}
              defaultValue={values.timezone || ''}
              options={timezoneList()}
              callback={handleChange}
            />
          </div>


          <div className='form-group col-3 px-3'>
            <label htmlFor='startsAt'>
              Time
              <span className='required'>*</span>
            </label>
            <AtsSelect
              id='startsAt'
              name='startsAt'
              required={true}
              isClearable={false}
              className='d-inline-block'
              validated={formValidated}
              defaultValue={values.startsAt || ''}
              options={startsAtChoices}
              callback={updateCallback}
            />
          </div>
          <div className='align-self-center'style={{'marginTop': '10px'}}>to</div>
          <div className='form-group flex-grow-1 pl-3 pr-0'>
            <label htmlFor='endsAt'>
              &nbsp;
              <span className='required'></span>
            </label>
            <AtsSelect
              id='endsAt'
              name='endsAt'
              required={true}
              isClearable={false}
              validated={formValidated}
              defaultValue={values.endsAt || ''}
              options={endsAtChoices || []}
              callback={handleChange}
            />
          </div>
        </div>

        <div className='form-row col-12 mx-0 px-0 pt-3'>
          {/*meeting with candidate, interview */}
          {['CANDIDATE', 'SUBMISSION'].includes(get(values, 'entity.type')) ?
            <div className='form-group col-6 pl-0 pr-3'>
              <div class='label-wrapper'>
                <label class='label-text'>
                  Candidate Attendee
                  <span className='required'></span>
                </label>
                {
                  !defaultValue.sendInviteToCandidates || !defaultValue.appointmentId ?
                    <AtsCheckBox
                      id='sendInviteToCandidates'
                      name='sendInviteToCandidates'
                      defaultValue={values.sendInviteToCandidates? ['CHECKED']:[]}
                      options={[{label: <div className='send-invite-label'>Send Invite</div>, value: 'CHECKED'}]}
                      handleChange={updateCallback }
                    />
                    : null
                }
              </div>
              <div className='black-text biotif-semi-bold'>
                {get(values, 'attendeesByEntity.candidates[0].name')}
              </div>
            </div>
            :
            null
          }

          {/*meeting with contact*/}
          {get(values, 'entity.type') === 'CONTACT' ?
            <div className='form-group col-6 pl-0 pr-3'>
              <div class='label-wrapper'>
                <label className='label-text'>
                  Contact Attendee
                  <span className='required'></span>
                </label>
                {
                  !defaultValue.sendInviteToContacts || !defaultValue.appointmentId ?
                    <AtsCheckBox
                      id='sendInviteToContacts'
                      name='sendInviteToContacts'
                      defaultValue={values.sendInviteToContacts? ['CHECKED']:[]}
                      options={[{label: <div className='send-invite-label'>Send Invite</div>, value: 'CHECKED'}]}
                      handleChange={updateCallback }
                    />
                    : null
                }
              </div>
              <div
                className='black-text biotif-semi-bold'>{get(values, 'entity.name')}
              </div>
            </div>
            :
            null
          }

          {get(values, 'entity.job.jobTitle') ? '' :
            <div className='form-group col-6 pl-3 pr-0'>
              <label>
                Owner
                <span className='required'>*</span>
              </label>
              <AtsSelect
                id='ownerUserId'
                name='ownerUserId'
                required={true}
                isClearable={false}
                validated={formValidated}
                defaultValue={values.ownerUserId || ''}
                options={lookups.users || []}
                callback={handleChange}
              />
            </div>
          }
        </div>

        {get(values, 'entity.job.jobTitle') ?
          <div className='form-row col-12 mx-0 px-0 pt-3'>
            <div className='form-group col-6 pl-0 pr-3'>
              <label>
                Related Jobs
                <span className='required'></span>
              </label>
              <div className='black-text biotif-semi-bold'>{get(values, 'entity.job.jobTitle')}</div>
            </div>
            <div className='form-group col-6 pl-3 pr-0'>
              <label>
                Owner
                <span className='required'>*</span>
              </label>
              <AtsSelect
                id='ownerUserId'
                name='ownerUserId'
                required={true}
                isClearable={false}
                validated={formValidated}
                defaultValue={values.ownerUserId || ''}
                options={lookups.users || []}
                callback={handleChange}
              />
            </div>
          </div>
          :
          ''
        }

        <div className='form-row col-12 mx-0 px-0 pt-3'>
          <div className='form-group col-6 pl-0 pr-3'>
            <div class='label-wrapper'>
              <label htmlFor='contacts' className='label-text'>
                {get(values, 'entity.type') === 'CONTACT' ? 'Other' : ''} Contact Attendees
              </label>
              {
                !defaultValue.sendInviteToContacts || !defaultValue.appointmentId ?
                  <AtsCheckBox
                    id='sendInviteToContacts'
                    name='sendInviteToContacts'
                    defaultValue={values.sendInviteToContacts? ['CHECKED']:[]}
                    options={[{label: <div className='send-invite-label'>Send Invite</div>, value: 'CHECKED'}]}
                    handleChange={updateCallback }
                  />
                  : null
              }
            </div>
            <AtsTypeAheadSearchSelect
              id='contacts'
              name='attendeesByEntity.contacts'
              entityType='CONTACT'
              clearButton={false}
              isMulti={true}
              defaultValue={
                (get(values, 'attendeesByEntity.contacts') || []).map((entity) => {
                  return ({...entity, entityType: 'CONTACT', 'entityId': entity.contactId || entity.id, contactId: entity.contactId || entity.id, fullName: entity.name });
                })
              }
              callback={updateCallback}
            />
          </div>

          <div className='form-group col-6 pl-3 pr-0'>
            <label htmlFor='recruiterUserIds'>
              Other Recruiter Attendees
            </label>
            <AtsSelect
              id='userIds'
              name='attendeesByEntity.userIds'
              isClearable={false}
              isMulti={true}
              placeholder='Recruiter Attendees'
              defaultValue={get(values, 'attendeesByEntity.userIds') || []}
              options={lookups.users || []}
              validated={formValidated}
              callback={handleChange}
            />
          </div>
        </div>

        <div className='form-row col-12 mx-0 px-0 pt-3'>
          <div className='form-group col-6 pl-0 pr-3'>
            <label htmlFor='communicationMethod'>
              Appt Method
              <span className='required'>*</span>
            </label>
            <AtsSelect
              id='communicationMethod'
              name='communicationMethod'
              isClearable={false}
              required={true}
              defaultValue={values.communicationMethod}
              options={appLookups.appointmentCommunicationMethods || []}
              validated={formValidated}
              callback={updateCallback}
            />
          </div>
          <div className='form-group col-6 pl-3 pr-0'>
            <label htmlFor='onLineMeetingUrl'>
              Meeting URL (if any)
              <span className='required' style={{'visibility': 'hidden'}}>*</span>
            </label>
            <AtsInput
              id='onlineMeetingUrl'
              name='onlineMeetingUrl'
              validated={formValidated}
              defaultValue={values.onlineMeetingUrl || ''}
              callback={handleChange}
            />
          </div>
        </div>

        <div className='form-row col-12 mx-0 px-0 pt-3'>
          <div className='form-group col-12 px-0'>
            <label htmlFor='location'>
              Location
            </label>
            <AtsInput
              id='location'
              name='location'
              validated={formValidated}
              defaultValue={values.location || ''}
              callback={handleChange}
            />
          </div>
        </div>

        <div className='form-row col-12 mx-0 px-0 pt-3'>
          <div className='form-group col-12'>
            <AtsRichTextArea
              label={<label>Description</label>}
              id='appointment-body'
              name='body'
              required={false}
              entityType={get(values, 'entity.type')}
              initialValue={values.body || ''}
              handleChange={handleChange}
            />
          </div>
        </div>

        <FormFields
          fields={notifyTargetIdsField}
          formValidated={formValidated}
          callback={handleChange}
        />

        <div className='form-row col-12 mx-0 px-0 pt-3'>
          <div className='form-group col-12 d-flex justify-content-end pt-3'>
            <AtsCheckBox
              name='noteCreation'
              defaultValue={noteCreation?['CHECKED']:[]}
              options={[{label: 'Add Note', value: 'CHECKED'}]}
              handleChange={() => setNoteCreation(!noteCreation)} />
            <Button type='button' id='cancel-appointment' variant='btn-secondary mr-4' click={modalCancel}>Cancel</Button>
            <Button type="submit" id='save-appointment' variant='btn-primary ats-md-btn'>Save</Button>
          </div>
        </div>
      </form>
    </AtsModal>
  );
}
