import {Spinner} from '../../compoments/common';
import ResetPassword from './ResetPassword';
import ResetSentInfo from './ResetSentInfo';
import SetPasswordSuccess from './SetPasswordSuccess';
import SSOLogin from './SSOLogin';
import EmailLogin from './EmailLogin';
import React from 'react';
import TokenService from '../../services/TokenService';

export default ({type, isAuthorize, isLoading, handleCallback, switchType, checkEmailLoginPath, forgotPassword, onlySSO}) => {
  const loginWrapperObj = document.querySelector('.login-wrapper');

  if (isAuthorize || isLoading || TokenService.accessToken) {
    return (
      <div className='pl-0 item pr-4'>
        <div className='pt-3 pr-5'>
          <Spinner variant='fa-4x' />
        </div>
      </div>
    );
  }

  if (type === 'resetPassword') {
    return <ResetPassword handleCallback={handleCallback} isLoading={isLoading} switchType={switchType} />;
  }

  if (type === 'sentReset') {
    return <ResetSentInfo switchType={switchType} />;
  }

  if (type === 'setPasswordSuccess') {
    return <SetPasswordSuccess switchType={switchType} />;
  }

  if (type === 'sso') {
    if (loginWrapperObj) {
      loginWrapperObj.style.paddingTop = 'calc(((100vh - 284px - 56px) / 2))';
    }
    return <SSOLogin checkEmailLoginPath={checkEmailLoginPath} isLoading={isLoading} switchType={switchType} onlySSO={onlySSO} />;
  }

  if (type === 'email') {
    if (loginWrapperObj) {
      loginWrapperObj.style.paddingTop = 'calc(((100vh - 284px - 125px) / 2))';
    }
    return <EmailLogin handleCallback={handleCallback} forgotPassword={forgotPassword} isLoading={isLoading} switchType={switchType} />;
  }

  return (
    <div className='pl-0 item pr-4'>
      <div className='pt-3 pr-5'>
        <Spinner variant='fa-4x' />
      </div>
    </div>
  );
};
