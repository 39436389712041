import React, {useEffect, useState} from 'react';
import tinymce from 'tinymce/tinymce';
import AtsEditor from '../AtsEditor';
import AtsPersonalize from '../AtsPersonalize';
import useModal from '../../../hooks/useModal';
import SelectModal from '../../Template/SelectModal';
import EditModal from '../../Template/EditModal';

import {allEntityTypes} from '../../../constants/templateConst';

import './style.scss';

export default function AtsRichTextArea({entityType, initialValue, handleChange, name='content', label='Comment', id='ats-rich-text-editor', required=true, category='STANDARD', personalizeVariables=[], personalize=false}){
  const {modalVisible, toggleModal} = useModal();
  const [modalId, setModalId] = useState('select-template-modal');

  const useTemplate = (template) => {
    handleChange({[name]: template.content});
    const editor = tinymce.get(id);
    editor.setContent(template.content);
    toggleModal();
  };

  const openTemplateSelectModal = () => {
    toggleModal();
    window.amplitudeLogEvent('templates', { action: 'initiate', timer : 'start' });
  };

  const addTemplateCallback = () => {
    setModalId('add-template-modal');
  };

  const selectTemplateCallback = () => {
    setModalId('select-template-modal');
  };

  const saveContent = (newTemplate) => {
    console.log('newTemplate:', newTemplate);
  };

  const showModal = () => {
    if (!modalVisible) {
      return;
    }
    switch (modalId) {
    case 'add-template-modal':
      const defaultValue = {
        name: '',
        status: 'Active',
        isPublic: false,
        entityTypes: allEntityTypes,
        objectTypes: ['ALL'],
        category: category,
        content: ''
      };
      return <EditModal saveContent={saveContent} contentType={'Templates'} modalVisible={modalVisible} modalCancel={toggleModal} actionType={'Add'} selectTemplateCallback={selectTemplateCallback} defaultValue={defaultValue} personalizeVariables={personalizeVariables} />;
    case 'select-template-modal':
    default:
      return <SelectModal modalVisible={modalVisible} modalCancel={toggleModal} useTemplate={useTemplate} entityType={entityType} category={category} addTemplateCallback={addTemplateCallback}/>;
    }
  };

  useEffect(() => {
    if (modalVisible && modalId === 'select-template-modal') {
      document.getElementById('select-template-modal').parentElement.style.backgroundColor = 'rgba(0,0,0,0.7)';
    }
  }, [modalVisible, modalId]);

  return (
    <>
      <div className='ats-rich-text-label'>
        {label}
        {personalize ? <AtsPersonalize id={id} personalizeVariables={personalizeVariables} /> : ''}
        <div className='select-template-icon' onClick={openTemplateSelectModal}>Templates</div>
      </div>
      <AtsEditor
        id={id}
        name={name}
        initialValue={initialValue || ''}
        required={required}
        personalizeVariables={personalizeVariables}
        personalize={personalize}
        callback={handleChange}
        canMention={!personalize}
      />
      {showModal()}
    </>
  );
}
