import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';

import RecruiterInitialName from './RecruiterInitialName';
import Location from './Location';
import UpdatedCreatedInfo from './UpdatedCreatedInfo';
import LinkToNewTabForEntity from './LinkToNewTabForEntity';
import { Metadata, BadgeList } from '../../../common';
import { has, get } from '../../../../libs/lodash';
import utils from '../../../../libs/utils';

export default function Job({entity, isActive, lookups, openNewTab, isReport=false}) {
  const billRate = get(entity, 'rateInfo.billRate');
  const baseSalary = get(entity, 'rateInfo.baseSalary');
  const currencyType = has(entity, 'rateInfo.currencyType');
  const rateType = has(entity, 'rateInfo.rateType');

  return (
    <>
      {isReport ? '' :
        <>
          {isActive ?
            <div className='active-item d-flex align-items-center'>
              {utils.contentForRender(entity.jobTitle, (entity.badges || []).length === 0 ? 25 : 20)}
              <BadgeList badges={entity.badges || []} width='11' height='13' />
              <RecruiterInitialName recruiter={entity.primaryRecruiter} />
            </div>
            :
            <div className='d-flex align-items-center'>
              {openNewTab ?
                <LinkToNewTabForEntity entity={entity}/>
                :
                <Link to='#'
                  className='biotif-semi-bold font-size-16 d-flex'>{utils.contentForRender(entity.jobTitle, (entity.badges || []).length === 0 ? 25 : 20)}</Link>
              }
              <BadgeList badges={entity.badges || []} width='11' height='13' />
              <RecruiterInitialName recruiter={entity.primaryRecruiter} />
            </div>
          }
        </>
      }

      {isReport ? '' :
        <div className='pt-1'>
          JOB{entity.jobId}
        </div>
      }
      <div className={`pt-1 text-wrap${entity.company ? '' : ' '}`}>
        {entity.company ? utils.contentForRender(entity.company.name, 30) : ''}
      </div>
      <div className='pt-1'>
        {`${(entity.numOpenPositions || 0) < 2 ? '' : entity.numOpenPositions} ${utils.convertIdToName(lookups, 'jobTypes', entity.jobType)}`}
        {billRate && currencyType ? ' at ' + utils.dollarForRender(entity.rateInfo.billRate, entity.rateInfo.currencyType, 'compact') : ''}
        {baseSalary && currencyType ? ' at ' + utils.dollarForRender(entity.rateInfo.baseSalary, entity.rateInfo.currencyType, 'compact') : ''}
        {billRate && rateType ? '/' + utils.convertIdToName(lookups, 'jobRateTypes', entity.rateInfo.rateType).toLowerCase() : ''}
      </div>

      <Location entity={entity} />

      <Metadata entityType='job' metadata={entity.metadata || {}} isReport={isReport} />

      <UpdatedCreatedInfo entityType='job' createdAt={entity.createdAt} updatedAt={entity.updatedAt} isReport={isReport} />
    </>
  );
};

Job.propTypes = {
  entity: PropTypes.object.isRequired,
  isActive: PropTypes.bool
};
