import React from 'react';

export default ({ isDragReject, rejectedFiles, maxSize }) => {
  return <>
    {
      isDragReject && (<div className="text-danger mt-6">File type not accepted, sorry!</div>)
    }

    {
      rejectedFiles.length > 0 && rejectedFiles[0].size > maxSize &&
      <div className="text-danger mt-2">
        File is too large.
      </div>
    }
  </>;
};
