import './style.scss';
import React from 'react';
import PropTypes, { object } from 'prop-types';
import DataTable from 'react-data-table-component';

export default function AtsExpandableTable({columns, dataTable, customExpandedComponent, pagination = false}) {
  const defaultExpandedComponent = ({ rowData }) => {
    return (
      <div className='expanded__container'>
        <p className='expanded__text'>{JSON.stringify(rowData, null, 2)}</p>
      </div>
    );
  };

  return (
    <DataTable columns={columns} data={dataTable} expandableRows expandableRowsComponent={customExpandedComponent || defaultExpandedComponent} pagination={pagination}/>
  );
};

AtsExpandableTable.propTypes = {
  columns: PropTypes.array.isRequired,
  dataTable: PropTypes.arrayOf(object).isRequired,
  customExpandedComponent: PropTypes.func
};
