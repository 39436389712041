import {cloneDeep, has, pick} from '../../../libs/lodash';
import utils from '../../../libs/utils';

const prepareForRenderNoteActivity = (data) => {
  return {
    entityType: data.entity.entityType,
    entity: {
      content: data.content,
      createdAt: data.createdAt,
      noteId: data.noteId,
      createdBy: data.createdBy
    },
    activityType: data.activityType,
    activityTypeFilter: data.activityType.category,
  };
};

const prepareDefaultNote = (data) => {
  const tempNote = {
    content: data.content || ''
  };
  if (data.entity) {
    tempNote.entity = data.entity;
  } else {
    tempNote.entity = {};
  }
  if (data.activityTypeId) {
    tempNote.activityTypeId = data.activityTypeId;
  }
  if (data.notifyTargetIds) {
    tempNote.notifyTargetIds = data.notifyTargetIds;
  }
  return tempNote;
};

const prepareForApiNote = (data) => {
  const apiData = pick(cloneDeep(data), [
    'activityTypeId',
  ]);

  apiData.entity = pick(data.entity, [
    'entityId',
    'entityType',
  ]);

  if (has(data, 'content')) {
    apiData.content = encodeURIComponent(data.content);
  }

  if (has(data, 'notifyTargetIds') && Array.isArray(data.notifyTargetIds) && data.notifyTargetIds.length>0) {
    utils.splitIdsForApi(data.notifyTargetIds, apiData);
  }

  return apiData;
};

export {
  prepareForRenderNoteActivity,
  prepareDefaultNote,
  prepareForApiNote,
};
