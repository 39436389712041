import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import maxMinConst from '../../constants/maxMinConst';
import {isEmpty} from '../../libs/lodash';

export default function AtsInput(props) {
  const [value, setValue] = useState('');

  useEffect(() => {
    setValue(props.defaultValue || '');
  }, [props.defaultValue]);

  const handleChange = event => {
    if (props.type === 'email') {
      const value = event.target.value;
      setValue(value ? value.trim() : value);
      emailPatternValidate(value ? value.trim() : value);

      try {
        if (props.name === 'additionalInfo.primaryEmail') {
          if (!(isEmpty(props.defaultValue) && isEmpty(value)) && props.defaultValue !== value) {
            const invalidEmail = document.querySelector('input#primaryEmail.invalid-email');
            if (invalidEmail) {
              invalidEmail.classList.remove('invalid-email');
              invalidEmail.classList.add('invalid-email-orig');
            }
          } else {
            const invalidEmailOrg = document.querySelector('input#primaryEmail.invalid-email-orig');
            if (invalidEmailOrg) {
              invalidEmailOrg.classList.remove('invalid-email-orig');
              invalidEmailOrg.classList.add('invalid-email');
            }
          }
        }
      } catch (err) {
        console.log(err);
      }
    } else {
      setValue(event.target.value);
    }
  };

  const handleKeyPress = (e, value) => {
    if (e.key === 'Enter') {
      if (props.type === 'email') {
        setValue(value ? value.trim() : value);
        props.callback({[props.name]: value ? value.trim() : null});
      } else {
        setValue(value);
        props.callback({[props.name]: value ? value : null});
      }
    }
  };

  function emailPatternValidate(value) {
    const emailInput = document.getElementById(props.id);
    if (props.pattern && value) {
      const re = new RegExp(props.pattern);
      const result =  re.test(String(value).toLowerCase());
      if (!result) {
        emailInput.classList.add('is-invalid');
      } else {
        emailInput.classList.remove('is-invalid');
      }
    } else {
      emailInput.classList.remove('is-invalid');
    }
  }

  return (
    <input type={props.type || 'text'}
      id={props.id}
      placeholder={props.placeholder}
      name={props.name}
      required={props.required}
      disabled={props.disabled}
      pattern={props.pattern}
      maxLength={props.maxLength ? props.maxLength : maxMinConst.ShortTextMaxlength}
      value={value}
      onBlur={() => {
        props.callback({[props.name]: value ? value : null});
      }}
      onKeyDown={(e) => handleKeyPress(e, value)}
      onChange={handleChange}
      className={`form-control ${props.classname}`}
      autoComplete='off' />
  );
};

AtsInput.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  callback: PropTypes.func.isRequired,
  placeholder: PropTypes.string
};

