const groupByOptions = [
  // {id: 'rateInfo.billRate', name: 'Bill Rate'},
  {id: 'businessUnit.id', name: 'Business Unit',  valueAttr: 'businessUnit.name'},
  {id: 'candidate.candidateId', name: 'Candidate', valueAttr: 'candidate.candidateName', entityTypeAttr: 'CAN'},
  {id: 'candidate.source', name: 'Candidate Source', lookupsKey: 'candidateSources'},
  // {id: 'rateInfo.grossProfit', name: 'Gross Profit'},
  {id: 'job.jobId', name: 'Job', valueAttr: 'job.jobTitle', entityTypeAttr: 'JOB'},
  {id: 'job.company.companyId', name:'Job Company', valueAttr: 'job.company.name', entityTypeAttr: 'CMP'},
  {id: 'job.company.companyType', name: 'Job Company Type'},
  {id: 'job.primaryRecruiter.userId', name: 'Job Primary Recruiter', valueAttr: 'job.primaryRecruiter'},
  {id: 'job.owner.userId', name: 'Job Owner', valueAttr: 'job.owner'},
  // {id: 'rateInfo.markup', name: 'Markup'},
  // {id: 'rateInfo.payRate', name: 'Pay Rate'},
  // {id: 'rateInfo.permRate', name: 'Perm Rate'},
  {id: 'rateInfo.rateType', name: 'Rate Type', lookupsKey: 'jobRateTypes'},
  {id: 'stage', name: 'Stage', lookupsKey: 'submissionStages'},
  {id: 'status', name: 'Status', lookupsKey: 'submissionStatuses'},
  {id: 'rejectedReason', name: 'Rejected Reason', lookupsKey: 'submissionRejectedReasons'},
  {id: 'owner.userId', name: 'Submission Owner', valueAttr: 'owner'},
  // {id: 'rateInfo.totalCompensation', name: 'Total Compensation'}
];

export default groupByOptions;
