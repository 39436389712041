import './style.scss';
import { AtsInput, Button } from '../../compoments/common';
import React from 'react';
import patternConst from '../../constants/patternConst';

export default ({handleCallback, isLoading, switchType}) => {
  return (
    <div className='reset-password'>
      <div className='form-group'>
        <div className='label'>Please enter the email address for your account:</div>
      </div>

      <div className='form-group'>
        <AtsInput id='email' name='email' placeholder="Email" required callback={handleCallback} type={'email'} pattern={patternConst.EmailPattern} />
      </div>

      <div className='form-group'>
        <div className='info'>If we recognize it, we'll send a reset email to this address</div>
      </div>

      <Button type='submit' id='reset-password-button' variant='my-4 w-100' disabled={isLoading}>
        Continue <span className='caret-right' >&#8250;</span>
      </Button>

      <div className='d-block switch-sso'>
        <span id='switch-sso-link' className='cursor' onClick={() => switchType('email')}>Back to Login</span>
      </div>
    </div>
  );
};
