import React, {useEffect, useState} from 'react';
import CreatableInputOnly from '../../common/AtsSelect/CreatableInputOnly';
import AtsSelect from '../../common/AtsSelect';
import FormFields from '../../common/FormFields';
import JobFunctionsSkillsSelect from './JobFunctionsSkillsSelect';
import { filter } from '../../../libs/lodash';
import utils from '../../../libs/utils';
import layoutConst from '../../../constants/layoutConst';

function CategoriesEdit({object={}, namePrefix='', formValidated, callback, lookups, required={}, businessUnitId}) {
  const fullColCss = layoutConst.fullColCss;
  const [jobFunctionList, setJobFunctionList] = useState([]);
  const [skillList, setSkillList] = useState([]);

  useEffect(() => {
    if(lookups.jobFunctions) {
      setJobFunctionList((lookups.jobFunctions || []).filter((jf) => jf.isActive || (!jf.isActive && (object.jobFunctionIds || []).includes(jf.id))));
    }

    if (lookups.skills) {
      setSkillList((lookups.skills || []).filter((skill) => skill.isActive || (!skill.isActive && (object.skillIds || []).includes(skill.id))));
    }
  }, [lookups.jobFunctions, lookups.skills, object.jobFunctionIds, object.skillIds]);

  function filterByDepts(deptIds, origList) {
    return filter(origList, function(item) {
      return (deptIds || []).includes(item.department.id);
    });
  }

  function jobFunctionSkillIdsByDepts(deptIds) {
    const jobFunctionIdsFilterByDepts = filterByDepts(deptIds, jobFunctionList).map((jobFunction) => jobFunction.id);
    const skillIdsFilterByDepts = filterByDepts(deptIds, skillList).map((skill) => skill.id);

    const jobFunctionIds = filter(object.jobFunctionIds, function(jobFunctionId) {
      return jobFunctionIdsFilterByDepts.includes(jobFunctionId);
    });

    const skillIds = filter(object.skillIds, function(skillId) {
      return skillIdsFilterByDepts.includes(skillId);
    });

    return {
      [namePrefix + 'jobFunctionIds']: jobFunctionIds,
      [namePrefix + 'skillIds']: skillIds
    };
  }

  const departmentsCallback = (departments) => {
    callback({...departments, ...jobFunctionSkillIdsByDepts(departments[namePrefix + 'departmentIds'])});
  };

  const categoryFields = [
    [
      { label: 'Industries',
        className: fullColCss,
        component: AtsSelect,
        props: {
          id: 'industryIds',
          name:  namePrefix + 'industryIds',
          isMulti: true,
          options: (lookups.industries || []).filter((ind) => ind.isActive || (!ind.isActive && (object.industryIds || []).includes(ind.id))),
          defaultValue: object.industryIds || [],
          required: false,
          placeholder: 'Type to search'
        }
      }
    ],
    [
      { label: 'Departments',
        className: fullColCss,
        component: AtsSelect,
        props: {
          id: 'departmentIds',
          name: namePrefix + 'departmentIds',
          isMulti: true,
          defaultValue: object.departmentIds || [],
          options: (lookups.departments || []).filter((dept) => dept.isActive || (!dept.isActive && (object.departmentIds || []).includes(dept.id))),
          required: false,
          callback: departmentsCallback,
          placeholder: 'Type to search'
        }
      }
    ],
    [
      { label: 'Job Functions',
        className: fullColCss,
        component: JobFunctionsSkillsSelect,
        props: {
          id: 'jobFunctionIds',
          name: namePrefix + 'jobFunctionIds',
          isMulti: true,
          required: false,
          placeholder: 'Type to search',
          departmentIds: object.departmentIds || [],
          defaultValue: object.jobFunctionIds || [],
          options: jobFunctionList || []
        }
      }
    ],
    [
      { label: 'Skills',
        className: fullColCss,
        component: JobFunctionsSkillsSelect,
        props: {
          id: 'skillIds',
          name: namePrefix + 'skillIds',
          isMulti: true,
          required: false,
          placeholder: 'Type to search',
          departmentIds: object.departmentIds || [],
          defaultValue: object.skillIds || [],
          options: skillList || []
        }
      }
    ],
    [
      {
        label: 'Highlighted Skills',
        className: fullColCss,
        component: CreatableInputOnly,
        props: {
          id: 'highlightedSkills',
          name: namePrefix + 'highlightedSkills',
          placeholder: 'Type to add',
          defaultValue: object.highlightedSkills || [],
          required: false,
          options: []
        }
      }
    ]
  ];

  const PHCFields = [
    [
      { label: 'Facility Types',
        className: fullColCss,
        component: AtsSelect,
        props: {
          id: 'phcFacilityTypeIds',
          name:  namePrefix + 'phcFacilityTypeIds',
          isMulti: true,
          defaultValue: object.phcFacilityTypeIds || [],
          options: lookups.phcFacilityTypes || [],
          required: false,
          placeholder: 'Type to search'
        }
      }
    ],
    [
      { label: 'Career Codes',
        className: fullColCss,
        component: AtsSelect,
        props: {
          id: 'phcCareerCodeIds',
          name: namePrefix + 'phcCareerCodeIds',
          isMulti: true,
          defaultValue: object.phcCareerCodeIds || [],
          options: lookups.phcCareerCodes || [],
          required: false,
          callback: departmentsCallback,
          placeholder: 'Type to search'
        }
      }
    ],
    [
      {
        label: 'Highlighted Skills',
        className: fullColCss,
        component: CreatableInputOnly,
        props: {
          id: 'highlightedSkills',
          name: namePrefix + 'highlightedSkills',
          placeholder: 'Type to add',
          defaultValue: object.highlightedSkills || [],
          required: false,
          options: []
        }
      }
    ]
  ];

  return (
    <>
      {
        businessUnitId && utils.checkBusinessUnitById(businessUnitId, lookups) ?
          <FormFields
            fields={PHCFields}
            formValidated={formValidated}
            callback={callback}
            isMulti
          />
          :
          <FormFields
            fields={categoryFields}
            formValidated={formValidated}
            callback={callback}
            isMulti
          />
      }
    </>
  );
}

export default CategoriesEdit;
