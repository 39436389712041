import layoutConst from '../../../constants/layoutConst';
import {Link} from 'react-router-dom';
import utils from '../../../libs/utils';
import React from 'react';
import useFile from '../../../hooks/useFile';
import attachedDocIcon from '../../../assets/img/attached-doc.png';

export default ({name, entityType, dropType, readOnly, files, removeFile}) => {
  const {isImage, canPreview, downloadFile} = useFile();

  const download = (file) => {
    const activeTabName = utils.activeTabName();

    if(name === 'resume') {
      window.amplitudeLogEvent(
        'view',
        {
          action: 'download resume',
          object: (entityType || '').toLowerCase(),
          detailsTab: activeTabName
        }
      );
    } else if(name === 'supplementalDocuments') {
      window.amplitudeLogEvent(
        'view',
        {
          action: 'download supplemental document',
          object: (entityType || '').toLowerCase(),
          detailsTab: activeTabName
        }
      );
    }

    downloadFile(file.attachmentId);
  };

  if (dropType === 'image') {
    const baseImgPath = window.REACT_APP_API_BASE_PATH + '/core/images/';
    return files.map((file, index) => (
      <div className='row file-row' key={file.imageId} id={`file-${index}`}>
        <div className='col-1'><img key={`${file.imageId}`} src={`${baseImgPath}${file.imageId}/file`} alt={`${file.imageId}`} title={file.name} width='auto' height='26'/></div>
        <div className='col-8 file-name'>{file.fileName}</div>
        <div className='col-2'>
          <span className='d-inline-block pull-right'>
          </span>
        </div>

        <div className='col-1'>
          <span className='d-inline-block pull-right cursor circle-delete' onClick={() => removeFile(index)} />
        </div>
      </div>
    ));
  }
  return files.map((file, index) => (
    <div className={readOnly ? layoutConst.twoColWiderScreenCss : ''} key={`docs-${index}`}>
      <div className='d-table file-row align-items-center w-100 font-size-16' key={file.attachmentId} id={`file-${file.attachmentId}`}>
        <div className='d-table-row align-items-center'>
          <div className='d-table-cell align-middle' style={{'width' : '40px'}}>
            <img src={attachedDocIcon} alt='attached doc icon' width='20' height='26' />
          </div>
          <div className='d-table-cell text-wrap align-middle'>
            {canPreview(file) ?
              <Link to={`/file/${file.attachmentId}?image=${isImage(file.fileName)}`} target='_blank' className='biotif-semi-bold'>
                {file.fileName}
              </Link>
              :
              <span className='file-name'>{file.fileName}</span>
            }
            <span className='light-grey-text pl-3 text-nowrap'>{utils.dateForRender(file.createdAt, 'MM / DD / YYYY')}</span>
          </div>
          <div className='d-table-cell text-right align-middle' style={{'width' : '80px', 'paddingTop': '5px'}}>
            {readOnly ? '' : <span className='d-inline-block cursor circle-delete pr-3 align-middle' onClick={() => removeFile(index)} /> }
            {dropType !== 'mass-import' ? <span className='d-inline-block cursor sm-download-link align-middle' onClick={() => download(file)}/> : '' }
          </div>
        </div>
      </div>
    </div>
  ));
};
