import React from 'react';
import {Link} from 'react-router-dom';
import utils from '../../../libs/utils';
import {get, isEmpty} from '../../../libs/lodash';
import { BadgeList } from '../../common';
import PayInfo from './PayInfo';
import AtsTooltip from '../../common/AtsTooltip';

export default function Submission({submission, lookups, entityType}) {
  const {firstName, lastName} = (submission.candidate || {}).candidateName || {};
  const {jobTitle = ''} = submission.job || {};
  const {name = ''} = (submission.job || {}).company || {};

  function updatedInitials() {
    return (
      <div className='pt-2 light-grey-text font-size-12 align-text-bottom'>
        Updated {utils.dateDiff(submission.updatedAt, (new Date()).toISOString())} ago
        <span className='float-right'>
          {utils.initialName(submission.owner)}
        </span>
      </div>
    );
  }

  function submissionActivityInfo() {
    if (submission.status === 'REJECTED') {
      return (
        <div>
          <div className='red-text'>
            { utils.camelize(get(submission, 'status', '')) }
          </div>
          <div className='dark-grey-text biotif-bold' style={{whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden'}}>
            { utils.convertIdToName(lookups, 'submissionRejectedReasons', get(submission, 'rejectedReason', '')) }
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <div className='dark-grey-text' style={{whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden'}}>
            { utils.camelize(get(submission, 'latestActivity.activityTypeName', '')) }
          </div>
        </div>
      );
    }
  }

  function renderSubmissionForJob() {
    return (
      <>
        <AtsTooltip
          targetQuery={`#sub-candidate-${submission.submissionId}`}
          placement='top'
          tips={`${firstName} ${lastName}`}
          checkSpace={true}
          checkWidth={true}
        >
          <div className='candidateName' id={`sub-candidate-${submission.submissionId}`}>
            <Link to='#' className='biotif-semi-bold'>
              {firstName} {lastName}
            </Link>
          </div>
        </AtsTooltip>

        <div className='dark-grey-text'>
          {PayInfo({submission, lookups})}
          &nbsp;
        </div>

        {submissionActivityInfo()}

        {
          !isEmpty(get(submission, 'candidate.badges')) ?
            <div className='pt-3 text-right'>
              <BadgeList badges={get(submission, 'candidate.badges')} width='26' />
            </div>
            : ''
        }

        {updatedInitials()}
      </>
    );
  }

  function renderSubmissionForCandidate() {
    return (
      <>
        <div className='jobTitleCompanyName'>
          <AtsTooltip
            targetQuery={`#sub-jobTitle-${submission.submissionId}`}
            placement='top'
            tips={jobTitle}
            checkSpace={true}
            checkWidth={true}
          >
            <div className='jobTitle' id={`sub-jobTitle-${submission.submissionId}`}>
              <Link to='#' className='biotif-semi-bold'>
                {jobTitle}
              </Link>
            </div>
          </AtsTooltip>
          <AtsTooltip
            targetQuery={`#sub-company-${submission.submissionId}`}
            placement='top'
            tips={name}
            checkSpace={true}
            checkWidth={true}
          >
            <div className='dark-grey-text companyName' id={`sub-company-${submission.submissionId}`}>
              {name}
              &nbsp;
            </div>
          </AtsTooltip>
          {submissionActivityInfo()}
        </div>

        {
          !isEmpty(get(submission, 'job.badges')) ?
            <div className='pt-3 text-right'>
              <BadgeList badges={get(submission, 'job.badges')} width='26' />
            </div>
            : ''
        }

        {updatedInitials()}
      </>
    );
  }

  function renderSubmission() {
    return (
      <>
        <AtsTooltip
          targetQuery={`#sub-candidate-${submission.submissionId}`}
          placement='top'
          tips={`${firstName} ${lastName}`}
          checkSpace={true}
          checkWidth={true}
        >
          <div className='candidateName' id={`sub-candidate-${submission.submissionId}`}>
            <Link to='#' onClick={e => e.preventDefault()} className='biotif-semi-bold'>
              <span className={submission.status === 'REJECTED' ? 'red-text' : ''}>
                {firstName} {lastName}
              </span>
            </Link>
          </div>
        </AtsTooltip>

        <div className='jobTitleCompanyName'>
          <AtsTooltip
            targetQuery={`#sub-jobTitle-${submission.submissionId}`}
            placement='top'
            tips={jobTitle}
            checkSpace={true}
            checkWidth={true}
          >
            <div className='jobTitle dark-grey-text' id={`sub-jobTitle-${submission.submissionId}`}>
              {jobTitle}
            </div>
          </AtsTooltip>
          <AtsTooltip
            targetQuery={`#sub-company-${submission.submissionId}`}
            placement='top'
            tips={name}
            checkSpace={true}
            checkWidth={true}
          >
            <div className='dark-grey-text companyName' id={`sub-company-${submission.submissionId}`}>
              {name}
              &nbsp;
            </div>
          </AtsTooltip>
        </div>

        {updatedInitials()}
      </>
    );
  }

  return entityType === 'JOB' ?  renderSubmissionForJob() :  entityType === 'CANDIDATE' ? renderSubmissionForCandidate() : renderSubmission();
}
