import React from 'react';
import {Link} from 'react-router-dom';
import moment from 'moment';
import { formatPhoneNumber } from 'react-phone-number-input';
import {isEmpty, compact, keyBy, cloneDeep, has, get, omit, set, isArray, isNaN, uniq, upperCase, split, join, isObject} from './lodash';
import candidateConst from '../constants/candidateConst';
import htmlToFormattedText from 'html-to-formatted-text';
import detailsConst from '../constants/detailsConst';
import searchConst from '../constants/searchConst';
import apiUserStatus from '../services/apiUserStatus';
import AtsHighlighter from '../compoments/common/AtsHighlighter';
import AtsTooltip from '../compoments/common/AtsTooltip';
import {notifyTeamIdsList, notifyUserIdsList} from '../constants/notificationConst';
import {activityFilterCategoriesDefault} from '../constants/activityConst';

function dollarForRender(number, currency='USD', notation='standard') {
  try {
    if (number && currency && currency !== 'Unknown') {
      return new Intl.NumberFormat('en', {style: 'currency', currency: currency, notation: notation}).format(number);
    } else {
      return number;
    }
  } catch (err) {
    console.log(err);
    return number;
  }
}

function currencyIconForRender(currency='USD') {
  try {
    const temp = new Intl.NumberFormat('en', {style: 'currency', currency: currency}).format();
    return temp.split('NaN')[0];
  } catch (error) {
    return currency;
  }
}

function currencyForRender(number, notation='standard') {
  return new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 3, notation: notation }).format(number);
}

// user ats timezone date/time
function dateForRender(date, format='MM / DD / YY') {
  try {
    date = momentDate(date, false);
    if ( !date) {
      return '';
    }

    return date.format(format);

  } catch (err) {
    console.log(err);
    return '';
  }
}

function dateForApi(date, from= true) {
  try {
    const {settings = {}} = apiUserStatus.get() || {};
    const tz = settings.timeZone || Intl.DateTimeFormat().resolvedOptions().timeZone;

    date = momentDate(date, false);
    if ( !date) {
      return '';
    }
    const dateString = date.format('YYYY-MM-DD');

    if (from) {
      date = new Date(moment.tz(dateString + ' 00:00:00.000', tz));
    } else {
      date = new Date(moment.tz(dateString + ' 23:59:59.999', tz));
    }

    return date;
  } catch (err) {
    console.log(err);
    return date;
  }
}
//end user ats timezone date/time

// user pick date only, like date string
function dateOnlyForRender(date, format='MM / DD / YY') {
  try {
    date = momentDate(date, true);
    if ( !date) {
      return '';
    }

    return date.format(format);
  } catch (err) {
    console.log(err);
    return '';
  }
}

function dateOnlyForApi(date, isStart=true) {
  try {
    if ( !date) {
      return date;
    }

    const dateOffset = moment.parseZone(date).utcOffset();
    if (dateOffset) {
      const dateString = moment(date).format('YYYY-MM-DD');
      date = new Date(moment.tz(dateString, 'UTC'));
      if (!isStart) {
        date.setUTCHours(23, 59, 59, 999);
      } else {
        date.setUTCHours(0, 0, 0, 0);
      }
    } else {
      date = new Date(date);
    }
    return date;
  } catch (err) {
    console.log(err);
    return date;
  }
}
// end date only

function momentDate(date, dateOnly) {
  try {
    const {settings = {}} = apiUserStatus.get() || {};
    const tz = settings.timeZone || Intl.DateTimeFormat().resolvedOptions().timeZone;

    if ( !date) {
      return '';
    }
    const dateOffset = moment.parseZone(date).utcOffset();
    if (dateOffset) {
      return moment(date);
    } else {
      return dateOnly ? moment.utc(date) : moment.utc(date).tz(tz);
    }
  } catch (err) {
    console.log(err);
    return '';
  }
}

function originalDate(date, format='MM / DD / YY') {
  return date ? moment.utc(date).format(format) : '';
}

function timeForRender(time) {
  return dateForRender(time, 'MM/DD/YY - hh:mm A');
}

function duration(value = 0) {
  return `${parseInt(value / (60 * 60))}h:${parseInt((value / 60) % 60)}m:${value % 60}s`;
}

function getSpecificDateFromNow(daysCount) {
  const date = new Date();
  return date.setDate(date.getDate() + daysCount);
  // return moment().add(daysCount,'days');
}

function checkDueDate(dueDate) {
  return moment().isBefore(dueDate, 'day') || moment().isSame(dueDate, 'day');
}

function dateDiff(dateStart, dateEnd) {
  const start = moment.utc(dateStart).local();
  const end = moment.utc(dateEnd ? dateEnd : (new Date()).toISOString()).local();

  let roundMinutes = Math.round(moment.duration(end.diff(start)).asMinutes());
  const roundHours = Math.round(moment.duration(end.diff(start)).asHours());
  const roundDays = Math.round(moment.duration(end.diff(start)).asDays());
  const roundMonths = Math.round(moment.duration(end.diff(start)).asMonths());
  const roundYears = Math.round(moment.duration(end.diff(start)).asYears());

  if(roundMinutes < 60) {
    roundMinutes = roundMinutes < 1 ? 1 : roundMinutes;
    return roundMinutes + ' min' + (roundMinutes === 1 ? '' : 's');
  }

  if(roundHours < 24) {
    return roundHours + ' hour' + (roundHours === 1 ? '' : 's');
  }

  if(roundMonths < 1) {
    return roundDays + ' day' + (roundDays === 1 ? '' : 's');
  }

  if (roundMonths < 12) {
    return roundMonths + ' month' + (roundMonths === 1 ? '' : 's');
  } else {
    return roundYears + ' year' + (roundYears === 1 ? '' : 's');
  }
}

function initialName(name) {
  if(isEmpty(name)) {
    return '';
  }

  let fullName = compact([get(name, 'firstName'), get(name, 'lastName')]);
  if (isEmpty(fullName)) {
    fullName = get(name, 'name');
  } else {
    fullName = fullName.join(' ');
  }

  if (!fullName) {
    return '';
  }

  const tempName = fullName.split(' ');
  return tempName[0].charAt(0) + (tempName.length > 1 ? tempName[1].charAt(0) : '');
}

function contentForRender(content, size = 60, withTooltip=true) {
  if(!content) {
    return '';
  }
  const temp = content.split('\n').filter(item => {
    return item.trim() !== '';
  });
  if (temp.length>1) {
    content = temp.slice(0, 2).join('\n');
    return content.length <= size ? content+'\n...' : content.slice(0, size) + '...';
  } else {
    // return content.length <= size ? content : content.slice(0, size) + '...';
    return content.length <= size ? content :  !withTooltip ? content.slice(0, size) + '...' : <AtsTooltip placement='bottom' tips={content}><span>{content.slice(0, size) + '...'}</span></AtsTooltip>;
  }
}

function phoneForRender(phoneNum, country='United States', extNum) {
  if(country === 'United States') {
    if (phoneNum) {
      if(phoneNum.startsWith('1')) {
        phoneNum = '+' + phoneNum;
      } else if (!phoneNum.startsWith('+1')) {
        phoneNum = '+1' + phoneNum;
      }
    }
    const formattedNum = phoneNum ? formatPhoneNumber(phoneNum) : phoneNum;
    return formattedNum && extNum ? formattedNum + ' x' + extNum : formattedNum;
  } else {
    return phoneNum && extNum ? phoneNum + ' x' + extNum : phoneNum;
  }
}

function phoneHighlighter(searchTerm, phoneNumber, isUSA){
  const cleanupSearchTerm = searchTerm.replace(/[^A-Za-z0-9,.@]+/g, '');
  const formattedPhoneNumber = isUSA ? phoneForRender(phoneNumber).replace(/[^A-Za-z0-9,.@]+/g, '') : phoneNumber;
  const target1 = formattedPhoneNumber.slice(0, 3);
  const target2 = formattedPhoneNumber.slice(3, 6);
  const target3 = formattedPhoneNumber.slice(6);
  const startIndex = formattedPhoneNumber.indexOf(cleanupSearchTerm);
  const searchTerm1 = cleanupSearchTerm.slice(0, 3-startIndex);
  const searchTerm2 = cleanupSearchTerm.slice(3-startIndex, 3-startIndex+3);
  const searchTerm3 = cleanupSearchTerm.slice(3-startIndex+3);

  if (startIndex === -1) {
    return <AtsHighlighter search={searchTerm} target={isUSA ? phoneForRender(phoneNumber) : phoneNumber} isPrefix={false} />;
  } else if (isUSA){
    return (
      <>
        (<AtsHighlighter search={searchTerm1} target={target1} isPrefix={false} />) <AtsHighlighter search={searchTerm2} target={target2} isPrefix={false} />-<AtsHighlighter search={searchTerm3} target={target3} isPrefix={false} />
      </>
    );
  } else {
    return <AtsHighlighter search={searchTerm} target={phoneNumber} isPrefix={false} />;
  }
}

function getFirstMatchedElement(list, targetArr) {
  for (const el of list) {
    if (targetArr.indexOf(el)!==-1) {
      return el;
    }
  }
  return '';
}

function shortName(jsonObj, size = candidateConst.maxFullNameLength, withTooltip=false) {
  if(!jsonObj) {
    return '';
  }
  const formatContent = compact([jsonObj.firstName, jsonObj.lastName]).join(' ');
  return contentForRender(formatContent, size, withTooltip);
}

function activeTabName() {
  let activeTab = document.querySelector('#details-tab .ant-tabs .ant-tabs-nav .ant-tabs-tab-active .tabName');
  activeTab = activeTab ? activeTab : document.querySelector('.ant-tabs-tab-active');
  return  activeTab ?  activeTab.innerHTML.toLowerCase() : '';
}

function monthsToYears(months) {
  if (isNaN(months)) {
    return '';
  }
  const years = Math.round( (months / 12) * 10 ) / 10;
  return years + ' ' + (years === 1 ? 'Year' : 'Years');
}

function managementScoreToLevel(score) {
  if (isNaN(score)) {
    return '';
  }
  let level = 'Low';
  if ( score <= 29 ) {
    level = 'Low';
  } else if ( score <= 59) {
    level = 'Mid';
  } else {
    level = 'High';
  }
  return score + ' (' + level + ')';
}

function convertIdToName(lookups, key, id, maxNameLength, withTooltip) {
  if(lookups && lookups[key] && id) {
    const lookupsMap = keyBy(key === 'allUsers' ? addInactiveToUsers(lookups[key]) : lookups[key], 'id');
    return !isEmpty(lookupsMap) && lookupsMap[id] ? maxNameLength ? contentForRender(lookupsMap[id].name, maxNameLength, withTooltip) : lookupsMap[id].name : id;
  }
  return id;
}

// Convert id to upper case name and replace space to underscore
function convertIdToNameUpperCaseUnderscore(lookups, key, id) {
  if(lookups && lookups[key] && id) {
    const lookupsMap = keyBy(lookups[key], 'id');
    return !isEmpty(lookupsMap) && lookupsMap[id] ? join(split(upperCase(lookupsMap[id].name), ' '), '_') : id;
  }
  return id;
}

function convertIdsToOptionFormat(list = [], ids =[], key) {
  const listsById = keyBy(key === 'allUsers' ? addInactiveToUsers(list) : list, 'id');
  let result = [];
  ids.forEach(id => {
    result.push(listsById[id]);
  });
  return compact(result);
}

function convertListToOptionFormat(list = []) {
  return list.map(item => {
    return { value: item.id, label: item.name };
  });
}

function convertCollectionToArray(collection = [], fieldName) {
  return collection.map(item => {
    if (!isEmpty(item)) {
      return item[fieldName];
    } else {
      return item;
    }
  });
}

function convertIdToUser(lookups, key, id) {
  const obj = {};
  if(lookups && lookups[key] && id) {
    obj.userId = id;
    const matched = keyBy(lookups[key], 'id')[id];
    if (matched && matched.name) {
      const names =  matched.name.split(' ');
      obj.firstName = names[0];
      obj.lastName = names.length > 0 ? names[1] : '';
    }
  }
  return obj;
}

function addInactiveToUsers (users) {
  return (users || []).map((user) => {
    if (!user.isActive) {
      return {...user, name: `${user.name} (Inactive)`, isInactive: true};
    }
    return user;
  }).sort((a, b) => a.name.localeCompare(b.name));
}

function addInactiveToList(ids, origValues, list=[], idField='userId')  {
  const listsById = keyBy(list, 'id');

  if(isArray(ids) && isArray(origValues)) {
    const inactiveObjs = (origValues || []).filter((value) => {
      return !listsById[get(value, idField)];
    });

    return isEmpty(inactiveObjs) ? list : compact(inactiveObjs.concat(list));

  } else {
    return listsById[ids] ?  list : compact([origValues].concat(list));
  }
}

function addInactiveUsersToList(ids, origValues, list=[], allUsersList)  {
  const listsById = keyBy(list, 'id');
  const allUsersListById = keyBy(addInactiveToUsers(allUsersList), 'id');
  if(isArray(ids) && isArray(origValues)) {
    const inactiveList = [];
    (ids || []).forEach((value) => {
      !!get(listsById, value) === false && inactiveList.push(allUsersListById[value]);
    });
    return compact(inactiveList.concat(addInactiveToUsers(list)));

  } else {
    return listsById[ids] ?  addInactiveToUsers(list) : compact([allUsersListById[ids]].concat(addInactiveToUsers(list)));
  }
}

function hireIndex(additionalSearch) {
  const option = additionalSearch.criteria.option;
  switch (option) {
  case searchConst.below:
    additionalSearch.criteria.option = searchConst.above;
    additionalSearch.criteria.value = additionalSearch.criteria.value.map(v => 100 - v);
    break;
  case searchConst.above:
    additionalSearch.criteria.option = searchConst.below;
    additionalSearch.criteria.value = additionalSearch.criteria.value.map(v => 100 - v);
    break;
  default:
    const from = cloneDeep(additionalSearch.criteria.value[0]);
    additionalSearch.criteria.value[0] = 100 - additionalSearch.criteria.value[1];
    additionalSearch.criteria.value[1] = 100 - from;
  }

  return additionalSearch;
}

function cleanSearchValues(additionalSearch, api=true) {
  const {option=null} = get(additionalSearch, 'criteria') || get(additionalSearch, 'criteria.dateRange') || {};
  const valueAttr = has(additionalSearch, 'criteria.dateRange.option') ? 'criteria.dateRange.value' : 'criteria.value';
  const value = get(additionalSearch, valueAttr);

  switch (option) {
  case searchConst.with:
  case searchConst.without:
    set(additionalSearch, valueAttr, []);
    break;
  case searchConst.before:
  case searchConst.after:
  case searchConst.below:
  case searchConst.above:
  case searchConst.inTheLastCalendar:
  case searchConst.inTheCurrentCalendar:
  case searchConst.inTheNextCalendar:
    set(additionalSearch, valueAttr, !isEmpty(value) ? value.slice(0, 1) : []);
    break;
  case searchConst.inTheLast:
  case searchConst.inTheNext:
    if(isEmpty(value)) {
      set(additionalSearch, valueAttr, []);
      break;
    }

    if (api) {
      if (value[0] !== 'custom') {
        set(additionalSearch, valueAttr, value.slice(0, 1));
      } else {
        const inputDays = value && value.length === 2 ? value[1] : null;
        set(additionalSearch, valueAttr, inputDays ? [inputDays * 24 * 60] : []);
      }

      break;
    }

    if (!api) {
      const dateRangeOptionsKeyby = keyBy(searchConst.dateRangeOptions, 'id');
      if (isEmpty(dateRangeOptionsKeyby[value[0]])) {
        additionalSearch.criteria.value = ['custom', value[0] / (24 * 60)];
      }
      break;
    }
    break;
  default:
    break;
  }

  if (additionalSearch.name === 'activity') {
    ['filterTypes', 'dateRange', 'text'].forEach( attr => {
      const checkedAttr = 'criteria.' + attr + '.option';
      const option = get(additionalSearch, checkedAttr);

      if(option === 'null' ) {
        api ? additionalSearch.criteria = omit(additionalSearch.criteria, [attr]) : set(additionalSearch, 'criteria.' + attr + '.value', []);
      }
    });
  }
}

function convertListToDropDownOptionFormat(list = [], defaultValue = {}) {
  return list.reduce((obj, item) => {
    return {...obj, ...{[item.id]:item.name}};
  }, defaultValue);
}

function camelize(str) {
  if (!str) {
    return '';
  }
  if (str.includes(' ')) {
    return str.split(' ').map(txt => {
      return txt.charAt(0).toUpperCase() + txt.toLowerCase().slice(1);
    }).join(' ');
  }
  if (str.includes('_')) {
    return str.split('_').map(txt => {
      return txt.charAt(0).toUpperCase() + txt.toLowerCase().slice(1);
    }).join(' ');
  }
  return str.charAt(0).toUpperCase() + str.toLowerCase().slice(1);
}

function convertCamelCaseToTitle(str) {
  const tempStr = str.replace( /([A-Z])/g, ' $1' );
  return tempStr.charAt(0).toUpperCase() + tempStr.slice(1);
}

function titleCase(str) {
  if(typeof str !== 'string') {
    return '';
  }

  str = str.split(' ');
  const regex = /[A-Z]{5,}/g;
  for (let i = 0; i < str.length; i++) {
    if(String(str[i]).match(regex)) {
      str[i] = camelize(str[i].toLowerCase());
    }
  }
  return str.join(' ');
}

function convertHtmlToText(content) {
  return htmlToFormattedText(content);
}

function detectEditMode() {
  // disabled hotkey when elements show in the page
  const queryElems =
    'form, .edit-mode, .modal, #overlay, input.ag-input-field-input:focus, ' +
    '.submissionSearch .ats-select__control--is-focused, ' +
    '.submissionSearch .ant-input-number-input, ' +
    '.deals-search-bar-input, ' +
    '.dealSearch .owner';
  const editElms = document.querySelectorAll(queryElems);
  return editElms;
}

function detectDropZone() {
  const dropZoneElms = document.querySelectorAll('.dropzone');
  return dropZoneElms;
}

function flatObject(data, pKeyName) {
  return Object.keys(data).reduce((acc, key) => {
    if (typeof data[key] === 'string' || typeof data[key] === 'number' || ( Array.isArray(data[key]) && typeof data[key][0] !== 'object' ) ) {
      const newKeyName = pKeyName?pKeyName+' '+key:key;
      return {...acc, [newKeyName]: data[key]};
    } else if( Array.isArray(data[key]) && typeof data[key][0] === 'object' ){
      return {...acc, ...flatObject(arrayToObj(data[key], key), key)};
    } else if(data[key] && typeof data[key] === 'object'){
      const newKeyName = pKeyName?pKeyName+' '+key:key;
      return {...acc, ...flatObject(data[key], newKeyName)};
    } else {
      if (isEmpty(acc)) {
        return {[key]: data[key]};
      } else {
        return acc;
      }
    }
  }, {});
}

function arrayToObj(arrData) {
  return arrData.reduce((acc, el, index)=>{
    return {
      ...acc,
      ['['+index+']']: el
    };
  }, {});
}

function refLink(entityType, id, name, className='') {
  [entityType, id, name].forEach((value) => {
    if(!value) {
      return '';
    }
  });
  const objType = (entityType).toLowerCase();
  const defaultTab = detailsConst.defaultTab[objType || 'details'];
  return (
    <Link to={`/${objType}/${id}`} target={'_blank'}  className={className} key={`${entityType}-${id}`}
      onClick={() => window.amplitudeLogEvent('view', { action: 'view ' + objType, object: objType, globalNav: '', detailsTab: defaultTab })}>
      {name}
    </Link>
  );
}

function cleanQuickSearchInput(query) {
  // ATS-1370
  // Take the text that is entered.  Remove the following symbols:  +-()[space][period]
  // If after removing these special characters, the remaining text is a number, then send the number.  Otherwise, send the full text as is.
  try {
    const cleanQuery = (query || '').replace(/\+|-|\(|\)| |\./g, '');
    return isNaN(parseInt(cleanQuery)) ? query : cleanQuery;
  } catch (err) {
    console.log(err);
    return query;
  }
}

async function downloadFile(fileBlob, fileName) {
  const downloadEl = document.createElement('a');
  document.body.appendChild(downloadEl);
  downloadEl.style = 'display: none';

  const url = await window.URL.createObjectURL(fileBlob);
  downloadEl.href = url;
  downloadEl.download = fileName;
  downloadEl.click();
  await window.URL.revokeObjectURL(url);
  document.body.removeChild(downloadEl);
}

function mailTo(entityType, email, className='') {
  if(email) {
    return (
      <a href={`mailto:${email}`}
        className={className}
        target='_top'
        rel='noopener noreferrer'
        onClick={() => window.amplitudeLogEvent('external link', { action: 'click email', object: (entityType || '').toLowerCase()})}
      >
        {email}
      </a>
    );
  } else {
    return '';
  }
}

function wait(timeout) { return  new Promise(resolve => setTimeout(resolve, timeout)); }

function removeQuotes(string) {
  return string ? string.replace(/['"]+/g, '') : '';
}

function removeWildChar(string) {
  return string ? string.replace(/[^\w\d\s_-]/g, '') : '';
}

function parsedFileName(fileNameWithExt) {
  const lastIndexOfDot  = fileNameWithExt.lastIndexOf('.');

  let parsedFileName;

  if (lastIndexOfDot >= 0) {
    parsedFileName = fileNameWithExt.substring(0, lastIndexOfDot);
  } else {
    parsedFileName = fileNameWithExt;
  }

  return parsedFileName;
}

function splitIds(combinedIds) {
  const idsCollection = {};
  for (const combinedId of combinedIds) {
    const temp = combinedId.split('_');
    const idsName = temp[0];
    const id = +(temp[1]) ? +(temp[1]) : temp[1];
    if (isEmpty(idsCollection[idsName])) {
      idsCollection[idsName] = [id];
    } else if (idsCollection[idsName].indexOf(id)===-1) {
      idsCollection[idsName].push(id);
    }
  }
  return idsCollection;
}

function splitIdsForApi(targetIds, targetData) {
  const idsCollection = splitIds(targetIds);
  for (const idsName of Object.keys(idsCollection)) {
    targetData[idsName]=idsCollection[idsName];
  }
}

function notifyCollection(targetIds) {
  const tempTargetIds = {};
  const idsCollection = splitIds(targetIds);
  for (const idsName of Object.keys(idsCollection)) {
    tempTargetIds[idsName]=idsCollection[idsName];
  }
  return tempTargetIds;
}

function searchValueTransform(value, dateOnly) {
  const dateFormat = 'YYYY-MM-DD';

  try {
    if (typeof value === 'string') {
      if (!isNaN(Date.parse(value) && moment(value).isValid())) {
        return dateOnly ? dateOnlyForRender(value, dateFormat) : dateForRender(moment.utc(value), dateFormat);
      } else {
        return camelize(value).toLowerCase();
      }
    } else if (Array.isArray(value)) {
      return value.map((v) => {
        return searchValueTransform(v);
      });
    } else if(typeof value === 'object' && Date.parse(value)) {
      return dateOnly ? dateOnlyForRender(value, dateFormat) : dateForRender(moment.utc(value), dateFormat);
    } else {
      return value;
    }
  } catch (err) {
    console.log( err);
    return value;
  }
}

function addressTransform(address) {
  if(address.countryName) {
    address.country = address.countryName;
  }

  delete address.countryName;

  return address;
}

function prepareNotifyTargetIds(data) {
  let notifyUserIds = [];

  for (const listName of notifyUserIdsList) {
    if (Array.isArray(get(data, listName))) {
      notifyUserIds = notifyUserIds.concat(data[listName]);
    } else if (get(data, listName)) {
      notifyUserIds.push(get(data, listName));
    }
  }

  let notifyTeamIds = [];

  for (const listName of notifyTeamIdsList) {
    if (Array.isArray(get(data, listName))) {
      if(isEmpty(notifyTeamIds)) {
        notifyTeamIds = get(data, listName);
      } else {
        notifyTeamIds.concat(get(data, listName));
      }
    } else if (has(data, listName)) {
      notifyTeamIds.push(get(data, listName));
    }
  }

  const {userId = null} = apiUserStatus.get() || {};
  const filterNotifyUserIds = notifyUserIds.filter((notifyUserId) => notifyUserId !== userId);

  return  prepareCombinedIds({notifyUserIds: filterNotifyUserIds, notifyTeamIds});
}

function prepareRecordVisibility(data) {
  const { firstName='', lastName='' } = data.createdBy || {};
  const createUserName = `${firstName} ${lastName}`;
  const isPublic = data.isPublic;

  // public
  if(isPublic) {
    return 'Public';
  }

  const users = data.users || [];
  const teams = data.teams || [];

  // not public
  if (!isPublic) {
    const userNames = users.map((user) => user.name || `${user.firstName} ${user.lastName}`);
    const teamNames = teams.map((team) => team.name);
    return `${[createUserName].concat(userNames).concat(teamNames).join(', ')}`;
  }
}

function prepareDefaultTask() {
  const {userId = null} = apiUserStatus.get() || {};

  return  {
    subject: '',
    status: 'TO_DO',
    dueDate: getSpecificDateFromNow(+1),
    priority: 'NORMAL',
    description: '',
    assignedToUserId: userId,
    entity: {},
    isPrivate: false,
  };
}

function prepareDefaultTaskFromData(data) {
  const entityName = get(data, 'entity.primaryLink') || get(data, 'entity.fullName') || get(data, 'entity.name') || shortName(data.entity) || get(data, 'entity.jobTitle') || '';
  const subject = entityName ? `Follow up with ${entityName}` : '';
  const entity = {
    id: get(data, 'entity.entityId') || get(data, 'entity.id'),
    type: get(data, 'entity.entityType') || get(data, 'entity.type'),
    name: entityName
  };
  const noteId = get(data, 'noteId');
  const {userId = null} = apiUserStatus.get() || {};

  const result = {
    subject,
    status: 'TO_DO',
    dueDate: getSpecificDateFromNow(+1),
    priority: 'NORMAL',
    description: data.content || data.auditLogComment || data.comment || '',
    assignedToUserId: userId,
    entity,
    isPrivate: false,
  };

  if (noteId) {
    result.noteId = noteId;
  }

  return  result;
}

function prepareActivityTypes(data) {
  return {
    ...data,
    status: data.isActive ? 'Active' : 'Inactive',
    id: data.activityTypeId
  };
}

function preparePermissionLabel(data){
  return { ...data, value: data.id, label: data.name };
}

function preparePermissionGroup(data){
  return { ...data, value: data.id, label: data.name };
}

function prepareEntitiesRecentTimeStamp(entities){
  if (entities && entities.length>0) {
    const createdAt = entities[0].createdAt ? entities[0].createdAt : (entities[0].entity && entities[0].entity.createdAt);
    const receivedDateTime = entities[0].entity && entities[0].entity.receivedDateTime;
    const activityStartDate = entities[0].entity && entities[0].entity.activityStartDate;
    return createdAt || receivedDateTime || activityStartDate || '';
  } else {
    return '';
  }
}

function prepareTypeAheadLabelKey(option){
  switch (option.entityType) {
  case 'CANDIDATE':
  case 'CONTACT':
  case 'PLACEMENT':
    const {fullName, firstName, lastName} = option.candidateName || option.contactName || get(option, 'candidate.candidateName') || option;
    const name = option.name || compact([firstName, lastName]).join(' ');
    return name || fullName;
  case 'JOB':
  case 'COMPANY':
    return `${option.jobTitle || option.name || ''}`;
  default:
    return '';
  }
}

function convertObjectToOptionFormat(obj){
  if (isArray(obj)) {
    return compact(obj).map((thisObj) => {
      const {userId, preferredName, firstName, lastName} = thisObj || {};
      return {id: userId, name: compact([firstName, lastName]).join(' '), preferredName, firstName, lastName};
    });
  } else {
    const {userId, preferredName, firstName, lastName} = obj || {};
    return {id: userId, name: compact([firstName, lastName]).join(' '), preferredName, firstName, lastName};
  }
}

function checkBusinessUnitById(id, lookups, entity) {
  const PHC = 'PRIDE HEALTHCARE';
  const PHS = 'PRIDE HEALTHCARE STAFFING LLC';
  // Check if Bussiness Unit ID references to Pride Healthcare
  const businessUnits = compact((lookups.businessUnits || []).concat(!isEmpty(entity?.businessUnit) ? [entity?.businessUnit] : []));
  const selectUnit = businessUnits.filter(unit => unit.id === id);
  return selectUnit[0]?.name.toUpperCase() === PHC || selectUnit[0]?.name.toUpperCase() === PHS;
};

function prepareCombinedLookups(lookupsCollection){
  let combinedLookups = [];
  for (const idsName of Object.keys(lookupsCollection)) {
    if (lookupsCollection[idsName]) {
      const temp = lookupsCollection[idsName].map((item) => {
        return {
          id: `${idsName}_${item.id}`,
          name: item.name,
          isInactive: item.isInactive || false,
        };
      });
      combinedLookups = [
        ...combinedLookups,
        ...temp,
      ];
    }
  }
  return combinedLookups;
}

function prepareCombinedIds(idsCollection){
  let combinedIds = [];
  for (const idsName of Object.keys(idsCollection)) {
    const temp = idsCollection[idsName].map((id) => `${idsName}_${id}`);
    combinedIds = [
      ...combinedIds,
      ...temp,
    ];
  }
  return compact(uniq(combinedIds));
}

function prepareCodingTransform(coding){
  if (coding) {
    coding.industryIds = (coding.industries || []).map((industry) => industry.id);
    coding.jobFunctionIds = (coding.jobFunctions || []).map((jobFunction) => jobFunction.id);
    coding.skillIds = (coding.skills || []).map((skill) => skill.id);
    coding.departmentIds = (coding.departments || []).map((department) => department.id);
    coding.phcCareerCodeIds = (coding.phcCareerCodes || []).map((phcCareerCode) => phcCareerCode.id);
    coding.phcFacilityTypeIds = (coding.phcFacilityTypes || []).map((phcFacilityType) => phcFacilityType.id);
  }
  return coding;
}

function prepareDisabledButton(form, buttonSelector, disabled){
  let btns = null;
  if (form) {
    btns = form.querySelectorAll(buttonSelector);
  } else {
    btns = document.querySelectorAll(buttonSelector);
  }

  if(!isEmpty(btns)) {
    btns.forEach(function(btn) {
      btn.disabled = disabled;
    });
  }
}

function getTableViewSettings() {
  const { settings={} } = apiUserStatus.get() || {};
  const { tableViewSettings = {} } = settings || {};
  return tableViewSettings;
}

function prepareUsersAndTeamsInfo(data) {
  const users = (data.users || []).map((user) => {
    return {id: user.userId || user.id, name: user.name || `${user.firstName} ${user.lastName}`};
  });
  const teams = (data.teams || []).map((team) => {
    return {id: team.teamId || team.id, name: team.name};
  });
  const userIds = users.map((user) => user.id);
  const teamIds = teams.map((team) => team.id);

  return {
    users,
    teams,
    userIds,
    teamIds
  };
}

function generateRandomString(length = 4) {
  const CHARS = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXTZabcdefghiklmnopqrstuvwxyz';
  let randomstring = '';
  for (let i = 0; i < length; i++) {
    const INDEX = Math.floor(Math.random() * CHARS.length);
    randomstring += CHARS[INDEX];
  }
  return randomstring;
}

function getActivityFilterCategories(entityType) {
  return get(apiUserStatus.get(), `settings.activityFilterSettings.${entityType.toLowerCase()}`) || activityFilterCategoriesDefault[(entityType || '').toUpperCase()] || [];
}

function validateIsCovered(job) {
  const reducedStages = (get(job, 'metadata.submissions.numByStage[2].numTotal', 0) + get(job, 'metadata.submissions.numByStage[3].numTotal', 0) + get(job, 'metadata.submissions.numByStage[4].numTotal', 0) + get(job, 'metadata.submissions.numByStage[5].numTotal', 0));
  const numOfPositions = job.numOpenPositions;
  return reducedStages >= numOfPositions;
}

function checkUpdate(originalData, updatedData) {
  let check = false; 
  
  for (const fieldName in updatedData) {
    const originalValue = originalData[fieldName];
    const newValue = updatedData[fieldName];
    if (fieldName === 'effectiveDate') {
      continue;
    }
    if (updatedData[fieldName] instanceof Date) {
      const currentDate = new Date(originalData[fieldName]);
      const updatedDate = new Date(updatedData[fieldName]);
      if (currentDate.getTime() !== updatedDate.getTime()) {
        check = true;
        break;
      }
      continue;
    }
    if (newValue === null || newValue === undefined) {
      if ((originalValue !== undefined) && (originalValue !== null)) {
        check = true;
        break;
      }
      continue;
    }

    if (isObject(newValue) && !Array.isArray(newValue)) {
      const result = checkUpdate(originalValue, newValue);
      if (result) {
        check = true; 
        break; 
      }
    } else if (Array.isArray(newValue)) {
      const updatedArrayStr = newValue.slice().sort().join(',');
      const originalArrayStr = originalValue.slice().sort().join(',');

      if (originalArrayStr !== updatedArrayStr) {
        check = true;
        break;
      }
    } else {
      if (newValue !== originalValue) {
        check = true;
        break;
      }
    }
  }
  
  return check;
}

export default {
  dollarForRender,
  currencyIconForRender,
  currencyForRender,
  dateForRender,
  dateForApi,
  dateOnlyForRender,
  dateOnlyForApi,
  originalDate,
  timeForRender,
  duration,
  getSpecificDateFromNow,
  checkDueDate,
  dateDiff,
  initialName,
  contentForRender,
  phoneForRender,
  phoneHighlighter,
  getFirstMatchedElement,
  shortName,
  activeTabName,
  monthsToYears,
  managementScoreToLevel,
  convertIdToName,
  convertIdToNameUpperCaseUnderscore,
  convertIdsToOptionFormat,
  convertListToOptionFormat,
  convertCollectionToArray,
  checkBusinessUnitById,
  convertIdToUser,
  addInactiveToList,
  hireIndex,
  cleanSearchValues,
  convertListToDropDownOptionFormat,
  camelize,
  convertCamelCaseToTitle,
  titleCase,
  convertHtmlToText,
  detectEditMode,
  detectDropZone,
  flatObject,
  arrayToObj,
  refLink,
  cleanQuickSearchInput,
  downloadFile,
  mailTo,
  removeQuotes,
  removeWildChar,
  parsedFileName,
  wait,
  splitIdsForApi,
  notifyCollection,
  searchValueTransform,
  addressTransform,
  prepareRecordVisibility,
  prepareDefaultTask,
  prepareDefaultTaskFromData,
  prepareActivityTypes,
  preparePermissionLabel,
  preparePermissionGroup,
  prepareEntitiesRecentTimeStamp,
  prepareTypeAheadLabelKey,
  prepareCombinedLookups,
  prepareCombinedIds,
  prepareNotifyTargetIds,
  prepareDisabledButton,
  convertObjectToOptionFormat,
  prepareCodingTransform,
  getTableViewSettings,
  prepareUsersAndTeamsInfo,
  generateRandomString,
  getActivityFilterCategories,
  validateIsCovered,
  addInactiveUsersToList, 
  addInactiveToUsers, 
  checkUpdate
};
