import React from 'react';
import PropTypes from 'prop-types';

export default function AtsInlineDetail({className, label, displayValue, editIcon, sectionName, propsName}) {
  return (
    <>
      <div id={`${propsName}-detail`} className={className}>
        <div className='label'>{label}</div>
        <div className='value d-inline-block ats-inline'>
          {displayValue}
          {editIcon(sectionName, propsName)}
        </div>
      </div>
    </>
  );
};

AtsInlineDetail.propTypes = {
  label: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
  displayValue: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.object.isRequired]),
  editIcon: PropTypes.func.isRequired,
  sectionName: PropTypes.string.isRequired,
  propsName: PropTypes.string.isRequired,
};
