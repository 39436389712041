import './style.scss';
import { AtsInput, Button } from '../../compoments/common';
import React from 'react';
import patternConst from '../../constants/patternConst';
const isSSOLogin = (window.REACT_APP_AUTHORIZATION_METHOD || '').includes('SSO');

export default ({handleCallback, switchType, forgotPassword, isLoading}) => {
  return (
    <>
      <div className='form-group'>
        <AtsInput id='email' name='email' classname='email' placeholder="Email" required callback={handleCallback} type={'email'} pattern={patternConst.EmailPattern} />
      </div>

      <div className='form-group'>
        <AtsInput id='password' name='password' type={'password'} placeholder="Password" required callback={handleCallback} />
      </div>

      <div className='d-block forgot-password'>
        <span id='forgot-password-link' className='cursor' onClick={forgotPassword}>Forgot Password</span>
      </div>

      <Button type='submit' id='email-login-button' variant='my-4 w-100' disabled={isLoading}>
        Sign In
      </Button>

      {
        isSSOLogin ?
          <div className='d-block switch-sso'>
            <span id='switch-sso-link' className='cursor' onClick={() => switchType('sso')}>Sign In with Microsoft</span>
          </div> : ''
      }
    </>
  );
};
