import actionTypes from '../constants/actionTypes';

const entityTypes = ['candidate', 'job', 'company', 'contact', 'placement'];
const prepareInitialState = (entityTypes) => {
  return entityTypes.reduce((acc, entityType) => {
    return {
      ...acc,
      [entityType]: {
        isFetched: false,
        lastRunAtOffsetZero: null,
        parameters: {},
        entityType: entityType,
        entityId: null,
        data: {summary: {}, entities: []},
        metadata: {},
        savedSearch: {}
      }
    };
  }, {});
};
const initialState = prepareInitialState(entityTypes);

export default function searchEntityReducer(state = initialState, action) {
  const entityType = action.entityType ? action.entityType.toLowerCase() : entityTypes[0];
  switch (action.type) {
  case actionTypes.entityActionTypes.SEARCH_ENTITIES_REQUEST:
    return {
      ...state,
      [entityType]: {
        ...state[entityType],
        isFetched: true
      }
    };

  case  actionTypes.entityActionTypes.SEARCH_ENTITIES_SUCCESS:
    return {
      ...state,
      [entityType]: {
        ...state[entityType],
        isFetched: false
      }
    };

  case actionTypes.entityActionTypes.SEARCH_ENTITIES_FAIL:
    return {
      ...state,
      [entityType]: {
        ...state[entityType],
        isFetched: false
      }
    };

  case  actionTypes.entityActionTypes.SET_ENTITIES:
    return {
      ...state,
      [entityType]: {
        ...state[entityType],
        data: action.payload,
        lastRunAtOffsetZero: state[entityType].parameters.offset ? state[entityType].lastRunAtOffsetZero : new Date()
      }
    };

  case actionTypes.entityActionTypes.SET_ENTITY_ID:
    return {
      ...state,
      [entityType]: {
        ...state[entityType],
        entityId: action.payload
      }
    };

  case actionTypes.entityActionTypes.UPDATE_PARAMETERS:
    return {
      ...state,
      [entityType]: {
        ...state[entityType],
        parameters: action.payload
      }
    };

  case actionTypes.entityActionTypes.UPDATE_METADATA:
    const entityId = action.entityId;
    const entityIndex = state[entityType].data.entities.findIndex(
      entity => entity[entityType+'Id'] === entityId
    );

    if (entityIndex !== -1) {
      const entity = { ...state[entityType].data.entities[entityIndex], 'metadata': action.payload };

      const entities = [
        ...state[entityType].data.entities.slice(0, entityIndex),
        entity,
        ...state[entityType].data.entities.slice(entityIndex + 1)
      ];

      return {
        ...state,
        [entityType]: {
          ...state[entityType],
          entityId: state[entityType].entityId,
          data: {summary: state[entityType].data.summary, entities: entities},
          metadata: action.payload
        }
      };
    } else {
      return {
        ...state,
        [entityType]: {
          ...state[entityType],
          metadata: action.payload
        }
      };
    }
  case actionTypes.entityActionTypes.UPDATE_ENTITY:
    const updatedEntity = { ...action.payload };
    const updatedEntityIndex = state[entityType].data.entities.findIndex(
      entity => entity[entityType+'Id'] === state[entityType].entityId
    );

    if (updatedEntityIndex !== -1) {
      const updatedEntities = [
        ...state[entityType].data.entities.slice(0, updatedEntityIndex),
        {...updatedEntity, 'metadata': state[entityType].data.entities[updatedEntityIndex].metadata},
        ...state[entityType].data.entities.slice(updatedEntityIndex + 1)
      ];
      return {
        ...state,
        [entityType]: {
          ...state[entityType],
          entityId: state[entityType].entityId,
          data: {summary: state[entityType].data.summary, entities: updatedEntities}
        }
      };
    } else {
      return {
        ...state,
        [entityType]: {
          ...state[entityType]
        }
      };
    }
  case actionTypes.entityActionTypes.SET_SAVED_SEARCH:
    return {
      ...state,
      [entityType]: {
        ...state[entityType],
        savedSearch: action.payload
      }
    };
  default:
    return state;
  }
}
