import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import {AtsDropdown, AtsInput, AtsTooltip, AtsTypeAheadSearchSelect} from '../index';
import helpIcon from '../../../assets/img/quickItemSubmission/quickItem/status.png';
import maxMinConst from '../../../constants/maxMinConst';
import searchConst from '../../../constants/searchConst';
import React from 'react';
import './style.scss';

const searchHelpText = 'For better results, use boolean search terms such as AND, OR, NOT, and parentheses. Use * to search for part of a word, and use quotes to search for exact text.';

export default ({entityType, criteria, handleChange}) => {
  const kwTips= () => {
    switch (entityType.toLowerCase()) {
    case 'candidate':
      return 'Job Title, Current Company, Resume Skills, Highlighted Skills, Full Resume, Job Functions, Industries, Departments';
    case 'job':
      return 'Title, Summary, Owner, Client Company Name, Description, Published Description, Industries, Departments, Job Functions, Highlighted Skills';
    case 'company':
      return 'Company Name, Summary, Sales Owner, Recruiter, Industries';
    case 'placement':
      return 'Candidate Name, Job Title, Client Company Name';
    case 'contact':
      return 'Name, Title, Client Company Name, Summary';
    default:
      return '';
    }
  };

  const handleDistance = (name, value) => {
    handleChange({[name]: value});
  };

  return (
    <>
      <Row>
        <Col>
          <div className="input-group keyword-location">
            <AtsTooltip id='keywords-tips' placement='right' tips={`Searches: ${kwTips()}`}>
              <div className="fas fa-search light-grey-text align-self-center" style={{'zIndex': '1051', position: 'absolute', height: '60px !important'}}/>
            </AtsTooltip>

            <AtsInput id='keywords' name='keywords' defaultValue={criteria.keywords} maxLength={maxMinConst.QueryMaxlength} placeholder='Keywords' callback={handleChange} />

            <AtsTypeAheadSearchSelect
              isMulti={true}
              required={false}
              defaultValue={criteria.locations ? criteria.locations : []}
              labelKey='location'
              clearButton={false}
              id='locations'
              name='locations'
              minLength={entityType === 'job' ? 0 : 2}
              maxLengthToApi={2}
              delay={0}
              placeholder='Type Locations or Zipcodes'
              searchOperation='locationsSearch'
              filterBy={['location']}
              allowNew={true}
              callback={handleChange}
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col className='py-3 col-7 text-right light-grey-text biotif' style={{opacity: 1, paddingRight: '65px'}}>
          Keyword Help
          <AtsTooltip id='keywords-help-text' placement='right' tips={searchHelpText}>
            <img src={helpIcon} alt='Keyword Help' width={'auto'} height={'24'} className='pl-2'/>
          </AtsTooltip>
        </Col>
        <Col className='py-3 col-5 text-right'>
          <AtsDropdown
            dropdownChoices={searchConst.maxDistanceChoices}
            callback={handleDistance}
            default={criteria.maxDistance}
            label='Distance'
            id='maxDistance'
            variant='border-0'
            labelVariant='light-grey-text pr-0 font-size-14'
          />
        </Col>
      </Row>
    </>
  );
};
