import entityConst from './entityConst';

const statusOption = [
  {id: 'Active', name: 'Active'},
  {id: 'Inactive', name: 'Inactive'}
];

const categoryOptions = [
  {id: 'STANDARD', name: 'Standard'},
  {id: 'BULK_EMAIL', name: 'Bulk Email'}
];

const visibilityOptions = [
  {id: 'ALL', name: 'All Templates'},
  {id: 'PUBLIC', name: 'Public Templates'},
  {id: 'TEAM', name: 'Team Templates'},
  {id: 'PRIVATE', name: 'Private Templates'}
];

const entityTypesOptions = entityConst.entityTypeOptions;

const allEntityTypes = ['CANDIDATE', 'JOB', 'CONTACT', 'PLACEMENT', 'SUBMISSION', 'COMPANY', 'DEAL'];

export {
  statusOption,
  entityTypesOptions,
  categoryOptions,
  visibilityOptions,
  allEntityTypes,
};
