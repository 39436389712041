const entityTypeOptions = [
  {id: 'CANDIDATE', name: 'Candidates'},
  {id: 'JOB', name: 'Jobs'},
  {id: 'CONTACT', name: 'Contacts'},
  {id: 'COMPANY', name: 'Companies'},
  {id: 'PLACEMENT', name: 'Placements'}
];

const scopeOptions = [
  {value: 'PRIVATE', label: 'Private'},
  {value: 'TEAM', label: 'Team'},
  {value: 'PUBLIC', label: 'Public'}
];

const categoryOptions = [
  {id: 'STANDARD', name: 'Standard'}
];

export {
  entityTypeOptions,
  scopeOptions,
  categoryOptions
};
