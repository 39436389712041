import React, {useState} from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

export default function AtsTooltip(props) {
  const [show, setShow] = useState(false);
  const onToggle = () => {
    const obj = document.querySelector(props.targetQuery);

    if (obj && props.checkSpace) {
      if (props.checkWidth && (obj.offsetWidth < obj.scrollWidth)) {
        setShow(!show);
      }
    }
  };

  return (
    <>
      <OverlayTrigger
        key={`key-${props.id}`}
        placement={props.placement}
        {...props.target ? {target: props.target} : {}}
        {...props.container ? {container: props.container} : {}}
        {...props.checkSpace ? { show: show} : {}}
        {...props.checkSpace ? { onToggle: onToggle } : {}}
        overlay={
          <Tooltip id={props.id} {...props.style ? {style: props.style} : {}}>
            {props.tips}
          </Tooltip>
        }
      >
        {props.children}
      </OverlayTrigger>
    </>
  );
};
