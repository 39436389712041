import React, {useState, useLayoutEffect} from 'react';
import { StatusCodes } from 'http-status-codes';
import './style.scss';
import Title from './Title';
import SetPassword from './SetPassword';
import LoginContent from './LoginContent';
import HolidayFeaturesForLogin from '../../compoments/HolidayTheme/HolidayFeaturesForLogin';
import ApiClient from '../../services/ApiClient';
import apiUserStatus from '../../services/apiUserStatus';
import TokenService from '../../services/TokenService';

export default function Login({ isReady }) {
  const [urlParams] = useState(new URLSearchParams(window.location.search));
  const code = urlParams.has('code') ? urlParams.get('code') : '';

  const [user, setUser] = useState({});
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isAuthorize, setIsAuthorize] = useState(false);

  const isNativeLogin = (window.REACT_APP_AUTHORIZATION_METHOD || '').includes('NATIVE');
  const isSSOLogin = (window.REACT_APP_AUTHORIZATION_METHOD || '').includes('SSO');

  const checkEmailLoginPath = () => {
    return window.location.pathname.toLowerCase().startsWith('/login-email');
  };

  const checkSetPasswordPath = () => {
    return window.location.pathname.toLowerCase().startsWith('/reset-password') || window.location.pathname.toLowerCase().startsWith('/register');
  };

  const checkAuthorizePath = () => {
    return window.location.pathname.startsWith('/authorize') && window.location.search.startsWith('?code=');
  };

  function prepareType() {
    if (checkAuthorizePath()) {
      return 'sso';
    }

    if (checkSetPasswordPath()) {
      return 'setPassword';
    }

    if (checkEmailLoginPath()) {
      return 'email';
    }

    if (isSSOLogin) {
      return 'sso';
    }

    if (isNativeLogin) {
      return 'email';
    }

    return 'sso';
  }

  const onlySSO = !isNativeLogin && !checkEmailLoginPath() && !checkSetPasswordPath();
  const [type, setType] = useState(prepareType());

  useLayoutEffect(() => {
    const fetchApi = async() => {
      try {
        if (isReady && ApiClient.identityAuthorize && code) {
          setIsLoading(true);
          setIsAuthorize(true);

          const response = await ApiClient.identityAuthorize({code});
          if (response.status === StatusCodes.OK) {
            const { accessToken, refreshToken } = response.body;

            TokenService.setAccessTokenInfo(accessToken);
            TokenService.setRefreshTokenInfo(refreshToken);
            await apiUserStatus.prepareUserDetails();

            window.location.href = '/';
          }

          setIsLoading(false);
          setIsAuthorize(false);
        }
      } catch (error) {
        console.log(error);
        setTimeout(() => {
          apiUserStatus.remove();
          window.location.href = '/login';
        }, 2000);
      }
      document.title = 'Login';
    };

    fetchApi();
  }, [code, isReady]);

  const handleCallback= updateData => {
    setUser({
      ...user,
      ...updateData
    });
  };

  const forgotPassword = () => {
    setError('');
    setType('resetPassword');
  };

  const handleSubmit = async (event) => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }

    if (type === 'sso') {
      try {
        const res = await ApiClient.identitySingleSignOn();
        console.log('res: ', res.body.signInUrl);
        const signInUrl= res.body.signInUrl;
        if (signInUrl) {
          window.location.assign(signInUrl);
        }
      } catch (error) {
        console.log('usersSingleSignOn', error);
        apiUserStatus.remove();
        setIsLoading(false);
        setTimeout( () => window.location.reload(), 5000);
      }
    }

    if (type === 'email') {
      let form = event.currentTarget;
      const inputs = form.querySelectorAll('input:invalid, select:invalid, textarea:invalid, input.is-invalid');
      if (form.checkValidity() === true && inputs.length === 0) {
        setError(undefined);
        setIsLoading(true);
        try {
          const {
            email = document.getElementById('email').value,
            password = document.getElementById('password').value
          } = user;
          const response = await ApiClient.identityTokensRequest({}, { email, password: encodeURIComponent(password) });
          if (response.status === StatusCodes.OK) {
            const { accessToken, refreshToken } = response.body;

            TokenService.setAccessTokenInfo(accessToken);
            TokenService.setRefreshTokenInfo(refreshToken);
            await apiUserStatus.prepareUserDetails();

            window.location.href = '/';
          }

          setIsLoading(false);

        } catch (error) {
          console.log('email login', error);
          if (error.response) {
            const errorMsg = error.response.body.message;
            setError(errorMsg);
          } else {
            setError(error.toString());
          }

          apiUserStatus.remove();
          setIsLoading(false);
          setTimeout( () => window.location.reload(), 5000);
        }
      } else {
        form.classList.add('was-validated');
      }
    }

    if (type === 'resetPassword') {
      try {
        const response = await ApiClient.usersSendResetPasswordLink({email: user.email});
        if (response.status === StatusCodes.NO_CONTENT) {
          setError('');
          setType('sentReset');
        }
      } catch (error) {
        console.log('resetPassword', error);
        setTimeout( () => window.location.reload(), 5000);
      }
    }
  };

  const switchType = (type) => {
    setError('');
    setType(type);
  };

  return (
    <div className='vh-100 px-0 w-100'>
      <div className={`d-flex justify-content-center login-wrapper${onlySSO ? ' onlySSO' : ''}`}>
        {
          type === 'setPassword' ?
            <SetPassword switchType={switchType} /> :
            <form onSubmit={handleSubmit} autoComplete='off'>
              <Title type={type} />
              {error &&
              <div className="alert alert-secondary border-0 ats-error mb-5" role="alert">
                {error}
              </div>
              }
              <LoginContent
                type={type}
                switchType={switchType}
                handleCallback={handleCallback}
                forgotPassword={forgotPassword}
                checkEmailLoginPath={checkEmailLoginPath}
                isLoading={isLoading}
                isAuthorize={isAuthorize}
                onlySSO={onlySSO}
              />
            </form>
        }
      </div>
      <div className='footer d-flex'>
        <div className='copy-right-info'>Copyright &copy; Pride Capital Partners 2014-{new Date(Date.now()).getFullYear()}. All Rights Reserved.</div>
        <div className='need-help-info'>Need help?</div>
      </div>
      <HolidayFeaturesForLogin />
    </div>
  );
}
