import React from 'react';
import PropTypes from 'prop-types';
import AtsModal from '../../common/AtsModal';
import Form from './Form';

import './style.scss';

export default function FormModal(props) {
  return (
    <>
      {props.modalVisible ?
        <AtsModal
          id='bulk-email-form-modal'
          modalTitle='Send Email'
          modalSize='sm'
          modalVisible={props.modalVisible}
          modalCancel={props.modalCancel}
        >
          <Form {...props} />
        </AtsModal> : ''
      }
    </>
  );
}

FormModal.propTypes = {
  modalVisible: PropTypes.bool.isRequired,
  modalCancel: PropTypes.func.isRequired
};
