import React from 'react';
import {Link} from 'react-router-dom';
import utils from '../../../../libs/utils';
import candidateConst from '../../../../constants/candidateConst';

export default ({entity, eventLogCallback}) => {
  const entityType = (entity.entityType || '').toLowerCase();
  const openNewTabForEntityDetail = (e) => {
    e.preventDefault();
    const redirectPath = `/${entityType}/${entity[entityType+'Id']}`;
    const scheme = window.REACT_APP_SCHEME || 'https';
    const newCandidateUrl = `${scheme}://${window.location.host}${redirectPath}`;
    window.open(newCandidateUrl, '_blank');

    const eventProperties = {action: 'view ' + entityType, object: entityType};
    if (typeof eventLogCallback === 'function') {
      eventLogCallback(eventProperties, 'view');
    } else {
      window.amplitudeLogEvent('view', eventProperties);
    }
  };

  const prepareTitle = () => {
    try {
      switch (entity.entityType) {
      case 'JOB':
        return entity.showFull ? entity.jobTitle || entity.name : utils.contentForRender(entity.jobTitle || entity.name, 25);
      case 'CANDIDATE':
      default:
        return entity.name || utils.shortName(entity, candidateConst.maxFullNameLength, true);
      }
    } catch (err) {
      console.log(err);
      return '';
    }
  };

  return (
    <>
      {
        <Link to='#' className='biotif-semi-bold font-size-16 d-flex' onClick={openNewTabForEntityDetail}>
          {prepareTitle()}<i className='fa fa-arrow-up link-to-new-tab' style={{transform: 'rotate(45deg)', color: '#4948ac', fontSize: '10px'}} />
        </Link>
      }
    </>
  );
};
