import DropzoneText from './DropzoneText';
import Button from '../Button';
import DropzoneAcceptedFilesItems from './DropzoneAcceptedFilesItems';
import React, {useCallback, useEffect, useState} from 'react';
import {useDropzone} from 'react-dropzone';
import DropzoneService from './DropzoneService';
import utils from '../../../libs/utils';
import DropzoneMessage from './DropzoneMessage';

export default ({ props, files, setFiles, parseResume, addEditMode, removeFile }) => {
  const maxSize = 10485760;//10MB
  const [dropAnywhereActive, setDropAnywhereActive] = useState(false);
  const [targetElemId, setTargetElemId] = useState('');

  const trackClickElem = (e) => {
    setTargetElemId(e.target.id);
  };

  const checkModeForDropResume = useCallback(() => {
    return utils.detectEditMode().length === 0 && utils.detectDropZone().length === 0;
  }, []);

  const onDrop = async (acceptedFiles) => {
    switch (props.dropType) {
    case 'image':
      DropzoneService.imageProcess(acceptedFiles, files, setFiles, props);
      break;
    case 'mass-import':
      await DropzoneService.massImportProcess(acceptedFiles, files, setFiles, props);
      break;
    case 'drop-resume-anywhere':
      setDropAnywhereActive(false);
      if (checkModeForDropResume()) {
        DropzoneService.resumeProcess(acceptedFiles, files, setFiles, targetElemId, parseResume, props);
      }
      break;
    case 'supplementalDocuments':
      DropzoneService.documentProcess(acceptedFiles, files, setFiles, addEditMode, props);
      break;
    default:
      DropzoneService.resumeProcess(acceptedFiles, files, setFiles, targetElemId, parseResume, props);
    }
  };

  const {getRootProps, getInputProps, isDragActive, rejectedFiles, isDragReject} = useDropzone({ noKeyboard: true, multiple: props.multiple, maxSize:maxSize, accept: props.accept, onDrop});

  useEffect(() => {
    if (!dropAnywhereActive && isDragActive && checkModeForDropResume()) {
      setDropAnywhereActive(true);
    }
    if (!isDragActive) {
      setDropAnywhereActive(false);
    }
  }, [dropAnywhereActive, isDragActive, checkModeForDropResume]);

  switch (props.dropType) {
  case 'image':
    return (
      <>
        <div {...getRootProps({className: (props.required && props.validated && files.length === 0 ? 'dropzone error' : 'dropzone') + (isDragActive ? ' active' : '')})}>
          <input {...getInputProps({
            id: 'file-' + props.name,
            required: props.required && files.length === 0,
            className: 'form-control'
          })} />
          <div className='w-100 mb-2 d-flex'>
            {props.children}

            <DropzoneText dropType={props.dropType} acceptFileTypeText={props.acceptFileTypeText} name={props.name} />

            <div className='col-5'>
              <Button type='button' id={props.name} variant='btn-primary btn-no-form'>
                {props.buttonName || 'Browse'}
              </Button>
            </div>

            <DropzoneMessage isDragReject={isDragReject} maxSize={maxSize} rejectedFiles={rejectedFiles} />
          </div>
        </div>
        <DropzoneAcceptedFilesItems dropType={props.dropType} files={files} readOnly={props.readOnly} removeFile={removeFile}/>
      </>
    );
  case 'supplementalDocuments':
    return (
      <>
        <div {...getRootProps({className: (props.required && props.validated && files.length === 0 ? 'dropzone error' : 'dropzone') + (isDragActive ? ' active' : '')})}>
          <input {...getInputProps({
            id: 'file-' + props.name,
            required: props.required && files.length === 0,
            className: 'form-control'
          })} />
          <div className='w-100 mb-2 d-flex'>
            {props.children}

            <DropzoneText dropType={props.dropType} acceptFileTypeText={props.acceptFileTypeText} name={props.name} />

            <div className='col-5 pt-2'>
              <Button type='button' id={props.name} variant='btn-primary btn-no-form'>
                {props.buttonName || 'Browse'}
              </Button>
            </div>

            <DropzoneMessage isDragReject={isDragReject} maxSize={maxSize} rejectedFiles={rejectedFiles} />
          </div>
        </div>
      </>
    );
  case 'drop-resume-anywhere':
    return (
      <>
        <div {...getRootProps({className: 'drop-resume-anywhere' + ( isDragActive && checkModeForDropResume() ? ' active' : '' ) })}>
          <div id='drop-resume-overlay' style={ dropAnywhereActive ? {'display': 'block'} : {display: 'none'} }>
            <div className='drop-text'>
              <DropzoneText dropType={props.dropType} acceptFileTypeText={props.acceptFileTypeText} name={props.name} />
            </div>
          </div>
          {props.children}
        </div>
      </>
    );
  case 'mass-import':
    return (
      <>
        {
          !files.length ?
            <div {...getRootProps({className: (props.required && props.validated && files.length === 0 ? 'dropzone error' : 'dropzone') + (isDragActive ? ' active' : '')})}>
              <input {...getInputProps({
                id: 'file-' + props.name,
                required: props.required && files.length === 0,
                className: 'form-control'
              })} />
              <div className='w-100 mb-2'>
                {props.children}

                <DropzoneText dropType={props.dropType} acceptFileTypeText={props.acceptFileTypeText} name={props.name} />

                <Button type='button' id={props.name} variant='btn-primary btn-no-form'>
                  {props.buttonName || 'Browse'}
                </Button>

                <DropzoneMessage isDragReject={isDragReject} maxSize={maxSize} rejectedFiles={rejectedFiles} />
              </div>
            </div>
            :''
        }
        <DropzoneAcceptedFilesItems dropType={props.dropType} files={files} readOnly={props.readOnly} removeFile={removeFile}/>
      </>
    );
  default:
    return (
      <>
        {!props.readOnly && !(props.dropType === 'resume-parsing' && files.length !==0) ?
          <div {...getRootProps({
            className: (props.required && props.validated && files.length === 0 ? 'dropzone error' : 'dropzone') + (isDragActive ? ' active' : ''),
            onClick: e => trackClickElem(e)
          })}>
            <input {...getInputProps({
              id: 'file-' + props.name,
              required: props.required && files.length === 0,
              className: 'form-control'
            })} />
            <div className='w-100 mb-2'>
              {props.children}

              <DropzoneText dropType={props.dropType} acceptFileTypeText={props.acceptFileTypeText} name={props.name} />

              <Button type='button' id={props.name} variant='btn-primary btn-no-form'>
                {props.buttonName || 'Browse'}
              </Button>

              {props.dropType === 'resume-parsing' ?
                <Button type='button' id='resume-parsing' variant='ml-4 btn-primary btn-no-form'>
                  Browse and Parse
                </Button>
                :
                ''
              }

              {rejectedFiles.length > 0 && rejectedFiles[0].size > maxSize &&
              <div className="text-danger mt-2">
                File is too large.
              </div>}
            </div>

          </div>
          :
          ''
        }
        <DropzoneAcceptedFilesItems name={props.name} entityType={props.entityType} dropType={props.dropType} files={files} readOnly={props.readOnly} removeFile={removeFile}/>
        {
          (props.entityType==='CANDIDATE' && props.dropType === 'resume-parsing' && files.length !== 0) ?
            <div {...getRootProps({onClick: e => trackClickElem(e)})}>
              <input {...getInputProps({
                id: 'file-' + props.name,
                required: props.required && files.length === 0,
                className: 'form-control'
              })} />
              <div className='w-100 mb-2'>
                <div className='text-right'>
                  <Button type='button' id='replaceResume' variant='ats-md-btn mr-4'>
                    Replace
                  </Button>

                  <Button type='button' id='replaceAndParseResume' variant='ats-md-btn btn-primary'>
                    Replace and Parse
                  </Button>
                </div>

                {rejectedFiles.length > 0 && rejectedFiles[0].size > maxSize &&
                <div className="text-danger mt-2">
                  File is too large.
                </div>}
              </div>
            </div>
            :
            ''
        }
      </>
    );
  }
};
