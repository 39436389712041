import {cloneDeep, compact, get, groupBy, has, isArray, isEmpty, keyBy, pick} from '../../../libs/lodash';
import utils from '../../../libs/utils';
import subHighlights from '../TableView/config/highlights';
import groupByOptions from '../TableView/config/groupByOptions';

const prepareForRenderSubmissionActivity = (data) => {
  return {
    entity: data,
    activityType: { name: 'SUBMISSION'}
  };
};

const prepareForRenderSubmissionActivityList = (activity) => {
  const reversedActivity = cloneDeep(activity.reverse());
  let cachedStage = 'PRE_SUBMISSION';
  const processedActivity = reversedActivity.map((activity) => {
    activity.entity.difference = activity.entity.difference ? activity.entity.difference : {};
    if (!activity.entity.difference.after) {
      activity.entity.difference.after = {};
    }
    if (activity.entity.difference.after.stage) {
      cachedStage = activity.entity.difference.after.stage;
    } else {
      activity.entity.difference.after.stage = cachedStage;
    }
    return activity;
  });
  return processedActivity.reverse();
};

const prepareForRenderSubmission = (data) => {
  const notifyTargets = {
    submissionOwnerUserId: get(data, 'owner.userId'),
    jobOwnerUserId: get(data, 'job.owner.userId'),
    primaryRecruiterUserId: get(data, 'job.primaryRecruiter.userId'),
    candidateRecruiterUserId: get(data, 'candidate.recruiter.userId'),
    coRecruiterUserIds: (data.job.coRecruiters || []).map((recruiter) => recruiter.userId)
  };
  data.notifyTargetIds = utils.prepareNotifyTargetIds(notifyTargets);
  data.origOwner = utils.convertObjectToOptionFormat(data.owner);
  data.ownerUserId = data.owner && data.owner.userId;

  return data;
};

const prepareForApiSubmission = (data) => {
  const apiData = {};
  const submission = pick(cloneDeep(data), [
    'stage',
    'candidateIds',
    'jobIds',
    'ownerUserId',
    'status',
    'rejectedReason',
  ]);

  if (data.rateInfo && !isEmpty(data.rateInfo)) {
    submission.rateInfo = pick(data.rateInfo, [
      'jobType',
      'rateType',
      'currencyType',
      'payRate',
      'billRate',
      'markup',
      'baseSalary',
      'permRate',
      'grossProfit',
      'totalCompensation',
    ]);
  }

  if (data.auditLogComment) {
    submission.auditLogComment = encodeURIComponent(data.auditLogComment);
  }

  if (data.submissionId) {
    apiData.id = data.submissionId;
    apiData.submission = pick(submission, ['rateInfo', 'ownerUserId', 'stage', 'status', 'auditLogComment', 'rejectedReason']);
  } else {
    apiData.submission = submission;
  }

  if (has(data, 'notifyTargetIds') && Array.isArray(data.notifyTargetIds) && data.notifyTargetIds.length>0) {
    utils.splitIdsForApi(data.notifyTargetIds, apiData.submission);
  }

  return apiData;
};

const calculateHighlightsSubmission = (data) => {
  let result = {};
  try {
    subHighlights.forEach((h) => {
      result[h.value] = 0;
    });

    data.forEach((sub) => {
      if (sub.status === 'REJECTED') {
        result['NUM_REJECTED'] += 1;
      } else {
        result['NUM_OPEN'] += 1;

        if (!['PRE_SUBMISSION', 'INTERNAL_REVIEW'].includes(sub.stage)) {
          result['NUM_ACTIVE'] += 1;
        }
      }

      result['NUM_PAST_INTERVIEWS'] += get(sub, 'pastInterviewsCount', 0);
      result['NUM_UPCOMING_INTERVIEWS'] += get(sub, 'upcomingInterviewsCount', 0);
    });

    return result;
  } catch (err) {
    console.log(err);
    return result;
  }
};

const calculateNumOfSubmissionByGroup = (subs, groupByName, initData, lookups) => {
  try {
    let numSubs = [], maxSubs = 0, subsGroupBy, keys, valueAttrName, entityTypeAttrName, identity;
    const lookupsKeyBy = keyBy(groupByOptions, 'id');
    const groupByOption = lookupsKeyBy[groupByName] || {};
    keys = Object.keys(keyBy(lookups[groupByOption.lookupsKey] || [], 'id'));
    valueAttrName = groupByOption.valueAttr;
    entityTypeAttrName = groupByOption.entityTypeAttr;

    switch (groupByName) {
    case 'stage':
    case 'job.company.companyType':
    case 'job.company.companyId':
    case 'job.jobId':
    case 'businessUnit.id':
    case 'candidate.candidateId':
    case 'rateInfo.rateType':
    case 'job.primaryRecruiter.userId':
    case 'job.owner.userId':
    case 'owner.userId':
    case 'status':
    case 'rejectedReason':
    case 'candidate.source':
      subsGroupBy = groupBy(subs, groupByName);
      if(isEmpty(keys)) {
        keys = Object.keys(subsGroupBy);
      } else if (subsGroupBy['undefined']) {
        keys.push('undefined');
      }

      (keys || []).forEach((key, index) => {
        let subsCount;

        if (key === 'undefined' && subsGroupBy[null]) {
          subsCount = (subsGroupBy[key] || []).concat(subsGroupBy[null] || []).length;
        } else {
          subsCount = (subsGroupBy[key] || []).length;
        }

        maxSubs = subsCount > maxSubs ? subsCount : maxSubs;
        let label;

        if (valueAttrName) {
          label = get(subsGroupBy[key][0], valueAttrName);
          label = typeof label !== 'string' ? compact([label.preferredName || label.firstName, label.lastName]).join(' ') : label;
        } else {
          if (['job.company.companyType'].includes(groupByName)) {
            label = utils.camelize(key);
          } else {
            label = (keyBy(lookups[(lookupsKeyBy[groupByName] || {}).lookupsKey], 'id')[key] || {}).name || 'Not Specified';
          }
        }

        identity = entityTypeAttrName && label !== 'Not Specified' ? `\n(${entityTypeAttrName}${key})` : '';
        numSubs.push({
          filterBy: isNaN(parseInt(key)) ? key : +key,
          filterByLabel: utils.contentForRender(label, 30, false) + (identity ? identity : ''),
          num: subsCount
        });
      });

      break;
    default:
    }

    return {data: numSubs, maxNum: maxSubs};
  } catch (err) {
    console.log(err);
    return {data: initData[groupByName] || [], maxNum: 1};
  }
};

const doesExternalFilterPassSubmissons = (submission, filteredBy) => {
  try {
    const filterCriteria = filteredBy.filterCriteria || {};
    const filteredFrom = filteredBy.filteredFrom;
    const keys = Object.keys(filterCriteria);

    // no any filter
    if (!keys) {
      return true;
    }

    const key = keys[0];
    let value = filterCriteria[key];

    // no any filter value
    if (!value) {
      return true;
    }

    const notSpecifiedIndex = isArray(value) ? value.indexOf('undefined') : -1;
    let realValue = get(submission, key);

    // active submission
    if (key === 'stage' && filteredFrom === 'highlights') {
      return value.includes(realValue) && submission.status !== 'REJECTED';
    }

    if (notSpecifiedIndex !== -1) {
      value = [...value.slice(0, notSpecifiedIndex), ...value.slice(notSpecifiedIndex + 1)];
      return value.includes(realValue) || !realValue;
    } else {
      return value.includes(realValue);
    }
  } catch (err) {
    console.log(err);
  }
};

export {
  prepareForRenderSubmission,
  prepareForRenderSubmissionActivity,
  prepareForRenderSubmissionActivityList,
  prepareForApiSubmission,
  calculateHighlightsSubmission,
  calculateNumOfSubmissionByGroup,
  doesExternalFilterPassSubmissons
};
