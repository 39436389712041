import React from 'react';
import utils from '../../../../libs/utils';
import PropTypes from 'prop-types';

export default function UpdatedCreatedInfo({entityType, createdAt, updatedAt, isReport=false}) {
  return (
    <div className={`${isReport ? 'col-sm-3 col-md-3 col-2 pl-0 text-nowrap ' : ''}pt-1 light-grey-text`}>
      Updated {utils.dateDiff(updatedAt, (new Date()).toISOString())} ago
      <span className='float-right'>
        {entityType==='job' ? 'Open ' : ''}{utils.dateDiff(createdAt, (new Date()).toISOString())}{['candidate', 'company'].includes(entityType) ? ' old' : ''}
      </span>
    </div>
  );
}

UpdatedCreatedInfo.propTypes = {
  entityType: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired,
  updatedAt: PropTypes.string.isRequired
};
