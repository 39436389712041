import React from 'react';

export default ({ type }) => {
  if (type === 'email' || type === 'sso') {
    return (
      <div className='d-flex align-items-center pb-4 mb-4'>
        <span className='ats-brand'/>
        <div className='ats-tile'>
          <div className='loginTitle'>
            Launch ATS
          </div>
        </div>
      </div>
    );
  }

  if (type === 'resetPassword') {
    return (
      <div className='d-flex pb-4 mb-4'>
        <div className='ats-tile'>
          <div className='loginTitle'>
            Reset Password
          </div>
        </div>
      </div>
    );
  }

  if (type === 'sentReset') {
    return (
      <div className='d-flex pb-4 mb-4'>
        <div className='ats-tile'>
          <div className='loginTitle'>
            Reset email sent
          </div>
        </div>
      </div>
    );
  }

  if (type === 'setPasswordSuccess') {
    return (
      <div className='d-flex pb-4 mb-4'>
        <div className='ats-tile'>
          <div className='loginTitle'>
            You are set
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className='d-flex pb-4 mb-4'>
      <div className='ats-tile'>
        <div className='loginTitle'>
        </div>
      </div>
    </div>
  );

};
