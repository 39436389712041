import React from 'react';
import {groupBy, isEmpty} from '../../../libs/lodash';
import Skills from '../../Candidates/Details/Skills';
import layoutConst from '../../../constants/layoutConst';

function CategoriesRead({object={}, editIcon, businessUnitId}) {
  const twoColWiderScreenCss = layoutConst.twoColWiderScreenCss;

  function groupsByDepartment(list){
    const result = groupBy(list, 'department.name');
    let groupByDepartment = [];
    Object.keys(result).forEach(function(key) {
      groupByDepartment.push({name:key, options: result[key]});
    });
    return groupByDepartment;
  }

  const prepareCategoriesDetails = () => {
    if (businessUnitId && businessUnitId===2) {
      return (
        <div id='categories-details-read'>
          <div className={twoColWiderScreenCss}>
            <div className='label'>Facility Types</div>
            <div className='value d-inline-block ats-inline'>
              {(object.phcFacilityTypes || []).map((phcFacilityType) => phcFacilityType.name).join(', ')}
              {editIcon('categories', 'phcFacilityTypeIds')}
            </div>
          </div>

          <div className={twoColWiderScreenCss}>
            <div className='label'>Career Codes</div>
            <div className='value d-inline-block ats-inline'>
              {(object.phcCareerCodes || []).map((phcCareerCode) => phcCareerCode.name).join(', ')}
              {editIcon('categories', 'phcCareerCodeIds')}
            </div>
          </div>

          <div className={twoColWiderScreenCss}>
            <div className='label'>Highlighted Skills</div>
            <div className='value d-inline-block ats-inline force-wrap mr-5'>
              {(object.highlightedSkills || []).join(', ')}
              {editIcon('categories', 'highlightedSkills')}
            </div>
          </div>

          <Skills parsedResumeInfo={object.parsedResumeInfo} />
        </div>
      );
    } else {
      return (
        <div id='categories-details-read'>
          <div className={twoColWiderScreenCss}>
            <div className='label'>Industries</div>
            <div className='value d-inline-block ats-inline'>
              {(object.industries || []).map((industry) => industry.name).join(', ')}
              {editIcon('categories', 'industryIds')}
            </div>
          </div>

          <div className={twoColWiderScreenCss}>
            <div className='label'>Departments</div>
            <div className='value d-inline-block ats-inline'>
              {(object.departments || []).map((department) => department.name).join(', ')}
              {editIcon('categories', 'departmentIds')}
            </div>
          </div>

          <div className={twoColWiderScreenCss}>
            <div className='label'>Job Functions</div>
            <div className='value d-inline-block ats-inline'>
              {isEmpty(object.jobFunctions) ? editIcon('categories', 'jobFunctionIds') : ''}
              {groupsByDepartment(object.jobFunctions).map((group, index) => {
                return (
                  <div key={`department-jobFunction-${index}`}>
                    <div>
                      {group.name}
                      {index===0 ? editIcon('categories', 'jobFunctionIds') : ''}
                    </div>
                    {group.options.map((jobFunction, jfIndex) => {
                      return <div className='pl-3' key={`jobFunction-${jfIndex}`}>{jobFunction.name}</div>;
                    })}
                  </div>
                );
              })}
            </div>
          </div>

          <div className={twoColWiderScreenCss}>
            <div className='label'>Skills</div>
            <div className='value d-inline-block ats-inline'>
              {isEmpty(object.skills) ? editIcon('categories', 'skillIds') : ''}
              {groupsByDepartment(object.skills).map((group, index) => {
                return (
                  <div key={`department-skill-${index}`}>
                    <div>
                      {group.name}
                      {index===0 ? editIcon('categories', 'skillIds') : ''}
                    </div>
                    {group.options.map((skill, skillIndex) => {
                      return <div className='pl-3' key={`skill-${skillIndex}`}>{skill.name}</div>;
                    })}
                  </div>
                );
              })}
            </div>
          </div>

          <div className={twoColWiderScreenCss}>
            <div className='label'>Highlighted Skills</div>
            <div className='value d-inline-block ats-inline force-wrap mr-5'>
              {(object.highlightedSkills || []).join(', ')}
              {editIcon('categories', 'highlightedSkills')}
            </div>
          </div>

          <Skills parsedResumeInfo={object.parsedResumeInfo} />
        </div>
      );
    }
  };

  return (
    <>
      {prepareCategoriesDetails()}
    </>
  );
}

export default CategoriesRead;
