import React from 'react';
import PropTypes from 'prop-types';
import utils from '../../../libs/utils';
import {isEmpty} from '../../../libs/lodash';
import Candidate from '../../Search/SearchList/DetailView/Candidate';
import Job from '../../Search/SearchList/DetailView/Job';
import Submission from '../../Submissions/Tab/Submission';
import Company from '../../Search/SearchList/DetailView/Company';
import Placement from '../../Search/SearchList/DetailView/Placement';
import Contact from '../../Search/SearchList/DetailView/Contact';
import useLookup from '../../../hooks/useLookup';
import Deal from '../../Search/SearchList/DetailView/Deal';

export default function AtsRelatedEntitesInfo({modalContent, entity, relatedEntity, openRelatedEntityModal, elementId}) {
  const entityType = entity.entityType || entity.type;
  const modalContentType = modalContent.entityType || modalContent.type;
  const modalContentId = modalContent.entityId || modalContent.id;
  const lookups = useLookup({names: ['users', 'teams', 'businessUnits', 'practiceAreas', 'badges', 'industries', 'departments', 'jobFunctions', 'skills', 'phcCareerCodes', 'phcFacilityTypes']});
  const mapToEntityComponent = {
    'CANDIDATE': Candidate,
    'JOB': Job,
    'SUBMISSION': Submission,
    'COMPANY': Company,
    'PLACEMENT': Placement,
    'CONTACT': Contact,
    'DEAL': Deal
  };

  const clickRelatedEntity = (e, openRelatedEntityModal) => {
    e.preventDefault();
    if (openRelatedEntityModal && typeof openRelatedEntityModal === 'function') {
      openRelatedEntityModal({entityType: modalContentType, entityId: modalContentId});
    }
  };

  const adjustModalPosition = (elementId) => {
    const updateModalEl = document.getElementById(elementId);
    if (updateModalEl) {
      updateModalEl.style.marginLeft = '35%';
      updateModalEl.style.marginRight = '10%';
    }
  };
  adjustModalPosition(elementId);

  const EntityComponent = mapToEntityComponent[modalContentType];
  return <div className='related-entity-info-box' onClick={(e) => {clickRelatedEntity(e, openRelatedEntityModal);}} style={{cursor: 'pointer'}}>
    <div className='pb-4 biotif-semi-bold font-size-22'>Related {utils.titleCase(modalContentType)}:</div>
    {!isEmpty(relatedEntity) && EntityComponent
      ?
      <EntityComponent submission={relatedEntity} entityType={entityType} entity={relatedEntity} isActive={false} lookups={lookups} openNewTab={true}/>
      :''
    }
  </div>;
};

AtsRelatedEntitesInfo.propTypes = {
  modalContent: PropTypes.object.isRequired,
  entity: PropTypes.object.isRequired,
  relatedEntity: PropTypes.object.isRequired,
};


