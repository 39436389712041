const maxResults = 100;
const sortOrder = -1;
const sortBy = 'updatedAt';
const submissionTabSortBy = 'stageOrder';
const submissionTabMaxResults = 500;
const maxJobIdsForSubmissionSearch = 1000;

const stages = ['PRE_SUBMISSION', 'INTERNAL_REVIEW', 'CLIENT_REVIEW', 'INTERVIEWING', 'OFFERED', 'PLACED'];

const defaultActivityTypeNames = [
  'Pre-Submission',
  'Internal Review',
  'Client Review',
  'Interviewing',
  'Offered',
  'Placed',
];

const sortByOptions = {'updatedAt': 'Activity Date', 'activityName': 'Activity Name'};
const submissionTabSortByOptions = {'stageOrder': 'Stage', 'updatedAt': 'Activity Date', 'activityName': 'Activity Name'};

const INTERNAL_REJECTION    = 'INTERNAL_REJECTION';
const PREVIOUSLY_SUBMITTED  = 'PREVIOUSLY_SUBMITTED';
const COMPETITOR_SUBMISSION = 'COMPETITOR_SUBMISSION';
const WITHDRAWN_RATE        = 'WITHDRAWN_RATE';
const WITHDRAWN_ROLE        = 'WITHDRAWN_ROLE';
const WITHDRAWN_OTHER       = 'WITHDRAWN_OTHER';
const CLIENT_PRE_INTERVIEW  = 'CLIENT_PRE_INTERVIEW';
const CLIENT_POST_INTERVIEW = 'CLIENT_POST_INTERVIEW';
const NO_START              = 'NO_START';

const rejectedReasons = [
  {id: INTERNAL_REJECTION,    name: 'Internal Rejection'},
  {id: PREVIOUSLY_SUBMITTED,  name: 'Previously Submitted'},
  {id: COMPETITOR_SUBMISSION, name: 'Competitor Submission'},
  {id: WITHDRAWN_RATE,        name: 'Withdrawn - Rate'},
  {id: WITHDRAWN_ROLE,        name: 'Withdrawn - Role'},
  {id: WITHDRAWN_OTHER,       name: 'Withdrawn - Other'},
  {id: CLIENT_PRE_INTERVIEW,  name: 'Client Pre-Interview Rejection'},
  {id: CLIENT_POST_INTERVIEW, name: 'Client Post-Interview Rejection'},
  {id: NO_START,              name: 'No Start'},
];

const notAutoFillFields = ['baseSalary', 'grossProfit', 'totalCompensation', 'billRate', 'markup', 'payRate', 'perDiem', 'permRate'];

const submissionConst = {
  stages,
  sortBy,
  sortOrder,
  maxResults,
  sortByOptions,
  rejectedReasons,
  notAutoFillFields,
  defaultActivityTypeNames,
  submissionTabSortBy,
  submissionTabMaxResults,
  submissionTabSortByOptions,
  maxJobIdsForSubmissionSearch
};
export default submissionConst;
