import {has, isEmpty} from '../../../libs/lodash';
import utils from '../../../libs/utils';

function prepareForRenderMergeRecord(defaultEntities = []) {
  const defaultRecords = defaultEntities.filter(entity => has(entity, 'entityType') && has(entity, 'entityId'));

  if (isEmpty(defaultRecords)) {
    return [{}];
  }

  defaultRecords.push({});

  return defaultRecords;
}

function prepareForApiMergeRecord(data) {
  const { entities, primaryEntity } = data;
  const { entityId, entityType } = primaryEntity;

  const apiData = {
    primaryEntityId: entityId,
    sourceEntityIds: entities.filter(entity => entity.entityType === entityType).map(entity => entity.entityId).filter(id => id !== entityId),
  };

  if (data.auditLogComment && data.auditLogComment.trim()) {
    apiData.auditLogComment = encodeURIComponent(data.auditLogComment.trim());
  }

  if (has(data, 'notifyTargetIds') && Array.isArray(data.notifyTargetIds) && data.notifyTargetIds.length > 0) {
    utils.splitIdsForApi(data.notifyTargetIds, apiData);
  }

  return apiData;
}

export {
  prepareForRenderMergeRecord,
  prepareForApiMergeRecord
};
