import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import InputNumber from 'antd/es/input-number';
import 'antd/es/input-number/style/css';
import './style.scss';
import maxMinConst from '../../../constants/maxMinConst';

export default function AtsInputNumber(props) {
  const [value, setValue] = useState('');

  useEffect(() => {
    setValue((props.defaultValue || props.defaultValue === 0) ?  props.defaultValue : '');
  }, [props.defaultValue]);

  const handleChange = (value) => {
    setValue(value);
    props.callback({[props.name]:  (value || value === 0 ? parseFloat(value) : null)});
  };

  /*const handleKeyPress = (e, value) => {
    if (e.key === 'Enter') {
      setValue(value);
      props.callback(props.name, value ? value : null);
    }
  };*/

  return (
    <InputNumber
      id={props.id}
      name={props.name}
      value={value}
      min={props.min}
      max={props.max || Infinity}
      precision={props.precision}
      maxLength={props.maxLength ? props.maxLength : maxMinConst.ShortTextMaxlength}
      formatter={value => `${value}`.replace(props.formatter, ',')}
      parser={value => value.replace(props.parser, '')}
      onChange={handleChange}
      //onBlur={() => props.callback(props.name,value)}
      //onKeyDown={(e) => handleKeyPress(e, value)}
      placeholder={props.placeholder}
      required={props.required}
      className={`form-control ${props.classname}`}
      autoComplete='off'
    />
  );
}

AtsInputNumber.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  defaultValue: PropTypes.number,
  required: PropTypes.bool,
  min: PropTypes.number,
  callback: PropTypes.func.isRequired
};
