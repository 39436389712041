import './style.scss';
import React, {useCallback, useState} from 'react';
import PropTypes from 'prop-types';
import {Button, AtsRichTextArea, FormFields, AtsConfirmModal} from '../common';
import {toast} from 'react-toastify';
import useLookup from '../../hooks/useLookup';
import useNotifyUserIds from '../../hooks/useNotifyUserIds';
import MergeRecordsItem from './MergeRecordsItem';
import {has, isEmpty, uniq} from '../../libs/lodash';
import MergeRecordsConfirmInfo from './MergeRecordsConfirmInfo';
import searchConst from '../../constants/searchConst';
import Spinner from '../common/Spinner';
import ApiClient from '../../services/ApiClient';
import {prepareForApiMergeRecord, prepareForRenderMergeRecord} from './libs/utils';

export default function MergeRecordForm({defaultEntities=[{}], modalCancel, closeDrawer, defaultNotifyTargetIds=[], history}) {
  const lookups = useLookup({names: ['users', 'teams']});
  const [entities, setEntities] = useState(prepareForRenderMergeRecord(defaultEntities));
  const [primaryEntity, setPrimaryEntity] = useState({index: 0, entityType: defaultEntities[0].entityType, entityId: defaultEntities[0].entityId});
  const [notifyTargetIds, setNotifyTargetIds] = useState(defaultNotifyTargetIds);
  const [auditLogComment, setAuditLogComment] = useState('');
  const [confirmModalVisible, setConfirmModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const closeForm = () => {
    modalCancel();
    if(closeDrawer) {
      closeDrawer();
    }
  };

  const toggleConfirmModal = (e) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }

    setConfirmModalVisible(!confirmModalVisible);
  };

  const validateMergeCompanyTypes = (companies) => {
    const companyTypes = uniq(companies.map(company => company.companyType).filter(type => !!type));
    if (companyTypes.length > 1) {
      const msg = 'Companies must be of the same type';
      toast.error(msg, {
        position: toast.POSITION.TOP_RIGHT
      });

      return false;
    }

    return true;
  };

  const submitForm = async () => {
    if (isLoading) {
      toast.warn('Currently merge is processing, so please wait!', { position: 'top-right' });
      return;
    }

    if (primaryEntity.entityType.toUpperCase() === 'COMPANY' && !validateMergeCompanyTypes([primaryEntity, ...entities])) {
      return;
    }

    const entity = prepareForApiMergeRecord({primaryEntity, entities, notifyTargetIds, auditLogComment});
    try {
      if (['PLACEMENT'].includes(primaryEntity.entityType.toUpperCase())) {
        toast.warn(`Currently ${primaryEntity.entityType.toLowerCase()} merge not available yet!`, { position: 'top-right' });
        return;
      }

      setIsLoading(true);

      const operationId = `${searchConst.entityMap[primaryEntity.entityType.toLowerCase()]}Merge`;
      const response = await ApiClient[operationId]({}, entity);
      const identifier = `${primaryEntity.entityType.toLowerCase()}Id`;

      const msg = 'Merge in progress!';
      toast(msg, {
        className: 'toast-response-background',
        bodyClassName: 'toast-response-body',
        position: 'top-right'
      });

      closeForm();
      checkMergeInProgress(response.body, response.body[identifier]);


    } catch (error) {
      console.error('submitForm error:', error);
      setIsLoading(false);
    }
  };

  const checkMergeInProgress = useCallback(async (mergedEntity, entityId) => {

    try {
      const operationId = `${searchConst.entityMap[primaryEntity.entityType.toLowerCase()]}Details`;
      const { body: { mergeInProgress } } = await ApiClient[operationId]({id: entityId});
      const msg = 'Merge Records is done!';
      const toastClass = {
        className: 'toast-response-background',
        bodyClassName: 'toast-response-body',
        position: 'top-right'
      };

      if(mergeInProgress === undefined || mergeInProgress === false){
        toast(msg, toastClass);

        if (history) {
          const entity = mergedEntity;
          history.push(`/${primaryEntity.entityType.toLowerCase()}/${entity[`${primaryEntity.entityType.toLowerCase()}Id`]}`);
        }
        return;
      }

      setTimeout(()=>{
        checkMergeInProgress(mergedEntity, entityId);
      }, 2000);

    } catch (error) {
      console.error('checkMergeInProgress error:', error);
    };
  }, [history, primaryEntity.entityType]);

  const callback = (data) => {
    if (has(data, 'record')) {
      const newEntities = [...entities];
      if (data.record) {
        const { entityType, entityId } = data.record;

        newEntities.splice(data.index, 1, data.record);
        newEntities.push({});

        if (data.index === primaryEntity.index) {
          setPrimaryEntity({...primaryEntity, entityType, entityId});
        }
      } else {
        newEntities.splice(data.index, 1);
        if (newEntities.length === 0) {
          newEntities.push({});
        }
        if (newEntities.length === 1 && isEmpty(newEntities[0])) {
          setPrimaryEntity({index: 0, entityType: defaultEntities[0].entityType, entityId: defaultEntities[0].entityId});
        }
      }
      setEntities(newEntities);
    }

    if (has(data, 'primaryEntity')) {
      setPrimaryEntity(data.primaryEntity);
    }

    if (has(data, 'auditLogComment')) {
      setAuditLogComment(data.auditLogComment);
    }

    if (has(data, 'notifyTargetIds')) {
      setNotifyTargetIds(data.notifyTargetIds);
    }
  };

  const {notifyTargetIdsField} = useNotifyUserIds({lookups, handleChange: callback, entity: { notifyTargetIds } });

  function checkMergeEntities() {
    if (isLoading) {
      return true;
    }

    const entityIds = entities.map(entity => entity.entityId).filter(id => id !== undefined);
    return entityIds.length < 2;
  }

  return (
    <>
      <form id='merge-Records-form' name='merge-Records-form' noValidate onSubmit={toggleConfirmModal} autoComplete='off'>
        {/* Records */}
        <div>
          <label htmlFor='primary'>
            Primary
            <span className='required'>*</span>
          </label>
        </div>
        <div>
          {
            entities.map((entity, index) =>
              <MergeRecordsItem
                entity={entity}
                callback={callback}
                primaryEntity={primaryEntity}
                index={index}
                entities={entities}
                key={`merge-record-${entity.entityId}-${index}`}
              />
            )
          }
        </div>

        {/* Comment */}
        <div className='form-group'>
          <AtsRichTextArea
            name='auditLogComment'
            label={<label>Comment</label>}
            entityType={primaryEntity.entityType || ''}
            handleChange={callback}
          />
        </div>

        {/* Notify Users or Teams */}
        <FormFields
          fields={notifyTargetIdsField}
          callback={callback}
        />

        <div className='form-group'>
          <div className='d-flex justify-content-end pt-3'>
            <Button type='button' id='close-merge-Records' variant='btn-secondary mr-4' click={() => closeForm()}>
              Cancel
            </Button>
            <Button type="submit" id='submit-merge-Records' variant='btn-primary ats-md-btn' disabled={checkMergeEntities()}>
              Merge
            </Button>
          </div>
        </div>
      </form>
      {
        confirmModalVisible?
          <AtsConfirmModal
            modalId='merge-records-confirm-modal'
            modalTitle='Confirm Merge'
            modalVisible={confirmModalVisible}
            modalCancel={toggleConfirmModal}
            confirmCallback={submitForm}
            disabled={isLoading}
          >
            <form name='merge-records-confirm' noValidate onSubmit={(e) => e.preventDefault()} autoComplete='off'>
              {
                !isLoading ? <MergeRecordsConfirmInfo /> : <span className='font-size-36'><Spinner variant='text-center'/></span>
              }
            </form>
          </AtsConfirmModal> : ''
      }
    </>
  );
}

MergeRecordForm.propTypes = {
  entity: PropTypes.object
};
