import { combineReducers } from 'redux';
import searchEntityReducer from './searchEntityReducer';
import activityReducer from './activityReducer';
import hotKeyReducer from './hotKeyReducer';
import submissionReducer from './submissionReducer';
import notificationReducer from './notificationReducer';
import unSavedReportsReducer from './unSavedReportsReducer';
import taskReducer from './taskReducer';
import appointmentReducer from './appointmentReducer';
import dealsReducer from './dealsReducer';
import dealReducer from './dealReducer';

export default combineReducers({
  searchEntityReducer,
  activityReducer,
  hotKeyReducer,
  submissionReducer,
  dealReducer,
  notificationReducer,
  unSavedReportsReducer,
  taskReducer,
  appointmentReducer,
  dealsReducer
});
