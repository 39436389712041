import React from 'react';
import Action from './Actions';
import {get} from '../../../libs/lodash';
import utils from '../../../libs/utils';

import './style.scss';

export default function AtsSearchReportListHeader({actionFor, exportPermissionKey, criteria, criteriaLabel, displayCritera, toggleList, openModal, canEdit, canSavecriteria, saveSavedCriteria}) {
  const actionForIdAttr = actionFor === 'report' ? 'reportId' : 'savedSearchId';

  return (
    <div className='header'>
      <div className='title d-flex align-items-center'>
        <div>{criteriaLabel}</div>
        <div className='ml-auto cursor toggle-side-menu' onClick={toggleList}>
          &nbsp;
        </div>
      </div>

      <div>Current filters:</div>
      <div className={`d-flex search-for ${canEdit ? 'cursor' : ''}`}>
        {get(criteria, actionForIdAttr) ?
          <>
            <div className='criteria'>{utils.contentForRender(criteria.name, 38)}</div>
            {canEdit ?
              <div className='editIcon' title='click to edit' onClick={(e) => openModal(e, `edit-${actionFor}`)}/>
              :
              ''
            }
          </>
          :
          <span className='criteria'>{displayCritera() || 'No Criteria'}</span>
        }
      </div>
      <div className='d-flex pt-2 pb-3'>
        <Action
          actionFor={actionFor}
          canEdit={canEdit}
          exportPermissionKey={exportPermissionKey}
          criteria={criteria}
          openModal={openModal}
          canSavecriteria={canSavecriteria}
          saveSavedCriteria={saveSavedCriteria}
        />
      </div>
    </div>
  );
}
