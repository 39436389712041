import utils from '../../../libs/utils';
import candidateConst from '../../../constants/candidateConst';
import {cloneDeep, get, has, isEmpty, pick} from '../../../libs/lodash';
import settingConst from '../../../constants/settingConst';

const prepareForRenderTasks = (data) => {
  const id = data.taskId || '';
  const entityType = 'TASK';
  const assignedToUserName = utils.shortName(data.assignedTo, candidateConst.maxFullNameLength, true);
  const assignedToUserId = get(data, 'assignedTo.userId');

  const relatedEntityId = get(data, 'entity.id');
  const relatedEntityType = get(data, 'entity.type');
  const relatedEntityShortName = settingConst.globalEntityAbbreviations[relatedEntityType] || relatedEntityType;
  const relatedEntityName = relatedEntityId ? get(data, 'entity.name') + ` (${relatedEntityShortName}${relatedEntityId})` : '';
  return {
    ...data,
    id,
    entityType,
    assignedToUserName,
    assignedToUserId,
    relatedEntityName,
  };
};

const prepareForApiTask = (data) => {
  const apiData = pick(cloneDeep(data), [
    'subject',
    'status',
    'description',
    'assignedToUserId',
    'format',
    'priority',
    'dueDate',
    'format',
    'noteId',
    'format',
    'isDeleted',
    'isPrivate',
    'bullhornId',
  ]);

  if (has(apiData, 'description')) {
    apiData.description = encodeURIComponent(apiData.description);
  }

  if (has(apiData, 'dueDate')) {
    apiData.dueDate = apiData.dueDate ? (new Date(apiData.dueDate)).toISOString() : apiData.dueDate;
  }

  if (!isEmpty(data.entity)) {
    apiData.entity = data.entity;
  }

  if (has(data, 'notifyTargetIds') && Array.isArray(data.notifyTargetIds) && data.notifyTargetIds.length>0 && get(data, 'entity.id')) {
    utils.splitIdsForApi(data.notifyTargetIds, apiData);
  }

  return apiData;
};

export {
  prepareForRenderTasks,
  prepareForApiTask,
};
