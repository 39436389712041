import React from 'react';
import PropTypes from 'prop-types';
import utils from '../../../libs/utils';

export default function Metadata({entityType, metadata, isReport=false}) {
  const submissions = metadata.submissions ?  metadata.submissions : {};
  const jobs = metadata.jobs ?  metadata.jobs : {};
  const placements = metadata.placements ?  metadata.placements : {};
  const contacts = metadata.contacts ?  metadata.contacts : {};
  function renderMetadata() {
    switch (entityType) {
    case 'job':
      return (
        <>
          <div className='d-table-cell totalCount pr-1'>{submissions.numTotal || 0} Total</div>
          <div className='d-table-cell activeSentCount pr-1 text-center'>{submissions.numActive || 0} Active</div>
          <div className='d-table-cell interviewRate text-right'>{submissions.interviewRate || 0}%</div>
        </>
      );
    case 'candidate':
      return (
        <>
          <div className='d-table-cell activeSentCount pr-1'>{submissions.numSent || 0} Sent</div>
          <div className='d-table-cell metCount pr-1 text-center'>{submissions.numMet || 0} Met</div>
          <div className='d-table-cell placedCount text-right'>{placements.numTotal || 0} Placed</div>
        </>
      );
    case 'company':
      return (
        <>
          <div className='d-table-cell jobOpenCount pr-1'>{jobs.numOpen || 0} Open</div>
          <div className='d-table-cell jobActiveCount pr-1 text-center'>{jobs.numActive || 0} Active</div>
          <div className='d-table-cell contactCount text-right'>{contacts.numTotal || 0} {contacts.numTotal === 1 ? 'Contact' : 'Contacts'}</div>
        </>
      );
    case 'contact':
      return (
        <>
          <div className='d-table-cell jobOpenCount pr-1'>{jobs.numOpen || 0} Open</div>
          <div className='d-table-cell placedCount pr-1 text-center'>{placements.numTotal || 0} Active</div>
        </>
      );
    default:
      break;
    }
  }

  return (
    <>
      {entityType !== 'job' || isReport ? '' :
        <div className={`pt-1${submissions.latestStageStatus ? '' : ' d-none'}`}>
          {utils.camelize(submissions.latestStageStatus)}
        </div>
      }
      <div className={`metadata d-table pt-1 ${isReport ? 'col-sm-3 col-md-3 col-2 pl-0 text-nowrap' : 'w-100'}`}>
        <div className='d-table-row'>
          {renderMetadata()}
        </div>
      </div>
    </>
  );
}

Metadata.propTypes = {
  entityType: PropTypes.string.isRequired,
  metadata: PropTypes.object.isRequired
};
