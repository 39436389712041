import React from 'react';
import attachedImgIcon from '../../../assets/img/attach.png';

export default ({ dropType, acceptFileTypeText, name }) => {
  const dropZoneText =  dropType === 'image' ? 'Image' : (dropType === 'resume-parsing' ? 'Files to Parse' : 'Files');

  switch (dropType) {
  case 'image':
  case 'drop-resume-anywhere':
    return (
      <div>
        <div className='item'><img src={attachedImgIcon} alt={name} width='80' height='100' /></div>
        <div className='item bold-text'>Drag & Drop {dropZoneText}</div>
        <div className='item file-extensions'>{acceptFileTypeText}</div>
      </div>
    );
  case 'supplementalDocuments':
    return (
      <>
        <div className='col-5'>
          <div className='bold-text'>Drag & Drop {dropZoneText}</div>
          <div className='file-extensions'>{acceptFileTypeText}</div>
        </div>
        <div className='or py-3 col-1'>or</div>
      </>
    );
  case 'mass-import':
  default:
    return (
      <>
        <div><img src={attachedImgIcon} alt={name} width='80' height='100' /></div>
        <div className='bold-text'>Drag & Drop {dropZoneText}</div>
        <div className='file-extensions'>{acceptFileTypeText}</div>
        <div className='or py-3'>or</div>
      </>
    );
  }
};
