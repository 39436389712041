import React from 'react';
import PropTypes from 'prop-types';
import searchConst from '../../constants/searchConst';
import utils from '../../libs/utils';
import AtsHighlighter from '../common/AtsHighlighter';
import {Link} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import * as notificationActions from '../../actions/notification';
import detailsConst from '../../constants/detailsConst';
import {notificationBasicInfo, notificationNameField} from './libs/utils';
import apiUserStatus from '../../services/apiUserStatus';
import {get} from '../../libs/lodash';

import trashIcon from '../../assets/img/Trash Icon.png';
import notificationFlagIcon from '../../assets/img/Notify Flag Icon - Red.png';
import notificationUnFlagIcon from '../../assets/img/Notify Flag Icon - Grey.png';

export default function NotificationItem({item, notificationFilter, history, lookups, closeDrawer, replyNotification}) {
  const dispatch = useDispatch();
  const entityType = (item.entity.type || '').toLowerCase();
  const entityTypePlural = searchConst.entityMap[entityType];
  const {userPermissionGroupIds = []} = apiUserStatus.get() || {};

  const readNotification = async () => {
    try {
      const parameters = {
        id: item.notificationId,
        notification: {
          isRead: true,
        }
      };
      dispatch(notificationActions.updateNotification(parameters));
    } catch (error) {
      console.log(error);
    }
  };

  const flagNotification = () => {
    const parameters = {
      id: item.notificationId,
      notification: {
        isFlagged: !item.isFlagged,
      }
    };
    dispatch(notificationActions.updateNotification(parameters));

    window.amplitudeLogEvent(
      'global notifications',
      {
        action: `${item.isFlagged ? 'unflag' : 'flag'} notification`,
        filterValue: (notificationFilter.searchEntityType || '').toLowerCase(),
        statusSelected: (notificationFilter.statusSelected || '').toLowerCase(),
        searchString: (notificationFilter.searchTerm || '').toLowerCase(),
        object: entityType
      }
    );
  };

  const deleteNotification = () => {
    const parameters = {
      id: item.notificationId,
      notification: {
        isDeleted: true,
      }
    };
    dispatch(notificationActions.updateNotification(parameters));

    window.amplitudeLogEvent(
      'global notifications',
      {
        action: 'delete notification',
        filterValue: (notificationFilter.searchEntityType || '').toLowerCase(),
        statusSelected: (notificationFilter.statusSelected || '').toLowerCase(),
        searchString: (notificationFilter.searchTerm || '').toLowerCase(),
        object: entityType
      }
    );
  };

  const clickToOpenView = async (e, entity) => {
    e.preventDefault();
    await readNotification();

    if (entity.type && entity.type.toLowerCase() !== 'submission') {
      const objectType = entity.type.toLowerCase();
      window.amplitudeLogEvent(
        'global notifications',
        {
          action: 'view object',
          filterValue: (notificationFilter.searchEntityType || '').toLowerCase(),
          statusSelected: (notificationFilter.statusSelected || '').toLowerCase(),
          searchString: (notificationFilter.searchTerm || '').toLowerCase(),
          object: objectType,
          timer: 'end'
        }
      );
      window.amplitudeLogEvent(
        'view',
        {
          action: 'view ' + objectType,
          globalNav: 'notifications slide-in',
          object: objectType,
          detailsTab: detailsConst.defaultTab[objectType] || 'details',
          from: '"notifications slide-in'
        }
      );

      let link = `/${entity.type.toLowerCase()}/${entity.id}`;
      if (entity.relatedEntity) {
        link = `/${entity.relatedEntity.type.toLowerCase()}/${entity.relatedEntity.id}`;
      } 

      history.push(link);
      closeDrawer();
    }
  };

  const prepareLink = (entity) => {
    if ((entity.type === 'APPOINTMENT' || entity.type === 'DEAL') && entity.relatedEntity) {
      return (
        <Link to={`/${entity.relatedEntity.type.toLowerCase()}/${entity.relatedEntity.id}`} id={`${entity.relatedEntity.type.toLowerCase()}-${entity.relatedEntity.id}`} onClick={(e) => clickToOpenView(e, entity)}>
          <AtsHighlighter search={notificationFilter.searchTerm} target={notificationNameField(entity, lookups)} />
        </Link>
      );
    }

    return (
      <Link to={`/${entity.type.toLowerCase()}/${entity.id}`} id={`${entity.type.toLowerCase()}-${entity.id}`} onClick={(e) => clickToOpenView(e, entity)}>
        <AtsHighlighter search={notificationFilter.searchTerm} target={notificationNameField(entity, lookups)} />
      </Link>
    );
  };

  const prepareNotifyLinkEntity = () => {
    if (item.entity.type.toLowerCase() === 'submission') {
      return (
        <>
          <div>
            {prepareLink(item.relatedEntities[0])}
          </div>
          <div>
            {prepareLink(item.relatedEntities[1])}
          </div>
        </>
      );
    }

    if (item.entity.type.toLowerCase() === 'target') {
      return (
        <div className='notification-target-name' id={`${item.entity.type.toLowerCase()}-${item.entity.id}`}>
          <AtsHighlighter search={notificationFilter.searchTerm} target={notificationNameField(item.entity, lookups)} />
        </div>
      );
    }

    if (item.entity.type.toLowerCase() === 'appointment' && item.relatedEntities[0]) {
      const relatedEntity = item.relatedEntities[0];
      return prepareLink({...item.entity, relatedEntity});
    }

    return prepareLink(item.entity);
  };

  function renderItem() {
    return (
      <>
        {
          !item.isDeleted ?
            <div id='notification-item' className={`d-flex item px-4 ${item.isRead?'read':'unread'} ${item.isFlagged?'flagged':''}`}>
              <div className='px-2 pt-1 mt-2'>
                <div className={`circle ${entityTypePlural}`} />
              </div>
              <div className='py-2 flex-grow-1'>
                <div className={`dark-grey-text cursor ${item.isRead?'biotif':'biotif-bold'}`} onClick={readNotification}>
                  <AtsHighlighter search={notificationFilter.searchTerm} target={notificationBasicInfo(item)} />
                </div>
                <div className={item.action !== 'TARGET' ? 'cursor' : ''} onClick={(e) => clickToOpenView(e, item)}>
                  {prepareNotifyLinkEntity()}
                </div>
                {item.company && get(item, 'entity.type') !== 'COMPANY' ?
                  <div className='light-grey-text'>{get(item, 'company.name')}</div>
                  :
                  ''
                }
                {
                  item.message && item.action !== 'TARGET'?
                    <div className='light-grey-text'>
                      <AtsHighlighter search={notificationFilter.searchTerm} target={utils.contentForRender(utils.convertHtmlToText(item.message), 100, false)} />&nbsp;
                    </div>:''
                }
                <div className='light-grey-text'>
                  <AtsHighlighter search={notificationFilter.searchTerm} target={utils.timeForRender(item.createdAt)} />&nbsp;
                  <img className={`notification-delete far fa-trash-alt cursor ${item.action === 'TARGET' ? 'target' : ''}`}
                    src={trashIcon}
                    alt='unflag notify'
                    width='auto' height='16'
                    onClick={deleteNotification}/>
                  {userPermissionGroupIds.includes('CREATE_EDIT_PRIMARY_OBJECTS') && item.action !== 'TARGET' ?
                    <span className={`${item.hasReplied ? 'notification-has-replied' : 'notification-reply-to'} cursor`} onClick={() => replyNotification(item)}/>
                    :
                    ''
                  }
                  {
                    !item.isFlagged?
                      <img className={'notification-unflag cursor'}
                        src={notificationUnFlagIcon}
                        alt='flag notify'
                        width='auto' height='16'
                        onClick={flagNotification}/>:
                      <img className={'notification-flag cursor'}
                        src={notificationFlagIcon}
                        alt='unflag notify'
                        width='auto' height='16'
                        onClick={flagNotification}/>
                  }
                </div>
              </div>
            </div> : ''
        }
      </>
    );
  }

  return (
    renderItem()
  );
};

NotificationItem.propTypes = {
  item: PropTypes.object.isRequired,
  notificationFilter: PropTypes.object.isRequired,
  lookups: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

