import actionTypes from '../constants/actionTypes';

const {
  GET_DEALS,
  UPDATE_DEAL_KANBAN_FILTER_SETTINGS,
  UPDATE_DEAL_SEARCH_BAR_INPUT,
  UPDATE_DEAL_KANBAN_SORT_BY,
  CREATE_DEAL,
  UPDATE_DEAL,
  GET_DEAL_BY_ID,
} = actionTypes.dealsActionTypes;

const initialState = {
  deals: [],
  dealKanbanFilterSettings: {
    typeOfDeal: false,
    responsible: false,
    country: false,
  },
  dealKanbanSortBy: 'title',
  dealsSearchBarInput: '',
};

export default function dealsReducer(state = initialState, action){
  const {
    type,
    payload
  } = action;

  switch (type) {
  case GET_DEALS: {
    return {
      ...state,
      deals: payload,
    };
  }
  case UPDATE_DEAL_KANBAN_FILTER_SETTINGS: {
    return {
      ...state,
      dealKanbanFilterSettings: {
        ...state.dealKanbanFilterSettings,
        [payload.filter]: payload.value,
      },
    };
  }
  case UPDATE_DEAL_SEARCH_BAR_INPUT: {
    return {
      ...state,
      dealsSearchBarInput: payload,
    };
  }
  case UPDATE_DEAL_KANBAN_SORT_BY: {
    return {
      ...state,
      dealKanbanSortBy: payload,
    };
  }
  case CREATE_DEAL: {
    return {
      ...state,
      deals: [...state.deals, payload]
    };
  }
  case UPDATE_DEAL: {
    return {
      ...state,
      deals: [...state.deals, payload]
    };
  }
  case GET_DEAL_BY_ID: {
    return {
      ...state,
      deals: [...state.deals, payload]
    };
  }
  default: {
    return state;
  }
  }
};
