import {cloneDeep, compact, get, has, isEmpty, keyBy, orderBy, pick} from '../../../libs/lodash';
import emailRejectedReasonLookups from '../config/emailRejectedReasonLookups';
import utils from '../../../libs/utils';
import dateSearchConst from '../../../constants/dateSearchConst';

function prepareForRenderBulkEmails(data) {
  try {
    const notSent = ['CREATED', 'QUEUED', 'PENDING', 'REJECTED', 'FAILED'];

    orderBy(data.emails, 'recipientName', 'desc').forEach((email) => {
      if(notSent.includes(email.status)) {
        const rejectedReason = email.rejectedReason && keyBy(emailRejectedReasonLookups, 'id')[email.rejectedReason];
        email.sentStatus = 'Not Sent' + (rejectedReason ? ' - ' + (rejectedReason.displayName || '') : '');
      } else {
        email.sentStatus = 'Sent';
      }
      email.searchStatus = email.sentStatus === 'Sent' ? 'Sent' : 'Not Sent';
      email.resultStatusDisplay = utils.camelize(email.resultStatus);

      const recipient = !isEmpty(get(email, 'recipients')) ? get(email, 'recipients')[0] : {};
      email.email = recipient.email || '';
      email.recipientName = recipient.name || '';
      email.entityId =  + get(recipient, 'entity.entityId', '');
      email.entityType = get(recipient, 'entity.entityType');

      const entityType = (email.entityType || '').toLowerCase();
      email.emailEntityId = (entityType === 'candidate' ? 'CAN' : 'CNT') + email.entityId ;

      const url = '/' + entityType + '/' + email.entityId;
      const onClick = window.amplitudeLogEvent('view', { action: 'view ' + entityType, object: entityType, detailsTab: utils.activeTabName()});

      email.entityLink = <a href={url} target='_blank' rel="noreferrer" onClick={onClick}>{email.emailEntityId}</a>;
    });
  } catch (err) {
    console.log('prepareForRenderBulkEmails:', err);
  } finally {
    return data;
  }
}

function prepareForApiBulkEmails(data, personalizeVariables) {
  const apiData = pick(cloneDeep(data),
    ['candidateIds', 'contactIds', 'candidateListIds', 'contactListIds', 'subject', 'body']
  );

  try {
    if (has(data, 'body')) {
      apiData.subject = encodeURIComponent(data.subject);
      apiData.body = data.body;
      personalizeVariables.forEach(function (v) {
        const from = new RegExp(v.id.replace(/</gi, '&lt;').replace(/>/gi, '&gt;'), 'gi');
        apiData.body = apiData.body.replace(from, v.id);
      });
      apiData.body = encodeURIComponent(apiData.body);
    }
  } catch (err) {
    console.log('prepareForApiBulkEmails:', err);
  } finally {
    return apiData;
  }
}

function prepareForSearchApiBulkEmails(data) {
  try {
    const apiData = cloneDeep(data);
    const dateRangeOption = get(apiData, 'criteria.dateRangeOption');

    if (!get(apiData, 'criteria.entityType')) {
      delete apiData.criteria.entityType;
    }

    (apiData.criteria.lists || []).forEach(function (list) {
      apiData.criteria.listIds = (apiData.criteria.listIds || []).concat(list.listId);
    });
    delete apiData.criteria.lists;

    switch (dateRangeOption) {
    case dateSearchConst.after:
      delete apiData.criteria.dateTimeTo;
      break;
    case dateSearchConst.before:
      delete apiData.criteria.dateTimeFrom;
      break;
    default:
      break;
    }

    delete apiData.criteria.dateRangeOption;
    if (apiData.criteria.dateTimeFrom) {
      apiData.criteria.dateTimeFrom = utils.dateForApi(apiData.criteria.dateTimeFrom, true);
    } else {
      delete apiData.criteria.dateTimeFrom;
    }

    if (apiData.criteria.dateTimeTo) {
      apiData.criteria.dateTimeTo = utils.dateForApi(apiData.criteria.dateTimeTo, false);
    } else {
      delete apiData.criteria.dateTimeTo;
    }

    if (!apiData.criteria.search) {
      delete apiData.criteria.search;
    }

    if (get(apiData, 'criteria.senderTargetId')) {
      utils.splitIdsForApi([get(apiData, 'criteria.senderTargetId')], apiData.criteria);
    }

    delete apiData.criteria.senderTargetId;

    return apiData;
  } catch (err) {
    console.log('prepareForSearchApiBulkEmails:', err);
    return data;
  }
}

function prepareForSearchRenderBulkEmails(data) {
  try {
    return data.map((d) => {
      d.subjectLink =
        <a href='#/' data-id={d.bulkEmailId} target='_blank' rel="noreferrer">{utils.contentForRender(d.subject, 30)}</a>;
      d.sent = get(d, 'summary.sent');
      d.delivered = ((get(d, 'summary.delivered') / get(d, 'summary.recipients')) * 100).toFixed(0) + '%';
      d.opened = ((get(d, 'summary.opened') / get(d, 'summary.recipients')) * 100).toFixed(0) + '%';
      d.clicked = ((get(d, 'summary.clicked') / get(d, 'summary.recipients')) * 100).toFixed(0) + '%';
      d.notSent = get(d, 'summary.notSent');
      d.sender = compact([get(d, 'createdBy.firstName'), get(d, 'createdBy.lastName')]).join(' ');
      d.createdAt = utils.dateForRender(d.createdAt);

      return d;
    });
  } catch (err) {
    console.log('prepareForSearchRenderBulkEmails:', err);
    return data;
  }
}

export {
  prepareForRenderBulkEmails,
  prepareForApiBulkEmails,
  prepareForSearchApiBulkEmails,
  prepareForSearchRenderBulkEmails
};
