import utils from '../libs/utils';

const LAUNCH_LOCK_KEY = 'LAUNCH-TABS-RESOURCE-LOCK';
const MAX_TIME_OUT    = 6000;

export default class TabsResourceLock {
  static #queue = [];

  constructor(resourceName = '', timeout = 5000) {
    this.id      = Date.now() + utils.generateRandomString(15);
    this.key     = `${LAUNCH_LOCK_KEY}-${resourceName}`;
    this.timeout = timeout;
  }

  static cleanup() {
    for (const name of Object.keys(window.localStorage)) {
      if (name.includes(LAUNCH_LOCK_KEY)) {
        window.localStorage.removeItem(name);
      }
    }
  }

  async lock() {
    const MAX_TIME = Date.now() + MAX_TIME_OUT;

    while (Date.now() < MAX_TIME) {
      if (window.localStorage.getItem(this.key) === null) {
        window.localStorage.setItem(this.key, this.id);

        // prevent race conditions
        await utils.wait(30);

        if (window.localStorage.getItem(this.key) === this.id) {
          window.localStorage.setItem(`${this.key}-TIME`, Date.now().toString());
          return true;
        }
      } else {
        await this.waitForUnlock();
        this.checkLockExpiration();
      }
    }
  }

  async waitForUnlock() {
    console.log('waitForUnlock', new Date().toISOString());

    return new Promise(resolve => {
      function stopWaiting() {
        TabsResourceLock.removeFromQueue(stopWaiting);
        clearTimeout(timeOutId);
        resolve();
      }

      TabsResourceLock.addToQueue(stopWaiting);

      let timeOutId = setTimeout(stopWaiting, this.timeout);

      window.onstorage = (event) => {
        if(event.key === this.key && TabsResourceLock.#queue.length && event.newValue === null) {
          stopWaiting();
        }
      };
    });
  }

  checkLockExpiration() {
    if (window.localStorage.getItem(`${this.key}-TIME`) === null) {
      return;
    }

    const lockTime = parseInt(window.localStorage.getItem(`${this.key}-TIME`));
    if (Date.now() > lockTime + this.timeout) {
      window.localStorage.removeItem(this.key);
      window.localStorage.removeItem(`${this.key}-TIME`);
    }
  }

  static addToQueue(func) {
    this.removeFromQueue(func);
    TabsResourceLock.#queue.push(func);
  }

  static removeFromQueue(func) {
    TabsResourceLock.#queue = TabsResourceLock.#queue.filter(i => i !== func);
  }

  static notifyWaiters() {
    TabsResourceLock.#queue.forEach(func => func());
  }

  async unLock() {
    if (window.localStorage.getItem(this.key) === this.id) {
      window.localStorage.removeItem(this.key);
      window.localStorage.removeItem(`${this.key}-TIME`);
      TabsResourceLock.notifyWaiters();
    }
  }
}
