import ActivityTypeSelect from '../ActivityTypeSelect';
import CreatableInputOnly from '../../../common/AtsSelect/CreatableInputOnly';
import AtsSelect from '../../../common/AtsSelect';
import JobFunctionsSkillsSelect from '../../../common/Categories/JobFunctionsSkillsSelect';
import AtsTypeAheadSearchSelect from '../../../common/AtsTypeAheadSearchSelect';
import AtsDatePicker from '../../../common/AtsDatePicker';

import searchConst from '../../../../constants/searchConst';

const includeAll = searchConst.searchOptions.includeAll;
const includeAny = searchConst.searchOptions.includeAny;
const exclude = searchConst.searchOptions.exclude;
const withValue = searchConst.searchOptions.withValue;
const withoutValue = searchConst.searchOptions.withoutValue;

const Contact = [
  {
    value: 'activity',
    label: 'Activity',
    searchOptions: [searchConst.searchOptions.includeAnyActivity, searchConst.searchOptions.includeAllActivity, searchConst.searchOptions.excludeActivity, searchConst.searchOptions.activityDefaultValue],
    searchOptionComponent: ActivityTypeSelect
  },
  {
    value: 'bullhornIds',
    label: 'Bullhorn ID',
    searchOptions: [includeAny, exclude, withValue, withoutValue],
    searchOptionComponent: CreatableInputOnly
  },
  {
    value: 'businessUnitIds',
    label: 'Business Units',
    searchOptions: [includeAny, includeAll],
    dropdownOptionsLookupId: 'businessUnits',
    searchOptionComponent: AtsSelect
  },
  {
    value: 'companyIds',
    label: 'Company - Main',
    searchOptions: [includeAny, exclude],
    searchOptionComponent: AtsTypeAheadSearchSelect,
    entityType: 'COMPANY',
    companyTypes: ['MSP', 'CLIENT'],
    clearButton: false
  },
  {
    value: 'programCompanyIds',
    label: 'Company - Programs',
    searchOptions: [includeAny, includeAll, exclude],
    searchOptionComponent: AtsTypeAheadSearchSelect,
    entityType: 'COMPANY',
    companyTypes: ['PROGRAM'],
    clearButton: false
  },
  {
    value: 'dateCreatedRange',
    label: 'Date Added',
    searchOptions: searchConst.searchOptions.date.concat([searchConst.searchOptions.inTheLast, searchConst.searchOptions.inTheLastCalendar, searchConst.searchOptions.inTheCurrentCalendar]),
    searchOptionComponent: AtsDatePicker
  },
  {
    value: 'dateUpdatedRange',
    label: 'Date Last Updated',
    searchOptions: searchConst.searchOptions.date.concat([searchConst.searchOptions.inTheLast, searchConst.searchOptions.inTheLastCalendar, searchConst.searchOptions.inTheCurrentCalendar]),
    searchOptionComponent: AtsDatePicker
  },
  {
    value: 'departmentIds',
    label: 'Departments',
    searchOptions: [includeAny, includeAll, exclude],
    dropdownOptionsLookupId: 'departments',
    searchOptionComponent: AtsSelect
  },
  {
    value: 'supplementalDocumentsKeywords',
    label: 'Documents',
    searchOptions: [includeAny, includeAll, exclude, withValue, withoutValue],
    searchOptionComponent: CreatableInputOnly
  },
  {
    value: 'emailAddress',
    label: 'Email Address',
    searchOptions: [includeAny, exclude, withValue, withoutValue],
    searchOptionComponent: CreatableInputOnly
  },
  {
    value: 'bulkEmailOptOut',
    label: 'Email Opted Out',
    searchOptions: [includeAll],
    dropdownOptionsLookupId: 'bulkEmailOptOuts',
    searchOptionComponent: AtsSelect,
    hideSearchOptionField: true,
    singleSearchField: true
  },
  {
    value: 'emailValidity',
    label: 'Email Validity',
    searchOptions: [includeAny, exclude],
    dropdownOptionsLookupId: 'emailAddressValidities',
    searchOptionComponent: AtsSelect
  },
  {
    value: 'highlightedSkills',
    label: 'Highlighted Skills',
    searchOptions: [includeAny, includeAll, exclude, withValue, withoutValue],
    searchOptionComponent: CreatableInputOnly
  },
  {
    value: 'industryIds',
    label: 'Industries',
    searchOptions: [includeAny, includeAll, exclude],
    dropdownOptionsLookupId: 'industries',
    searchOptionComponent: AtsSelect
  },
  {
    value: 'jobFunctionIds',
    label: 'Job Functions',
    searchOptions: [includeAny, includeAll, exclude, withValue, withoutValue],
    dropdownOptionsLookupId: 'jobFunctions',
    searchOptionComponent: JobFunctionsSkillsSelect
  },
  {
    value: 'jobIds',
    label: 'Job Assigned To',
    searchOptions: [includeAny, exclude, withValue, withoutValue],
    entityType: 'JOB',
    searchOptionComponent: AtsTypeAheadSearchSelect,
    clearButton: false,
  },
  {
    value: 'listIds',
    label: 'Lists',
    searchOptions: [includeAny, includeAll, exclude, withValue, withoutValue],
    searchOptionComponent: AtsTypeAheadSearchSelect,
    entityType: 'CONTACT',
    clearButton: false,
    maxLengthToApi: searchConst.minLength, // typeAhead min input length, send to api, then filter in the frontEnd
    delay: 0,
    searchOperation:'listsSearchMy',
    filterBy: ['name']
  },
  {
    value: 'name',
    label: 'Name',
    searchOptions: [includeAny, exclude],
    searchOptionComponent: CreatableInputOnly
  },
  {
    value: 'relationshipOwnerUserIds',
    label: 'Owner',
    searchOptions: [includeAny, exclude],
    dropdownOptionsLookupId: ['users', 'teams'],
    dropdownOptionsMapTo: ['relationshipOwnerUserIds', 'relationshipOwnerTeamIds'],
    searchOptionComponent: AtsSelect,
  },
  {
    value: 'reportsToContactIds',
    label: 'Reports To',
    searchOptions:[includeAny, exclude, withValue, withoutValue],
    entityType: 'CONTACT',
    searchOptionComponent: AtsTypeAheadSearchSelect,
    clearButton: false
  },
  {
    value: 'referredBy',
    label: 'Referred By',
    searchOptions: [includeAny, exclude, withValue, withoutValue],
    searchOptionComponent: CreatableInputOnly
  },
  {
    value: 'skillIds',
    label: 'Skills',
    searchOptions: [includeAny, includeAll, exclude, withValue, withoutValue],
    dropdownOptionsLookupId: 'skills',
    searchOptionComponent: JobFunctionsSkillsSelect
  },
  {
    value: 'sourceOptionIds',
    label: 'Source',
    searchOptions: [includeAny, exclude],
    dropdownOptionsLookupId: 'sourceOptions',
    orderBy: 'id',
    searchOptionComponent: AtsSelect
  },
  {
    value: 'status',
    label: 'Status',
    searchOptions: [includeAny, exclude],
    dropdownOptionsLookupId: 'contactStatuses',
    searchOptionComponent: AtsSelect
  },
  {
    value: 'jobTitle',
    label: 'Title',
    searchOptions: [includeAny, exclude, withValue, withoutValue],
    searchOptionComponent: CreatableInputOnly
  },
];

export default Contact;
