import React, {useState} from 'react';
import Dropdown from 'react-bootstrap/Dropdown';

import NewNoteModal from '../../Notes/NoteFormModal';
import NewTaskModal from '../../Tasks/EditModal';
import SelectModal from '../../Lists/SelectModal';
import NewSubmissionModal from '../../Submissions/Form/SubmissionFormModal';
import BulkEmailFormModal from '../../BulkEmails/Form/FormModal';

import useModal from '../../../hooks/useModal';

import {cloneDeep, get, has} from '../../../libs/lodash';
import utils from '../../../libs/utils';
import NewAppointmentModal from '../../Appointments/EditModal';

import apiUserStatus from '../../../services/apiUserStatus';
import * as appointmentActions from '../../../actions/appointment';
import {useDispatch} from 'react-redux';
import * as searchEntityActions from '../../../actions/searchEntity';
import MergeRecordsFormModal from '../../MergeRecords/MergeRecordsFormModal';
import ConfirmExportModal from '../../Export/ConfirmExportModal';

export default function AtsHeaderActions(props) {
  const user = apiUserStatus.get();
  const {userId = null, settings = {}, userPermissionGroupIds = [] } = user || {};
  const dispatch = useDispatch();
  const {modalVisible, toggleModal} = useModal();
  const [modalId, setModalId] = useState('');
  const entity = props.entity;
  const entityType = (props.entity.entityType || '').toLowerCase();
  const notifyTargetIds = utils.prepareNotifyTargetIds(entity);

  // clone entity start
  function prepareCloneEntity () {
    const cloneEntity = cloneDeep(entity);
    delete cloneEntity[`${entityType}Id`];
    delete cloneEntity.effectiveDate;
    delete cloneEntity.entityType;
    delete cloneEntity.primaryLink;
    delete cloneEntity.name;

    switch (entityType) {
    case 'placement':
      delete cloneEntity.scheduledStartDate;
      delete cloneEntity.scheduledEndDate;
      if (has(cloneEntity, 'contractRateInfo.stage')) {
        cloneEntity.contractRateInfo.stage = 'ONBOARDING';
      }
      cloneEntity.status = 'PENDING';
      return { ...cloneEntity};
    case 'job':
    default:
      delete cloneEntity.expectedStartDate;
      delete cloneEntity.expectedEndDate;
      cloneEntity.openStatus = 'OPEN';
      return {...cloneEntity};
    }
  }

  const cloneEntity = (e) => {
    e.preventDefault();
    e.stopPropagation();
    window.cloneEntity = prepareCloneEntity();
    const newEntityUrl = `/new-${entityType}?clone=true`;
    ampLogEvent(entityType, 'clone');
    props.history.push(newEntityUrl);
  };
  // clone entity end

  // task
  const [task, setTask] = useState({});
  function createTaskFromData(data) {
    setTask(utils.prepareDefaultTaskFromData(data));
    setModalId('new-task');
    if (!modalVisible) {
      toggleModal();
    }
    ampLogEvent('task');
  }

  const [note, setNote] = useState({});
  function createNoteFromData (data) {
    data.entity = {...data.entity,  entityType: get(data, 'entity.type'), entityId: get(data, 'entity.id'), fullName: get(data, 'entity.name') };
    setNote({...data, content: data.body});

    setModalId('new-note-from-appointment');
    if (!modalVisible) {
      toggleModal();
    }

    ampLogEvent('note');
  }

  // ampLogEvent
  function ampLogEvent (object, action='add', timer='start', options={}) {
    window.amplitudeLogEvent('create', {...options, action: `${action} ${object}`, object: object, globalNav: '', method: 'select actions button', detailsTab: utils.activeTabName(), timer: timer});
  }

  function saveSubmissionCallback() {
    ampLogEvent('submission', 'save new', 'end');
  }

  function saveTaskCallback () {
    ampLogEvent('task', 'save new', 'end');
  }

  const saveNoteCallback = async (newActivity) => {
    ampLogEvent('note', 'save new', 'end', {type:  newActivity.activityType.name.toLowerCase()});
  };

  const saveAppointmentCallback = async (newContent) => {
    ampLogEvent('appointment', 'save new', 'end', {apptinvite: newContent.sendInviteToCandidates || newContent.sendInviteToContacts ? 'Yes' : 'No'});

    // if appointments tab is loaded, refresh appointments tab
    const isAppointmentsTabLoad = document.querySelector('.appointment-tabs');
    if (isAppointmentsTabLoad) {
      dispatch(appointmentActions.refreshAppointmentsList(true)); // refresh appointments tab
    }

    dispatch(searchEntityActions.getMetadata({entityType: 'candidate', id: entity.entityId}));
  };

  const saveBulkEmailCallback = () => {
    ampLogEvent('bulk email', 'save new', 'end');
  };

  // toggle model
  const toggleActionModal = (e, modalId) => {
    e.preventDefault();
    if (modalId === 'new-task') {
      setTask(utils.prepareDefaultTaskFromData({entity: entity}));
    }
    if (modalId !== 'add-to-list') {
      ampLogEvent(modalId.replace('new-', ''));
    }

    setModalId(modalId);
    if (!modalVisible) {
      toggleModal();
    }
  };

  function showModal () {
    if (!modalVisible) {
      return;
    }
    switch (modalId) {
    case 'new-note':
      return <NewNoteModal
        modalVisible={modalVisible}
        modalCancel={toggleModal}
        history={props.history}
        entity={entity}
        createTaskFromData={createTaskFromData}
        notifyTargetIds={notifyTargetIds}
        saveCallback={saveNoteCallback} />;
    case 'new-note-from-appointment':
      return <NewNoteModal
        content={note.content}
        modalVisible={modalVisible}
        modalCancel={toggleModal}
        history={props.history}
        entity={note.entity}
        createTaskFromData={createTaskFromData}
        notifyTargetIds={note.notifyTargetIds}
        saveCallback={saveNoteCallback} />;
    case 'new-task':
      return <NewTaskModal
        modalVisible={modalVisible}
        modalCancel={toggleModal}
        defaultValue={task}
        saveCallback={saveTaskCallback}
      />;
    case 'add-to-list':
      return <SelectModal
        selectModalVisible={modalVisible}
        selectModalCancel={toggleModal}
        checkedItemsNum={1}
        entityType={entity.entityType}
        entityIds={[entity.entityId]}
      />;
    case 'new-submission':
      return <NewSubmissionModal
        modalVisible={modalVisible}
        modalCancel={toggleModal}
        history={props.history}
        entity={entity}
        createTaskFromData={createTaskFromData}
        candidateRecruiterUserId={get(entity, 'recruiterUserId')}
        jobOwnerUserId = {get(entity, 'ownerUserId')}
        jobRecruiterUserId = {get(entity, 'primaryRecruiterUserId')}
        notifyTargetIds={notifyTargetIds}
        saveCallback={saveSubmissionCallback} />;
    case 'new-appointment':
      return <NewAppointmentModal
        modalVisible={modalVisible}
        modalCancel={toggleModal}
        lookups={props.lookups}
        defaultValue={
          {
            activityTypeId: props.activityTypeId,
            entity: entity,
            attendeesByEntity: {candidates: entityType==='candidate' ?  [entity] : []},
            timezone: settings.timeZone || Intl.DateTimeFormat().resolvedOptions().timeZone,
            ownerUserId: userId,
            notifyTargetIds: notifyTargetIds,
            appointmentType: 'MEETING',
            isPrivate: false,
            sendInviteToCandidates: false,
            sendInviteToContacts: false,
          }
        }
        saveCallback={saveAppointmentCallback}
        createNoteFromData={createNoteFromData}
      />;
    case 'bulk-email':
      return <BulkEmailFormModal
        modalVisible={modalVisible}
        modalCancel={toggleModal}
        defaultCandidates ={entityType === 'candidate' ? [entity] : []}
        defaultContacts ={entityType === 'contact' ? [entity] : []}
        saveCallback={saveBulkEmailCallback}
      />;
    case 'merge-duplicates':
      return <MergeRecordsFormModal
        modalVisible={modalVisible}
        modalCancel={toggleModal}
        defaultEntities={[entity]}
        history={props.history}
        defaultNotifyTargetIds={notifyTargetIds}
      />;
    case 'export-deals':
      return <ConfirmExportModal
        modalCancel={toggleModal}
        relatedEntity={entity}
        modalVisible={modalVisible}
        entityType={'Companies'}
        type='COMPANY_DEALS'
        criteria={{
          additionalCriteria: [
            {
              name: 'mspClientCompanyIds',
              criteria: {
                option: 'includeAny',
                value: [entity.companyId]
              }
            }
          ]
        }}
      />;
    default:
      break;
    }
  }

  function entityActionItems() {
    switch (entityType) {
    case 'candidate':
      return (
        <>
          {get(entity, 'resume.attachmentId') ?
            <Dropdown.Item as='a' href="#/" className='download-resume' onClick={(e) => props.downloadResume(e)}>Download
              Resume</Dropdown.Item>
            :
            ''
          }
          <Dropdown.Item as='a' href="#/" onClick={(e) => toggleActionModal(e, 'new-submission')}>Submit to Job</Dropdown.Item>
          <Dropdown.Item as='a' href={`/new-placement?candidateId=${get(entity, 'candidateId')}`} onClick={() => ampLogEvent('placement')}>
            Add Placement
          </Dropdown.Item>
          <Dropdown.Item as='a' href="#/" onClick={(e) => toggleActionModal(e, 'new-appointment')}>Add Meeting</Dropdown.Item>
          {userPermissionGroupIds.includes('MERGE_CANDIDATES') ?
            <Dropdown.Item as='a' href="#/" onClick={(e) => toggleActionModal(e, 'merge-duplicates')}>
              Merge Duplicates
            </Dropdown.Item>
            :
            ''
          }
          {userPermissionGroupIds.includes('BULK_EMAILS_CANDIDATES') ?
            <Dropdown.Item as='a' href="#/" onClick={(e) => toggleActionModal(e, 'bulk-email')}>
              Send an Email
            </Dropdown.Item>
            :
            ''
          }
        </>
      );
    case 'job':
      return (
        <>
          <Dropdown.Item as='a' href="#/" onClick={(e) => toggleActionModal(e, 'new-submission')}>Submit Candidate</Dropdown.Item>
          <Dropdown.Item as='a' href="#/" onClick={(e) => cloneEntity(e)}>Clone Job</Dropdown.Item>
          {userPermissionGroupIds.includes('MERGE_JOBS') ?
            <Dropdown.Item as='a' href="#/" onClick={(e) => toggleActionModal(e, 'merge-duplicates')}>
              Merge Duplicates
            </Dropdown.Item>
            :
            ''
          }
        </>
      );
    case 'company':
      return (
        <>
          <Dropdown.Item as='a' href={`/new-job?companyId=${get(entity, 'companyId')}`} onClick={() => ampLogEvent('job')}>Add Job</Dropdown.Item>
          <Dropdown.Item as='a' href='/new-placement' onClick={() => ampLogEvent('placement')}>Add Placement</Dropdown.Item>
          <Dropdown.Item as='a' href={`/new-contact?companyId=${get(entity, 'companyId')}`} onClick={() => ampLogEvent('contact')}>Add Contact</Dropdown.Item>
          {userPermissionGroupIds.includes('MERGE_COMPANIES') ?
            <Dropdown.Item as='a' href="#/" onClick={(e) => toggleActionModal(e, 'merge-duplicates')}>
              Merge Duplicates
            </Dropdown.Item>
            :
            ''
          }
          <Dropdown.Item as='a' onClick={(e) => toggleActionModal(e, 'export-deals')}>Export Deals</Dropdown.Item>
        </>
      );
    case 'contact':
      return (
        <>
          <Dropdown.Item as='a' href={`/new-job?contactId=${get(entity, 'contactId')}&companyId=${get(entity, 'companyId')}`} onClick={() => ampLogEvent('job')}>Add Job</Dropdown.Item>
          <Dropdown.Item as='a' href="#/" onClick={(e) => toggleActionModal(e, 'new-appointment')}>Add Meeting</Dropdown.Item>
          {userPermissionGroupIds.includes('MERGE_CONTACTS') ?
            <Dropdown.Item as='a' href="#/" onClick={(e) => toggleActionModal(e, 'merge-duplicates')}>
              Merge Duplicates
            </Dropdown.Item>
            :
            ''
          }
          {userPermissionGroupIds.includes('BULK_EMAILS_CONTACTS') ?
            <Dropdown.Item as='a' href="#/" onClick={(e) => toggleActionModal(e, 'bulk-email')}>
              Send an Email
            </Dropdown.Item>
            :
            ''
          }
        </>
      );
    case 'placement':
      return (
        <>
          <Dropdown.Item as='a' href="#/" onClick={(e) => cloneEntity(e)}>Clone Placement</Dropdown.Item>
          {/*<Dropdown.Item as='a' href="#/" onClick={(e) => toggleActionModal(e,'merge-duplicates')}>Merge Duplicates</Dropdown.Item>*/}
        </>
      );
    default:
      break;
    }
  }

  return (
    <>
      {userPermissionGroupIds.includes('CREATE_EDIT_PRIMARY_OBJECTS') ?
        <>
          <Dropdown as='div' className={'atsDropdown header-more-actions'}>
            <Dropdown.Toggle as='div' className='cursor'/>
            <Dropdown.Menu>
              <Dropdown.Item as='a' href="#/" onClick={(e) => toggleActionModal(e, 'new-note')}>Add Note</Dropdown.Item>
              <Dropdown.Item as='a' href="#/" onClick={(e) => toggleActionModal(e, 'new-task')}>Add Task</Dropdown.Item>
              <Dropdown.Item as='a' href="#/" onClick={(e) => toggleActionModal(e, 'add-to-list')}>Add to List</Dropdown.Item>
              {entityActionItems()}
            </Dropdown.Menu>
          </Dropdown>
          {showModal()}
        </>
        :
        ''
      }
    </>
  );
}
