import React from 'react';
import PropTypes from 'prop-types';
import utils from '../../../../libs/utils';

export default function RecruiterInitialName({recruiter, className}) {
  return (
    <span className={`ml-auto biotif-book dark-grey-text font-size-14 ${className || ''}`}>
      {utils.initialName(recruiter)}
    </span>
  );
}

RecruiterInitialName.propTypes = {
  recruiter: PropTypes.object.isRequired
};

