import React from 'react';
import './style.scss';

export default function BadgeList({badges=[], width, height, isHeader=false}){
  const baseImgPath = window.REACT_APP_API_BASE_PATH + '/core/images/';
  return (
    <>
      {badges.length ?
        <div className={`badges text-nowrap${isHeader ? ' header' : ''}`}>
          {badges.map((badge, badgeIndex) => {
            return (
              <img key={`badge-${badgeIndex}`}
                src={`${baseImgPath}${badge.imageId}/file`}
                alt={`${badge.name}`}
                title={badge.name}
              />
            );
          })}
          &nbsp;
        </div>
        :
        ''
      }
    </>
  );
};
