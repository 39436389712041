import apiUserStatus from '../services/apiUserStatus';
import { compact } from '../libs/lodash';

const sideDrawerConfig = function () {
  const { userPermissionGroupIds = [] } = apiUserStatus.get() || {};
  const manageExternalAppSettings = userPermissionGroupIds.includes('MANAGE_EXTERNAL_APP_SETTINGS') ? [{text: 'Manage External App Settings', url: '/appusers/list'}] : [];
  const manageUsers = userPermissionGroupIds.includes('MANAGE_SYSTEM_USERS') || userPermissionGroupIds.includes('VIEW_USERS_TEAMS') ? [{text: 'Users', url: '/users/list'}] : [];
  const manageTeams = userPermissionGroupIds.includes('MANAGE_SYSTEM_TEAMS') || userPermissionGroupIds.includes('VIEW_USERS_TEAMS') ? [{ text: 'Teams', url: '/teams/list'}] : [];
  const manageConfiguration = userPermissionGroupIds.includes('MANAGE_SYSTEM_CONFIGURATION') ?
    [
      {
        text: 'Badges',
        url: '/badges/list'
      },
      {
        text: 'Source',
        url: '/sourceoptions/list'
      },
      {
        text: 'Business Units',
        url: '/businessunits/list'
      },
      {
        text: 'Job Categories',
        url: '/practicearea/list'
      },
      { text: 'Metrics',
        url: '/metrics/list'
      }
    ]
    :
    [];

  return {
    'setting': compact(manageUsers.concat(manageTeams).concat(manageConfiguration).concat(manageExternalAppSettings)),
    'profile': [
      {
        text: 'My Profile',
        url: '/my-profile'
      },
      {
        text: 'My Settings',
        url: '/my-settings'
      },
      {
        text: 'Sign Out',
        url: '/sign-out'
      },
    ]
  };
};

export default sideDrawerConfig;
