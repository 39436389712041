import React from 'react';
import PropTypes from 'prop-types';
import {compact, flattenDeep} from '../../libs/lodash';

export default function FormFields({fields, formValidated, callback, lastRowNoPadding=true, maxMenuHeight}) {
  return (
    <>
      {fields.map((row, index) =>
        <div className={`form-row col-12 mx-0 px-0 pt-3${index ===fields.length-1 && lastRowNoPadding ? '' : ' pb-3'}`} key={`row${index}`}>
          {row.map((group) =>
            <div className={`form-group ${group.className}${group.dependedValue && !compact(flattenDeep([group.dependedValue])).includes(group.depended) ? ' d-none' : ''}`}
              key={group.props.id ? group.props.id : group.props.name}>
              {
                group.label?
                  <label htmlFor={group.props.id ? group.props.id : group.props.name}>
                    {group.label}
                    {group.props.required && <span className='required'>*</span>}
                    {group.helpText && <span className='help-text'>{group.helpText}</span>}
                  </label>
                  :''
              }
              {
                group.component?
                  <>
                    <group.component
                      disabled={ group.dependedValue && !compact(flattenDeep([group.dependedValue])).includes(group.depended)}
                      {...group.props}
                      id={group.props.id ? group.props.id : group.props.name}
                      validated={formValidated}
                      callback={group.props.callback ? group.props.callback : callback}
                      maxMenuHeight={maxMenuHeight}
                    />
                    {group.underInputHelpText && <div className='help-text'>{group.underInputHelpText}</div>}
                  </>
                  :
                  <>
                    {group.displayValue && !group.editable?<div className={`biotif-semi-bold font-size-16 ${group.className}`}>{group.displayValue}</div>:''}
                  </>
              }
            </div>
          )}
        </div>
      )}
    </>
  );
}

FormFields.propTypes = {
  fields: PropTypes.array.isRequired,
  formValidated: PropTypes.bool,
  callback: PropTypes.func.isRequired,
  isMulti: PropTypes.bool
};
