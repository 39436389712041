import candidate from './candidate';
import job from './job';
import company from './company';
import contact from './contact';
import placement from './placement';

const Index = {
  candidate,
  job,
  company,
  contact,
  placement,
};
export default Index;
