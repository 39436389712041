import React from 'react';
import {isEmpty} from '../../../libs/lodash';
import {AtsInputNumber} from '../../common';
import patternConst from '../../../constants/patternConst';
import utils from '../../../libs/utils';

export default (props) => {
  const rateCallback = (payBillMarkup) => {
    const changedField = Object.keys(payBillMarkup)[0];
    const value = payBillMarkup[changedField];
    const callbackValues = payBillMarkup;
    let newMarkup = 0.00;

    if(!value) {
      props.callback(payBillMarkup);
      return;
    }

    // calculate based on launchVMS rules
    switch (changedField) {
    case 'rateInfo.payRate':
      // Change Pay Rate -> Bill Rate Stay, Markup Change. New M = ( ( B / P ) - 1 ) * 100
      if (props.rateInfo.billRate) {
        newMarkup = ( ( props.rateInfo.billRate / value ) - 1 ) * 100.00;
        callbackValues['rateInfo.markup'] = parseFloat(newMarkup.toFixed(2));
      }
      break;
    case 'rateInfo.billRate':
      // Change Bill Rate -> Markup Stay, Pay Rate Change. New P = B / ( 1 + ( M / 100 ) ); Side Note: B = P * ( 1 + ( M / 100 ) )
      if ( props.rateInfo.markup !== undefined && props.rateInfo.markup !== null ) {
        const newPayRate = value / ( 1 + ( props.rateInfo.markup / 100.00 ) );
        callbackValues['rateInfo.payRate'] =  parseFloat(newPayRate.toFixed(2));
      }
      break;
    case 'rateInfo.markup':
      // Change Markup -> Bill Rate Stay, Pay Rate Change. New P = B / ( 1 + ( M / 100 ) ); Side Note: B = P * ( 1 + ( M / 100 ) )
      if(props.rateInfo.billRate) {
        const newPayRate = props.rateInfo.billRate / (1 + (value / 100.00));
        callbackValues['rateInfo.payRate'] =  parseFloat(newPayRate.toFixed(2));
      }
      break;
    default:
      break;
    }
    props.callback(callbackValues);
  };

  const baseGrossProfitCallback = (baseProfitFee) => {
    const changedField = Object.keys(baseProfitFee)[0];
    const value = baseProfitFee[changedField];
    const callbackValues = baseProfitFee;
    let newPermFee = 0.00;

    if(!value) {
      props.callback(baseProfitFee);
      return;
    }

    // calculate based on launchVMS rules
    // Base Salary x Perm Fee = Gross Profit
    switch (changedField) {
    case 'rateInfo.grossProfit':
      // Change Gross Profit -> Base Salary Stay, Perm Fee Change. F=(G/B) * 100
      if (props.rateInfo.baseSalary) {
        newPermFee = (value / props.rateInfo.baseSalary) * 100.00;
        callbackValues['rateInfo.permRate'] = parseFloat(newPermFee.toFixed(2));
      }
      break;
    case 'rateInfo.baseSalary':
      // Change Base Salary -> Perm Fee Stay, grossProfit Change. New G = B * ( F / 100 ) )
      if ( props.rateInfo.permRate !== undefined && props.rateInfo.permRate !== null ) {
        const newGrossProfit = value * (props.rateInfo.permRate / 100) ;
        callbackValues['rateInfo.grossProfit'] =  parseInt(newGrossProfit.toFixed(2));
      }
      break;
    case 'rateInfo.permRate':
      // Change permRate -> Base Salary Stay, Gross Profit Change. New G = B * ( F / 100 )
      if(props.rateInfo.baseSalary) {
        const newGrossProfit = props.rateInfo.baseSalary * (value / 100) ;
        callbackValues['rateInfo.grossProfit'] =  parseInt(newGrossProfit.toFixed(2));
      }
      break;
    default:
      break;
    }
    props.callback(callbackValues);
  };

  const renderRateFields = (scope, job, rateInfo) => {
    if (isEmpty(job) || scope === 'MASS_SUBMIT' || isEmpty(rateInfo)) {
      return '';
    }

    if (job.jobType === 'PERMANENT') {
      return <>
        <div className='form-group d-flex'>
          <div className='col-5 px-0'>
            <label htmlFor='totalCompensation'>
              Total Compensation
            </label>
            <div>
              <AtsInputNumber
                id='totalCompensation'
                name='rateInfo.totalCompensation'
                defaultValue={rateInfo.totalCompensation}
                formatter={patternConst.DollarFormatPattern}
                parser={patternConst.DollarParsePattern}
                callback={baseGrossProfitCallback}
              />
            </div>
          </div>
          <div className='col-2 px-0 center-section'>
            <div className='center-section-object'>Per Year ({rateInfo.currencyType})</div>
          </div>
          <div className='col-5 px-0'>
            <label htmlFor='permRate'>
              Perm Rate
            </label>
            <div>
              <AtsInputNumber
                id='permRate'
                name='rateInfo.permRate'
                defaultValue={rateInfo.permRate}
                precision={2}
                callback={baseGrossProfitCallback}
              />
            </div>
          </div>
        </div>
        <div className='form-group d-flex'>
          <div className='col-5 px-0'>
            <label htmlFor='baseSalary'>
              Base Salary
            </label>
            <div>
              <AtsInputNumber
                id='baseSalary'
                name='rateInfo.baseSalary'
                defaultValue={rateInfo.baseSalary}
                formatter={patternConst.DollarFormatPattern}
                parser={patternConst.DollarParsePattern}
                callback={baseGrossProfitCallback}
              />
            </div>
          </div>
          <div className='col-2 px-0 center-section'/>
          <div className='col-5 px-0'>
            <label htmlFor='grossProfit'>
              Gross Profit
            </label>
            <div>
              <AtsInputNumber
                id='grossProfit'
                name='rateInfo.grossProfit'
                defaultValue={rateInfo.grossProfit}
                formatter={patternConst.DollarFormatPattern}
                parser={patternConst.DollarParsePattern}
                callback={baseGrossProfitCallback}
              />
            </div>
          </div>
        </div>
      </>;
    } else {
      return <>
        <div className='form-group d-flex'>
          <div className='col-5 px-0'>
            <label htmlFor='billRate'>
              Bill Rate
            </label>
            <div>
              <AtsInputNumber
                id='billRate'
                name='rateInfo.billRate'
                defaultValue={rateInfo.billRate}
                formatter={patternConst.DollarFormatPattern}
                parser={patternConst.DollarParsePattern}
                callback={rateCallback}
              />
            </div>
          </div>
          <div className='col-2 px-0 center-section'>
            <div className='center-section-object'>Per {utils.camelize(rateInfo.rateType)} ({rateInfo.currencyType})</div>
          </div>
          <div className='col-5 px-0'>
            <label htmlFor='markup'>
              Markup
            </label>
            <div>
              <AtsInputNumber
                id='markup'
                name='rateInfo.markup'
                defaultValue={rateInfo.markup}
                precision={2}
                callback={rateCallback}
              />
            </div>
          </div>
        </div>
        <div className='form-group d-flex'>
          <div className='col-5 px-0'>
            <label htmlFor='payRate'>
              Pay Rate
            </label>
            <div>
              <AtsInputNumber
                id='payRate'
                name='rateInfo.payRate'
                defaultValue={rateInfo.payRate}
                formatter={patternConst.DollarFormatPattern}
                parser={patternConst.DollarParsePattern}
                callback={rateCallback}
              />
            </div>
          </div>
          <div className='col-2 px-0 center-section'>
            <div className='center-section-object'>OT {rateInfo.isNonexempt}</div>
          </div>
          <div className='col-5 px-0'/>
        </div>
      </>;
    }
  };

  return (
    <>
      { renderRateFields(props.scope, props.job, props.rateInfo, props.callback) }
    </>
  );
};
