import {cloneDeep, get, pick, has} from '../../../libs/lodash';
import candidateConst from '../../../constants/candidateConst';
import utils from '../../../libs/utils';

function prepareForRenderCandidates(data) {
  if (!data.additionalInfo) { data.additionalInfo = {};}
  if (!data.additionalInfo.address) {data.additionalInfo.address = {};}
  if (!data.expectedSalary) {data.expectedSalary = {};}
  if (!data.candidateName) {data.candidateName = {};}
  data.recruiterUserId = data.recruiter && data.recruiter.userId;
  data.sourceOptionId = data.sourceOption && data.sourceOption.id;

  // deal for the inactive user
  data.origRecruiter = utils.convertObjectToOptionFormat(data.recruiter);
  data.origSourceOption = utils.convertObjectToOptionFormat(data.sourceOption);

  data.badgeIds = (data.badges || []).map((badge) => badge.id);
  data.businessUnitId = (data.businessUnit && data.businessUnit.id) || data.businessUnitId;
  data.isValidEmail = data.emailValidity !== 'INVALID';

  const coding = utils.prepareCodingTransform(data);
  data = {...data, ...coding};

  return data;
}

function prepareForApiCandidates(data) {
  const apiData = pick(cloneDeep(data), [
    'candidateName',
    'additionalInfo',
    'jobTitle',
    'currentCompany',
    'experienceLevel',
    'status',
    'sourceOptionId',
    'referredBy',
    'recruiterUserId',
    'businessUnitId',
    'industryIds',
    'departmentIds',
    'jobFunctionIds',
    'skillIds',
    'phcCareerCodeIds',
    'phcFacilityTypeIds',
    'highlightedSkills',
    'badgeIds',
    'resume',
    'supplementalDocuments',
    'expectedSalary',
    'auditLogComment',
    'eeocInfo.gender',
    'eeocInfo.ethnicity',
    'eeocInfo.education',
    'eeocInfo.veteranStatus',
    'eeocInfo.disabilityStatus',
    'eeocInfo.lgbtqStatus',
    'eeocInfo.dob',
    'emergencyContactInfo.name',
    'emergencyContactInfo.phone',
    'accountingWorkerId',
    'dojoRating',
    'linkedInProfileUrl',
    'desiredLocation',
    'desiredEmploymentTypes',
    'description'
  ]);

  if ( has(apiData, 'resume') && apiData.resume) {
    delete apiData.resume.fileName;
    delete apiData.resume.createdAt;
  }

  if (has(apiData, 'supplementalDocuments')) {
    for (let i = 0; i < apiData.supplementalDocuments.length; i++) {
      apiData.supplementalDocuments[i] = {attachmentId: apiData.supplementalDocuments[i].attachmentId};
    }
  }

  if (has(apiData, 'recruiterUserId')) {
    apiData.recruiterUserId = parseInt(apiData.recruiterUserId);
  }

  if (has(apiData, 'sourceOptionId') && !candidateConst.referredByDependedValue.includes(apiData.sourceOptionId)) {
    apiData.referredBy = data.referredBy;
  }

  if (has(apiData, 'additionalInfo.dupesCheckAt')) {
    delete apiData.additionalInfo.dupesCheckAt;
  }

  const rtFields = ['auditLogComment', 'description'];
  rtFields.forEach(function(field) {
    if (apiData[field]) {
      apiData[field] = encodeURIComponent(apiData[field]);
    }
  });

  if (has(data, 'notifyTargetIds') && Array.isArray(data.notifyTargetIds) && data.notifyTargetIds.length>0) {
    utils.splitIdsForApi(data.notifyTargetIds, apiData);
  }

  if (get(apiData, 'additionalInfo.address')) {
    apiData.additionalInfo.address = utils.addressTransform(apiData.additionalInfo.address);
  }

  if (apiData.additionalInfo) {
    delete apiData.additionalInfo.primaryEmailDetails;
  }

  if (get(apiData, 'eeocInfo.dob')) {
    apiData.eeocInfo.dob = utils.dateOnlyForApi(get(apiData, 'eeocInfo.dob'));
  }

  return apiData;
}

function prepareForRecentViewedEntityCandidates(data) {
  try {
    const {primaryEmail, otherEmail, mobilePhone, homePhone, workPhone} = get(data, 'additionalInfo') || data;
    const {city, state} = get(data, 'additionalInfo.address') || data;
    const recentVisitData = {
      ...pick(cloneDeep(data), [
        'candidateName',
        'jobTitle',
        'currentCompany',
        'status',
        'statusValue',
        'candidateId',
        'bullhornId'
      ]),
      primaryEmail, otherEmail, mobilePhone, homePhone, workPhone,
      city, state
    };

    recentVisitData.statusValue = recentVisitData.statusValue ? recentVisitData.statusValue : utils.camelize(recentVisitData.status);
    recentVisitData.currentJob = recentVisitData.jobTitle;
    recentVisitData.entityType = 'CANDIDATE';
    recentVisitData.entityId = recentVisitData.candidateId;
    recentVisitData.stringEntityId = 'CAN' + recentVisitData.entityId;
    recentVisitData.recentViewedEntity = true;

    return recentVisitData;
  } catch (err) {
    console.log(err);
    return data;
  }
}

export {
  prepareForRenderCandidates,
  prepareForApiCandidates,
  prepareForRecentViewedEntityCandidates
};
