import React from 'react';
import PropTypes from 'prop-types';

export default function FormTitle({children}){
  return (
    <>
      <div className='form-title'>
        <div className='d-block form-title-text'>
          {children}
        </div>
        
        <div className='form-color-line' />
      </div>
    </>
  );
};

FormTitle.propTypes = {
  children: PropTypes.node.isRequired
};
