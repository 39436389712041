import React from 'react';
import {Highlighter} from 'react-bootstrap-typeahead';
import AtsTooltip from './AtsTooltip';

export default function AtsHighlighter({search, target, targetFull, isPrefix=true}) {
  function checkPrefix(search, target) {
    return search && target && (target.toLowerCase()).startsWith(search.toLowerCase());
  }

  const render = () => {
    try {
      if (search && !isPrefix) {
        if (targetFull) {
          return (
            <AtsTooltip placement='bottom' tips={targetFull}>
              <span><Highlighter search={search}>{target}</Highlighter></span>
            </AtsTooltip>
          );
        } else {
          return <Highlighter search={search}>{target}</Highlighter>;
        }
      } else {
        if (targetFull) {
          return (
            <AtsTooltip placement='bottom' tips={targetFull}>
              <span>{checkPrefix(search, target) ? <Highlighter search={search}>{target}</Highlighter> : target}</span>
            </AtsTooltip>
          );
        } else {
          return checkPrefix(search, target) ? <Highlighter search={search}>{target}</Highlighter> : target;
        }

      }
    } catch (err) {
      console.log(err);
      return target;
    }
  };

  return render();
}
