import './style.scss';
import React from 'react';
import DatePicker from 'react-datepicker';
import utils from '../../../libs/utils';

function TaskDatePicker({defaultValue, id, name, required, position, callback, minDate=false, maxDate=false, placeholderText=''}) {
  return (
    <div className='ats-date-picker'>
      <DatePicker
        id={id || name}
        name={name}
        required={required}
        placeholderText={placeholderText}
        className='form-control'
        dateFormat='MMMM d, yyyy'
        selected={defaultValue ? new Date(utils.dateForRender(defaultValue, 'MM/DD/YY')): null}
        minDate={minDate ? new Date(utils.originalDate(minDate, 'MM/DD/YY')) : ''}
        maxDate={maxDate ? new Date(utils.originalDate(maxDate, 'MM/DD/YY')) : ''}
        onChange={date => callback({[name]: date})} />
      <label className={`calendar-icon form ${position==='to'?'to':'from'}`} htmlFor={name} />
    </div>
  );
}

export default TaskDatePicker;
