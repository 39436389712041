import React from 'react';
import { Link } from 'react-router-dom';
import AtsInlineEdit from './AtsInlineEdit';
import AtsTypeAheadSearchSelect from './AtsTypeAheadSearchSelect';
import FormFields from './FormFields';
import { isEmpty } from '../../libs/lodash';
import searchConst from '../../constants/searchConst';
import layoutConst from '../../constants/layoutConst';
import apiUserStatus from '../../services/apiUserStatus';

export default function AtsListsOnObject(props) {
  const user = apiUserStatus.get() || {};
  const fullColCss = layoutConst.fullColCss;
  const selected = (props.object.lists || []).map((item) => {
    return ({
      listId: item.listId || item.id,
      name: item.name,
      entityType: props.object.entityType
    });
  });

  const viewListsSearch = (e, listIds) => {
    try {
      e.preventDefault();
      const entityType = props.object.entityType.toLowerCase();
      user.listIds = listIds;
      apiUserStatus.update(user);
      const searchPath = searchConst.entityMap[entityType];
      window.open('/' + searchPath, '_blank');
    } catch (err) {
      console.log(err);
    }
  };

  const listsDisplay = selected.map((list, index) => {
    return (
      <span key={`listIncluded-${index}`}>
        <Link to='#/' target={'_blank'} onClick={(e) => viewListsSearch(e, [list.listId])}>
          {list.name}
        </Link>
        {selected.length === index + 1 ? '' : ', '}
      </span>
    );
  });

  const listsIncludedOn = {
    label: 'Lists Included On',
    displayValue: isEmpty(selected) ? '' : listsDisplay,
    className: fullColCss,
    component: AtsTypeAheadSearchSelect,
    props: {
      isMulti: true,
      required: false,
      clearButton: false,
      labelKey: 'name',
      id: 'lists',
      name: 'lists',
      entityType: props.object.entityType,
      defaultValue: selected,
      maxLengthToApi: searchConst.minLength,
      delay: 0,
      placeholder:'Type to search',
      searchOperation:'listsSearchMy',
      filterBy: ['name']
    }
  };

  return (
    <>
      {props.props.inlineEdit ?
        <div id='lists-on-details'>
          <div id='listsIncludedOn' className='force-wrap mr-5'>
            <AtsInlineEdit
              {...{...listsIncludedOn, ...props.props}}
            />
          </div>
        </div>
        :
        <FormFields fields={[[listsIncludedOn]]} formValidated={props.props.formValidated} callback={props.props.callback} />
      }
    </>
  );
};
