import React, {useState, useEffect} from 'react';
import AtsGroupSelect from '../AtsSelect/AtsGroupSelect';
import {filter, groupBy, sortBy, cloneDeep} from '../../../libs/lodash';
import utils from '../../../libs/utils';
import ApiClient from '../../../services/ApiClient';

function JobFunctionsSkillsSelect(props) {
  const [list, setList] = useState([]);
  const cleanProps = cloneDeep(props);
  delete cleanProps.departmentIds;

  useEffect(() => {
    if (!props.options) {
      const apiCall = async () => {
        try {
          const res = await ApiClient[(props.id || '').includes('jobFunctionIds') ? 'jobFunctionsLookup' : 'skillsLookup']();
          setList(res.body);
        } catch (error) {
          console.log(error);
        }
      };
      apiCall();
    } else {
      setList(props.options);
    }
  }, [props.id, props.options]);

  function filterByDepts() {
    if(!props.departmentIds) {
      return list;
    }
    return filter(list, function(item) {
      return (props.departmentIds || []).includes(item.department.id);
    });
  }

  function groupListByDepts() {
    const listFilterByDepts = filterByDepts();
    const result = groupBy(listFilterByDepts, 'department.name');
    const groupByDepartment = [];
    Object.keys(result).forEach(function(key) {
      groupByDepartment.push({name:key, options: result[key]});
    });
    return sortBy(groupByDepartment, 'name', 'asc');
  }

  return (
    <AtsGroupSelect
      {...cleanProps}
      defaultValue={utils.convertIdsToOptionFormat(list, props.defaultValue)}
      options={groupListByDepts()}
    />
  );
}

export default JobFunctionsSkillsSelect;
