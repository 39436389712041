import {cloneDeep, get, pick, has, compact, isEmpty} from '../../../libs/lodash';
import utils from '../../../libs/utils';

function prepareForRenderCompanies(data) {
  data.ownerUserId = (data.owner && data.owner.userId) || data.ownerUserId;
  data.coOwnerUserIds = (data.coOwners || []).map((owner) => owner.userId) || data.coOwnerUserIds;
  data.primaryRecruiterUserId = (data.primaryRecruiter && data.primaryRecruiter.userId) || data.primaryRecruiterUserId;
  data.originatorUserId = (data.originator && data.originator.userId) || data.originatorUserId;
  data.salespersonUserId = (data.salesperson && data.salesperson.userId) || data.salespersonUserId;
  data.accountCoordinatorUserIds = (data.accountCoordinators || []).map((recruiter) => recruiter.userId) || data.accountCoordinatorUserIds;
  
  if (has(data, 'parentCompany')) {
    data.parentCompany.entityType = 'COMPANY';
    data.parentCompany.entityId = data.parentCompany.companyId;
  }
  data.parentCompanyId = (data.parentCompany && data.parentCompany.companyId) || data.parentCompanyId;
  data.industryIds = (data.industries || []).map((industry) => industry.id) || data.industryIds;
  data.badgeIds = (data.badges || []).map((badge) => badge.id);
  data.businessUnitId = data.businessUnit && data.businessUnit.id;

  // deal with inactive
  data.origOwner = utils.convertObjectToOptionFormat(data.owner);
  data.origCoOwners = utils.convertObjectToOptionFormat(data.coOwners);
  data.origCoRecruiters = utils.convertObjectToOptionFormat(data.coRecruiters);
  data.origPrimaryRecruiter = utils.convertObjectToOptionFormat(data.primaryRecruiter);
  data.origOriginator = utils.convertObjectToOptionFormat(data.originator);
  data.origSalesperson = utils.convertObjectToOptionFormat(data.salesperson);
  data.origAccountCoordinators = utils.convertObjectToOptionFormat(data.accountCoordinators);


  if(!isEmpty(data.primaryContact)) {
    data.primaryContact.name = compact([
      get(data, 'primaryContact.contactName.preferredName') || get(data, 'primaryContact.contactName.firstName'),
      get(data, 'primaryContact.contactName.lastName')
    ]).join(' ');
  }

  if(data.companyType === 'PROGRAM') {
    data.programInfo = {
      ...data.programInfo,
      mspCompany: get(data, 'programInfo.mspCompany.companyId') ? {...get(data, 'programInfo.mspCompany'), entityType: 'COMPANY', entityId: get(data, 'programInfo.mspCompany.companyId')} : null,
      mspContact: {
        ...get(data, 'programInfo.mspContact'),
        name: compact([
          get(data, 'programInfo.mspContact.contactName.preferredName') || get(data, 'programInfo.mspContact.contactName.firstName'),
          get(data, 'programInfo.mspContact.contactName.lastName')
        ]).join(' ')
      },
      clientCompany: get(data, 'programInfo.clientCompany.companyId') ? {...get(data, 'programInfo.clientCompany'), entityType: 'COMPANY', entityId: get(data, 'programInfo.clientCompany.companyId') } : null,
      clientContact: {
        ...get(data, 'programInfo.clientContact'),
        name: compact([
          get(data, 'programInfo.clientContact.contactName.preferredName') || get(data, 'programInfo.clientContact.contactName.firstName'),
          get(data, 'programInfo.clientContact.contactName.lastName')
        ]).join(' '),
      }
    };
  }

  return data;
}

function prepareForApiCompanies(data) {
  const apiData = pick(cloneDeep(data), [
    'name',
    'businessUnitId',
    'companyType',
    'status',
    'summary',
    'primaryContactId',
    'ownerUserId',
    'primaryRecruiterUserId',
    'coOwnerUserIds',
    'salespersonUserId',
    'accountCoordinatorUserIds',
    'originatorUserId',
    'address',
    'jobTypes',
    'parentCompanyId',
    'targetPotentialType',
    'revenue',
    'numEmployees',
    'ownershipType',
    'industryIds',
    'badgeIds',
    'websiteUrl',
    'primaryPhone',
    'supplementalDocuments',
    'isClientMSAExecuted',
    'mspFee',
    'backgroundAndComplianceDetails',
    'preferredBackgroundVendor',
    'tenureRule',
    'contractEffectiveDate',
    'contractExpirationDate',
    'contractTerminationDate',
    'timeCaptureMethod',
    'externalTimeCaptureSystemName',
    'vmsName',
    'workWeek',
    'payrollFrequencyType',
    'payrollFrequencyDetails',
    'payrollDay',
    'accountsPayableTimeLimit',
    'accountsPayableContactName',
    'accountsPayableContactEmail',
    'isAccountsPayableDirectAllowed',
    'expensePolicy',
    'isPTOEligible',
    'ptoDetails',
    'isSickLeaveBillable',
    'acaCostDetails',
    'holidayDetails',
    'penaltyDetails',
    'sanFranciscoHCSODetails',
    'invoiceMethod',
    'invoiceMethodDetails',
    'markupDetails',
    'otBillRateMultiplier',
    'invoicingServiceTax',
    'invoiceFrequencyType',
    'invoiceFrequencyDetails',
    'invoiceTimeLimit',
    'invoiceDetails',
    'isBackgroundBillable',
    'backgroundBillableDetails',
    'isBackgroundCostPassdown',
    'backgroundCostPassdownDetails',
    'paymentTerms',
    'rebateDiscount',
    'conversionFeeDetails',
    'reportingRequirements',
    'slaRequirements',
    'timecardApprovalInstructions',
    'submissionRequirements',
    'numSubmissionsAllowed',
    'guaranteePeriod',
    'isNonBillableOrientation',
    'breakInServiceDetails',
    'clientPerks',
    'subSupplierOTMarkup',
    'subSupplierMarkupMax',
    'subSupplierMarkupExceptions',
    'subContractingRules',
    'subSupplierInsuranceRequirements',
    'accountType',
    'isSickLeavePayable',
    'adjudicationGuidelines',
    'sanFranciscoAdjustmentTimeline',
    'bonusPayments',
    'discrepanciesAndDisputesTimeline',
    'bonusPaymentBillingDetails',
    'invoicingHolidayDetails',
    'auditLogComment'
  ]);

  const tinyMCEFields = ['auditLogComment'];

  const operationsFields = [
    'mspFee',
    'backgroundAndComplianceDetails',
    'preferredBackgroundVendor',
    'tenureRule',
    'externalTimeCaptureSystemName',
    'vmsName',
    'payrollFrequencyDetails',
    'accountsPayableTimeLimit',
    'accountsPayableContactName',
    'accountsPayableContactEmail',
    'expensePolicy',
    'ptoDetails',
    'acaCostDetails',
    'holidayDetails',
    'penaltyDetails',
    'sanFranciscoHCSODetails',
    'invoiceMethodDetails',
    'markupDetails',
    'invoicingServiceTax',
    'invoiceFrequencyDetails',
    'invoiceTimeLimit',
    'invoiceDetails',
    'backgroundBillableDetails',
    'backgroundCostPassdownDetails',
    'paymentTerms',
    'rebateDiscount',
    'conversionFeeDetails',
    'reportingRequirements',
    'slaRequirements',
    'timecardApprovalInstructions',
    'submissionRequirements',
    'guaranteePeriod',
    'breakInServiceDetails',
    'clientPerks',
    'subSupplierMarkupExceptions',
    'subContractingRules',
    'subSupplierInsuranceRequirements',
    'adjudicationGuidelines',
    'sanFranciscoAdjustmentTimeline',
    'bonusPayments',
    'discrepanciesAndDisputesTimeline',
    'bonusPaymentBillingDetails',
    'invoicingHolidayDetails'
  ];

  tinyMCEFields.concat(operationsFields).forEach(function(field) {
    if (apiData[field]) {
      apiData[field] = encodeURIComponent(apiData[field]);
    }
  });

  if (has(apiData, 'supplementalDocuments')) {
    for (let i = 0; i < apiData.supplementalDocuments.length; i++) {
      apiData.supplementalDocuments[i] = {attachmentId: apiData.supplementalDocuments[i].attachmentId};
    }
  }

  if (has(data, 'parentCompany.companyId')) {
    apiData.parentCompanyId = data.parentCompany.companyId;
  }
  if (has(data, 'parentCompany')) {
    apiData.parentCompanyId = has(data, 'parentCompany.companyId') ? data.parentCompany.companyId : null;
  }

  if (has(data, 'notifyTargetIds') && Array.isArray(data.notifyTargetIds) && data.notifyTargetIds.length>0) {
    utils.splitIdsForApi(data.notifyTargetIds, apiData);
  }

  if (get(apiData, 'address')) {
    apiData.address= utils.addressTransform(apiData.address);
  }

  if (get(apiData, 'contractEffectiveDate')) {
    apiData.contractEffectiveDate = utils.dateOnlyForApi(apiData.contractEffectiveDate);
  }

  ['contractExpirationDate', 'contractTerminationDate'].forEach((field) => {
    if (get(apiData, field)) {
      apiData[field] = utils.dateOnlyForApi(apiData[field], false);
    }
  });

  if (apiData.timeCaptureMethod !== 'EXTERNAL_SYSTEM' && apiData.externalTimeCaptureSystemName) {
    apiData.externalTimeCaptureSystemName = null;
  }

  const dependedFieldsLookups = {
    'payrollFrequencyType': 'payrollFrequencyDetails',
    'invoiceMethod': 'invoiceMethodDetails',
    'invoiceFrequencyType': 'invoiceFrequencyDetails'
  };

  Object.keys(dependedFieldsLookups).forEach((field) => {
    const dependedField = dependedFieldsLookups[field];
    if(has(apiData, field) && apiData[field] !== 'OTHER' && apiData[dependedField]) {
      apiData[dependedField] = null;
    }
  });

  if(!isEmpty(get(data, 'primaryContact'))) {
    apiData.primaryContactId = get(data, 'primaryContact.contactId');
  }

  if (!isEmpty(get(data, 'mainProgramCompany'))) {
    apiData.mainProgramCompanyId = get(data, 'mainProgramCompany.companyId');
  }

  if (has(data, 'programInfo')) {
    if (apiData.companyType === 'PROGRAM') {
      apiData.programInfo = {
        mspCompanyId: get(data, 'programInfo.mspCompany.companyId'),
        mspContactId: get(data, 'programInfo.mspContact.contactId'),
        clientCompanyId: get(data, 'programInfo.clientCompany.companyId'),
        clientContactId: get(data, 'programInfo.clientContact.contactId')
      };
      apiData.primaryContactId = get(apiData, 'programInfo.mspContactId');
    } else {
      apiData.programInfo = {
        mspCompanyId: null,
        mspContactId: null,
        clientCompanyId: null,
        clientContactId: null
      };
    }
  }

  return apiData;
}

function prepareForRecentViewedEntityCompanies(data) {
  try {
    const {city, state} = get(data, 'address') || data;
    const recentVisitData = {
      ...pick(cloneDeep(data), [
        'name',
        'status',
        'statusValue',
        'companyId',
        'bullhornId',
        'companyType'
      ]),
      city, state
    };

    recentVisitData.statusValue = recentVisitData.statusValue ? recentVisitData.statusValue : utils.camelize(recentVisitData.status);
    recentVisitData.entityType = 'COMPANY';
    recentVisitData.entityId = recentVisitData.companyId;
    recentVisitData.stringEntityId = 'CMP' + recentVisitData.entityId;
    recentVisitData.recentViewedEntity = true;
    recentVisitData.businessUnitId = get(data, 'businessUnit.id');

    return recentVisitData;
  } catch (err) {
    console.log(err);
    return data;
  }
}

export {
  prepareForRenderCompanies,
  prepareForApiCompanies,
  prepareForRecentViewedEntityCompanies
};
