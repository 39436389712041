import {toast} from  'react-toastify';

export default function useNotification() {
  const showNotification = (type, msg) => {
    const position = toast.POSITION.TOP_RIGHT;
    switch (type) {
    case 'error':
      toast.error(msg, {
        position: position
      });
      break;
    case 'warn':
      toast.warn(msg, {
        position: position
      });
      break;
    case 'info':
      toast.info(msg, {
        position: position
      });
      break;
    default:
      toast.success(msg, {
        position: position
      });
      break;
    }
  };

  return {
    showNotification
  };
};
