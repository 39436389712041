import Button from '../Button';
import React from 'react';

export default ({cancelBulkUpload, bulkUploadFiles, files}) => {
  return <>
    <div className='d-flex justify-content-end pt-3'>
      <Button type='button' id='close-drop-zone' variant='btn-secondary mr-4' click={(e) => cancelBulkUpload(e)}>
        Cancel
      </Button>
      <Button type="submit" id='done-drop-zone' variant='btn-primary ats-md-btn' click={(e) => bulkUploadFiles(e)} disabled={!files.length}>
        Done
      </Button>
    </div>
  </>;
};
