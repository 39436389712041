const sideNavConfig = {
  mainNav: [
    {
      className: 'nav-link',
      id: 'nav-home',
      to: '/',
      iconName: 'ats-brand',
      activeIconName: 'ats-brand',
      alt: 'dashboard',
      title: 'Dashboard',
      width: '34',
      height: '34',
      allow: true,
      eventType: 'homepage click'
    },
    {
      className: 'nav-link',
      id: 'nav-search',
      to: '#',
      iconName: 'search',
      activeIconName: 'search-active',
      alt: 'Search',
      title: 'Search',
      showInputBox: true,
      allow: true,
      eventType: 'quick search',
      eventProperties: {
        action: 'initiate',
        timer: 'start'
      },
      permissionGroupIds: ['VIEW_PRIMARY_OBJECTS']
    },
    {
      className: 'nav-link',
      id: 'nav-new',
      to: '#',
      iconName: 'add',
      activeIconName: 'add-active',
      alt: 'Add',
      title: 'Add New',
      allow: true,
      permissionGroupIds: ['CREATE_EDIT_PRIMARY_OBJECTS']
    },
    {
      className: 'nav-link',
      id: 'nav-candidate',
      to: '#',
      iconName: 'candidates',
      activeIconName: 'candidates-active',
      alt: 'Candidates',
      title: 'Candidates',
      allow: true,
      pathNames: ['candidate', 'candidates', 'new-candidate'],
      eventType: 'global recent',
      eventProperties: {
        action: 'initiate',
        globalNav: 'candidate slide-in',
        timer : 'start'
      },
      permissionGroupIds: ['VIEW_PRIMARY_OBJECTS']
    },
    {
      className: 'nav-link',
      id: 'nav-job',
      to: '#',
      iconName: 'jobs',
      activeIconName: 'jobs-active',
      alt: 'Jobs',
      title: 'Jobs',
      allow: true,
      pathNames: ['job', 'jobs', 'new-job'],
      eventType: 'global recent',
      eventProperties: {
        action: 'initiate',
        globalNav: 'job slide-in',
        timer : 'start'
      },
      permissionGroupIds: ['VIEW_PRIMARY_OBJECTS']
    },
    {
      className: 'nav-link',
      id: 'nav-contact',
      to: '#',
      iconName: 'contacts',
      activeIconName: 'contacts-active',
      alt: 'Contacts',
      title: 'Contacts',
      allow: true,
      pathNames: ['contact', 'contacts', 'new-contact'],
      eventType: 'global recent',
      eventProperties: {
        action: 'initiate',
        globalNav: 'contact slide-in',
        timer : 'start'
      },
      permissionGroupIds: ['VIEW_PRIMARY_OBJECTS']
    },
    {
      className: 'nav-link',
      id: 'nav-more-option',
      to: '#',
      iconName: 'more-options',
      activeIconName: 'more-options-active',
      alt: 'More Options',
      title: 'More Options',
      allow: true,
      pathNames: ['templates', 'company', 'companies', 'new-company', 'placement', 'placements', 'new-placement', 'reports', 'bulkEmails', 'bulkEmail', 'targets', 'lists', 'tasks', 'savedReports', 'savedSearches', 'submissions'],
      permissionGroupIds: [
        'VIEW_PRIMARY_OBJECTS',
        'VIEW_REPORTS_FOR_PRIMARY_OBJECTS',
        'IMPORT_PRIMARY_OBJECTS',
        'VIEW_BULK_EMAIL_RESULTS',
        'MANAGE_SYSTEM_SHARED_DATA',
        'CREATE_EDIT_USER_TARGETS',
        'MERGE_PRIMARY_OBJECTS',
        'MERGE_COMPANIES',
        'MERGE_CONTACTS',
        'MERGE_CANDIDATES',
        'MERGE_JOBS'
      ]
    },
  ],

  otherNavs: [
    {
      id: 'nav-notification',
      to: '#',
      iconName: 'notifications',
      activeIconName: 'notifications-active',
      alt: 'Notifications',
      title: 'Notifications',
      allow: true,
      eventType: 'global notifications',
      eventProperties: {
        action: 'initiate',
        timer: 'start'
      },
      permissionGroupIds: ['VIEW_PRIMARY_OBJECTS']
    },
    {
      id: 'nav-setting',
      to: '#',
      iconName: 'settings',
      activeIconName: 'settings-active',
      alt: 'Settings',
      title: 'Settings',
      pathNames: ['user', 'teams', 'badges', 'businessunits', 'practicearea', 'metrics'],
      allow: true,
      permissionGroupIds: ['VIEW_USERS_TEAMS', 'MANAGE_SYSTEM_USERS', 'MANAGE_SYSTEM_TEAMS', 'MANAGE_SYSTEM_CONFIGURATION']
    },
    {
      id: 'nav-profile',
      to: '#',
      iconName: 'profile',
      activeIconName: 'profile-active',
      alt: 'Profile',
      title: 'Profile',
      pathNames: ['my-profile'],
      allow: true
    }
  ]
};

export default sideNavConfig;
