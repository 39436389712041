import React, {useEffect, useState, useCallback, useMemo} from 'react';
import PropTypes from 'prop-types';
import {AtsModal, Button, Spinner} from '../common';
import searchConst from '../../constants/searchConst';
import useNotification from '../../hooks/useNotification';
import {omit} from '../../libs/lodash';
import utils from '../../libs/utils';
import useApiQuery from '../../hooks/useApiQuery';
import ApiClient from '../../services/ApiClient';

export default function ConfirmExportModal({modalVisible, modalCancel, entityType, criteria, includedAgExport=false, relatedEntity, type}) {
  const { showNotification } = useNotification();
  const defaultCriteria = {
    additionalCriteria: [],
    maxDistance: 10,
    sortBy: ''
  };
  const [exportCriteria, setexportCriteria] = useState(criteria || defaultCriteria);

  const prepareEntityTypePlural = (entity) => {
    switch (entity) {
    case 'Users':
      return 'Users';
    case 'Teams':
      return 'Teams';
    case 'Business Units':
      return 'BusinessUnits';
    case 'Job Categories':
      return 'PracticeAreas';
    case 'ACTIVITY_REPORT':
      return 'ActivityReport';
    case 'JOB_COVERAGE':
      return 'JobCoverage';
    case 'RECRUITING_ANALYTICS':
      return 'RecruitingAnalytics';
    case 'TARGET_LOG':
      return 'TargetLogs';
    default:
      return utils.camelize(searchConst.entityMap[entity.toLowerCase()]);
    }
  };
  const RETRY_ONCE = false;

  const entityTypePlural = prepareEntityTypePlural(relatedEntity?.entityType || '');
  const [exportId, setExportId] = useState(null);
  const [status, setStatus] = useState('INITIAL');

  const params = useMemo(() => {
    const defaultParams = entityType !== 'ATTACHMENT' ? { criteria: exportCriteria, type } : {export: criteria};
    const omittedParams = omit(defaultParams, ['criteria', 'export']);
    const restParams = defaultParams.criteria || defaultParams.export;

    return { omittedParams, restParams };
  }, [criteria, entityType, exportCriteria, type]);

  const { data: exportEntityResponse, error: exportEntityError, resetQuery: resetExportEntityQuery } = useApiQuery(['exports' + entityTypePlural], ['exports' + entityTypePlural], params.omittedParams, params.restParams, Boolean(status === 'START'), RETRY_ONCE);

  useEffect(() => {
    if (exportEntityResponse && status === 'START') {
      setExportId(exportEntityResponse.body.exportId);
      setStatus('PENDING');
    }
    if(exportEntityError){
      setStatus('INITIAL');
      modalCancel();
    }
  }, [exportEntityResponse, exportEntityError, modalCancel, status]);

  const closeExportModal = () => {
    setStatus('CLOSE');
    setExportId(null);
    modalCancel();
  };

  useEffect(() => {
    if (criteria && criteria !== exportCriteria) {
      setexportCriteria(criteria);
    }
  }, [criteria, exportCriteria]);

  const sendExportRequest = async () => {
    try {
      utils.prepareDisabledButton(null, '#confirm-export-modal', true);
      if(status === 'INITIAL'){
        setStatus('START');
      }
    } catch (err) {
      console.log(err);
    } finally {
      utils.prepareDisabledButton(null, '#confirm-export-modal', false);
    }
  };
  const { data: queryData, isFetched: dealsExportIsFetched } = useApiQuery(['exportsFile', exportId], 'exportsFile', { id: exportId }, null, Boolean(status === 'READY'), RETRY_ONCE);

  const downloadFileRequest = useCallback(() => {
    const fetchFile = async () => {
      try {
        const fileData = queryData.data;
        const fileHeader = queryData.headers;
        const fileType = fileHeader['content-type'].split(';')[0];
        const fileBlob = new Blob([fileData], {type: fileType});
        const fileExtension = fileType.split('/')[1];

        const currentTime = Date.now();
        const fileName = entityTypePlural+'_'+currentTime+'.'+fileExtension;
        await utils.downloadFile(fileBlob, fileName);
        setStatus('INITIAL');
        modalCancel();
      } catch (error) {
        setStatus('INITIAL');
        console.log(error);
      } finally {
        resetExportEntityQuery();
      }
    };
    if (!!dealsExportIsFetched) {
      fetchFile();
    }
  }, [dealsExportIsFetched, queryData, entityTypePlural, modalCancel, resetExportEntityQuery]);

  useEffect(() => {
    let checkExportStatusInterval;
    const duration = 1000;

    const checkExportStatus = async () => {
      try {
        if (!ApiClient.exportsDetails) {
          clearInterval(checkExportStatusInterval);
          showNotification('error', 'You do not have permission to export objects!');
          setStatus('INITIAL');
          return;
        }

        const res = await ApiClient.exportsDetails({id: exportId});
        if (res.body.status!=='PENDING') {
          setStatus(res.body.status);
          clearInterval(checkExportStatusInterval);
        }
      } catch (error) {
        console.log(error);
        setStatus('FAILED');
        clearInterval(checkExportStatusInterval);
      }
    };

    const processFn = async () => {
      switch (status) {
      case 'PENDING':
        checkExportStatusInterval = setInterval(checkExportStatus, duration);
        break;
      case 'READY':
        downloadFileRequest();
        break;
      case 'CLOSE':
        clearInterval(checkExportStatusInterval);
        setStatus('INITIAL');
        break;
      case 'FAILED':
        showNotification('error', 'File failed to download!');
        await utils.wait(2000);
        setStatus('INITIAL');
        break;
      default:
      }
    };

    processFn();

    return () => {
      //clear interval when component destroy
      clearInterval(checkExportStatusInterval);
    };
  }, [status, exportId, showNotification, downloadFileRequest]);

  const prepareProgress = () => {
    switch (status) {
    case 'FAILED':
    case 'PENDING':
    case 'READY':
      return (
        <div className='pl-0 item pr-4'>
          <div className='pt-3 pr-5'>
            <Spinner variant='fa-4x'/>
          </div>
        </div>
      );
    case 'START':
    default:
      return (
        <p>Are you sure you want to export all {type.replace('_', ' ').toLowerCase()}?</p>
      );
    }
  };

  return (
    <>
      {
        modalVisible?
          <AtsModal
            id='export-modal'
            modalTitle='Confirm Export'
            modalSize='md'
            modalVisible={modalVisible}
            modalCancel={closeExportModal}
          >
            <>
              <>
                <div className='form-group export-modal-options'>
                  { prepareProgress() }
                </div>
                <div className='form-group'>
                  <div className='d-flex justify-content-end pt-3'>
                    <Button type='button' id='close-export-modal' variant='btn-secondary mr-4' click={closeExportModal}>
                      Cancel
                    </Button>
                    {
                      ['INITIAL', 'START'].includes(status)
                        ? <Button type="confirm" id='confirm-export-modal' variant='btn-primary ats-md-btn' click={sendExportRequest}>
                          Confirm
                        </Button>
                        : null
                    }
                  </div>
                </div>
              </>
            </>
          </AtsModal>:''
      }
    </>
  );
};

ConfirmExportModal.propTypes = {
  modalVisible: PropTypes.bool.isRequired,
  modalCancel: PropTypes.func.isRequired,
  entityType: PropTypes.string.isRequired,
  criteria: PropTypes.object,
};
