import React, {useEffect} from 'react';
import useFile from '../../hooks/useFile';

export default function EmbedContent(props){
  const attachmentId = (props.match && props.match.params.id) ?  props.match.params.id : '';
  const {embedFile} = useFile();
  const isImage = props.location.search === '?image=true';
  
  useEffect(() => {
    // hide sidebar
    const sideBar = document.querySelector('#sidebar');
    sideBar.style.display = 'none';
    sideBar.style.width = '0px';

    // cross whole screen
    const content = document.querySelector('#content');
    content.style.marginLeft= '0';
    content.style.width = 'calc(100vw)';

    if (attachmentId) {
      embedFile(attachmentId);
    }
  }, [attachmentId, embedFile]);

  return (
    <>
      {isImage ?
        <img id='embed-img' src='' type='' className='w-100 border-0' alt='embed file'/>
        :
        <div className='embed-responsive embed-responsive-1by1'>
          <object data='' type='' className='border-0 embed-responsive-item'>
            <embed src='' type='' className='w-100 border-0 embed-responsive-item'/>
          </object>
        </div>
      }
    </>
  );
};
