import React from 'react';
import AtsSelect from '../AtsSelect';

export default function AtsGroupSelect(props) {
  const groupStyles = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  };

  const formatGroupLabel = data => {
    return (
      <div style={groupStyles}>
        {data.name}
      </div>
    );
  };

  return (
    <AtsSelect
      {...props}
      formatGroupLabel={formatGroupLabel}
    />
  );
};
