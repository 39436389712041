import './style.scss';
import React from 'react';
import DatePicker from 'react-datepicker';
import utils from '../../../libs/utils';

function AtsDatePicker({defaultValue, id, name, required, position, callback, minDate=null, maxDate=null, placeholderText='', dateOnly=true, form=true, dateFormat='MMMM d, yyyy', onFocus, onBlur}) {
  const dateProps = {
    id: id || name,
    name: name,
    required: required,
    placeholderText: placeholderText,
    className: 'form-control',
    dateFormat: dateFormat,
    selected: defaultValue ? (dateOnly ? new Date(utils.dateOnlyForRender(defaultValue, 'MM/DD/YY')) : new Date(utils.dateForRender(defaultValue, 'MM/DD/YY'))): null,
    minDate: minDate ? (dateOnly ? new Date(utils.dateOnlyForRender(minDate, 'MM/DD/YY')) : new Date(utils.dateForRender(minDate, 'MM/DD/YY'))) : null,
    maxDate: maxDate ? (dateOnly ? new Date(utils.dateOnlyForRender(maxDate, 'MM/DD/YY')) : new Date(utils.dateForRender(maxDate, 'MM/DD/YY'))) : null,
    ...(onFocus && { onFocus: onFocus }),
    ...(onBlur && { onBlur: onBlur })
  };
  return (
    <>
      {form ?
        <div className='ats-date-picker'>
          <DatePicker {...dateProps} onChange={date => callback({[name]: date})} />
          <label className={`calendar-icon form ${position==='to'?'to':'from'}`} htmlFor={name} />
        </div>
        :
        <>
          <DatePicker {...dateProps} onChange={date => callback({[name]: date})} />
          <label className={`calendar-icon ${position==='to'?'to':'from'}`} htmlFor={name} />
        </>
      }
    </>
  );
}

export default AtsDatePicker;
