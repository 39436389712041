import './style.scss';
import React from 'react';
import useEditMode from '../../../hooks/useEditMode';
import {hotKeys} from '../../../constants/hotKeys';
import {useDispatch} from 'react-redux';
import * as hotKeyActions from '../../../actions/hotkey';
import utils from '../../../libs/utils';

export default function AtsSearchBar(props) {
  const dispatch = useDispatch();
  const {inputId = 'search-bar-input', trackHotKey = true} = props;

  const inputEl = document.getElementById(inputId);
  const [addEditMode, removeEditMode] = useEditMode(inputEl);
  const onChange = (event) => {
    props.callback(event.target.value);
  };

  const handleOnFocus = () => {
    if (props.onFocusCallback) {
      props.onFocusCallback();
    }
    addEditMode();
  };

  const handleKeyDown = (event) => {
    event.stopImmediatePropagation();
    if (utils.detectEditMode().length === 0) {
      if (event.keyCode === hotKeys.KEY_A) {
        setTimeout(() => {
          inputEl.focus();
          inputEl.value = '';
          props.callback('');
        }, 0);
      }
    }
  };

  const inputProps = trackHotKey ? {
    value: props.value,
    onInput: onChange,
    onFocus: handleOnFocus,
    onBlur: removeEditMode
  } : {
    defaultValue: props.defaultValue,
    ref: props.inputRef,
    maxLength: props.maxLength,
    onChange: props.onChange
  };

  if(trackHotKey) {
    dispatch(hotKeyActions.addHotKey('search-bar-input', handleKeyDown));
  }

  return (
    <div className='search-bar-container align-self-center' style={trackHotKey ? {'overflow': 'hidden'} : {}}>
      <input id={inputId} className='search-bar-input' placeholder={props.placeholder} {...inputProps} />
      <span className='search-bar-icon'/>
    </div>
  );
}
