import {isEmpty} from '../libs/lodash';
import TabsResourceLock from './TabsResourceLock';
import apiUserStatus from './apiUserStatus';
import {StatusCodes} from 'http-status-codes';
import ApiClient from './ApiClient';

export default class TokenService {
  static get accessToken(){
    const accessTokenInfo = window.localStorage.getItem('accessToken');

    if (accessTokenInfo === null) {
      return null;
    }

    return JSON.parse(accessTokenInfo);
  }

  static get refreshToken(){
    const refreshTokenInfo = window.localStorage.getItem('refreshToken');

    if (refreshTokenInfo === null) {
      return null;
    }

    return JSON.parse(refreshTokenInfo);
  }

  static setAccessTokenInfo(accessToken) {
    window.localStorage.setItem('accessToken', JSON.stringify(accessToken));
  }

  static setRefreshTokenInfo(refreshToken) {
    window.localStorage.setItem('refreshToken', JSON.stringify(refreshToken));
  }

  static flashTokens() {
    window.localStorage.removeItem('accessToken');
    window.localStorage.removeItem('refreshToken');
  }

  static async getAccessToken() {
    if (isEmpty(this.accessToken)) {
      return null;
    }

    if (this.isTokenExpired(this.accessToken)) {
      // use TabsResourceLock to prevent race condition of updating accessToken
      const lockTokenRequest = new TabsResourceLock('access-token', 3000);
      if (await lockTokenRequest.lock()) {
        console.log('lock', new Date().toISOString());

        // double check if other requests already update accessToken during locking
        if (this.isTokenExpired(this.accessToken)) {
          console.log('updateAccessToken', new Date().toISOString());
          await this.updateAccessToken();
        }

        await lockTokenRequest.unLock();
        console.log('unLock', new Date().toISOString());
      }
    }

    return this.accessToken.token;
  }

  static async updateAccessToken() {
    if (this.isTokenExpired(this.refreshToken)) {
      console.log('refreshToken isTokenExpired', this.isTokenExpired(this.refreshToken));
      apiUserStatus.remove();
      return;
    }

    const { userEmail } = apiUserStatus.get() || {};

    const response = await ApiClient.identityTokensRefresh({}, { email: userEmail, refreshToken: this.refreshToken.token});

    if (response.status === StatusCodes.OK) {
      const { accessToken } = response.body;
      TokenService.setAccessTokenInfo(accessToken);
    }
  }

  static isTokenExpired(tokenInfo) {
    if (!tokenInfo) {
      return true;
    }

    const { expiresAt } = tokenInfo;

    return Date.parse(expiresAt) <= Date.now() + 1000 * 60 * 5;
  }
};
