import utils from '../libs/utils';
import {notifyUserIdsList, notifyTeamIdsList} from '../constants/notificationConst';
import {AtsSelect} from '../compoments/common';
import {has, get} from '../libs/lodash';

export default ({lookups, handleChange, entity={}, labelName}) => {
  const combinedLookups = utils.prepareCombinedLookups({notifyUserIds: lookups.users, notifyTeamIds: lookups.teams});

  const handleNotifyUsersChange = (updatedValues) => {
    if (has(updatedValues, 'division')) {
      if (get(updatedValues, 'division')) {
        updatedValues.divisionTeamIds = get(lookups, 'teams', []).filter((team) => (team.divisions || []).includes(get(updatedValues, 'division'))).map((t) => t.id);
      } else {
        updatedValues.divisionTeamIds = [];
      }
    } else if (get(entity, 'division') && !has(entity, 'divisionTeamIds')){
      updatedValues.divisionTeamIds = get(lookups, 'teams', []).filter((team) => (team.divisions || []).includes(get(entity, 'division'))).map((t) => t.id);
    }

    if (Object.keys(updatedValues).some((keyName) => notifyUserIdsList.includes(keyName) || notifyTeamIdsList.includes(keyName))) {
      handleChange({...updatedValues, notifyTargetIds: utils.prepareNotifyTargetIds({...entity, ...updatedValues})});
    } else {
      handleChange(updatedValues);
    }
  };

  const notifyTargetIdsField = [
    [
      {
        label: labelName ||'Notify Users or Teams',
        className: 'col-12 pl-12 pr-0',
        component: AtsSelect,
        props: {
          name: 'notifyTargetIds',
          defaultValue: entity.notifyTargetIds || [],
          isMulti: true,
          options: combinedLookups || [],
          required: entity.requiredNotifyTargetIds
        }
      }
    ],
  ];

  return {
    combinedLookups,
    handleNotifyUsersChange,
    notifyTargetIdsField,
  };
};
