import {cloneDeep, get, pick, has, isEmpty} from '../../../libs/lodash';
import {referredByDependedValue} from '../../../constants/contactConst';
import utils from '../../../libs/utils';
import candidateConst from '../../../constants/candidateConst';

function prepareForRenderContacts(data) {
  if (!data.additionalInfo) { data.additionalInfo = {};}
  if (!data.additionalInfo.address) {data.additionalInfo.address = {};}
  if (!data.contactName) {data.contactName = {};}

  //deal inactive user
  data.origRelationshipOwner = utils.convertObjectToOptionFormat(data.relationshipOwner);

  data.companyId = get(data, 'company.companyId') || get(data, 'company.id');
  if (!isEmpty(data.company)) {
    data.company.entityType = 'COMPANY';
    data.company.entityId = get(data, 'company.companyId') || get(data, 'company.id');
  }

  data.programCompanyIds = (get(data, 'programCompanies') || []).map((c) => c.companyId);
  (get(data, 'programCompanies') || []).forEach((c) => {
    c.entityType = 'COMPANY';
    c.entityId = get(c, 'companyId');
  });

  data.badgeIds = (data.badges || []).map((badge) => badge.id);
  data.relationshipOwnerUserId = data.relationshipOwner && data.relationshipOwner.userId;
  data.relationshipOwner = data.relationshipOwner && {id: data.relationshipOwner.userId, name: utils.shortName(data.relationshipOwner, candidateConst.maxFullNameLength, true)};
  data.reportsToContactId = data.reportsToContact && data.reportsToContact.contactId;
  data.reportsToContact = data.reportsToContact && {id: data.reportsToContact.contactId, name: utils.shortName(data.reportsToContact.contactName, candidateConst.maxFullNameLength, true)};
  data.businessUnitId = data.businessUnit && data.businessUnit.id;
  data.sourceOptionId = data.sourceOption && data.sourceOption.id;
  data.isValidEmail = data.emailValidity !== 'INVALID';

  const coding = utils.prepareCodingTransform(data);
  data = {...data, ...coding};

  return data;
}

function prepareForApiContacts(data) {
  const apiData = pick(cloneDeep(data), [
    'contactName',
    'additionalInfo',
    'jobTitle',
    'summary',
    'companyId',
    'programCompanyIds',
    'businessUnitId',
    'status',
    'sourceOptionId',
    'referredBy',
    'relationshipOwnerUserId',
    'reportsToContactId',
    'badgeIds',
    'industryIds',
    'departmentIds',
    'jobFunctionIds',
    'skillIds',
    'highlightedSkills',
    'supplementalDocuments',
    'auditLogComment',
    'candidateId'
  ]);

  if (has(data, 'companyId') && apiData.companyId ) {
    apiData.companyId = +apiData.companyId;
  }

  if (has(data, 'company.companyId')) {
    apiData.companyId = get(data, 'company.companyId');
  }

  apiData.programCompanyIds = (get(data, 'programCompanies') || []).map((c) => c.companyId);

  if (has(data, 'company.businessUnitId')) {
    apiData.businessUnitId = get(data, 'company.businessUnitId');
  }

  if (has(data, 'candidate')) {
    apiData.candidateId = has(data, 'candidate.candidateId') ? data.candidate.candidateId : null;
  }

  if (isEmpty(data.candidate) && data.candidateId === null) {
    apiData.candidateId = null;
  }

  if (has(apiData, 'supplementalDocuments')) {
    for (let i = 0; i < apiData.supplementalDocuments.length; i++) {
      apiData.supplementalDocuments[i] = {attachmentId: +apiData.supplementalDocuments[i].attachmentId};
    }
  }

  if (has(apiData, 'sourceOptionId') && !referredByDependedValue.includes(apiData.sourceOptionId)) {
    apiData.referredBy = data.referredBy;
  }

  if (has(apiData, 'auditLogComment')) {
    apiData.auditLogComment = encodeURIComponent(apiData.auditLogComment);
  }

  if (has(data, 'notifyTargetIds') && Array.isArray(data.notifyTargetIds) && data.notifyTargetIds.length>0) {
    utils.splitIdsForApi(data.notifyTargetIds, apiData);
  }

  if (get(apiData, 'additionalInfo.address')) {
    apiData.additionalInfo.address = utils.addressTransform(apiData.additionalInfo.address);
  }

  if (apiData.additionalInfo) {
    delete apiData.additionalInfo.primaryEmailDetails;
  }

  if (has(apiData, 'additionalInfo.dupesCheckAt')) {
    delete apiData.additionalInfo.dupesCheckAt;
  }

  return apiData;
}

function prepareForRecentViewedEntityContacts(data) {
  try {
    const {primaryEmail, otherEmail, mobilePhone, homePhone, workPhone} = get(data, 'additionalInfo') || data;
    const {city, state} = get(data, 'additionalInfo.address') || data;
    const recentVisitData = {
      ...pick(cloneDeep(data), [
        'contactName',
        'company',
        'status',
        'statusValue',
        'contactId',
        'bullhornId'
      ]),
      primaryEmail, otherEmail, mobilePhone, homePhone, workPhone,
      city, state
    };

    recentVisitData.statusValue = recentVisitData.statusValue ? recentVisitData.statusValue : utils.camelize(recentVisitData.status);
    recentVisitData.entityType = 'CONTACT';
    recentVisitData.entityId = recentVisitData.contactId;
    recentVisitData.stringEntityId = 'CNT' + recentVisitData.entityId;
    recentVisitData.recentViewedEntity = true;

    return recentVisitData;
  } catch (err) {
    console.log(err);
    return data;
  }
}

const prepareTypeAheadSelectedEntity = (entity, entityType) => {
  if(!isEmpty(entity)) {
    return {
      ...entity,
      entityId: entity[entityType.toLowerCase()+'Id'],
      entityType,
      ...entity[entityType.toLowerCase()+'Name'],
    };
  }
};

export {
  prepareForRenderContacts,
  prepareForApiContacts,
  prepareForRecentViewedEntityContacts,
  prepareTypeAheadSelectedEntity,
};
