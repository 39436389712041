import React from 'react';
import PropTypes from 'prop-types';

export default function Spinner({variant, status='PENDING'}) {
  const prepareStatusIcon = () => {
    switch (status) {
    case 'FAILED':
      return <i className='fa fa-times-circle red-text'>&nbsp;</i>;
    case 'READY':
      return <i className='fa fa-check-circle dark-blue-text'>&nbsp;</i>;
    case 'MERGE':
      return <i className='fa fa-spinner fa-spin off-white-text'>&nbsp;</i>;
    case 'PENDING':
    default:
      return <i className='fa fa-spinner fa-spin dark-blue-text'>&nbsp;</i>;
    }
  };
  return (
    <div className={variant}>
      <div className='text-center' role='status'>
        { prepareStatusIcon() }
      </div>
    </div>
  );
};

Spinner.propTypes = {
  variant: PropTypes.string,
  status: PropTypes.string
};
