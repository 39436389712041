import React from 'react';
import {compact, isEmpty, get} from '../../libs/lodash';
import utils from '../../libs/utils';

export default function PhoneList({phones, phonePriorityList, section, editIcon}) {
  function genPhoneList() {
    const phoneOrderList = phonePriorityList || ['mobilePhone', 'workPhone', 'homePhone'];
    let phoneList = phoneOrderList.map((p) => {
      return phones[p] && (
        <div className='dark-blue-text' key={p} id={p}>
          {p !== 'workPhone' ? utils.phoneForRender(phones[p], get(phones, 'address.country')) : utils.phoneForRender(phones[p], get(phones, 'address.country'), phones['workPhoneExt'])}
          <span className='help-text'>{p.replace('Phone', '')}</span>
          {editIcon(section, p)}
        </div>
      );
    });
    phoneList = compact(phoneList);
    return isEmpty(phoneList) ? editIcon(section, phoneOrderList[0]) : phoneList;
  }

  return genPhoneList();
};
