import React from 'react';

export default ({switchType}) => {
  return (
    <>
      <div className='form-group'>
        <div className='loginSubTitle'>You have successfully set your password</div>
      </div>

      <div className='reset-sent'>
        <span className='caret-left' >&#8249;</span><span id='reset-sent-link' className='cursor' onClick={() => switchType('email')}> Back to Login</span>
      </div>
    </>
  );
};
