import React from 'react';
import PropTypes from 'prop-types';
import maxMinConst from '../../constants/maxMinConst';
import {AtsDropdown, Spinner} from '../common';
import AtsSearchBar from '../common/AtsSearchBar';

export default function HeaderSearch({drawer, searchEntityTypes, closeDrawer, handleChange, handleFilter, searchEntityType, searchTerm, isFetched, handleStatusesFilter, statusSelected, statusesOption}) {
  return (
    <div className='mt-4 pb-1 px-4'>
      <i className='fa fa-chevron-left cursor' onClick={() => closeDrawer(true)} />
      <span className='title'>
        <AtsSearchBar
          inputId='search-input'
          placeholder={drawer.title}
          maxLength={maxMinConst.ShortTextMaxlength}
          trackHotKey={false}
          autoComplete='off'
          defaultValue={searchTerm}
          onChange={handleChange}
        />
        {isFetched ? <Spinner variant='text-right d-inline-block' /> : ''}
      </span>
      {/*<div className={`divider divider-horizontal${searchTerm ? ' black-divider' : ''}`} />*/}
      <div className='filter'>
        <AtsDropdown
          dropdownChoices={searchEntityTypes}
          callback={handleFilter}
          default={searchEntityType}
          label='Filter'
          id='quickSearchFilter'
          variant='border-0'
          labelVariant='light-grey-text biotif pr-0'
        />
        {
          statusesOption ?
            <AtsDropdown
              dropdownChoices={statusesOption}
              callback={handleStatusesFilter}
              default={statusSelected}
              label='Status'
              id='statusesFilter'
              variant='border-0'
              labelVariant='light-grey-text biotif pr-0'
            />
            :''
        }
      </div>
    </div>
  );
};

HeaderSearch.propTypes = {
  drawer: PropTypes.object.isRequired,
  searchEntityTypes: PropTypes.object.isRequired,
  closeDrawer: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleFilter: PropTypes.func.isRequired,
  searchEntityType: PropTypes.string.isRequired,
  searchTerm: PropTypes.string.isRequired,
};
