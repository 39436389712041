import React from 'react';
import SuspenseWrap from '../../common/SuspenseWrap';

const TinyMceEditor = React.lazy(() => import('./TinyMceEditor'));

export default function AtsEditor (props) {
  return (
    <SuspenseWrap>
      <TinyMceEditor {...props} />
    </SuspenseWrap>
  );
}
