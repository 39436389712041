import React, {useState, useEffect} from 'react';
import AtsSelect from '../../common/AtsSelect/index';
import {keyBy, omit, pick, isEmpty} from '../../../libs/lodash';

function ActivityTypeSelect(props) {
  const [activityTypeFilterList, setActivityTypeFilterList] = useState([]);
  const UPDATE = 'UPDATE', NOTE = 'NOTE', APPOINTMENT = 'APPOINTMENT', CALL = 'CALL', TEXT_MESSAGE = 'TEXT_MESSAGE';
  const EMAIL = 'EMAIL', CONTRACTOR_CARE = 'CONTRACTOR_CARE', SUBMISSION = 'SUBMISSION', CONTACT = 'CONTACT';
  const PLACEMENT = 'PLACEMENT', REFERENCED='REFERENCED', CANDIDATE='CANDIDATE', RELATED='RELATED';

  useEffect(() => {
    if (!isEmpty(props.options)) {
      const activityTypeListById = keyBy(props.options, 'id');
      let typeKeys = [];

      switch (props.entityType) {
      case 'candidate':
        typeKeys = [APPOINTMENT, CALL, CONTRACTOR_CARE, EMAIL, NOTE, PLACEMENT, REFERENCED, SUBMISSION, TEXT_MESSAGE, UPDATE];
        break;
      case 'job':
        typeKeys = [CONTACT, NOTE, PLACEMENT, SUBMISSION, UPDATE];
        break;
      case 'contact':
        typeKeys = [APPOINTMENT, CALL, EMAIL, NOTE, REFERENCED, TEXT_MESSAGE, UPDATE];
        break;
      case 'company':
        typeKeys = [NOTE, RELATED, UPDATE];
        break;
      case 'placement':
        typeKeys = [CONTACT, CANDIDATE, NOTE, RELATED, UPDATE];
        break;
      default:
        break;
      }

      const activityTypeCats = pick(activityTypeListById, typeKeys);

      setActivityTypeFilterList(Object.values(activityTypeCats));
    }
  }, [props.entityType, props.options]);

  return (
    <AtsSelect
      {...omit(props, ['entityType', 'options'])}
      options={activityTypeFilterList}
    />
  );
}

export default ActivityTypeSelect;
