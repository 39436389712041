import { StatusCodes } from 'http-status-codes';
import apiUserStatus from './apiUserStatus';
import {reserveOriginalPathname} from './pathHandler';

export default function ErrorHandler(error) {
  let errorMsg = '';
  if (error.response) {
    // if security key is expired, then remove token, go to login page
    if (error.response.status === StatusCodes.UNAUTHORIZED) {
      reserveOriginalPathname(window.location.pathname);
      apiUserStatus.remove('user');
      errorMsg = 'Unauthorized Operation';
      window.location.href = '/login';
    } else {
      // get error msg
      let data = error.response.data;
      if (data) {
        data = JSON.parse(data);
        if (data.httpStatusCode === StatusCodes.BAD_REQUEST && data.validationErrors && data.validationErrors.length) {
          errorMsg = data.validationErrors.map((thisError) => thisError.message).join('.');
        } else {
          errorMsg = data.message;
        }
      }
    }
  } else {
    errorMsg = error.toString();
  }

  return errorMsg;
}
