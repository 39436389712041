import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';

import {AtsModal, Button} from '../../common';
import AdditionalCriteriaItems from './AdditionalCriteriaItems';
import LocationSearchForm from '../../common/LocationSearch/LocationSearchForm';
import { resetFiltersAndSelected } from '../../common/TableView/libs/utils';

import searchConst from '../../../constants/searchConst';
import {set, isEmpty, cloneDeep, isArray, keyBy, get} from '../../../libs/lodash';
import utils from '../../../libs/utils';

import {searchEntities, setSavedSearch} from '../../../actions/searchEntity';
import additionalSearchFieldsConfig from './additionalSearchFieldsConfig';
import apiUserStatus from '../../../services/apiUserStatus';

import {prepareCriteriaForSearch, prepareForAmplitudeEventProperties, prepareForApiSearch, lastSortBy} from '../libs/utils';
import {useDispatch} from 'react-redux';

import 'react-datepicker/dist/react-datepicker.css';
import 'react-bootstrap-typeahead/css/Typeahead.css';

export default function Search({history, entityType, initialCriteria, modalTitle, modalVisible, modalCancel, closeDrawer, saveCallback, saveAsNewCallback}) {
  const [criteria, setCriteria] = useState({});
  const [formValidated, setFormValidated] = useState(false);
  const additionalSearchFields = additionalSearchFieldsConfig[entityType];
  const additionalFieldMap = keyBy(additionalSearchFields, 'value');
  const page = window.location.pathname.split('/')[1];
  const {settings={}} = apiUserStatus.get() || {};
  let currentViewType = get(settings, 'tableViewSettings.' + entityType + 'ViewType');
  currentViewType = currentViewType && page !== '' && {viewType: (searchConst.viewTypeOptions[entityType][currentViewType] || '').toLowerCase()};
  const dispatch = useDispatch();

  useEffect(() => {
    try {
      setCriteria(prepareCriteriaForSearch(initialCriteria));
    } catch (error) {
      console.log(error);
    }
  }, [initialCriteria]);

  const clearAll= () => {
    let newCriteria =  {maxDistance: 10, additionalCriteria: []};
    setCriteria(newCriteria);
  };

  const handleChange = (updateData) => {
    const name = Object.keys(updateData)[0];
    const values = updateData[name];
    const nameArray = name.split('.');
    let inputValues = '';

    switch (name) {
    case 'keywords':
      inputValues = values === null ? '' : values;
      break;
    case 'maxDistance':
      inputValues = values;
      break;
    default:
      inputValues = (values || values === 0 || values === false) ? values : null;
      break;
    }

    let updateCriteria = cloneDeep(criteria);

    switch (nameArray.length) {
    case 2:
      // pick additional search attribute
      // additionalCriteria[index].name - depth 2
      if(values === 'activity') {
        const defaultFilterTypes = {option: searchConst.searchOptions.activityDefaultValue.id, value: []};
        const defaultDateRange = {option: searchConst.searchOptions.dateDefaultValue.id, value: []};
        const defaultText= {option: searchConst.searchOptions.textDefaultValue.id, value: []};
        set(updateCriteria, nameArray[0], {name: inputValues, criteria: {filterTypes: defaultFilterTypes, dateRange: defaultDateRange, text: defaultText}});
      } else {
        // if search option field is hide, then need to set default value
        const matched = additionalFieldMap[inputValues];
        const defaultCriteria =  matched && matched.hideSearchOptionField ? {option: matched.searchOptions[0].id} : {};
        set(updateCriteria, nameArray[0], {name: inputValues, criteria: defaultCriteria});
      }
      break;
    case 1:
    case 3:
    case 4:
      // 1 - keywords, location, maxDistance
      // 3 - additional search option and values ( except for activity)
      // 4 - additional search option and values ( only for activity)
      // take care of single value fields since api only accept array value
      if (name.endsWith('criteria.value') && inputValues && !isArray(inputValues)) {
        inputValues = [inputValues];
      }
      set(updateCriteria, name, inputValues);
      break;
    default:
      break;
    }

    setCriteria({...criteria, ...updateCriteria});
  };

  const handleAddRemove = (newAdditionalCriteria) => {
    setCriteria({...criteria, 'additionalCriteria': newAdditionalCriteria});
  };

  const searchCallback = function() {
    const searchPath = searchConst.entityMap[entityType.toLowerCase()];

    if (window.location.pathname !== '/' + searchPath) {
      history.push('/' + searchPath);
    }
    modalCancel();

    if (closeDrawer) {
      closeDrawer();
    }
  };

  const handleSearch = (event) => {
    event.preventDefault();
    try {
      let form = event.currentTarget;
      setFormValidated(true);

      const saveAsNew = cloneDeep(criteria.saveAsNew);
      delete criteria.saveAsNew;
      const cleanCriteria = prepareForApiSearch(criteria);
      const searchParameters = prepareForAmplitudeEventProperties({...cleanCriteria, entityType});
      if (form.checkValidity() === true && document.querySelectorAll('.rbt-input.is-invalid').length === 0) {
        if (saveAsNewCallback && saveAsNew) {
          saveAsNewCallback(criteria);
        } else if (saveCallback) {
          saveCallback(cleanCriteria);
        } else {
          const lastSortByFromSetting = lastSortBy(entityType);

          if(!isEmpty(lastSortByFromSetting)) {
            criteria.sortBy = lastSortByFromSetting;
          }
          dispatch(searchEntities({criteria, offset: 0, maxResults: searchConst.maxResults, entityType}, searchCallback));
          if (modalTitle.startsWith('Modify')) {
            window.amplitudeLogEvent('search', {
              action: entityType + ' modify search',
              object: entityType,
              ...searchParameters,
              timer: 'end',
              ...currentViewType
            });
          } else {
            dispatch(setSavedSearch(entityType, {}));

            window.amplitudeLogEvent('search', {
              action: entityType + ' search',
              object: entityType,
              ...searchParameters,
              timer: 'end',
              ...currentViewType
            });
          }

          resetFiltersAndSelected();
        }
      } else {
        form.classList.add('was-validated');
      }
    } catch (err) {
      console.log(err);
    }
  };

  const cancelSearchModal = () => {
    if (initialCriteria && isEmpty(initialCriteria.additionalCriteria)) {
      initialCriteria.additionalCriteria = [{name: ''}];
    }
    setCriteria(initialCriteria ? initialCriteria : {'maxDistance': searchConst.maxDistance, 'additionalCriteria': [{name: ''}]});

    modalCancel();
  };

  return (
    <>
      {modalVisible ?
        <AtsModal id='search-modal' modalTitle={modalTitle} modalSize='lg' modalVisible={modalVisible} modalCancel={cancelSearchModal}>
          <form name='search-modal' noValidate onSubmit={handleSearch} autoComplete='off'>
            <div className='value pb-3 light-grey-text' style={{'marginTop': '-20px'}}>{utils.camelize(entityType)} Search</div>
            <Container className='px-0'>
              <Row>
                <Col>
                  <LocationSearchForm criteria={criteria} entityType={entityType} handleChange={handleChange} />
                  <Card>
                    <Card.Header>Filters</Card.Header>
                    <Card.Body>
                      <AdditionalCriteriaItems
                        entityType={entityType}
                        handleChange={handleChange}
                        additionalCriteria={criteria.additionalCriteria}
                        formValidated={formValidated}
                        handleAddRemove={handleAddRemove}
                      />
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col className='d-flex justify-content-end pt-5'>
                  {saveCallback || saveAsNewCallback ?
                    <>
                      <Button type='button' id='clear-all-search' variant='mr-4 btn-secondary' click={cancelSearchModal}>
                        Cancel
                      </Button>

                      {saveAsNewCallback ?
                        <Button type='submit' id='submit-new-search' variant='btn-primary mr-4 ats-md-btn' click={() => criteria.saveAsNew = true}>
                          Save As New
                        </Button>
                        :
                        ''
                      }

                      {saveCallback ?
                        <Button type='submit' id='submit-search' variant='btn-primary ats-md-btn' click={() => criteria.saveAsNew = false}>
                          Save
                        </Button>
                        :
                        ''
                      }
                    </>
                    :
                    <>
                      <Button type='button' id='clear-all-search' variant='mr-4 btn-secondary' click={clearAll}>
                        Clear All
                      </Button>

                      <Button type='submit' id='submit-search' variant='btn-primary ats-md-btn'>
                        Search
                      </Button>
                    </>
                  }
                </Col>
              </Row>
            </Container>
          </form>
        </AtsModal>
        :
        ''
      }
    </>
  );
}

Search.propTypes = {
  history: PropTypes.object,
  entityType: PropTypes.string.isRequired,
  modalTitle: PropTypes.string.isRequired,
  modalVisible: PropTypes.bool.isRequired,
  modalCancel: PropTypes.func.isRequired,
  search: PropTypes.func,
  setSavedSearch: PropTypes.func,
  saveCallback: PropTypes.func,
  saveAsNewCallback: PropTypes.func
};
