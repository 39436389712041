import React from 'react';
import PropTypes from 'prop-types';

export default function Header(props) {
  return (
    <div className='mt-4 mb-2 px-4'>
      <i className='fa fa-chevron-left cursor' onClick={() => props.closeDrawer(true)} />
      { props.children? props.children :<span className='title'>{props.drawer.title}</span> }
    </div>
  );
};

Header.propTypes = {
  drawer: PropTypes.object.isRequired,
  closeDrawer: PropTypes.func.isRequired
};
